import React from 'react';
import EditButton from '../EditButton';
import { useSelector } from 'react-redux';

const ViewDepartment = ({setUpdateCourses,handleButtonClick}) => {
  const allCourse = useSelector(state => state.allCourses);

  const handleEditCourse = (courses) => {
    setUpdateCourses(courses);
    handleButtonClick('create');
  };

  return (
    <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
    <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
        <thead className=''>
          <tr className='text-sm font-semibold'>
            <th className="px-4 py-3 text-center border border-gray-400">Sr.</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">Course Name</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Credit Hour</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Department</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Course Code</th>
            <th className="px-4 py-3 text-center border  border-gray-400">Status</th>
            <th className="px-4 py-3 text-center border  border-gray-400 ">Actions</th>
          </tr>
        </thead>
        <tbody>
        {allCourse && allCourse?.map((courses, index) => (
              <tr key={courses._id}
              className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}
            >
              <td className="px-4 py-3 text-center ">{index + 1}</td>
              <td className="px-4 py-3 truncate text-left">{courses.courseName}</td>
              <td className="px-4 py-3 text-left">{courses.credithour}</td>
              <td className="px-4 py-3 text-left">{courses.departmentcode}</td>
              <td className="px-4 py-3 text-left">{courses.courseCode}</td>
              <td className={`px-4 py-3 border-b text-center ${courses.status ? "text-green-600" : "text-red-600"}`}> {courses.status ? "Active" : "In-Active"} </td>
              <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center  ">
                <button onClick={() => handleEditCourse(courses)}>
                  <EditButton />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewDepartment