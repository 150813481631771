import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Avatar from '../../assets/images/avatar.png';
import { useSelector, useDispatch } from 'react-redux';
import { setUserInfo } from '../../state/action-creators';
import config from '../../config';
import Apis from '../../Apis';
import ToogleBtn from '../ToogleBtn';
import {MessageByName} from "./Dashboard/Modals/AddNotification"
import { toast } from "react-hot-toast";

const Header = () => {
  const backendBaseUrl = config.backendLocalhost;
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuLinks = ['Profile', 'Audit Trail', 'Settings', 'Logout'];
  const dispatch = useDispatch();
  const configName = useSelector(state => state.configName);
  const ResponseMessages = useSelector(state => state.responseMessage);


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const userInfo = useSelector(state => state.userInfo);

  const Logout = async (ID) => {
    try {
      const Logout = await Apis.Logout(ID);
      const ResponseMsg = MessageByName(Logout,ResponseMessages);
      toast.success(ResponseMsg);
      Apis.addAuditTrail(userInfo._id, "66339b5bf2d22493c51a5006", "");
    } catch (error) {
      console.error("Error! Logout log's can't be login", error);
    }
  };

  const handleMenuClick = (menuItem) => {
    if (menuItem === 'Logout') {
      Logout(userInfo.Id);
      localStorage.clear();
      dispatch(setUserInfo([]));
      navigate("/");
    }
    else if (menuItem === 'Profile') {
      navigate(`/${userInfo.role}/profile`);
    }
    else if (menuItem === 'Audit Trail') {
      navigate(`/${userInfo.role}/AuditTrail`);
    }
    toggleDropdown()
  };

  return (
    <header className='w-full h-[10vh] flex bg-gray-100 rounded-b-[1vw]'>
      <div className='w-[10%] h-full p-[1vw] flex justify-center items-center'>
        <img
          src={`${backendBaseUrl}/ConfigurtionFiles/${configName[0]?.nameConfiguration?.Logo}`}
          alt=''
          className='w-[8vw] h-[4vw]'
        />
      </div>
      <div className='w-[60%] h-full flex flexcol justify-center items-center gap-x-[1vw]'>
        <p className='text-[1.4vw]'>
          {configName[0]?.nameConfiguration.Title}</p>
        <Link
          to="/FacultyDashboard"
          className='text-[1vw] bg-blue-900 text-white p-[0.5vw] rounded-[0.5vw]'>
          <span className='font-semibold'>LMS - </span>
          Faculty Dashboard</Link>
          <ToogleBtn/>
      </div>
      <div className='w-[30%] h-full flex justify-end items-center'>
        <div className="flex flex-col items-end justify-center text-[1vw]">
          <div className=" font-semibold">{userInfo.firstName + " " + userInfo.lastName}</div>
          <div className="font-normal text-gray-500">{userInfo.email}</div>
        </div>
        <div className='relative flex items-start justify-center w-auto h-full'>
          <img
            className="w-[10vh] h-[10vh] p-[0.5vw] hover:p-[0.3vw] cursor-pointer animate rounded-full"
            src={Avatar}
            alt=""
            onClick={toggleDropdown}
          />
        </div>
        {isOpen && (
          <div className={`z-10 absolute right-[2vw] top-[10vh] w-[16vw] mt-[0.1vh] text-black bg-gray-100 rounded-lg shadow-2xl shadow-black animate ${isOpen ? "scale-100" : "scale-0"}`}>
            <ul className='p-[0.5vw] divide-y divide-slate-400'>
              {menuLinks.map((menuItem, index) => (
                <li
                  key={index}
                  className='p-2 cursor-pointer hover:bg-gray-200 hover:text-blue-600 hover:underline'
                  onClick={() => handleMenuClick(menuItem)}
                >
                  {menuItem}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;