import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux';
import Apis from '../../../../Apis';

export function extractCourseCode(courseInfo) {
  const parts = courseInfo.split(' ');
  const codePart = parts[parts.length - 1];
  const courseCode = codePart.substring(1, codePart.length - 1);

  return courseCode;
}

const CoursePlan = ({ openCoursePlan, CoursePlanModalHandler, courseName }) => {
  const [maxScreen, setMaxScreen] = useState(false);
  const [unique, setunique] = useState([]);
  const userInfo = useSelector(state => state.userInfo);

  const maxScreenHandler = () => {
    setMaxScreen(!maxScreen);
  }
  const fetchCoursePlan = async () => {
    try {
      const CoursePlan = await Apis.getCoursePlan(userInfo.studentDetails.class, extractCourseCode(courseName));
      if (CoursePlan.length === 0) {
        setunique([]);
      }
      else {
        setunique(CoursePlan);
      }
    } catch (error) {
      console.error('Error fetching course Plan:', error);
    }
  };

  const Closebtn = () => {
    CoursePlanModalHandler([]);
    setunique([]);
  }

  useEffect(() => {
    fetchCoursePlan()
  }, [userInfo.studentDetails.class, courseName]);



  return (
    <div
      className={`absolute flex-1 duration-300 rounded-lg bg-white z-20
        ${openCoursePlan
          ? "scale-100 duration-300"
          : "scale-0 -z-10"
        }
        ${maxScreen
          ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
          : "w-[90vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[5vw]"
        }
      `}
    >
      <header
        className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
         ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
          }`}
      >
        <div>
          <h1 className="xl:text-[2vw] font-semibold">Course Plan Detail</h1>
        </div>
        <div className="flex gap-x-[1vw]">
          <button className='w-3 h-3 duration-300 hover:scale-110'>
            <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
          </button>
          <button className="w-3 h-3 duration-300 hover:scale-110" onClick={() => maxScreenHandler()}>
            {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
              <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
          </button>
          {/* Provide the implementation for AddProjectHandler */}
          <button className="w-3 h-3 duration-300 hover:scale-110" onClick={() => Closebtn()}>
            <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
          </button>
        </div>
      </header>
      <div className={`w-full flex flex-col
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
        <div className='h-[15%] w-full p-[1vw] flex justify-start items-center'>
          <div className='flex items-center justify-center w-full'>
            <p className='xl:text-[1.5vw] text-black'>
              Course:
              <span className='font-semibold ml-[0.5vw]'>
                {courseName}
              </span>
            </p>
          </div>
        </div>
        <div className='w-full h-[85%] p-[1vw] overflow-auto style-scrollbar'>
          <table className="min-w-full bg-white border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-auto">
            <thead>
              <tr>
                <th className="w-[5%] px-4 py-2 text-center border border-b border-gray-400">Sr.</th>
                <th className="w-[5%] px-2 py-2 text-left border border-b border-gray-400 whitespace-nowrap">Week No.</th>
                <th className="w-[70%] px-4 py-2 text-left border border-b border-gray-400">Topic</th>
                <th className="w-[5%] px-4 py-2 text-left border border-b border-gray-400">Type</th>
                <th className="w-[5%] px-4 py-2 text-left border border-b border-gray-400">CLO</th>
                <th className="w-[5%] px-4 py-2 text-left border border-b border-gray-400">Weightage</th>
                <th className="w-[5%] px-4 py-2 text-left border border-b border-gray-400">Status</th>
              </tr>
            </thead>
            <tbody className='relative'>
            {unique ?
              <>
              {openCoursePlan && unique[0]?.Detail && unique[0]?.Detail.map((val, index) => (
                <tr key={index} className={`text-sm font-normal divide-y divide-gray-200 ${val % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}>
                  <td className="px-4 py-2 text-center border-b">{index + 1}</td>
                  <td className="px-4 py-2 text-left border-b whitespace-nowrap">{val.weekNo}</td>
                  <td className="px-4 py-2 overflow-x-auto text-left border-b style-scrollbar">{val.topic}</td>
                  <td className="px-4 py-2 text-left border-b whitespace-nowrap">{val.type}</td>
                  <td className="px-4 py-2 text-left border-b whitespace-nowrap">{val.CLO}</td>
                  <td className="px-4 py-2 text-left border-b whitespace-nowrap">{val.weightage}</td>
                  <td className={`px-4 py-2 text-left border-b whitespace-nowrap ${val.status ? "text-green-800" : "text-red-800"}`}>{val.status ? "Active" : "In-Active"}</td>
                </tr>
              ))}
               </>
              :
              <tr role="status" className='absolute top-0 left-[50%]'>
                <svg aria-hidden="true" className="w-[20%] h-auto text-gray-300 animate-spin dark:text-gray-600 fill-blue-800" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
              </tr>
            }
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

export default CoursePlan;
