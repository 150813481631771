import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faExpand, faCompress } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators';
import ViewChatbox from '../../../../components2/Faculty/Pages/ChatBox/ViewChatbox';

const Chatbox = ({ ChatBoxDetailHandler }) => {
    const selectedActivity = useSelector(state => state.selectedActivity);
    const dispatch = useDispatch();
    const [maxScreen, setMaxScreen] = useState(false);
    const userInfo = useSelector(state => state.userInfo);
    const allStudentInfo = useSelector(state => state.allStudentInfo);

    const handleActivityList = (value) => {
        dispatch(setSelectedActivity(value));
    };

    const [selectedClass, setSelectedClass] = useState('');
    const [classes, setClasses] = useState([]);

    useEffect(() => {
        const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map(info => info.class)));
        setClasses(uniqueClasses);
    }, [userInfo.facultyDetails]);

    const filterStudentsByClass = (targetClass) => {
        return allStudentInfo.filter(student => student.role === 'student' && student.studentDetails && student.studentDetails.class === targetClass);
    };

    // Example usage:
    const [classToFilter, setClassToFilter] = useState([]);

    useEffect(() => {
        setClassToFilter(filterStudentsByClass(selectedClass));
    }, [selectedClass]);

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
        if (maxScreen) {
            handleActivityList("Chatbox")
        }
        else {
            handleActivityList("")
        }

    };


    return (
        <div className={`${maxScreen ? "fixed w-full h-full top-0 left-0 z-20" : "w-full h-[5vh] rounded-[1vw] h-auto"} text-white bg-gray-200`}>
            <div
                className={`w-full h-[5vh] bg-gray-700 flex gap-x-[0.4vw] px-4 justify-between items-center cursor-pointer
    ${selectedActivity === "Chatbox" ? `${maxScreen ? "" : "rounded-t-lg"}` : "rounded-lg"}
    `}
                onClick={!maxScreen ? (() => handleActivityList("Chatbox")) : undefined}
            >
                <div className='flex gap-x-[1vw] justify-start items-center'>
                    <FontAwesomeIcon
                        className={`w-6 h-6 duration-300 ${selectedActivity === "Chatbox" ? "rotate-180" : "rotate-0"}`}
                        icon={faCaretDown}
                        style={{ color: '#fff' }}
                    />
                    <p className='text-lg'>Chat Box</p>
                </div>
                <div>
                    <button className="w-6 h-6 hover:scale-110 duration-300 text-white outline-none" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full " icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full " icon={faExpand} />}
                    </button>
                </div>
            </div>

            {selectedActivity === "Chatbox" ?
                <ViewChatbox
                    ChatBoxDetailHandler={ChatBoxDetailHandler} />
                : <></>
            }
        </div >
    )
}

export default Chatbox