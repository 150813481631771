import React, { useState, useEffect } from 'react';
import Apis from '../Apis';
import Dashboardmls from "../components2/Faculty/Dashboard"
import Header from '../components/FacultyComp/Header';
import Dashboard from '../components/FacultyComp/Dashboard';
import AddLearningMaterialsModal from '../components/FacultyComp/Dashboard/Modals/AddLearningMaterialsModal';
import AddAssignment from '../components/FacultyComp/Dashboard/Modals/AddAssignment';
import AddQuiz from '../components/FacultyComp/Dashboard/Modals/AddQuiz';
import AddNotification from '../components/FacultyComp/Dashboard/Modals/AddNotification';
import AssignmentDetail from '../components/FacultyComp/Dashboard/ActivitiesDetail/AssignmentDetail';
import QuizDetail from '../components/FacultyComp/Dashboard/ActivitiesDetail/QuizDetail';
import ExamDetail from '../components/FacultyComp/Dashboard/ActivitiesDetail/ExamDetail'
import ChatBoxModal from '../components/FacultyComp/Dashboard/Modals/ChatBoxModal';
import CoursePlanModal from '../components/FacultyComp/Dashboard/Modals/CoursePlanModal';
import AddExam from '../components/FacultyComp/Dashboard/Modals/AddExam';
import AddSelfLearning from '../components/FacultyComp/Dashboard/Modals/AddSelfLearning';
import ViewReport from '../components/FacultyComp/Dashboard/Modals/ViewReport';
import { setUserInfo, setAllStudentInfo } from '../state/action-creators';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-hot-toast";
import Loading from "../assets/images/splashscreen.jpg"
import OnlineLectureModal from '../components/FacultyComp/Dashboard/Modals/OnlineLectureModal';
import OpenJoinMeetingModal from '../components/FacultyComp/Dashboard/Modals/OpenJoinMeetingModal';
import { setAssignmentDetails, setQuizDetails, setLearningDetails, setAllAttendance, setAllExam, setAllCoursePlan,setAllCourses } from '../state/action-creators';
import SelfLearningDetailView from '../components/FacultyComp/Dashboard/ActivitiesDetail/SelfLearningDetailView';
import SelfLearningViewStudents from '../components/FacultyComp/Dashboard/ActivitiesDetail/SelfLearningViewStudents';

const AdminDashboard = () => {
  const [openLearningMaterial, setOpenLearningMaterial] = useState(false);
  const [openOnlineLecture, setOpenOnlineLecture] = useState(false);
  const [openAssignmentModal, setOpenAssignmentModal] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [chatBoxDetail, setChatBoxDetail] = useState({ StudDetail: [] });
  const [openChatBoxModal, setOpenChatBoxModal] = useState(false);
  const [assignmentDetail, setAssignmentDetail] = useState({ assignDetail: [] });
  const [openAssignmentDetail, setOpenAssignmentDetail] = useState(false);
  const [quizDetail, setQuizDetail] = useState({ quizDetail: [] });
  const [examDetail, setExamDetail] = useState({ examDetail: [] });
  const [openQuizDetail, setOpenQuizDetail] = useState(false);
  const [openQuizModal, setOpenQuizModal] = useState(false);
  const [openExamDetail, setOpenExamDetail] = useState(false);
  const [openExamModal, setOpenExamModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState('');
  const [updateCoursePlan, setUpdateCoursePlan] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [openCoursePlanModal, setOpenCoursePlanModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [individualReportDetail, setindividualReportDetail] = useState([]);
  const [openJoinMeeting, setOpenJoinMeeting] = useState(false);
  const [openSelfLearningDetail, setOpenSelfLearningDetail] = useState(false);
  const [openSelfLearningModal, setOpenSelfLearningModal] = useState(false);
  const [SelfLearningDetail, setSelfLearningetail] = useState('');
  const [openSelfLearningStudent, setOpenSelfLearningStudent] = useState(false);
  const [studentsCompletedSelfLearning, setStudentsCompletedSelfLearning] = useState([]);

  const LearningMaterialHandler = (classes, course) => {
    if (course !== '' && classes !== '') {
      setOpenLearningMaterial(true);
      setSelectedClass(course);
      setSelectedCourse(classes);
    }
    else {
      if (!openLearningMaterial) {
        if (classes === '') {
          toast.error("Error! Select Class")
        }
        else if (course === '') {
          toast.error("Error! Select Course")
        }
        else {
          toast.error("Error! Connection problem")
        }
      }
      else {
        setOpenLearningMaterial(false);
      }
    }
  };
  const JoinMeetingHandler = (val) => {
    setOpenJoinMeeting(val)
  }
  const OnlineLectureHandler = (classes, course) => {
    if (course !== '' && classes !== '') {
      setOpenOnlineLecture(true);
      setSelectedClass(course);
      setSelectedCourse(classes);
    }
    else {
      if (!openOnlineLecture) {
        if (classes === '') {
          toast.error("Error! Select Class")
        }
        else if (course === '') {
          toast.error("Error! Select Course")
        }
        else {
          toast.error("Error! Connection problem")
        }
      }
      else {
        setOpenOnlineLecture(false);
      }
    }
  };

  const AssignmentModalHandler = (classes, course) => {
    if (course !== '' && classes !== '') {
      setOpenAssignmentModal(true);
      setSelectedClass(course);
      setSelectedCourse(classes);
    }
    else {
      if (!openAssignmentModal) {
        if (classes === '') {
          toast.error("Error! Select Class")
        }
        else if (course === '') {
          toast.error("Error! Select Course")
        }
        else {
          toast.error("Error! Connection problem")
        }
      }
      else {
        setOpenAssignmentModal(false);
      }
    }
  };

  const CoursePlanModalHandler = (course, classes) => {
    if (course !== '' && classes !== '') {
      setOpenCoursePlanModal(true);
      setSelectedClass(course);
      setSelectedCourse(classes);
    }
    else {
      if (!openCoursePlanModal) {
        if (classes === '') {
          toast.error("Error! Select Class")
        }
        else if (course === '') {
          toast.error("Error! Select Course")
        }
        else {
          toast.error("Error! Connection problem")
        }
      }
      else {
        setOpenCoursePlanModal(false);
      }
    }
  };

  const NotificationModalHandler = (classes, course) => {
    if (course !== '' && classes !== '') {
      setOpenNotificationModal(true);
      setSelectedClass(course);
      setSelectedCourse(classes);
    }
    else {
      if (!openNotificationModal) {
        if (classes === '') {
          toast.error("Error! Select Class")
        }
        else if (course === '') {
          toast.error("Error! Select Course")
        }
        else {
          toast.error("Error! Connection problem")
        }
      }
      else {
        setOpenNotificationModal(false);
      }
    }
  };

  const QuizModalHandler = (classes, course) => {
    if (course !== '' && classes !== '') {
      setOpenQuizModal(true);
      setSelectedClass(course);
      setSelectedCourse(classes);
    } else {
      if (!openQuizModal) {
        if (classes === '') {
          toast.error("Error! Select Class");
        } else if (course === '') {
          toast.error("Error! Select Course");
        } else {
          toast.error("Error! Connection problem");
        }
      } else {
        setOpenQuizModal(false);
      }
    }
  };

  const ExamModalHandler = (classes, course) => {
    if (course !== '' && classes !== '') {
      setOpenExamModal(true);
      setSelectedClass(course);
      setSelectedCourse(classes);
    } else {
      if (!openExamModal) {
        if (classes === '') {
          toast.error("Error! Select Class");
        } else if (course === '') {
          toast.error("Error! Select Course");
        } else {
          toast.error("Error! Connection problem");
        }
      } else {
        setOpenExamModal(false);
      }
    }
  };

  const AssignmentDetailHandler = (detail) => {
    setOpenAssignmentDetail(!openAssignmentDetail);
    setAssignmentDetail(detail);
  };

  const ChatBoxDetailHandler = (detail) => {
    setOpenChatBoxModal(!openChatBoxModal);
    setChatBoxDetail(detail);
  }
  const QuizDetailHandler = (detail) => {
    setOpenQuizDetail(!openQuizDetail);
    setQuizDetail(detail);
  };
  const ExamDetailHandler = (detail) => {
    setOpenExamDetail(!openExamDetail);
    setExamDetail(detail);
  };

  const ReportIndividualDetailHandler = (detail) => {
    setOpenReportModal(!openReportModal);
    setindividualReportDetail(detail);
  };

  const SelfLearningHandler = (classes, course) => {
    if (course !== '' && classes !== '') {
      setOpenSelfLearningModal(true);
      setSelectedClass(course);
      setSelectedCourse(classes);
    } else {
      if (!openSelfLearningModal) {
        if (classes === '') {
          toast.error("Error! Select Class");
        } else if (course === '') {
          toast.error("Error! Select Course");
        } else {
          toast.error("Error! Connection problem");
        }
      } else {
        setOpenSelfLearningModal(false);
      }
    }
  };
  const SelfLearningDetailHandler = (detail) => {
    setOpenSelfLearningDetail(!openSelfLearningDetail);
    setSelfLearningetail(detail);
  };
  const SelfLearningStudentHandler = (detail) => {
    setOpenSelfLearningStudent(!openSelfLearningStudent);
    setStudentsCompletedSelfLearning(detail);
  };

  const userInfo = useSelector(state => state.userInfo);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await Apis.getUserInfo();
        dispatch(setUserInfo(userInfo || []));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [dispatch]);

  const fetchData = async () => {
    try {
      const user = {
        userID:userInfo._id,
        userRole:userInfo.role,
      }
      const [assignments, quizzes, learnings, attendance, exams, coursePlan,courses,students] = await Promise.all([
        Apis.getAllAssignments(),
        Apis.getAllQuizzes(),
        Apis.fetchLearnings(),
        Apis.getAllAttendance(),
        Apis.getAllExam(),
        Apis.getAllCoursePlan(),
        Apis.getAllCourses("",user),
        Apis.getActiveStudents(),
      ]);
      dispatch(setAssignmentDetails(assignments || []));
      dispatch(setQuizDetails(quizzes || []));
      dispatch(setLearningDetails(learnings || []));
      dispatch(setAllAttendance(attendance || []));
      dispatch(setAllExam(exams || []));
      dispatch(setAllCoursePlan(coursePlan || []));
      dispatch(setAllCourses(courses || []));
      dispatch(setAllStudentInfo(students || []));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [userInfo]);

  return (
    <>
      {userInfo.length === 0 ? (
        <div>
          <img
            alt=''
            src={Loading}
            className='w-[100vw] h-[100vh] cover-full'
          />
        </div>

      ) : (
        <div className='w-[100%] h-[100vh] bg-blue-950'>
          {userInfo.Theme ?
            <Dashboardmls
              LearningMaterialHandler={LearningMaterialHandler}
              AssignmentModalHandler={AssignmentModalHandler}
              NotificationModalHandler={NotificationModalHandler}
              QuizModalHandler={QuizModalHandler}
              ExamModalHandler={ExamModalHandler}
              AssignmentDetailHandler={AssignmentDetailHandler}
              QuizDetailHandler={QuizDetailHandler}
              ExamDetailHandler={ExamDetailHandler}
              ChatBoxDetailHandler={ChatBoxDetailHandler}
              CoursePlanModalHandler={CoursePlanModalHandler}
              setOpenCoursePlanModal={setOpenCoursePlanModal}
              setUpdateCoursePlan={setUpdateCoursePlan}
              ReportIndividualDetailHandler={ReportIndividualDetailHandler}
            />
            :
            <><Header />
              <Dashboard
                LearningMaterialHandler={LearningMaterialHandler}
                AssignmentModalHandler={AssignmentModalHandler}
                NotificationModalHandler={NotificationModalHandler}
                QuizModalHandler={QuizModalHandler}
                ExamModalHandler={ExamModalHandler}
                AssignmentDetailHandler={AssignmentDetailHandler}
                QuizDetailHandler={QuizDetailHandler}
                ExamDetailHandler={ExamDetailHandler}
                ChatBoxDetailHandler={ChatBoxDetailHandler}
                CoursePlanModalHandler={CoursePlanModalHandler}
                setOpenCoursePlanModal={setOpenCoursePlanModal}
                setUpdateCoursePlan={setUpdateCoursePlan}
                ReportIndividualDetailHandler={ReportIndividualDetailHandler}
                OnlineLectureHandler={OnlineLectureHandler}
                JoinMeetingHandler={JoinMeetingHandler}
                SelfLearningHandler={SelfLearningHandler}
                SelfLearningDetailHandler={SelfLearningDetailHandler}
                SelfLearningStudentHandler={SelfLearningStudentHandler}
              /></>}

          <AddLearningMaterialsModal
            openLearningMaterial={openLearningMaterial}
            LearningMaterialHandler={LearningMaterialHandler}
            selectedCourse={selectedCourse}
            selectedClass={selectedClass}
          />
          <AddAssignment
            openAssignmentModal={openAssignmentModal}
            AssignmentModalHandler={AssignmentModalHandler}
            selectedCourse={selectedCourse}
            selectedClass={selectedClass}
          />

          <CoursePlanModal
            openCoursePlanModal={openCoursePlanModal}
            CoursePlanModalHandler={CoursePlanModalHandler}
            selectedCourse={selectedCourse}
            selectedClass={selectedClass}
            setUpdateCoursePlan={setUpdateCoursePlan}
            updateCoursePlan={updateCoursePlan}
          />
          <AddNotification
            openNotificationModal={openNotificationModal}
            NotificationModalHandler={NotificationModalHandler}
            selectedCourse={selectedCourse}
            selectedClass={selectedClass}
          />
          <AddQuiz
            openQuizModal={openQuizModal}
            QuizModalHandler={QuizModalHandler}
            selectedCourse={selectedCourse}
            selectedClass={selectedClass}
          />
          <AddExam
            openExamModal={openExamModal}
            ExamModalHandler={ExamModalHandler}
            selectedCourse={selectedCourse}
            selectedClass={selectedClass}
          />
          <AssignmentDetail
            AssignmentDetailHandler={AssignmentDetailHandler}
            openAssignmentDetail={openAssignmentDetail}
            assignmentDetail={assignmentDetail}
          />
          <QuizDetail
            QuizDetailHandler={QuizDetailHandler}
            openQuizDetail={openQuizDetail}
            quizDetail={quizDetail}
          />
          <ExamDetail
            ExamDetailHandler={ExamDetailHandler}
            openExamDetail={openExamDetail}
            examDetail={examDetail}
          />
          <ChatBoxModal
            openChatBoxModal={openChatBoxModal}
            ChatBoxDetailHandler={ChatBoxDetailHandler}
            chatBoxDetail={chatBoxDetail}
          />
          <ViewReport
            individualReportDetail={individualReportDetail}
            openReportModal={openReportModal}
            ReportIndividualDetailHandler={ReportIndividualDetailHandler}
          />
              <OnlineLectureModal
            openOnlineLecture={openOnlineLecture}
            OnlineLectureHandler={OnlineLectureHandler}
            selectedCourse={selectedCourse}
            selectedClass={selectedClass}
          />
          <OpenJoinMeetingModal
          openJoinMeeting={openJoinMeeting}
          JoinMeetingHandler={JoinMeetingHandler}
          />
          <AddSelfLearning
             openSelfLearningModal={openSelfLearningModal}
             setOpenSelfLearningModal={setOpenSelfLearningModal}
             selectedCourse={selectedCourse}
             selectedClass={selectedClass}
          />
          <SelfLearningDetailView
          SelfLearningDetailHandler={SelfLearningDetailHandler}
          SelfLearningDetail={SelfLearningDetail}
          openSelfLearningDetail={openSelfLearningDetail}
          />
          <SelfLearningViewStudents
          openSelfLearningStudent={openSelfLearningStudent}
          SelfLearningStudentHandler={SelfLearningStudentHandler}
          studentsCompletedSelfLearning={studentsCompletedSelfLearning}
          />
                    

          {openLearningMaterial || openCoursePlanModal || openAssignmentModal || openNotificationModal || openQuizModal || openExamModal || openAssignmentDetail || openQuizDetail || openExamDetail || openChatBoxModal || openReportModal || openOnlineLecture || openJoinMeeting || openSelfLearningModal || openSelfLearningDetail || openSelfLearningStudent? (
            <div
              className={`fixed inset-0 opacity-80 z-30  transition-opacity`}
              style={{ backgroundColor: "#202020" }}
            ></div>
          ) : (
            ""
          )}

        </div>
      )}
    </>


  )
}
export default AdminDashboard