import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";

const Assignment = ({ openAssignment, AssignmentModalHandler, courseName, courseDetail }) => {
    const [maxScreen, setMaxScreen] = useState(false);

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    }

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-20
        ${openAssignment
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
        ${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[80vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[10vw]"
                }
      `}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
         ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="xl:text-[2vw] font-semibold">Assignments Detail</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-3 h-3 duration-300 hover:scale-110'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
                    </button>
                    <button className="w-3 h-3 duration-300 hover:scale-110" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    {/* Provide the implementation for AddProjectHandler */}
                    <button className="w-3 h-3 duration-300 hover:scale-110" onClick={() => AssignmentModalHandler([])}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
                <div className='h-[15%] w-full p-[1vw] flex justify-center items-center'>
                    <p className='xl:text-[1.5vw] text-black'>
                        Course:
                        <span className='font-semibold ml-[0.5vw]'>
                            {courseName}
                        </span>
                    </p>
                </div>
                <div className='w-full h-[85%] p-[1vw] overflow-auto style-scrollbar'>
                    <table className="min-w-full bg-white border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
                        <thead>
                            <tr>
                                <th className="px-4 py-2 text-center border border-b border-gray-400">Sr.</th>
                                <th className="px-4 py-2 text-center border border-b border-gray-400 whitespace-nowrap">No.</th>
                                <th className="px-4 py-2 text-left border border-b border-gray-400 whitespace-nowrap">Assigned Date</th>
                                <th className="px-4 py-2 text-left border border-b border-gray-400 whitespace-nowrap">Due Date</th>
                                <th className="px-4 py-2 text-left border border-b border-gray-400 whitespace-nowrap">Submitted Date-Time</th>
                                <th className="px-4 py-2 text-left border border-b border-gray-400">Obtained</th>
                                <th className="px-4 py-2 text-left border border-b border-gray-400 whitespace-nowrap">Total Marks</th>
                                <th className="px-4 py-2 text-left border border-b border-gray-400 whitespace-nowrap">Result (%)</th>
                                <th className="px-4 py-2 text-left border border-b border-gray-400">Weightage</th>
                                <th className="px-4 py-2 text-left border border-b border-gray-400 whitespace-nowrap">Weighted Marks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {openAssignment && courseDetail && courseDetail.AssignmentDetail.map((assignment, index) => (
                                <tr key={index}
                                    className={`${(assignment.obtainedMarks / assignment.totalMarks) * 100 < 40 ? 'bg-red-200' : 'bg-green-200'}`}
                                >
                                    <td className="px-4 py-2 text-center border-b">{index + 1}</td>
                                    <td className="px-4 py-2 text-center border-b">{assignment.No}</td>
                                    <td className="px-4 py-2 text-left border-b">{assignment.AssignedDate}</td>
                                    <td className="px-4 py-2 text-left border-b whitespace-nowrap">{assignment.DueDate}</td>
                                    <td className="px-4 py-2 text-left border-b whitespace-nowrap">{assignment.SubmittedDate_Time}</td>
                                    <td className="px-4 py-2 text-left border-b">{assignment.Obtained}</td>
                                    <td className="px-4 py-2 text-left border-b">{assignment.TotalMarks}</td>
                                    <td className="px-4 py-2 text-left border-b">{assignment && `${((assignment.Obtained / assignment.TotalMarks) * 100).toFixed(0)}%`}</td>
                                    <td className="px-4 py-2 text-left border-b">{assignment.weightage}</td>
                                    <td className="px-4 py-2 text-left border-b">{assignment.weightedMarks}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Assignment;
