// reducers/FacultyActivityListToggle.js
import { SET_SubmittedExam} from '../action-creators/ActionTypes'; // Update import statement

const SubmittedExam = (state = "", action) => { // Rename the reducer function
    switch (action.type) {
        case SET_SubmittedExam: // Update action type
            return state === action.payload ? "" : action.payload;
        default:
            return state;
    }
};

export default SubmittedExam; // Update export statement
