import React, { useEffect, useState,useRef } from 'react';
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import Logo from '../../../../assets/images/LogoLCWU.png';


const Item = ({ department, index }) => (
    <View style={styles.itemContainer}>
        <View style={styles.header}>
            <View style={styles.startHeader}>
                <Text style={styles.subHeading}>{index + 1}.  {department.deptCode}</Text>
            </View>
            <View style={styles.middleHeader}>
                <Text style={styles.subHeading}>{department.department}</Text>
            </View>
            <View style={styles.endHeader}>
                <Text style={styles.subHeading}>Status: {department.status ? 'Active' : 'Inactive'}</Text>
            </View>
        </View>
        <View style={styles.containerBody}>
            <View style={styles.containerFirst}>
                <View style={styles.textAlign}>
                    <Text style={styles.paraText}>1. Contact: </Text>
                    {department.contact && <Text style={styles.contentText}>{department.contact}</Text>}
                </View>
                <View style={styles.textAlign}>
                    <Text style={styles.paraText}>2. Email: </Text>
                    {department.email && <Text style={styles.contentText}>{department.email}</Text>}
                </View>
            </View>
            <View style={styles.containerFirst}>
                <View style={styles.textAlign}>
                    <Text style={styles.paraText}>3. Website: </Text>
                    {department.website && <Text style={styles.contentText}>{department.website}</Text>}
                </View>
                <View style={styles.textAlign}>
                    <Text style={styles.paraText}>4. Location: </Text>
                    {department.location && <Text style={styles.contentText}>{department.location}</Text>}
                </View>
            </View>
            <View style={styles.containerThird}>
                <Text style={styles.paraText}>5. Address: </Text>
                {department.address && <Text style={styles.contentText}>{department.address}</Text>}
            </View>
        </View>
    </View>
);

const getCurrentDateTime = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
    const formattedTime = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    return { date: formattedDate, time: formattedTime };
};

// const PageFooter = ({ pageNumber, totalPages }) => (
//     <View style={styles.footer}>
//         <Text style={styles.footerText}>{pageNumber} {totalPages}</Text>
//     </View>
// );
const PDFDocument = ({ data }) => {
    const { date, time } = getCurrentDateTime();
    const pageCount = useRef(0);

            return (
                <Document>
                <Page key={0} style={styles.page} size="A4" wrap>
                    <View style={styles.headerContainer}>
                        <View style={styles.logoContainer}>
                            <Image style={styles.logo} src={Logo} />
                        </View>
                        <View style={styles.middleHeadings}>
                            <Text style={styles.heading}>Lahore College for Women University</Text>
                            <Text style={styles.subHeading}>Department List</Text>
                        </View>
                        <View style={styles.dateTimeContainer}>
                            <Text style={styles.subHeading}>{date}</Text>
                            <Text style={styles.subHeading}>{time}</Text>
                        </View>
                    </View>
                    {data.map((department, pageIndex) => (
                       <view>
                         <View key={pageIndex}>
                            <View style={styles.departmentsContainer}>
                                <Item department={department} index={pageIndex} />
                            </View>
                        </View>
                     {/* {pageIndex%4 === 0 && pageIndex !== 0  &&
                    // <PageFooter pageNumber={`Page ${Math.round((pageIndex + 1)/5)}`} totalPages={`of ${Math.round((data.length)/5)}`} />
} */}
                       </view> 
                        ))}
                </Page>
            </Document>
            
            );
        };
        

const styles = StyleSheet.create({
    page: {
        padding: 20,
        backgroundColor: '#ffffff',
    },
    headerContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    logoContainer: {
        width: '20%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    logo: {
        width: 110,
        height: 70,
    },
    middleHeadings: {
        width: '60%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dateTimeContainer: {
        width: '20%',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    heading: {
        fontSize: 16,
        fontWeight: '800',
    },
    subHeading: {
        fontSize: 12,
        fontWeight: '600',
    },
    paraText: {
        fontSize: 10,
        fontWeight: '800',
    },
    departmentsContainer: {
        marginTop: 20,
    },
    itemContainer: {
        marginBottom: 20,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        padding: 10,
        backgroundColor: '#f2f2f2',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    startHeader: {
        width: '20%',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    middleHeader: {
        width: '60%',
        alignItems: 'start',
        justifyContent: 'center',
    },
    endHeader: {
        width: '20%',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    containerBody: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '5px',
        padding: '10px',
    },
    containerFirst: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    textAlign: {
        width: '50%',
        flexDirection: 'row',
        rowGap: '5px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    contentText: {
        fontSize: 8,
        fontWeight: '400',
        textAlign: 'left',
    },
    containerThird: {
        flexDirection: 'row',
        rowGap: '5px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    footerText: {
        fontSize: 10,
    },
});

export default PDFDocument;
