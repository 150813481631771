import React, { useState, useEffect } from 'react'
import { setAllDept } from '../../../state/action-creators';
import { useDispatch, useSelector } from 'react-redux';
import Apis from '../../../Apis';
import CheckBoxesList from './Dashboard/CheckBoxesList';
import Cards from './Dashboard/Cards';
import BarGraph from './Dashboard/BarGraph';
import PieCharts from './Dashboard/PieCharts';
import axios from 'axios';
import config from '../../../config';

const Dashboard = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.userInfo);
    const UserRole = userInfo.role;
    const backendBaseUrl = config.backendLocalhost;

    const [dashboard, setDashboard] = useState({
        No_Stud: {
            Total: 0,
            Active: 0
        },
        No_Faculty: {
            Total: 0,
            Active: 0
        },
        ELearning_Material: {
            Total: 0,
            EBooks: 0,
            Audio: 0,
            Video: 0
        },
        studentsPerCourse: [],
        facultyCourses: []
    });

    const [dashboardValues, setDashboardValues] = useState({
        selectedDept: [],
        selectedProg: [],
        selectedCourse: [],
    });
    const [allDepartment, setAllDepartment] = useState([]);
    const [allCourses, setAllCourse] = useState([]);
    const [allPrograms, setAllPrograms] = useState([]);
    const [loadingDataToogle, setLoadingDataToogle] = useState({
        fetchDept: false,
        fetchProg: false,
        fetchCourse: false,
    });

    const fetchDepartments = async () => {
        setLoadingDataToogle(prevState => ({
            ...prevState,
            fetchDept: false
        }));
        try {
            const user = {
                userID:userInfo._id,
                userRole:userInfo.role,
              }
            const AllDepartment = await Apis.getAllDepartment("",user);
            dispatch(setAllDept(AllDepartment || []));
            setAllDepartment(AllDepartment);
            setLoadingDataToogle(prevState => ({
                ...prevState,
                fetchDept: true
            }));
        } catch (error) {
            console.error('Error fetching departments:', error.message);
            setLoadingDataToogle(prevState => ({
                ...prevState,
                fetchDept: true
            }));
        }
    };
    const fetchProgram = async () => {
        setLoadingDataToogle(prevState => ({
            ...prevState,
            fetchProg: false
        }));
        try {
            const response = await axios.get(`${backendBaseUrl}/executiveDashboard/getProgram`, {
                params: dashboardValues
            });
            const AllProgram = response.data;
            setAllPrograms(AllProgram);
            setLoadingDataToogle(prevState => ({
                ...prevState,
                fetchProg: true
            }));
        } catch (error) {
            console.error('Error fetching Program:', error.message);
            setLoadingDataToogle(prevState => ({
                ...prevState,
                fetchProg: true
            }));
        }
    };
    const fetchCourses = async () => {
        setLoadingDataToogle(prevState => ({
            ...prevState,
            fetchCourse: false
        }));
        try {
            const response = await axios.get(`${backendBaseUrl}/executiveDashboard/getCourse`, {
                params: dashboardValues
            });
            const AllCourses = response.data;
            setAllCourse(AllCourses);
            setLoadingDataToogle(prevState => ({
                ...prevState,
                fetchCourse: true
            }));
        } catch (error) {
            console.error('Error fetching Courses:', error.message);
            setLoadingDataToogle(prevState => ({
                ...prevState,
                fetchCourse: true
            }));
        }
    };

    useEffect(() => {
        fetchDepartments();
        setDashboardValues(prevState => ({
            ...prevState,
            selectedDept: []
        }));
    }, []);
    useEffect(() => {
        fetchProgram();
        setDashboardValues(prevState => ({
            ...prevState,
            selectedProg: []
        }));
    }, [dashboardValues.selectedDept]);
    useEffect(() => {
        fetchCourses();
        setDashboardValues(prevState => ({
            ...prevState,
            selectedCourse: []
        }));
    }, [dashboardValues.selectedProg]);

    const handleDepartmentSelection = (selectedDepartments) => {
        setDashboardValues((prevState) => ({
            ...prevState,
            selectedDept: selectedDepartments,
        }));
    };

    const handlProgramSelection = (selectedPrograms) => {
        setDashboardValues((prevState) => ({
            ...prevState,
            selectedProg: selectedPrograms,
        }));
    };

    const handleCourseSelection = (selectedCourses) => {
        setDashboardValues((prevState) => ({
            ...prevState,
            selectedCourse: selectedCourses,
        }));
    };
    
    const GetDashboardValues = async (dashboardValues) => {
        try {
            const response = await axios.get(`${backendBaseUrl}/executiveDashboard/dashboardValues`, {
                params: dashboardValues
            });
            setDashboard(response.data);
        } catch (error) {
            console.log("Error Occured", error);
        }
    };

    useEffect(() => {
        GetDashboardValues(dashboardValues);
    }, [dashboardValues]);

    useEffect(() => {
        setDashboardValues(prevState => ({
            ...prevState,
            selectedDept: [userInfo.department]
        }));
    }, [userInfo.department]);

    return (
        <div className='w-full h-full flex bg-blue-100'>
            <div className={`w-[30%] max-h-full overflow-hidden ${userInfo.role === "ExcutiveManagement" ? "flex flex-col" : "grid grid-rows-3"} gap-y-4 py-4 pl-4 bg-transparent`}>
                {UserRole !== "ExcutiveManagement" ?
                    <CheckBoxesList
                        ArrData={allDepartment}
                        title={"Select Department"}
                        name={"department"}
                        code={"deptCode"}
                        onSelectionChange={handleDepartmentSelection}
                        loading={loadingDataToogle.fetchDept}
                    />
                    :
                    <div className="w-full h-[10%] flex flex-col justify-center items-center  text-md font-bold tracking-wider bg-PrimaryColor text-white rounded-xl">
                        Department: {userInfo.department}
                    </div>
                }
                <CheckBoxesList
                    ArrData={allPrograms}
                    title={"Select Program"}
                    name={"programName"}
                    code={"programCode"}
                    onSelectionChange={handlProgramSelection}
                    loading={loadingDataToogle.fetchProg}
                />
                <CheckBoxesList
                    ArrData={allCourses}
                    title={"Select Course"}
                    name={"courseName"}
                    code={"courseCode"}
                    onSelectionChange={handleCourseSelection}
                    loading={loadingDataToogle.fetchCourse}
                />
            </div>
            <div className='w-[70%] h-full flex flex-col gap-y-4'>
                <div className='w-full h-[25%] grid grid-cols-4 gap-x-4 p-4'>
                    <Cards
                        Title={"No. of students"}
                        heading1={"Registred"}
                        val1={dashboard.No_Stud.Total}
                        heading2={"Active"}
                        val2={dashboard.No_Stud.Active}
                    />
                    <Cards
                        Title={"Faculty Members"}
                        heading1={"Registred"}
                        val1={dashboard.No_Faculty.Total}
                        heading2={"Active"}
                        val2={dashboard.No_Faculty.Active}
                    />
                    <Cards
                        Title={"Learning Material"}
                        heading1={"Videos"}
                        val1={dashboard.ELearning_Material.Video}
                        heading2={"Audios"}
                        val2={dashboard.ELearning_Material.Audio}
                        heading3={"Documents"}
                        val3={dashboard.ELearning_Material.EBooks}
                    />
                    <div className='w-full h-full flex flex-col gap-y-2'>
                        <div className='w-full h-1/3 flex jusitfy-start items-center p-2 rounded-lg bg-PrimaryDarkColor text-white text-sm text-center'>
                            <p className='text-start w-[80%]'>Departments</p>
                            <p className='text-start font-bold w-[20%]'>{dashboardValues.selectedDept.length}</p>
                        </div>
                        <div className='w-full h-1/3 flex jusitfy-start items-center p-2 rounded-lg bg-PrimaryDarkColor text-white text-sm text-center'>
                            <p className='text-start w-[80%]'>Programs</p>
                            <p className='text-start font-bold w-[20%]'>{dashboardValues.selectedProg.length}</p>
                        </div>
                        <div className='w-full h-1/3 flex jusitfy-start items-center p-2 rounded-lg bg-PrimaryDarkColor text-white text-sm text-center'>
                            <p className='text-start w-[80%]'>Courses</p>
                            <p className='text-start font-bold w-[20%]'>{dashboardValues.selectedCourse.length}</p>
                        </div>
                    </div>
                </div>
                <div className='w-full h-[75%] flex'>
                    <div className='w-[55%] py-4 px-1'>
                        <BarGraph data={dashboard.facultyCourses} />
                    </div>
                    <div className='w-[45%] py-4 px-1'>
                        <PieCharts
                            data={dashboard.studentsPerCourse}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard