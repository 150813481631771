import { SET_ALLPROGRAM } from '../action-creators/ActionTypes'; // Assuming you have SET_ALL_PROGRAMS action type defined

const AllProgram = (state = [], action) => {
    switch (action.type) {
        case SET_ALLPROGRAM:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllProgram;
