import React from "react";

const Multi = ({ optionsArray, setSurveyResult, surveyResult, quesNo }) => {
  const handleChangeCheckbox = (option) => {
    setSurveyResult((prevSurveyResult) => {
      return prevSurveyResult.map((item) => {
        if (item.quesNo === quesNo) {
          const newAnswer = item.Answer.includes(option)
            ? item.Answer.filter((ans) => ans !== option)
            : [...item.Answer, option];
          return { ...item, Answer: newAnswer };
        }
        return item;
      });
    });
  };

  const currentAnswer = surveyResult.find(item => item.quesNo === quesNo)?.Answer || [];

  return (
    <div className="flex flex-col w-[50%] h-full">
      {optionsArray.map((option, optIndex) => (
        <div key={optIndex} className="flex items-center">
          <input
            type="checkbox"
            id={`${option}-${quesNo}-${optIndex}`}
            name={`question-${quesNo}-${optIndex}`}
            value={option}
            checked={currentAnswer.includes(option)}
            onChange={() => handleChangeCheckbox(option)}
            className="form-checkbox"
          />
          <label
            htmlFor={`${option}-${quesNo}-${optIndex}`}
            className="ml-2 text-gray-700"
          >
            {option}
          </label>
        </div>
      ))}
    </div>
  );
};

export default Multi;
