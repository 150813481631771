import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../../state/action-creators';
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import Header from "../Header/Header";
import Footer from "../Header/Footer";
import config from "../../../config";
import Apis from '../../../Apis';
import { useSelector } from 'react-redux';
import { MessageByName } from '../../../components/FacultyComp/Dashboard/Modals/AddNotification';

const Login = () => {
	const [data, setData] = useState({ email: "", password: "" });
	const [isForgetPassword, setIsForgetPassword] = useState(false);

	const ResponseMessages = useSelector(state => state.responseMessage);

	const [showPassword, setShowPassword] = useState(false);

	const handleTogglePasswordVisibility = () => {
		setShowPassword(prevState => !prevState);
	};

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const backendBaseUrl = config.backendLocalhost;

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};


	const handleSubmit = async (e) => {
		e.preventDefault();
		const Loading = toast.loading("Signing in...");
		try {
			const url = `${backendBaseUrl}/api/auth/login`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
			const result = await response.json();
			if (response.status === 200) {
				dispatch(setUserInfo(result.user));
				localStorage.setItem('authToken', result.data);
				localStorage.setItem('role', result.user.role);
				toast.dismiss(Loading);
				Apis.addAuditTrail(result.user._id, "66470b3ae3feea28cc069498", ``);
				toast.success(`Welcome ${result.user.firstName} ${result.user.lastName}`);
				navigate(
					result.user.role === "admin" ? "/admin" :
						result.user.role === "faculty" ? "/FacultyDashboard" :
							result.user.role === "student" ? "/StudentDashboard" :
							result.user.role === "ExcutiveAdmin" ? "/ExcutiveAdmin" :
							result.user.role === "ExcutiveManagement" ? "/ExcutiveManagement" :
								"/");
			} else {
				console.error('Error:', result);
				const ResponseMsg = MessageByName(result.message, ResponseMessages);
				toast.error(ResponseMsg);
			}
		} catch (err) {
			console.error('Error:', err);
			dispatch(setUserInfo([]));
			const ResponseMsg = MessageByName("Internal Server Error", ResponseMessages);
			toast.error(ResponseMsg);
		} finally {
			toast.dismiss(Loading);
		}
	};



	const handleForgetPassword = async (e) => {
		e.preventDefault();
		const officialEmail = data.email;
		try {
			const response = await fetch(`${backendBaseUrl}/forgotPassword/forgot`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ officialEmail }),
			});

			const data = await response.json();

			if (response.status === 200) {
				toast.success("Success! Check your email")
				return navigate("/")
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			console.error("Error:", error);
			toast.error("An error occurred while processing the request.");
		}
	}

	return (
		<div className="flex flex-col w-full h-full bg-blue-100">
			<Header />
			{isForgetPassword ?
				<div className={styles.login_container}>

					<div className={`${styles.login_form_container} flex items-center justify-center bg-white`}>
						<div className='h-[40%] max-h-full flex flex-col items-center justify-start gap-y-6'>
							<h1 className='text-2xl text-gray-800'>Forgot Password</h1>
							<form className={styles.form_container} onSubmit={handleForgetPassword}>
								<input
									type="email"
									placeholder="Email"
									name="email"
									onChange={handleChange}
									value={data.email}
									className={styles.input}
									required
								/>
								<div className='w-full flex justify-between items-center mt-4'>
									<button type="submit" className={styles.green_btn}>
										Get the link
									</button>
									<button
										onClick={() => setIsForgetPassword(false)}
										className={styles.green_btn}>
										Go Back ⏎
									</button>

								</div>
							</form>
						</div>
					</div>

				</div>
				:
				<div className={styles.login_container}>
					<div className={styles.login_form_container}>
						<div className={styles.left}>
							<h1>Login to Your Account </h1>
							<form className={styles.form_container} onSubmit={handleSubmit}>

								<input
									type="email"
									placeholder="Email"
									name="email"
									onChange={handleChange}
									value={data.email}
									className={styles.input}
									required
								/>
								<div className="w-full relative">
									<input
										type={showPassword ? "text" : "password"}
										placeholder={"Password"}
										className={styles.input}
										name="password"
										value={data.password}
										onChange={handleChange}
									/>
									<button
										type="button"
										className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
										onClick={handleTogglePasswordVisibility}
									>
										<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
									</button>
								</div>

								<div className='w-full flex justify-end items-center my-2'>
									<label
										onClick={() => setIsForgetPassword(true)}
										className='text-md text-black hover:underline hover:text-blue-600 cursor-pointer duration-300'> Forget Password </label>
								</div>

								<button type="submit" className={styles.green_btn}>
									Sing In
								</button>
							</form>
						</div>
						<div className={styles.right}>
							<h1>New User ?</h1>
							<Link to="/signup">
								<button type="button" className={styles.white_btn}>
									Sing Up
								</button>
							</Link>
						</div>
					</div>
				</div>
			}

			<Footer />
		</div>
	);
};

export default Login;