import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';
import Select from 'react-select';
import { useSelector } from 'react-redux';


const DayofWeek = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
]

const periods = [
    { periodNo: '1', periodTime: '08:40 - 09:30' },
    { periodNo: '2', periodTime: '09:40 - 10:30' },
    { periodNo: '3', periodTime: '10:40 - 11:30' },
    { periodNo: '4', periodTime: '11:40 - 12:30' },
    { periodNo: '5', periodTime: '12:40 - 13:30' },
    { periodNo: '6', periodTime: '13:40 - 14:30' },
    { periodNo: '7', periodTime: '14:40 - 15:30' },
    { periodNo: '8', periodTime: '15:40 - 16:30' },
    { periodNo: '9', periodTime: '16:40 - 17:30' },
];

const AddWeeklySchedule = () => {
    const backendBaseUrl = config.backendLocalhost;

    const [formdata, setFormData] = useState({
        course: '',
        class: '',
        schedules: [{
            dayOfWeek: "",
            periods: [],
        }],
    })

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formdata,
            [name]: value,
        });
    };
    
    const handleScheduleChange = (e, index) => {
        const { name, value } = e.target;
        const newSchedules = [...formdata.schedules];
        newSchedules[index][name] = value;
        setFormData(prevFormData => ({
            ...prevFormData,
            schedules: newSchedules
        }));
    };


    const selectedOptions = formdata.schedules?.periods?.map(period => ({
        label: period,
        value: period.periodNo
    }));

    const handleChange = (selectedOptions, index) => {
        const updatedSchedules = formdata.schedules.map((schedule, i) => {
            if (i === index) {
                return {
                    ...schedule,
                    periods: selectedOptions.map(option => option.value)
                };
            }
            return schedule;
        });

        setFormData({
            ...formdata,
            schedules: updatedSchedules
        });
    };

    const addSchedule = () => {
        setFormData({
            ...formdata,
            schedules: [...formdata.schedules, { dayOfWeek: "", periods: [] }],
        });
    };

    const deleteSchedule = (index) => {
        const newSchedules = [...formdata.schedules];
        newSchedules.splice(index, 1);
        setFormData({ ...formdata, schedules: newSchedules });
    };

    const resetField = () => {
        setFormData({
            course: '',
            class: '',
            schedules: [{
                dayOfWeek: "",
                periods: [],
            }],
        })
    };

    const classes = useSelector(state => state.allClass);
    const courses = useSelector(state => state.allCourses);
    const program = useSelector(state => state.allProgram);

    function getCoursesByClass(classCode) {
        const selectedClass = classes?.find((classObj) => classObj.classCode === classCode);
        if (!selectedClass) {
            return [];
        }
        const programCode = selectedClass.program;
        const selectedProgram = program.find((classObj) => classObj.programCode === programCode);
        const selectedProgramCode = selectedProgram?.programCourses[selectedClass?.semester] ? selectedProgram.programCourses[selectedClass.semester] : [];
        const matchingCourses = courses.filter((course) => selectedProgramCode.includes(course.courseCode));
        return matchingCourses;
    }

    const handleAddWeeklySchedule = async () => {
        if (!formdata.class || formdata.class === "") {
            toast.error("Please Select Class");
            return;
        }
        if (!formdata.course || formdata.course === "") {
            toast.error("Please Select Course");
            return;
        }
        const loadingToast = toast.loading('Loading...');
        try {
            const response = await axios.post(`${backendBaseUrl}/schedules`, formdata);
            resetField();
            toast.dismiss(loadingToast);
            toast.success(`Weekly Schedule for ${formdata.class} - ${formdata.course} added successfully`);
        } catch (error) {
            console.error('Error adding Weekly Schedule:', error);
            toast.dismiss(loadingToast);
            toast.error(error.response.data.message);
        }
    };


    return (
        <div className='w-full flex flex-col items-center justify-start 
    lg:h-full lg:overflow-y-auto lg:style-scrollbar
    h-auto overflow-y-auto style-scrollbar'>
            <form
                onSubmit={() => handleAddWeeklySchedule()}
                className='w-full bg-gray-300 flex flex-col items-start justify-center 
      lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh]
      text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh]
      '>

                <div className='w-full flex justify-start
          lg:gap-x-[1vw] lg:flex-row lg:items-center
          gap-x-[1vw] flex-col gap-y-[1vh] items-start
          '>
                    <div className='flex flex-col justify-center items-start
            lg:gap-y-[0.5vh] lg:text-sm lg:w-1/2
            gap-y-[0.5vh] text-sm w-full
            '>
                        <label htmlFor='deptCode' className='font-semibold'>Select Class Codes :</label>
                        <select className="select select-primary w-full bg-white"
                            id='class'
                            name="class"
                            value={formdata.class}
                            onChange={(e) => handleInputChange(e)}
                        >
                            <option selected disabled value="">
                                Select a Class
                            </option>
                            {classes.map((clas) => (
                                <option className='text-black' key={clas.classCode} value={clas.classCode}>
                                    {clas.classCode}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-col justify-center items-start
            lg:gap-y-[0.5vh] lg:text-sm lg:w-1/2
            gap-y-[0.5vh] text-sm w-full
            '>
                        <label htmlFor='deptCode' className='font-semibold'>Select Course Code :</label>
                        <select className="select select-primary w-full bg-white"
                            id='course'
                            name="course"
                            value={formdata.course}
                            onChange={(e) => handleInputChange(e)}
                        >
                            <option selected disabled value="">
                                Select a Course
                            </option>
                            {getCoursesByClass(formdata.class) && getCoursesByClass(formdata.class).map((course) => (
                                <option className='text-black' key={course.courseCode} value={course.courseCode}>
                                    {`${course.courseName}-(${course.courseCode})`}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {formdata.class && formdata.course &&
                    <div className='w-full flex flex-col justify-center items-end 
        lg:gap-y-[1vh]
        gap-y-[1vh]
        '>
                        {formdata.schedules && formdata.schedules.map((schedule, index) => (
                            <div key={index} className='w-full flex flex-row justify-center gap-x-[1vw] gap-y-[1vh] items-start'>
                                <div className='flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[45%] gap-y-[0.5vh] text-sm w-full'>
                                    <label htmlFor='dayOfWeek' className='font-semibold'>Select Days of week :</label>
                                    <select className="select select-primary w-full bg-white"
                                        id={`dayOfWeek-${index}`}
                                        name="dayOfWeek"
                                        value={schedule.dayOfWeek}
                                        onChange={(e) => handleScheduleChange(e, index)}
                                    >
                                        <option disabled value="">
                                            Select Days of week
                                        </option>
                                        {DayofWeek.map((day) => (
                                            <option className='text-black' key={day} value={day}>
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[45%] gap-y-[0.5vh] text-sm w-full'>
                                    <label htmlFor='periods' className='font-semibold'>Select Periods :</label>
                                    <Select
                                        className="h-full input select-primary min-w-full bg-white p-1"
                                        id={`periods-${index}`}
                                        name="periods"
                                        value={selectedOptions}
                                        onChange={(e) => handleChange(e, index)}
                                        placeholder={"Select multple periods"}
                                        options={periods.map(period => ({
                                            label: `${period.periodNo}: ${period.periodTime}`,
                                            value: period.periodNo
                                        }))}
                                        isMulti
                                    />
                                </div>
                                <div className='h-full flex justify-center items-end w-[10%]'>
                                    <button
                                        className='flex flex-col justify-center items-center px-[1vw] py-[1vh] bg-red-600 text-white font-semibold rounded-lg
                                lg:gap-y-[0.5vh] lg:text-sm lg:w-full gap-y-[0.5vh] text-sm w-full'
                                        type="button"
                                        onClick={() => deleteSchedule(index)}>Delete</button>
                                </div>
                            </div>
                        ))}
                        <button
                            className='flex flex-col justify-center items-center px-[1vw] py-[1vh] bg-green-600 text-white font-semibold rounded-lg
        lg:gap-y-[0.5vh] lg:text-sm lg:w-auto gap-y-[0.5vh] text-sm w-full'
                            onClick={() => addSchedule()}
                            type="button"
                        >Add More Days</button>
                    </div>
                }

                <button
                    className='bg-PrimaryColor text-white font-bold
          lg:px-[1vw] lg:py-[1vh] lg:rounded-[0.5vw] lg:text-md
          px-[4vw] py-[1.5vh] rounded-[1vw] text-sm
          '
                    type='button'
                    onClick={handleAddWeeklySchedule}
                >
                    Submit
                </button>
            </form>
        </div>
    )
}

export default AddWeeklySchedule