import { SET_RESPONSE_MESSAGE } from '../action-creators/ActionTypes';

const ResponseMessage = (state = 'Department' , action) => {
    switch (action.type) {
        case SET_RESPONSE_MESSAGE:
            return state === action.payload ? state : action.payload;
        default:
            return state;
            
    }
};

export default ResponseMessage;