import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import config from "../../../../config";
import { useSelector } from "react-redux";
import Select from 'react-select';
import Apis from "../../../../Apis";

const AddUpdateCourses = ({ updateCourses, setUpdateCourses }) => {
  const userInfo = useSelector(state => state.userInfo);
  const [courseName, setCourseName] = useState("");
  const [creditHours, setCreditHours] = useState("");
  const [courseCode, setCourseCode] = useState("");
  const [allExcutiveAdminInfo, setAllExcutiveAdminInfo] = useState([])
  const [refArray, setRefArray] = useState([])
  const [editingId, setEditingId] = useState(null);
  const [status, setStatus] = useState(true);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const backendBaseUrl = config.backendLocalhost;
  const departments = useSelector((state) => state.allDept);
  const [allDepartmentalAdminInfo, setAllDepartmentalAdminInfo] = useState([])

  const handleInputChange = () => {
    setStatus(!status);
  };

  const resetField = () => { 
    setEditingId(null);
    setCourseName("");
    setCreditHours("");
    setSelectedDepartment("");
    setCourseCode("");
    setStatus(true);
    setRefArray([])
    setUpdateCourses([]);
  };

  const handleAddCourse = async () => {
    try {
      const response = await axios.post(
        `${backendBaseUrl}/courses/addCourses/`,
        {
          courseName: courseName,
          credithour: creditHours,
          departmentcode: selectedDepartment,
          courseCode: courseCode,
          status: status,
          refArray: refArray,
          userID: userInfo._id,
        }
      );

      setCourseName("");
      setCreditHours("");
      setSelectedDepartment("");
      setCourseCode("");
      toast.success(
        `Course code: ${response.data.courseCode} added successfully`
      );
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        toast.error("v", error.response.data.errorMessage);
      } else {
        toast.error("An error occurred while adding the course.");
      }
    }
  };

  const fetchAllExcutiveAdmin = async () => {
    try {
      const AllExcutiveAdmin = await Apis.getAllExcutiveAdmin("");
      setAllExcutiveAdminInfo(AllExcutiveAdmin);
    } catch (error) {
      console.error('Error fetching All Excutive Admin:', error.message);
      toast.error('Error fetching All Excutive Admin:', error.message);
    }
  };

  const fetchAllExcutiveManagement = async () => {
    let userID;
    if (userInfo.role === 'admin') {
      userID = 'admin'
    }
    else {
      userID = userInfo._id
    }
    try {
      const AllExcutiveManagement = await Apis.getAllExcutiveManagement("", userID);
      setAllDepartmentalAdminInfo(AllExcutiveManagement);
    } catch (error) {
      console.error('Error fetching All Excutive Management:', error.message);
      toast.error('Error fetching All Excutive Management:', error.message);
    }
  };

  useEffect(() => {
    if(userInfo.role){
      fetchAllExcutiveAdmin();
      fetchAllExcutiveManagement();
    }
  }, [updateCourses,userInfo]);

  useEffect(() => {
    if (updateCourses.length !== 0 && updateCourses) {
      setEditingId(updateCourses._id);
      setCourseName(updateCourses.courseName);
      setCreditHours(updateCourses.credithour);
      setSelectedDepartment(updateCourses.departmentcode);
      setCourseCode(updateCourses.courseCode);
      setStatus(updateCourses?.status);
      setRefArray(updateCourses?.refArray)
    }
  }, [updateCourses]);

  const handleUpdateCourse = async () => {
    try {
      const updatedCourse = {
        courseName: courseName,
        credithour: creditHours,
        departmentcode: selectedDepartment,
        status: status,
        refArray:refArray,
      };
      const response = await axios.put(
        `${backendBaseUrl}/courses/Course/${editingId}`,
        updatedCourse
      );
      resetField();
      toast.success(`Course updated successfully`);
      setEditingId(null);
      setCourseName("");
      setCreditHours("");
      setSelectedDepartment("");
      setCourseCode("");
    } catch (error) {
      console.error("Error updating course:", error.message);
      toast.error(`Error updating course: ${error.message}`);
    }
  };

  const generateCourseCode = () => {
    if (courseName && typeof courseName === "string") {
      const initials = courseName
        .split(" ")
        .map((word) => (word && word[0] ? word[0].toUpperCase() : ""))
        .join("");

      const randomDigits = Math.floor(1000 + Math.random() * 9000);
      setCourseCode(initials + randomDigits);
    }
  };

  useEffect(() => {
    if (!editingId) {
      generateCourseCode();
    }
  }, [courseName]);

  return (
    <div className='flex flex-col items-center justify-start w-full h-auto overflow-y-auto lg:overflow-y-auto lg:style-scrollbar style-scrollbar'>
      <form className='w-full bg-gray-300 flex flex-col items-start justify-center lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh] text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh]'>
        <div className="w-full grid justify-center items-center gap-x-[1vw] gap-y-[1vh] 
        lg:grid-cols-2
        grid-cols-1">
          <div className="w-full flex flex-col justify-center items-start gap-y-[0.5vh] text-sm">
            <label htmlFor="courseName" className="font-semibold">
              Course Name:
            </label>
            <input
              type="text"
              placeholder="Write course name"
              className="w-full bg-white input outline-PrimaryColor font-noraml"
              id="department"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
            />
          </div>
          <div className=" flex flex-col gap-y-[0.5vh]">
            <label htmlFor="deptCode" className="font-semibold">
              Credit Hour:
            </label>
            <input
              type="number"
              placeholder="write credit code in number"
              className="w-full font-normal bg-white input outline-PrimaryColor"
              id="deptCode"
              value={creditHours}
              onChange={(e) => setCreditHours(e.target.value)}
            />
          </div>
          <div className="w-full flex flex-col gap-y-[0.5vh]">
            <label htmlFor="deptCode" className="font-semibold">
              Select Department :
            </label>
            <select
              className="w-full bg-white select select-primary"
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
            >
              <option selected disabled value="">
                Select a Department
              </option>
              {departments.map((dept) => (
                <option
                  className="text-black"
                  key={dept.deptCode}
                  value={dept.deptCode}
                >
                  {`${dept.department}-(${dept.deptCode})`}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full flex flex-col gap-y-[0.5vh]">
            <label htmlFor="deptCode" className="font-semibold">
              Course Code:
            </label>
            <input
              type="text"
              placeholder="Code will auto-Generated "
              className="w-full font-normal bg-white input outline-PrimaryColor"
              id="deptCode"
              value={courseCode}
              onChange={(e) => setCourseCode(e.target.value)}
              disabled={editingId !== null}
            />
          </div>
          {userInfo.role === "admin" &&
              <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm gap-y-[0.5vh] text-sm w-full">
                <label htmlFor="deptWebsiteLink" className="font-semibold">Assign Department to Executive Admins:</label>
                <Select
                  className="h-full input select-primary min-w-full bg-white p-1"
                  id="refArray"
                  name="refArray"
                  value={refArray.map(id => ({
                    value: id,
                    label: allExcutiveAdminInfo?.find(admin => admin._id === id)?.firstName || allDepartmentalAdminInfo?.find(admin => admin._id === id)?.firstName ||  '***'
                  }))}
                  onChange={(selectedOptions) => {
                    setRefArray(selectedOptions.map(option => option.value));
                  }}
                  placeholder="Select multiple Executive Admins"
                  options={allExcutiveAdminInfo.map(admin => ({
                    value: admin._id,
                    label: `${admin.firstName} ${admin.lastName}`
                  }))}
                  isMulti
                />

              </div>
            }
          <div className="w-full flex flex-col gap-y-[0.5vh]">
            <label htmlFor="deptCode" className="mb-0 font-semibold lg:mb-2 ">
              Status:
            </label>
            <div className="flex gap-x-[0.5vw] justify-start items-center">
              <input
                className="checkbox"
                type="checkbox"
                id="status"
                name="status"
                checked={status}
                onChange={handleInputChange}
              />
              <label
                className={`text-center ${status ? "text-green-600" : "text-red-600"
                  }`}
              >
                {" "}
                {status ? "Active" : "In-Active"}{" "}
              </label>
            </div>
          </div>
        </div>
        <button
          className="px-[1vw] py-[1vh] bg-PrimaryColor text-white rounded-[0.5vw] font-bold"
          type="button"
          onClick={editingId !== null ? handleUpdateCourse : handleAddCourse}
        >
          {editingId !== null ? "Update Department" : "Add Department"}
        </button>
      </form>
    </div>
  );
};

export default AddUpdateCourses;
