import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Apis from "../../../../Apis";

const AddUpdateProgram = ({ updateProgram, setUpdateProgram }) => {
  const userInfo = useSelector(state => state.userInfo);
  const [degreeLevel, setDegreeLevel] = useState('');
  const [programName, setProgramName] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [programCode, setProgramCode] = useState('');
  const [allExcutiveAdminInfo, setAllExcutiveAdminInfo] = useState([])
  const [refArray, setAllRefArray] = useState([])
  const [allProgram, setAllProgram] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [status, setStatus] = useState(true);
  const backendBaseUrl = config.backendLocalhost;

  const departments = useSelector(state => state.allDept);
  const [allDepartmentalAdminInfo, setAllDepartmentalAdminInfo] = useState([])

  const handleDegreeLevelChange = (e) => {
    setDegreeLevel(e.target.value);
  };

  const handleProgramNameChange = (e) => {
    setProgramName(e.target.value);
  };

  const handleProgramCodeChange = (e) => {
    setProgramCode(e.target.value);
  };

  const handleInputChange = () => {
    setStatus(!status);
  };

  const resetField = () => {
    setEditingId(null);
    setDegreeLevel('');
    setProgramName('');
    setProgramCode('');
    setSelectedDepartment('');
    setStatus(true);
    setUpdateProgram([]);
    setAllRefArray([]);
  };

  const generateProgramCode = () => {
    if (!updateProgram._id) {
      const programInitials = programName
        .split(' ')
        .map(word => word.charAt(0).toUpperCase())
        .join('');
      const generatedCode = (degreeLevel === "" ? "" : degreeLevel[0]) + programInitials;
      setProgramCode(generatedCode);
    }
  };

  const fetchAllExcutiveAdmin = async () => {
    try {
      const AllExcutiveAdmin = await Apis.getAllExcutiveAdmin("");
      setAllExcutiveAdminInfo(AllExcutiveAdmin);
    } catch (error) {
      console.error('Error fetching All Excutive Admin:', error.message);
      toast.error('Error fetching All Excutive Admin:', error.message);
    }
  };

  const fetchAllExcutiveManagement = async () => {
    let userID;
    if (userInfo.role === 'admin') {
      userID = 'admin'
    }
    else {
      userID = userInfo._id
    }
    try {
      const AllExcutiveManagement = await Apis.getAllExcutiveManagement("", userID);
      setAllDepartmentalAdminInfo(AllExcutiveManagement);
    } catch (error) {
      console.error('Error fetching All Excutive Management:', error.message);
      toast.error('Error fetching All Excutive Management:', error.message);
    }
  };

  useEffect(() => {
    if(userInfo.role){
      fetchAllExcutiveAdmin();
      fetchAllExcutiveManagement();
    }
  }, [updateProgram,userInfo]);

  useEffect(() => {
    generateProgramCode();
  }, [programName]);

  const handleAddProgram = async () => {
    try {
      const response = await axios.post(`${backendBaseUrl}/program/addProgram`, {
        degreeLevel,
        programName,
        programCode,
        status,
        departmentCode:selectedDepartment,
        refArray: refArray,
        userID: userInfo._id,
      });
      resetField();
      toast.success(`Program code:${programCode} successfully`)
    } catch (error) {
      // Handle errors from the server
      if (error.response && error.response.status === 400) {
        console.error('Error adding program:', error.response.data.error);
        toast.error('Program with the same program already exists');
      } else {
        // Handle other errors
        console.error('Error adding program:', error.message);
        toast.error('Error adding program:', error.message);
      }
    }
  };


  useEffect(() => {
    if (updateProgram.length !== 0 && updateProgram) {
      setEditingId(updateProgram?._id);
      setDegreeLevel(updateProgram?.degreeLevel);
      setProgramName(updateProgram?.programName);
      setStatus(updateProgram?.status);
      setProgramCode(updateProgram?.programCode);
      setAllRefArray(updateProgram?.refArray)
    }
  }, [updateProgram]);


  const handleUpdateProgram = async () => {
    try {
      const response = await axios.put(`${backendBaseUrl}/program/updatedProgram/${editingId}`, {
        degreeLevel,
        programName,
        programCode,
        departmentCode:selectedDepartment,
        status,
        refArray: refArray,
      });
      setAllProgram(allProgram && allProgram?.map(updateProgram => (updateProgram._id === editingId ? response.data : updateProgram)));
      resetField();
      toast.success(`Department code: ${programCode} updated successfully`);
    } catch (error) {
      console.error('Error updating program:', error.message);
      toast.error(`Error updating program: ${error.message}`);
    }
  };


  return (
    <div className='flex flex-col items-center justify-start w-full h-auto overflow-y-auto lg:overflow-y-auto lg:style-scrollbar style-scrollbar'>
    <form className='w-full bg-gray-300 flex flex-col items-start justify-center lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh] text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh]'>
      <div className="w-full grid justify-center items-center gap-x-[1vw] gap-y-[1vh] 
      lg:grid-cols-2
      grid-cols-1">
   
            <div className='w-full flex flex-col gap-y-[0.5vh]'>
              <label htmlFor='deptCode' className='font-semibold'>Program Name:</label>
              <input
                type="text"
                placeholder="Write Program Name"
                className="w-full font-normal bg-white input outline-PrimaryColor"
                id='deptCode'
                value={programName}
                onChange={handleProgramNameChange}
              />
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh]'>
              <label htmlFor='deptCode' className='font-semibold'>Program Code:</label>
              <input
                type="text"
                placeholder="Code will auto-Generated"
                className="w-full font-normal bg-white input outline-PrimaryColor"
                id='deptCode'
                value={programCode}
                onChange={handleProgramCodeChange}
                disabled={editingId !== null}
              />
            </div>

            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
                <label htmlFor='department' className='font-semibold'>Degree Level:</label>
                <select className="w-full bg-white select select-primary"
                  id="degreeLevel"
                  value={degreeLevel}
                  onChange={handleDegreeLevelChange}>
                  <option disabled selected value="">Select Degree Level</option>
                  <option value="Diploma">Postgraduate Diploma</option>
                  <option value="BS">Bachelor's degree</option>
                  <option value="MA">Master's degree</option>
                </select>
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>Select Department :</label>
              <select className="w-full bg-white select select-primary"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}>

                <option selected disabled value="">
                  Select a Department
                </option>
                {departments.map((dept) => (
                  <option className='text-black' key={dept.deptCode} value={dept.deptCode}>
                    {`${dept.department}-(${dept.deptCode})`}
                  </option>

                ))}
              </select>
            </div>
            {userInfo.role === "admin" &&
              <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm gap-y-[0.5vh] text-sm w-full">
                <label htmlFor="deptWebsiteLink" className="font-semibold">Assign Department to Executive Admins:</label>
                <Select
                  className="h-full input select-primary min-w-full bg-white p-1"
                  id="refArray"
                  name="refArray"
                  value={refArray?.map(id => ({
                    value: id,
                    label: allExcutiveAdminInfo?.find(admin => admin._id === id)?.firstName || allDepartmentalAdminInfo?.find(admin => admin._id === id)?.firstName ||  '***'
                  }))}
                  onChange={(selectedOptions) => {
                    setAllRefArray(selectedOptions.map(option => option.value));
                  }}
                  placeholder="Select multiple Executive Admins"
                  options={allExcutiveAdminInfo.map(admin => ({
                    value: admin._id,
                    label: `${admin.firstName} ${admin.lastName}`
                  }))}
                  isMulti
                />

              </div>
            }
             {userInfo.role === "ExcutiveAdmin" &&
              <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[50%] gap-y-[0.5vh] text-sm w-full">
                <label htmlFor="deptWebsiteLink" className="font-semibold">Assign Department to Departmental Admins:</label>
                <Select
                  className="h-full input select-primary min-w-full bg-white p-1"
                  id="refArray"
                  name="refArray"
                  value={refArray.map(id => ({
                    value: id,
                    label: allExcutiveAdminInfo?.find(admin => admin._id === id)?.firstName || allDepartmentalAdminInfo?.find(admin => admin._id === id)?.firstName ||  '***'
                  }))}
                  onChange={(selectedOptions) => {
                    setAllRefArray(selectedOptions.map(option => option.value));
                  }}
                  placeholder="Select multiple Departmental Admins"
                  options={allDepartmentalAdminInfo.map(admin => ({
                    value: admin._id,
                    label: `${admin.firstName} ${admin.lastName}`
                  }))}
                  isMulti
                />

              </div>
            }
            <div className='flex flex-col justify-center items-start
            lg:gap-y-[0.5vh] lg:text-sm lg:w-[10%] lg:px-[0vw]
            gap-y-[0.5vh] text-sm w-full 
            '>
              <label htmlFor='deptCode' 
              className='mb-0 font-semibold lg:mb-2 '>Status:</label>
              <div className='flex gap-x-[0.5vw] justify-center items-center'>
                <input
                  className='checkbox'
                  type="checkbox"
                  id='status'
                  name="status"
                  checked={status}
                  onChange={handleInputChange}
                />
                <label className={`text-center ${status ? "text-green-600" : "text-red-600"}`}> {status ? "Active" : "In-Active"} </label>
              </div>
            </div>
        </div>
        <button
          className='px-[1vw] py-[1vh] bg-PrimaryColor text-white rounded-[0.5vw] font-bold'
          type='button'
          onClick={editingId !== null ? handleUpdateProgram : handleAddProgram}
        >
          {editingId !== null ? 'Update Program' : 'Add Program'}
        </button>
      </form>
    </div>
  );
};

export default AddUpdateProgram