import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';
import LineChart from '../Linechart';

const ViewDashboard = () => {
    const [selectedYear, setSelectedYear] = useState("");
    const [lineChartData, setLineChartData] = useState(
        {
            Lecture:0,
            Assignment:0,
            Quiz:0,
            Exam:0,
        }
    );
    const backendBaseUrl = config.backendLocalhost;
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };
    const userInfo = useSelector(state => state.userInfo);
    const allStudentInfo = useSelector(state => state.allStudentInfo);
    const allAssignments = useSelector(state => state.assignmentDetails);
    const allQuizs = useSelector(state => state.quizDetails);
    const AllExam = useSelector(state => state.allExam);
    const allAttendance = useSelector(state => state.allAttendance);

    function countMatchingStudentsByClass(targetClass) {
        const matchingStudents = allStudentInfo.filter((student) =>
            student.studentDetails && student.studentDetails.class === targetClass
        )
        return matchingStudents.length;
    }
    const [courses, setCourses] = useState([]);
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await axios.get(`${backendBaseUrl}/courses/getAllCourses`);
                setCourses(response.data);
            } catch (error) {
                console.error('Error fetching courses:', error.message);
                toast.error('Error fetching courses:', error.message);
            }
        };
        const fetchSessions = async () => {
            try {
                const response = await axios.get(`${backendBaseUrl}/session/getAllSessions`);
                setSessions(response.data);
            } catch (error) {
                console.error('Error fetching courses:', error.message);
                toast.error('Error fetching courses:', error.message);
            }
        };
        fetchSessions();
        fetchCourse();
    }, []);

    function getCourseDetailsByCode(courseCode, defaultValue = { course: "", creditHour: "" }) {
        const foundCourse = courses.find(course => course.courseCode === courseCode);

        return foundCourse
            ? { course: foundCourse.courseName, creditHour: foundCourse.credithour, courseCode: foundCourse.courseCode  }
            : defaultValue;
    }
    function countAssignmentsByClassAndCourse(val, classFilter, courseFilter) {
        const filteredAssignments = val.filter((assignment) => {
            return assignment.class === classFilter && assignment.course === courseFilter;
        });

        return filteredAssignments.length;
    }

    const UpdateLineChartValue = (classCode,coursecode) => {
        setLineChartData({
            Lecture:countAssignmentsByClassAndCourse(allAttendance, classCode, coursecode),
            Assignment:countAssignmentsByClassAndCourse(allAssignments, classCode, coursecode),
            Quiz:countAssignmentsByClassAndCourse(allQuizs, classCode, coursecode),
            Exam:countAssignmentsByClassAndCourse(AllExam, classCode, coursecode)
        })

    }

    return (
        <div className='w-full h-full bg-transparent rounded-[2vw] shadow-lg shadow-blue-700'>

        <div className='w-full h-full bg-transparent rounded-[2vw] shadow-lg shadow-blue-700'>
            <div className='w-full h-full bg-gray-100 shadow-md shadow-blue-500 rounded-[1vw]'>
                {/* Table Header */}
                <div className='w-full h-[5vh] flex bg-blue-100 rounded-t-[1.2vw]'>
                    <div className='w-full h-full bg-blue-500 text-white flex justify-center items-center gap-x-[1vw] rounded-tl-[1vw]'>
                        <div className='w-[65%] h-full flex justify-start items-center gap-x-[1vw] xl:text-1 px-[1vw]'>
                            <p className='font-semibold tracking-wider '>Session:</p>
                            <select
                                id='select-academic-year min-h-[2vh]'
                                className='px-[0.5vw] border border-blue-800 xl:text-1 rounded-md max-[40%] h-full bg-transparent focus:outline-none hover:cursor-pointer focus:border-blue-300 text-left transition-all duration-300'
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                {sessions?.map((year) => (
                                    <option className="bg-blue-500" key={year.code} value={year.code}>
                                        {year.code}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className='w-full h-full bg-blue-500 text-white flex justify-around items-center rounded-r-md rounded-lr-xl border-l-[0.1vw]'>
                            <p className='font-semibold tracking-wider xl:text-1 '>Completed & Planned Activities</p>
                        </div>
                    </div>
                </div>
                    {/* Table Body */}
                    <div className='w-full h-[25vh] flex overflow-x-auto style-scrollbar rounded-b-[1vw]'>
                        <div className="w-full">
                            <table className="min-w-full bg-white border table-fixed">
                                <thead>
                                    <tr>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-black xl:text-[1vw] w-[16%] flex-shrink-0 border border-gray-400 text-left">
                                            Class
                                        </th>
                                        <th className="sticky top-0 bg-gray-200 px-[0.5vw] py-[1vh] text-black xl:text-[1vw] w-[30%] flex-shrink-0 text-left border border-gray-400">
                                            Course
                                        </th>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-black xl:text-[1vw] w-[10%] flex-shrink-0 border border-gray-400">
                                            Credits Hour
                                        </th>
                                        <th className="sticky top-0 bg-gray-200 px-[0.5vw] py-[1vh] text-black xl:text-[1vw] w-[10%] flex-shrink-0 border border-gray-400">
                                            Students
                                        </th>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-black xl:text-[1vw] w-[10%] flex-shrink-0 border border-gray-400">
                                            Lecture
                                        </th>
                                        <th className="sticky top-0 bg-gray-200 px-[0.5vw] py-[1vh] text-black xl:text-[1vw] w-[10%] flex-shrink-0 border border-gray-400">
                                            Assignment
                                        </th>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-black xl:text-[1vw] w-[7%] flex-shrink-0 border border-gray-400">
                                            Quiz
                                        </th>
                                        <th className="sticky top-0 bg-gray-200 px-[0.5vw] py-[1vh] text-black xl:text-[1vw] w-[7%] flex-shrink-0 border border-gray-400">
                                            Exam
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userInfo.facultyDetails && userInfo.facultyDetails.courseDetail.map((facultyDetail, index) => (
                                        <tr className='divide-y divide-gray-200 cursor-pointer' key={index}
                                        onClick={() => UpdateLineChartValue(facultyDetail.class,facultyDetail.course)}
                                        >
                                            <td className="px-[0.5vw] py-[1vh] text-black xl:text-[1vw] flex-shrink-0 text-left">
                                                {facultyDetail.class}
                                            </td>
                                            <td className="px-[0.5vw] py-[1vh] text-black xl:text-[1vw] flex-shrink-0 truncate text-left">
                                            {getCourseDetailsByCode(facultyDetail.course).courseCode} - {getCourseDetailsByCode(facultyDetail.course).course} </td>
                                            <td className="px-[0.5vw] py-[1vh] text-black xl:text-[1vw] flex-shrink-0 text-center ">{getCourseDetailsByCode(facultyDetail.course).creditHour}</td>
                                            <td className="px-[0.5vw] py-[1vh] text-black xl:text-[1vw] flex-shrink-0 text-center ">{countMatchingStudentsByClass(facultyDetail.class)}</td>
                                            <td className="px-[0.5vw] py-[1vh] text-black xl:text-[1vw] flex-shrink-0 text-center bg-blue-100">{countAssignmentsByClassAndCourse(allAttendance, facultyDetail.class, facultyDetail.course)}</td>
                                            <td className="px-[0.5vw] py-[1vh] text-black xl:text-[1vw] flex-shrink-0 text-center bg-gray-200">{countAssignmentsByClassAndCourse(allAssignments, facultyDetail.class, facultyDetail.course)}</td>
                                            <td className="px-[0.5vw] py-[1vh] text-black xl:text-[1vw] flex-shrink-0 text-center bg-blue-100">{countAssignmentsByClassAndCourse(allQuizs, facultyDetail.class, facultyDetail.course)}</td>
                                            <td className="px-[0.5vw] py-[1vh] text-black xl:text-[1vw] flex-shrink-0 text-center bg-blue-100">{countAssignmentsByClassAndCourse(AllExam, facultyDetail.class, facultyDetail.course)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                       
                    </div>
                    <LineChart
                    lineChartData={lineChartData}
                    />
                </div>
            </div>
        </div>
    )
}


 


export default ViewDashboard