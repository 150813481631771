// reducers/FacultyActivityListToggle.js
import { SET_SubmittedQuizzes } from '../action-creators/ActionTypes'; // Update import statement

const SubmittedQuizzes = (state = "", action) => { // Rename the reducer function
    switch (action.type) {
        case SET_SubmittedQuizzes: // Update action type
            return state === action.payload ? "" : action.payload;
        default:
            return state;
    }
};

export default SubmittedQuizzes; // Update export statement
