import React from "react";
import TextAssign from "./ViewAssign/TextAssign";
import SingalAssign from "./ViewAssign/SingalAssign";
import MultiAssign from "./ViewAssign/MultiAssign";
import GradingAssign from "./ViewAssign/GradingAssign";
import { formatDate } from "../../../FormatDate";

const ViewAssignFeedback = ({ viewDetail, field }) => {

  function aggregateSurveyResults(viewDetail) {
    if (!viewDetail || !viewDetail.length) return [];
    const aggregatedResults = [];
    viewDetail[0].surveyResult.forEach((question, index) => {
      const questionResult = {
        quesNo: question.quesNo,
        question: question.question,
        questionType: question.questionType,
        Answer: []
      };
      viewDetail.forEach(student => {
        const studentAnswer = student.surveyResult[index].Answer;
        const otherOptionText = student.surveyResult[index].otherOptionText;
        const processedAnswers = studentAnswer.map(answer =>
          answer === 'Other' ? otherOptionText : answer
        );

        questionResult.Answer = questionResult.Answer.concat(processedAnswers);
      });
      aggregatedResults.push(questionResult);
    });

    return aggregatedResults;
  }
  return (
    <div className="px-4">
      <div className="py-4">
        <div className="bg-blue-100 rounded-md">
          <h2 className="text-lg p-2 font-bold text-black px-4">{`Summary - ${field.assignTitle}`}</h2>
        </div>
        <form className="mt-2 border border-gray-400 rounded-md p-2 flex justify-center items-center">
          <div className="flex items-center w-full">
            <label className="block mr-2 text-xl text-black">Class:</label>
            <p className="text-black">{field.classCode}</p>
          </div>
          <div className="flex items-center w-full">
            <label className="block mr-2 text-xl text-black">Course:</label>
            <p className="text-black">{field.courseCode}</p>
          </div>
          <div className="flex items-center w-full">
            <label className="block mr-2 text-xl text-black">Assign Date:</label>
            <p className="text-black">{formatDate(field.assigningDate)}</p>
          </div>
          <div className="flex items-center w-full">
            <label className="block mr-2 text-xl text-black">Faculty:</label>
            <p className="text-black">{field.facultyID}</p>
          </div>

        </form>
      </div>
      <div className="pb-4">
        <div className="bg-blue-100 rounded-md">
          <h2 className="text-lg p-2 mb-1 font-bold text-black px-4">{`List of Student who submitted`}</h2>
        </div>
        <table className="min-w-full h-auto bg-white border-collapse rounded-2xl text-sm text-black">
          <thead>
            <tr>
              <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Sr.</th>
              <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Student ID</th>
              <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Student Name</th>
            </tr>
          </thead>
          <tbody>
            {viewDetail && viewDetail.map((item, index) => (
              <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`} key={index}>
                <td className="border-x border-gray-400 p-2 whitespace-nowrap">{index + 1}</td>
                <td className="border-x border-gray-400 p-2 whitespace-nowrap">{item.studID}</td>
                <td className="border-x border-gray-400 p-2 whitespace-nowrap">{item.studName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col items-center justify-center w-full rounded-xl">
        {viewDetail && aggregateSurveyResults(viewDetail).map((result, index) => (
          <div key={index} className="w-full my-2">
            <h2 className="mb-2 text-lg font-semibold text-gray-800">
              {index + 1}: {result.question}
            </h2>
            <div className="mb-4">
              {
                result.questionType === "singleSelection" ?
                  (
                    <SingalAssign result={result.Answer} />
                  ) :
                  result.questionType === "multipleSelection" ?
                    (
                      <MultiAssign result={result.Answer} />
                    ) :
                    result.questionType === "gradingOption" ?
                      (
                        <GradingAssign result={result.Answer} />
                      ) :
                      result.questionType === "numericValueOption" ?
                        (
                          <GradingAssign result={result.Answer} />
                        ) :
                        result.questionType === "multipleSelectOption" || result.questionType === "multipleSelectWithOtherOption" ?
                          (
                            <MultiAssign result={result.Answer} />
                          ) :
                          result.questionType === "singleSelectOption" || result.questionType === "singleSelectWithOtherOption" ?
                            (
                              <SingalAssign result={result.Answer} />
                            )
                            :
                            (<TextAssign result={result.Answer} />)
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewAssignFeedback;
