/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleExclamation,
    faFilePen,
    faMoneyCheckDollar,
    faCalculator,
    faNewspaper,
    faBook,
    faUserTie,
    faUserPlus,
    faEarthAmericas,
    faListCheck,
    faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMenuBtn } from '../../../state/action-creators/index';

function SideBar({
    menuToggle,
    btnHAndle,
}) {
    const dispatch = useDispatch();
    const selectedMenuBtn = useSelector(state => state.selectedMenuBtn);
    const handleButtonClick = (buttonName) => {
        dispatch(setSelectedMenuBtn(buttonName));
    };

    return (
        <div
            className={`h-full text-white bg-PrimaryColor transitionAll overflow-hidden
              5xl:rounded-r-[0.8vw] 5xl:mt-[1vh] 
              4md:rounded-r-[0.8vw] 4md:mt-[1vh] 
              rounded-r-[0.8vw] mt-[0vh] 
        ${menuToggle
                    ? `5xl:w-[20vw]
                    4md:w-[20vw]
                    w-[100vw]
                    `
                    : `w-[0vw]`
                }`}
        >
            <div className="h-[12%] w-full flex px-[1vw] bg-PrimaryDarkColor flex justify-center items-center
            5xl:rounded-[0.8vw]
            4md:rounded-[0.8vw]
            rounded-[0.8vw]
            ">
                <label className="text-2xl font-extrabold tracking-wider 5xl:text-2xl 4md:text-2xl ">Control Panel</label>
            </div>
            <div className="h-[88%] w-full flex flex-col items-center justify-center
            5xl:px-[1vw] 5xl:py-[2vh] 5xl:gap-y-[1vh]
            4md:px-[0.5vw] 4md:py-[2vh] 4md:gap-y-[1vh]
            px-[2vw] py-[1vh] gap-y-[1vh]">

                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Department"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Department")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Department</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Courses"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Courses")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faFilePen} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Courses</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Program"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Program")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faMoneyCheckDollar} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Program</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "LinkProgCourses"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("LinkProgCourses")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faCalculator} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Link Courses</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Session"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Session")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faNewspaper} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Session</button>
                    </div>
                </div>
                <button
                    className={`z-10 absolute bg-white flex justify-center items-center border border-black rounded-full transitionAll 
                        5xl:w-[5vh] 5xl:h-[5vh] 5xl:mt-[-12vh] 5xl:border-[1px]
                        4md:w-[5vh] 4md:h-[5vh] 4md:mt-[-12vh] 4md:border-[1px]
                        w-[6vh] h-[6vh] mt-[-10vh] border-[2px]
                       ${menuToggle
                            ? `rotate-180
                                5xl:ml-[20vw]
                                4md:ml-[20vw]
                                ml-[90vw]
                                `
                            : `5xl:ml-[-1vw] 5xl:pl-1
                            4md:ml-[-1vw] 4md:pl-1
                               ml-[-1vw] pl-1`
                        }`}
                    onClick={btnHAndle}
                >
                    <FontAwesomeIcon icon={faGreaterThan} style={{ color: "#000000", }} />
                </button>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Class"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Class")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faEarthAmericas} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Class</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Student"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Student")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faUserPlus} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Student</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Faculty"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Faculty")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faUserTie} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Faculty</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "ExcecutiveAdmin"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("ExcecutiveAdmin")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faUserPlus} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Excecutive Admin</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "auditTrail"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("auditTrail")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faBook} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Audit Trail</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "WeeklySchedule"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("WeeklySchedule")}
                >
                    <div className="flex items-center justify-start h-full">
                        <FontAwesomeIcon icon={faListCheck} style={{ color: "#ffffff", }} />
                    </div>
                    <div className="flex items-center justify-start h-full">
                        <button>Weekly Class Schedule</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Academic"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Academic")}
                >
                    <div>
                        <FontAwesomeIcon icon={faListCheck} style={{ color: "#ffffff", }} />
                    </div>
                    <div>
                        <button>Academic Calender</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Configurations"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Configurations")}
                >
                    <div>
                        <FontAwesomeIcon icon={faListCheck} style={{ color: "#ffffff", }} />
                    </div>
                    <div>
                        <button>Configurations</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "SelfLearning"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("SelfLearning")}
                >
                    <div>
                        <FontAwesomeIcon icon={faListCheck} style={{ color: "#ffffff", }} />
                    </div>
                    <div>
                        <button>Self Learning</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Feedback"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Feedback")}
                >
                    <div>
                        <FontAwesomeIcon icon={faListCheck} style={{ color: "#ffffff", }} />
                    </div>
                    <div>
                        <button>FeedBack/Survey</button>
                    </div>
                </div>
                {/* <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Paid"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Paid")}
                >
                    <div>
                        <FontAwesomeIcon icon={faListCheck} style={{ color: "#ffffff", }} />
                    </div>
                    <div>
                        <button>Add Payment (Paid)</button>
                    </div>
                </div>
                <div
                    className={`w-full items-center bg-white text-white flex h-[12%] justify-start items-center
                        5xl:rounded-[0.6vw] 5xl:text-lg 5xl:px-[1vw] 5xl:gap-x-[0.7vw]
                        4md:rounded-[0.75vw] 4md:text-md 4md:px-[0.75vw] 4md:gap-x-[0.75vw]
                        rounded-[1vw] text-lg px-[2vw] gap-x-[2vw]
                        ${selectedMenuBtn === "Pending"
                            ? "bg-opacity-10 font-normal"
                            : "bg-opacity-0 font-light"
                        }`}
                    onClick={() => handleButtonClick("Pending")}
                >
                    <div>
                        <FontAwesomeIcon icon={faListCheck} style={{ color: "#ffffff", }} />
                    </div>
                    <div>
                        <button>Add Payment (Pending)</button>
                    </div>
                </div> */}
            </div>

        </div>
    );
}

export default SideBar;
