import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector,useDispatch} from 'react-redux';
import { SetTriggerNotificationStudent } from '../../../../state/action-creators';
import { toast } from "react-hot-toast";
import config from '../../../../config';
import axios from 'axios';
import Apis from '../../../../Apis';
import { MessageByName } from '../../../FacultyComp/Dashboard/Modals/AddNotification';

const RemindersModel = ({
    openReminders,
    RemindersModalHandler,
    confirmAlertReminderHandler,
    Color,
    CourseCode,
    Desc,
    Deadline,
    assignedDate,
    type,
    link,
    assignmentID,
    status,
    ItemNo,
}) => {
    const [maxScreen, setMaxScreen] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const dispatch = useDispatch();
    const backendBaseUrl = config.backendLocalhost;

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    };

    const toggleDetails = () => {
        setExpanded(!expanded);
    };

    const [studFile, setStudFile] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setStudFile(file);
    };

    const currentDate = new Date();
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(currentDate);
    const day = ("0" + date.getDate()).slice(-2);
    const monthIndex = date.getMonth();
    const month = months[monthIndex];
    const year = date.getFullYear(-2);
    const optionsTime = {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
    };

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = currentDate.toLocaleTimeString('en-US', optionsTime);

    const formattedDateTime = `${formattedDate} - ${formattedTime}`;
    const userInfo = useSelector(state => state.userInfo);

    const assignDetailInstance = {
        course: CourseCode,
        No: ItemNo,
        status: 'submitted',
        submittedDetail: formattedDateTime,
        studId: userInfo._id,
        studName: `${userInfo.firstName} ${userInfo.lastName}`,
        obtainedMarks: '',
        studentFile: studFile,
        assignmentID: assignmentID,
        type: type,
    };

    const updateAssignment = () => {
        if (studFile === null) {
            toast.error("ERROR!! No file is attached.")
        }
        else {
            confirmAlertReminderHandler(assignDetailInstance);
        }
    };

    const ResponseMessages = useSelector(state => state.responseMessage);

    const updateNotificationStatus = async (notificationId, studentId, course) => {
        const loading = toast.loading("updating....")
        try {
            const response = await axios.put(`${backendBaseUrl}/notification/update/${notificationId}`, {
                studentID: studentId
            });
            const ResponseMsg = MessageByName("Notification Seen by Student", ResponseMessages);
            toast.success(ResponseMsg);
            Apis.addAuditTrail(userInfo._id, "663491604fba113615476c29", `- [CourseID:${course}, ID:${notificationId}]`);
            toast.dismiss(loading);
            dispatch(SetTriggerNotificationStudent());
            RemindersModalHandler();
            return response.data;
        } catch (error) {
            toast.error("Error occurred!");
            toast.dismiss(loading);
            throw new Error(error.response.data.message);
        }
    };



    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-20
        ${openReminders ? "scale-100 duration-300" : "scale-0 -z-10"}
        ${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[70vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
                }
      `}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
         ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"}
        `}
            >
                <div>
                    <h1 className="text-[2vw] font-semibold">{type} Detail</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <a
                        href={`${backendBaseUrl}/files/${link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300 ${link === "no file" ? 'hidden' : 'block'}`}
                    ><FontAwesomeIcon icon={faFileArrowDown} style={{ color: "#ffffff", }} /></a>
                    <button className="w-[1.2vw] h-[1.2vw]" onClick={maxScreenHandler}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full duration-300 hover:scale-110" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full duration-300 hover:scale-110" icon={faExpand} />}
                    </button>
                    <button className={`w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300 `}
                        onClick={() => RemindersModalHandler()}>
                        <FontAwesomeIcon className="w-full h-full duration-300 hover:scale-110" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col overflow-auto style-scrollbar gap-y-[1vh]
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
                <div className='h-auto w-full p-[1vw]  flex justify-center items-center'>
                    <div className={`flex justify-center items-center text-justify p-[1vw] w-full ${Color} rounded-xl border-[0.1vw] border-red-700 transition-height overflow-auto styled-scrollbar animate ${expanded ? "max-h-[60vh]" : "max-h-[8vh]"} overflow-hidden`} style={{ transitionDuration: '500ms' }}>
                        {expanded ? (
                            <div className={`w-full flex flex-col
                                       ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
                                   `}>
                                {type !== "Notification" &&
                                    <div className={`h-[25%] w-full p-[1vw] ${Color} flex justify-center items-center rounded-[1vw] gap-x-[1.5vw]`}>
                                        <div className='w-[15%] h-full flex flex-col justify-center items-end gap-y-[1vh]'>
                                            <p className='text-[1.2vw]'>{type} No: </p>
                                            <p className='text-[1.2vw]'>Course Code: </p>
                                            <p className='text-[1.2vw]'>Assigned Date: </p>
                                            <p className='text-[1.2vw]'>Due Date: </p>
                                        </div>
                                        <div className='w-[85%] h-full flex flex-col justify-center items-start gap-y-[1vh]'>
                                            <p className='text-[1.2vw] font-semibold'>{ItemNo}</p>
                                            <p className='text-[1.2vw] font-semibold'>{CourseCode}</p>
                                            <p className='text-[1.2vw] font-semibold'>{assignedDate}</p>
                                            <p className='text-[1.2vw] font-semibold text-red-600'>{Deadline}</p>
                                        </div>
                                    </div>
                                }
                                <div className='h-[75%] w-full p-[1vw] flex justify-center items-center gap-x-[0.5vw]'>
                                    <div className='w-[15%] h-full flex flex-col justify-start items-end py-[1vw]'>
                                        <p className='text-[1.2vw]'>Description: </p>
                                    </div>
                                    <div className='w-[85%] h-full flex flex-col justify-start items-start border-black p-[1vw] border-[0.2vw] overflow-auto style-scrollbar'>
                                        <p className='text-[1.2vw] font-medium text-justify'>{Desc}</p>
                                    </div>
                                </div>
                                <div className='flex justify-end px-[1vw]'>
                                    <button className='text-[1vw] font-semibold text-blue-600 hover:underline' onClick={toggleDetails}>
                                        {expanded ? 'See Less' : 'See More'}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <p className='text-[1vw] truncate'>
                                    <span className='font-bold text-red-600 px-[0.5vw] animate'>({CourseCode}) -</span>

                                    <>
                                        {`${Desc.slice(0, expanded ? Desc.length : 70)}... `}
                                        <button className='text-blue-600 hover:underline' onClick={toggleDetails}>
                                            {expanded ? 'See Less' : 'See More'}
                                        </button>
                                    </>


                                    <span className='font-bold text-red-600 px-[0.5vw]'>({Deadline})</span>
                                </p>
                            </>
                        )}
                    </div>
                </div>
                {status ?
                    (
                        <div className='w-full h-[20vh] p-[1vw] flex justify-center items-start'>
                            <div className='w-[20vw] h-[5vw] text-[1vw] cursor-no-drop text-white bg-green-900 rounded-[1vw] flex justify-center items-center'>
                                {type !== "Notification" ? `You already submitted the ${type}.` : `You already seen this ${type}.`}
                            </div>
                        </div>
                    ) :
                    (
                        <div className='w-full flex justify-center items-start'>
                            {type !== "Notification" ?
                                (
                                    <div className="max-w-[50%] px-[2vw] py-[2vh] bg-gray-200 rounded-[0.5vw] shadow-2xl">
                                        <div className="relative mt-[0.5vh] rounded-[0.5vw] shadow-sm ">
                                            <input
                                                id="file_input"
                                                type="file"
                                                className="block w-full px-3 py-2 leading-5 transition-all duration-300 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                                onChange={handleFileChange}
                                            />
                                            <p className="mt-[0.5vh] text-[0.8vw] text-gray-500" id="file_input_help">
                                                PDF Only (Max. 100MB).
                                            </p>
                                        </div>
                                        <button className='px-4 py-2 mt-4 text-sm text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300 focus:ring-blue-200 focus:ring-opacity-50'
                                            onClick={() => updateAssignment()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                ) :
                                (<div>
                                    <button
                                        onClick={() => updateNotificationStatus(assignmentID, userInfo._id, CourseCode)}
                                        className='py-[2vh] px-[2vw] bg-yellow-400 text-[1vw] rounded-xl border border-yellow-900 font-semibold'> Click to add in seen list</button>
                                </div>)}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default RemindersModel;
