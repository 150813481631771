import React from 'react';
import EditButton from '../EditButton';
import { useSelector } from 'react-redux';


const ViewDepartment = ({ setUpdateDept, handleButtonClick }) => {
  const departments = useSelector(state => state.allDept);

  const handleEditDepartment = (dept) => {
    setUpdateDept(dept);
    handleButtonClick('create');
  };

  return (
    <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
      <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
        <thead className=''>
          <tr className='text-sm font-semibold'>
            <th className="border border-gray-400 p-2 bg-gray-200 text-center">Sr.</th>
            <th className="border border-gray-400 p-2 bg-gray-200 text-left">Name</th>
            <th className="border border-gray-400 p-2 bg-gray-200 text-left">Department Code</th>
            <th className="border border-gray-400 p-2 bg-gray-200 text-left">Email</th>
            <th className="border border-gray-400 p-2 bg-gray-200 text-left">Contact No</th>
            <th className="border border-gray-400 p-2 bg-gray-200 text-left">Website</th>
            <th className="border border-gray-400 p-2 bg-gray-200 text-left">Location</th>
            <th className="border border-gray-400 p-2 bg-gray-200 text-left">Address</th>
            <th className="border border-gray-400 p-2 bg-gray-200 text-center">Status</th>
            <th className="border border-gray-400 p-2 bg-gray-200 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {departments.map((dept, index) => (
            <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`} key={index}>
              <td className="border-x border-gray-400 p-2 whitespace-nowrap text-left">{index + 1}</td>
              <td className="border-x border-gray-400 p-2 whitespace-nowrap text-left">{dept.department}</td>
              <td className="border-x border-gray-400 p-2 whitespace-nowrap text-left">{dept.deptCode}</td>
              <td className="border-x border-gray-400 p-2 whitespace-nowrap text-left">{dept.email}</td>
              <td className="border-x border-gray-400 p-2 whitespace-nowrap text-left">{dept.contact}</td>
              <td className="border-x border-gray-400 p-2 whitespace-nowrap text-left">
                <a
                  className={`cursor-pointer hover:text-blue-600 hover:underline duration-300 ${dept.website ? "" : "hidden"}`}
                  href={dept.website}
                  target="_blank"
                  rel="noopener noreferrer">
                  {"Click here"}
                </a>
              </td>
              <td className="border-x border-gray-400 p-2 whitespace-nowrap text-left">
                <a
                  className={`cursor-pointer hover:text-blue-600 hover:underline duration-300 ${dept.location ? "" : "hidden"}`}
                  href={dept.location}
                  target="_blank"
                  rel="noopener noreferrer">
                  {"Click here"}
                </a>
              </td>
              <td className="border-x border-gray-400 p-2 whitespace-nowrap text-left">
                {dept.address}
              </td>
              <td className={`border-x border-gray-400 p-2 whitespace-nowrap text-left ${dept.status ? "text-green-600" : "text-red-600"}`}> {dept.status ? "Active" : "In-Active"} </td>
              <td className="border-x border-gray-400 p-2 whitespace-nowrap text-left">
                <button onClick={() => handleEditDepartment(dept)}>
                  <EditButton />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewDepartment