import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faMessage,faExpand,faCompress } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators';
import { toast } from "react-hot-toast";
import axios from 'axios';
import config from '../../../../config';

const ViewChatbox = ({ ChatBoxDetailHandler }) => {
    const selectedActivity = useSelector(state => state.selectedActivity);
    const dispatch = useDispatch();
    const [maxScreen, setMaxScreen] = useState(false);
    const userInfo = useSelector(state => state.userInfo);
    const allStudentInfo = useSelector(state => state.allStudentInfo);
    
    const handleActivityList = (value) => {
        dispatch(setSelectedActivity(value));
    };

    const [selectedClass, setSelectedClass] = useState('');
    const [classes, setClasses] = useState([]);

    useEffect(() => {
        const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map(info => info.class)));
        setClasses(uniqueClasses);
    }, [userInfo.facultyDetails]);

    const filterStudentsByClass = (targetClass) => {
        return allStudentInfo.filter(student => student.role === 'student' && student.studentDetails && student.studentDetails.class === targetClass);
    };

    // Example usage:
    const [classToFilter, setClassToFilter] = useState([]);

    useEffect(() => {
        setClassToFilter(filterStudentsByClass(selectedClass));
    }, [selectedClass]);

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
        if(maxScreen){
          handleActivityList("Chatbox")
        }
        else{
          handleActivityList("")
        }
    
    };
  
    return (
        <div className='w-full pb-[2vh]'>

        <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh] px-[1vw]'>
            {/* Select Class */}
            <select
                id="classSelect"
                className='w-auto h-full text-black text-left border border-slate-700 rounded-[1vw] px-[1vw] focus:border-slate-700 focus:outline-none cursor-pointer'
                value={selectedClass}
                onChange={(e) => setSelectedClass(e.target.value)}
            >
                <option value="">Select a class</option>
                {classes.map((className) => (
                    <option key={className} value={className}>
                        {className}
                    </option>
                ))}
            </select>
        </div>
        <div className='w-full'>
            <table className="min-w-full bg-white text-black border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
                <thead className="sticky top-0 bg-white w-full">
                    <tr>
                        <th className="w-[5%] px-[1vw] py-[1vh] border-b text-center border border-gray-400">Sr</th>
                        <th className="w-[50%] px-[1vw] py-[1vh] border-b text-left border border-gray-400">Name</th>
                        <th className="w-[20%] px-[1vw] py-[1vh] border-b text-left border border-gray-400">Contact No.</th>
                        <th className="w-[15%] px-[1vw] py-[1vh] border-b text-left border border-gray-400">Roll No</th>
                        <th className="w-[10%] px-[1vw] py-[1vh] border-b text-center border border-gray-400">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {classToFilter && classToFilter.map((row, index) => (
                        <tr key={row.sr} className={index % 2 === 0 ? 'bg-blue-50' : 'bg-blue-200'}>
                            <td className="w-[5%] px-[1vw] py-[1vh] border-b text-center">{index + 1}</td>
                            <td className="w-[50%] px-[1vw] py-[1vh] border-b text-left">{row.firstName + " " + row.lastName}</td>
                            <td className="w-[20%] px-[1vw] py-[1vh] border-b text-left">{row.phoneNo}</td>
                            <td className="w-[15%] px-[1vw] py-[1vh] border-b text-left">{row.studentDetails.rollNo}</td>
                            <td className="w-[10%] px-[3vw] py-[1vh] border-b">
                                <button className={`w-full h-full p-[1vh] bg-[#6ECB32] hover:bg-green-950 duration-300 rounded-[0.3vw] flex justify-center items-center`}
                                    onClick={() => ChatBoxDetailHandler(row)}>
                                    <FontAwesomeIcon icon={faMessage} style={{ color: "#ffffff", }} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    </div>
    )
}

export default ViewChatbox