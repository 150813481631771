import React from 'react';
import EditButton from '../EditButton';
import { useSelector } from 'react-redux';

const ViewAcademic = ({setUpdateAcademic,handleButtonClick}) => {
  const allAcademic = useSelector(state => state.allAcademic)

  const DeleteAcademic = async (val) => {
    handleButtonClick("create");
    setUpdateAcademic(val);
  };


  return (
    <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
      <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
        <thead>
          <tr className='text-sm font-semibold'>
            <th className="px-4 py-3 text-center border border-gray-400">Sr.</th>
            <th className="px-4 py-3 text-left border border-gray-400">Session</th>
            <th className="px-4 py-3 text-left border border-gray-400">Activity</th>
            <th className="px-4 py-3 text-left border border-gray-400">Starting Date</th>
            <th className="px-4 py-3 text-left border border-gray-400">Ending Date</th>
            <th className="px-4 py-3 text-left border border-gray-400">Remarks</th>
            <th className="px-4 py-3 text-center border border-gray-400">Actions</th>
          </tr>
        </thead>
        <tbody>
          {allAcademic.map((dept, index) => (
            dept.academicActivity.map((activity, activityIndex) => (
              <tr key={`${dept._id}-${activityIndex}`} className={`text-sm font-normal divide-y divide-gray-200 ${activityIndex % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}>
 
                {activityIndex === 0 && (
                  <>
                    <td className="px-4 py-3 text-center" rowSpan={dept.academicActivity.length}>{index + 1}</td>
                    <td className="px-4 py-3 text-left" rowSpan={dept.academicActivity.length}>
                      {dept.sessionCode}
                    </td>
                  </>
                )}
                <td className="px-4 py-3 truncate text-left">{activity.activity}</td>
                <td className="px-4 py-3 text-left">{activity.startDate}</td>
                <td className="px-4 py-3 text-left">{activity.endDate}</td>
                <td className="px-4 py-3 text-left">{activity.remarks}</td>
                {activityIndex === 0 && (
                  <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center" rowSpan={dept.academicActivity.length}>
                    <button onClick={() => DeleteAcademic(dept)}>
                      <EditButton />
                    </button>
                  </td>
                )}
              </tr>
            ))
          ))}
        </tbody>
      </table>

    </div>
  );
};

export default ViewAcademic