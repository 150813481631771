// reducers/FacultyActivityListToggle.js
import { SET_VIEW_FILE } from '../action-creators/ActionTypes';

const ViewFile = (state = {
    openModal: false,
    FileData: {},
}, action) => {
    switch (action.type) {
        case SET_VIEW_FILE:
            return state === action.payload ? {
                openModal: false,
                FileData: {},
            } : action.payload;
        default:
            return state;
    }
};

export default ViewFile;
