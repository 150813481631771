import React from 'react';
import EditButton from '../EditButton';
import { useSelector } from 'react-redux';

const ViewClass = ({setUpdateClass,handleButtonClick}) => {
  const allClasses = useSelector(state => state.allClass);
  const allProgram = useSelector(state => state.allProgram);

  const generateProgramTitle = (programCode) => {
    if (!allProgram || !Array.isArray(allProgram)) {
        console.error('Invalid programs array.');
        return "";
    }
    const program = allProgram.find(prog => prog.programCode === programCode);
    return program ? program.programName + " ["+program.programCode+"]" : "";
};
    
  const handleEditClass = (updateClass) => {
    setUpdateClass(updateClass);
    handleButtonClick('create');
  };

  return (
    <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
    <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
        <thead className=''>
          <tr className='text-sm font-semibold'>
            <th className="px-4 py-3 text-center border border-gray-400">Sr.</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Class Code</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Semester</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">Program Code</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Session Code</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Section Code</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Department Code</th>
            <th className="px-4 py-3 text-center border  border-gray-400">Status</th>
            <th className="px-4 py-3 text-center border  border-gray-400 ">Actions</th>
          </tr>
        </thead>
        <tbody>
          {allClasses && allClasses?.map((updateClass, index) => (
            <tr key={updateClass._id}
              className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}
            >
              <td className="px-4 py-3 text-center ">{index + 1}</td>
              <td className="px-4 py-3 truncate text-left">{updateClass.classCode}</td>
              <td className="px-4 py-3 truncate text-left">{updateClass.semester}</td>
              <td className="px-4 py-3 truncate text-left">{generateProgramTitle(updateClass.program)}</td>
              <td className="px-4 py-3 text-left">{updateClass.session}</td>
              <td className="px-4 py-3 truncate text-left">{updateClass.section}</td>
              <td className="px-4 py-3 truncate text-left">{updateClass.department}</td>
              <td className={`px-4 py-3 border-b text-center ${updateClass.status ? "text-green-600" :"text-red-600"}`}> {updateClass.status ? "Active":"In-Active"} </td>
              <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center  ">
                <button onClick={() => handleEditClass(updateClass)}>
                  <EditButton />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewClass