import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../state/action-creators';
import Apis from '../Apis';
import Navbar from './Admin/compoonents/Navbar'
import FacultyHeader from './FacultyComp/Header';
import EmployeeHeader from './employeeComp/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-hot-toast";
import {MessageByName} from './FacultyComp/Dashboard/Modals/AddNotification';


function Profile() {
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const ResponseMessages = useSelector(state => state.responseMessage);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const [editingId, setEditingId] = useState(null);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNo: '',
        cnic: '',
        email: '',
        password: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const userInfo = useSelector(state => state.userInfo);

    useEffect(() => {
        if (userInfo) {
            setEditingId(userInfo._id);
            setFormData({
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                phoneNo: userInfo.phoneNo,
                cnic: userInfo.cnic,
                email: userInfo.email,
                password: '',
                role: userInfo.role,
            })
        }
    }, [userInfo]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userInfo = await Apis.getUserInfo();
                dispatch(setUserInfo(userInfo || []));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [dispatch]);


    const handleUpdateProfile = async () => {
        try {
            const userInfo = await Apis.updateUserProfileByID(editingId, formData);
            const ResponseMsg = MessageByName(userInfo.response.data.message,ResponseMessages);
            toast.error(ResponseMsg);
        } catch (error) {
            console.error('Error updating Profile Data:', error);
        }
    };

    return (
        <div className=' w-[100vw] h-[100vh] bg-blue-100'>
            {formData.role === "admin" ? <Navbar /> :
                formData.role === "faculty" ? <FacultyHeader /> :
                    formData.role === "student" ? <EmployeeHeader /> :
                        <Navbar />
            }

            <div className='flex h-[90vh] justify-center items-start overflow-hidden'>
                <div className='w-[80%] flex flex-col justify-center items-center gap-y-[1vh] my-[5vh] p-[2vw] bg-white shadow-2xl rounded-2xl'>
                    <div className='w-full flex items-center justify-start gap-x-[1vw]'>
                        <div className='w-1/2 flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='deptCode' className='font-semibold'>First Name:</label>
                            <input
                                type="text"
                                placeholder="Enter your First Name"
                                className="input w-full border-PrimaryColor focus:outline-PrimaryColor focus:border-PrimaryColor font-normal text-[1vw]"
                                id='firstName'
                                name="firstName"
                                value={formData.firstName}
                                disabled={formData.role === "admin" ? false :true}
                            />
                        </div>
                        <div className='w-1/2 flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='deptCode' className='font-semibold'>Last Name:</label>
                            <input
                                type="text"
                                placeholder="Enter your Last Name"
                                className="input w-full border-PrimaryColor focus:outline-PrimaryColor focus:border-PrimaryColor font-normal text-[1vw]"
                                id='lastName'
                                name="lastName"
                                value={formData.lastName}
                                disabled={formData.role === "admin" ? false :true}
                            />
                        </div>
                    </div>
                    <div className='w-full flex items-center justify-start gap-x-[1vw]'>
                        <div className='w-1/2 flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='phoneNo' className='font-semibold'>Phone No:</label>
                            <input
                                type="text"
                                placeholder="Enter your Phone No"
                                className="input w-full border-PrimaryColor focus:outline-PrimaryColor focus:border-PrimaryColor font-normal text-[1vw]"
                                id='phoneNo'
                                name="phoneNo"
                                value={formData.phoneNo}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-1/2 flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='cnic' className='font-semibold'>CNIC No:</label>
                            <input
                                type="text"
                                placeholder="Enter your CNIC without dashes"
                                className="input w-full border-PrimaryColor focus:outline-PrimaryColor focus:border-PrimaryColor font-normal text-[1vw]"
                                id='cnic'
                                name="cnic"
                                value={formData.cnic}
                                disabled={formData.role === "admin" ? false :true}
                            />
                        </div>
                    </div>
                    <div className='w-full flex items-center justify-start gap-x-[1vw]'>
                        <div className='w-1/2 flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='deptCode' className='font-semibold'>Email address:</label>
                            <input
                                type="text"
                                placeholder="Email address will used as username"
                                className="input w-full border-PrimaryColor focus:outline-PrimaryColor focus:border-PrimaryColor font-normal text-[1vw]"
                                id='email'
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-1/2 flex flex-col gap-y-[0.5vh] justify-center items-start relative'>
                            <label htmlFor='password' className='font-semibold'>Password:</label>
                            <div className="w-full relative">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder={"Enter new Password"}
                                    className="input w-full border-PrimaryColor focus:outline-PrimaryColor focus:border-PrimaryColor font-normal text-[1vw] pr-10"
                                    id='password'
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
                                    onClick={handleTogglePasswordVisibility}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex items-center justify-center gap-x-[1vw]'>
                        <button
                            className='px-[1vw] py-[1vh] bg-PrimaryColor text-white rounded-[0.5vw] font-bold'
                            type='button'
                            onClick={handleUpdateProfile}
                        >
                            Update Profile Data
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Profile