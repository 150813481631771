import React from 'react'
import LearningPlatforms from '../../../components/employeeComp/Dashboard/ELearningPlatforms/LearningPlatforms'

const LearningMaterial = ({ElearningModalHandler}) => {
  return (
    <div className='h-full w-full'>
     <LearningPlatforms
     ElearningModalHandler={ElearningModalHandler}/>
    </div>
  )
}

export default LearningMaterial
