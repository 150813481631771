import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function DeleteButton() {
  return (
    <div className={`flex justify-center items-center bg-[#FF6347]
    5xl:w-[4vh] 5xl:h-[3vh] 5xl:rounded-[0.3vw] 5xl:text-sm
    4md:p-[0.75vh] 4md:rounded-[0.3vw]  4md:text-sm
    p-[1vh] rounded-[0.7vw] text-lg`}> 
      <FontAwesomeIcon icon={faTrash} style={{ color: "#fafcff" }} />
    </div>
  );
}

export default DeleteButton;
