import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators'; 
import config11 from '../../../../config';

const ViewNotification = ({ NotificationModalHandler}) => {
  // State variables to manage selected values
  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);
  const [maxScreen, setMaxScreen] = useState(false);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const dispatch = useDispatch();
  const selectedActivity = useSelector(state => state.selectedActivity);
  const userInfo = useSelector(state => state.userInfo);

  const handleActivityList = (value) => {
    dispatch(setSelectedActivity(value));
  };

  useEffect(() => {
    const filteredCourses = userInfo.facultyDetails.courseDetail
      .filter(info => info.class === selectedClass)
      .map(info => info.course);
    setCourses(filteredCourses);
  }, [selectedClass]);

  useEffect(() => {
    const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map(info => info.class)));
    setClasses(uniqueClasses);
  }, []);

  const assignments = useSelector(state => state.assignmentDetails);

  useEffect(() => {
    const sortedAssignments = [...assignments].sort((b, a) => new Date(a.uploadDate) - new Date(b.uploadDate));
    if (sortedAssignments.length !== 0) {
      let filtered = sortedAssignments.filter((assignment) => {
        if (!selectedCourse && !selectedClass) {
          return true;
        }
        if (selectedCourse && selectedClass) {
          return (
            assignment.course === selectedCourse &&
            assignment.class === selectedClass
          );
        }
        if (selectedCourse) {
          return assignment.course === selectedCourse;

        }
        if (selectedClass) {
          return assignment.class === selectedClass;

        }
        return false;
      });
      if (selectedCourse && selectedClass) {
        setFilteredAssignments(filtered);
      }
      else {
        setFilteredAssignments([]);
      }
    }
  }, [selectedCourse, selectedClass, assignments]);

  const maxScreenHandler = () => {
    setMaxScreen(!maxScreen);
    if(maxScreen){
      handleActivityList("Notification")
    }
    else{
      handleActivityList("")
    }
};
  return (
    <div className='w-full pb-[2vh]'>
          <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh] px-[1vw]'>
            {/* Select Class */}
            <select
              id="classSelect"
              className='w-auto h-full text-black text-left border border-gray-700 rounded-[1vw] px-[1vw] focus:border-gray-700 focus:outline-none cursor-pointer'
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option value="">Select a class</option>
              {classes.map((className) => (
                <option key={className} value={className}>
                  {className}
                </option>
              ))}
            </select>

            {/* Select Course */}
            <select
              id="courseSelect"
              className='w-auto h-full text-black text-left border border-gray-700 rounded-[1vw] px-[1vw] focus:border-gray-700 focus:outline-none cursor-pointer'
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              <option value="">Select a course</option>
              {courses.map((course) => (
                <option key={course} value={course}>
                  {course}
                </option>
              ))}
            </select>
            {/* Add Notification */}
            <button className='w-auto h-full text-1 px-[1vw] text-center border border-gray-700 rounded-[1vw] focus:border-gray-700 focus:outline-none
          bg-white text-black hover:bg-gray-700 hover:text-white duration-300 text-0.5xl'
              onClick={() => NotificationModalHandler(selectedCourse, selectedClass)}>
              Add Notification
            </button>
          </div>
          <div className='w-full px-[1vw] flex flex-col gap-y-[1vh]'>
            
          </div>
        </div>
  )
}

export default ViewNotification