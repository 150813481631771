// reducers/FacultyActivityListToggle.js
import { SET_CONFIG_NAME } from '../action-creators/ActionTypes';

const ConfigName = (state = [] , action) => {
    switch (action.type) {
        case SET_CONFIG_NAME:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};
export default ConfigName