import React from 'react';
import EditButton from '../EditButton';
import { useSelector } from 'react-redux';

const ViewFaculty = ({ setUpdateFacultyInfo, handleButtonClick }) => {

  const allFacultyInfo = useSelector(state => state.allFacultyInfo);

  const handleEditFacultyt = (faculty) => {
    setUpdateFacultyInfo(faculty);
    handleButtonClick('create');
  };

  return (
    <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
      <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
        <thead className=''>
          <tr className='text-sm font-semibold'>
            <th className="px-4 py-3 text-center border border-gray-400">Sr.</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">First Name</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Last Name</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">Phone No</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">CNIC</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">Email</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">Department</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Course Detail</th>
            <th className="px-4 py-3 text-center border  border-gray-400 ">Status</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Action</th>
          </tr>
        </thead>
        <tbody>
          {allFacultyInfo.map((val, index) => (
            <tr key={val._id}
              className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}
            >
              <td className="px-4 py-3 text-center ">{index + 1}</td>
              <td className="px-4 py-3 text-left">{val.firstName}</td>
              <td className="px-4 py-3 text-left">{val.lastName}</td>
              <td className="px-4 py-3 text-left">{val.phoneNo}</td>
              <td className="px-4 py-3 text-left">{val.cnic}</td>
              <td className="px-4 py-3 text-left">{val.email}</td>
              <td className="px-4 py-3 text-left">{val.facultyDetails.department}</td>
              <td className="px-4 py-3 text-left flex flex-col justify-center items-start gap-y-[0.5vh]">
                {val.facultyDetails.courseDetail.map((detail, index) => (
                  <div key={index} className="flex justify-center items-start">{`${detail.class} - ${detail.course}`}</div>
                ))}
              </td>

              <td className={`px-4 py-3 text-center font-bold ${val.validity ? "text-green-600" : "text-red-600"}`}>{val.validity ? "Valid" : "In-Valid"}</td>
              <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center  ">
                <button onClick={() => handleEditFacultyt(val)}>
                  <EditButton />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewFaculty