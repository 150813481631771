import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../state/action-creators';
import Apis from '../Apis';
import MainDashboard from '../components2/Employee/MainDasboard';
import Header from '../components/employeeComp/Header'
import Dashboard from '../components/employeeComp/Dashboard';
import Assignment from '../components/employeeComp/Dashboard/GradeReport/Assignment';
import Quiz from '../components/employeeComp/Dashboard/GradeReport/Quiz';
import CoursePlan from '../components/employeeComp/Dashboard/GradeReport/CoursePlan';
import Exam from '../components/employeeComp/Dashboard/GradeReport/Exam';
import Attendance from '../components/employeeComp/Dashboard/GradeReport/Attendance';
import Classes from '../components/employeeComp/Dashboard/GradeReport/Classes';
import Paid from '../components/employeeComp/Dashboard/PaymentHistory/Paid';
import Pending from '../components/employeeComp/Dashboard/PaymentHistory/Pending';
import CalenderModal from '../components/employeeComp/Dashboard/AcademicCalenderModal/CalenderModal';
import RemindersModel from '../components/employeeComp/Dashboard/reminders/RemindersModel';
import ConfirmAlert from '../components/employeeComp/Dashboard/reminders/ConfirmAlert';
import AllSemModal from '../components/employeeComp/Dashboard/AllSemesterResult/AllSemModal';
import TimeTableModal from '../components/employeeComp/Dashboard/TimeTable/TimeTableModal';
import FeedBackModal from '../components/employeeComp/Dashboard/FeebBack/FeedBackModal';
import ELearningModal from '../components/employeeComp/Dashboard/ELearningPlatforms/ELearningModal';
import ViewFileModal from '../components/ViewFileModal';
import { useSelector } from 'react-redux';
import Loading from "../assets/images/splashscreen.jpg"
import OnlineclassModal from '../components/employeeComp/Dashboard/OnlineClass/OnlineclassModal';
import SurveyModal from '../components/employeeComp/Dashboard/Survey/SurveyModal';
import SelfLearning from '../components/employeeComp/Dashboard/GradeReport/SelfLearning';

const EmployeeDashboard = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await Apis.getUserInfo();
        dispatch(setUserInfo(userInfo || []));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [dispatch]);

  
  const userInfo = useSelector(state => state.userInfo);
  const [courseName, setCourseName] = useState('');
  const [courseDetail, setCourseDetail] = useState([{}]);
  const [openAssignment, setopenAssignment] = useState(false);
  const [openQuiz, setopenQuiz] = useState(false);
  const [openCoursePlan, setOpenCoursePlan] = useState(false);
  const [openFinal, setOpenFianl] = useState(false);
  const [openAttendance, setOpenAttendance] = useState(false);
  const [openClasses, setOpenClasses] = useState(false);
  const [openPaid, setOpenPaid] = useState(false);
  const [openPending, setOpenPending] = useState(false);
  const [openAcademic, setOpenAcademic] = useState(false);
  const [openSurvey, setOpenSurvey] = useState(false);
  const [openReminders, setOpenReminders] = useState(false);
  const [feedbackData, setFeedbackData] = useState()
  const [selectedData, setSelectedData] = useState({
    color: "",
    CourseCode: "",
    Desc: "",
    Deadline: "",
    assignedData: "",
    Type: "",
    link: "",
    assignmentID: '',
    status: false,
    ItemNo: 1,
  });
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [detailConfirmAlert, setDetailConfirmAlert] = useState([]);
  const [openAllSemModal, setOpenAllSemModal] = useState(false);
  const [openTimeTableModal, setOpenTimeTableModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [chatBoxDetail, setChatBoxDetail] = useState({ FacultyDetail: [] });
  const [openEearningModal, setOpenElearningModal] = useState(false);
  const [eLearningName, setELearningName] = useState('');
  const [eLearningDetail, setELearningDetail] = useState([]);
  const [openJoinMeeting, setOpenJoinMeeting] = useState(false);
  const [openSelfLearning, setOpenSelfLearning] = useState(false);
  const [selfLearningDetail, setSelfLearningDetail] = useState([]);

  const JoinMeetingHandler = (val) => {
    setOpenJoinMeeting(val)
  }

  const AssignmentModalHandler = (assignmentDetail, courseName) => {
    if (assignmentDetail.length !== 0) {
      setCourseDetail(assignmentDetail);
      setCourseName(courseName)
    }
    setopenAssignment(!openAssignment);
  };
  const QuizModalHandler = (quizDetail, courseName) => {
    if (quizDetail.length !== 0) {
      setCourseDetail(quizDetail);
      setCourseName(courseName)
    }
    setopenQuiz(!openQuiz);
  };
  const FinalModalHandler = (examDetail, courseName) => {
    if (examDetail.length !== 0) {
      setCourseDetail(examDetail);
      setCourseName(courseName)
    }
    setOpenFianl(!openFinal);
  };
  const AttendanceModalHandler = (attendanceDetail, courseName) => {
    if (attendanceDetail.length !== 0) {
      setCourseDetail(attendanceDetail);
      setCourseName(courseName)
    }
    setOpenAttendance(!openAttendance);
  };
  const CoursePlanModalHandler = (courseName) => {
    if (courseName) {
      setCourseName(courseName)
    }
    setOpenCoursePlan(!openCoursePlan);
  };

  const ClassesModalHandler = (courseTitle) => {
    setCourseName(courseTitle);
    setOpenClasses(!openClasses);
  };
  const PaidModalHandler = () => {
    setOpenPaid(!openPaid);
  };
  const PendingModalHandler = () => {
    setOpenPending(!openPending);
  };
  const AcademicModalHandler = () => {
    setOpenAcademic(!openAcademic);
  };
  const SurveyModalHandler = () => {
    setOpenSurvey(!openSurvey);
  };
  const RemindersModalHandler = () => {
    setOpenReminders(!openReminders);
  };

  const confirmAlertReminderHandler = (detailInfo) => {
    setOpenConfirmAlert(!openConfirmAlert);

    setDetailConfirmAlert(detailInfo)
  };

  const SelectedRemindersHandler = (data) => {
    if(data.type === "Survey"){
      setFeedbackData(data);
      SurveyModalHandler();
    }
    else{
      RemindersModalHandler();
      setSelectedData({
        color: data.Color,
        CourseCode: data.Course,
        Desc: data.Description,
        Deadline: data.DeadlineDate,
        assignedData: data.assignedDate,
        Type: data.type,
        link: data.link,
        assignmentID: data.assignID,
        status: data.statu,
        ItemNo: data.ItemNo,
      });
    }
  };

  const AllSemModalHandler = () => {
    setOpenAllSemModal(!openAllSemModal);
  };
  const TimeTableModalHandler = () => {
    setOpenTimeTableModal(!openTimeTableModal);
  };
  const FeedbackModalHandler = (detail) => {
    setOpenFeedbackModal(!openFeedbackModal);
    setChatBoxDetail(detail);
  };

  const ElearningModalHandler = (Detail, courseName, title) => {
    setELearningDetail(Detail);
    setCourseName(courseName);
    setELearningName(title);
    setOpenElearningModal(!openEearningModal);
  };

  const SelfLearningHandler = (objvalue,courseName) => {
    if (objvalue) {
      setSelfLearningDetail(objvalue);
      setCourseName(courseName)
    }
    setOpenSelfLearning(!openSelfLearning);
  };

  const viewFile = useSelector(state => state.viewFile);

  return (
    <>
      {!userInfo._id ? (
        <div>
          <img
            alt=''
            src={Loading}
            className='w-[100vw] h-[100vh] cover-full'
          />
        </div>

      ) : (
        <div className='relative w-full h-full bg-PrimaryDarkColor'>
          {userInfo.Theme ?
            <MainDashboard
              AssignmentModalHandler={AssignmentModalHandler}
              QuizModalHandler={QuizModalHandler}
              CoursePlanModalHandler={CoursePlanModalHandler}
              FinalModalHandler={FinalModalHandler}
              AttendanceModalHandler={AttendanceModalHandler}
              ClassesModalHandler={ClassesModalHandler}
              PaidModalHandler={PaidModalHandler}
              PendingModalHandler={PendingModalHandler}
              AcademicModalHandler={AcademicModalHandler}
              SelectedRemindersHandler={SelectedRemindersHandler}
              AllSemModalHandler={AllSemModalHandler}
              TimeTableModalHandler={TimeTableModalHandler}
              FeedbackModalHandler={FeedbackModalHandler}
              ElearningModalHandler={ElearningModalHandler}
              SurveyModalHandler={SurveyModalHandler}
              openAcademic={openAcademic}
              openSurvey={openSurvey}
            />
            :
            <><Header />
              <Dashboard
                AssignmentModalHandler={AssignmentModalHandler}
                QuizModalHandler={QuizModalHandler}
                CoursePlanModalHandler={CoursePlanModalHandler}
                FinalModalHandler={FinalModalHandler}
                AttendanceModalHandler={AttendanceModalHandler}
                ClassesModalHandler={ClassesModalHandler}
                PaidModalHandler={PaidModalHandler}
                PendingModalHandler={PendingModalHandler}
                AcademicModalHandler={AcademicModalHandler}
                SelectedRemindersHandler={SelectedRemindersHandler}
                AllSemModalHandler={AllSemModalHandler}
                TimeTableModalHandler={TimeTableModalHandler}
                FeedbackModalHandler={FeedbackModalHandler}
                ElearningModalHandler={ElearningModalHandler}
                JoinMeetingHandler={JoinMeetingHandler}
                SurveyModalHandler={SurveyModalHandler}
                SelfLearningHandler={SelfLearningHandler}
              /></>}
          <Assignment
            openAssignment={openAssignment}
            AssignmentModalHandler={AssignmentModalHandler}
            courseName={courseName}
            courseDetail={courseDetail}
          />

          <Quiz
            openQuiz={openQuiz}
            QuizModalHandler={QuizModalHandler}
            courseName={courseName}
            courseDetail={courseDetail}
          />

          <CoursePlan
            openCoursePlan={openCoursePlan}
            CoursePlanModalHandler={CoursePlanModalHandler}
            courseName={courseName}
          />

          <Exam
            openFinal={openFinal}
            FinalModalHandler={FinalModalHandler}
            courseName={courseName}
            courseDetail={courseDetail}
          />

          <Attendance
            openAttendance={openAttendance}
            AttendanceModalHandler={AttendanceModalHandler}
            courseName={courseName}
            courseDetail={courseDetail}
          />

          <Classes
            openClasses={openClasses}
            ClassesModalHandler={ClassesModalHandler}
            courseName={courseName}
          />

          <Paid
            openPaid={openPaid}
            PaidModalHandler={PaidModalHandler}
          />
          <Pending
            openPending={openPending}
            PendingModalHandler={PendingModalHandler}
          />

          <CalenderModal
            openAcademic={openAcademic}
            AcademicModalHandler={AcademicModalHandler}
          />

          <SurveyModal
            openSurvey={openSurvey}
            SurveyModalHandler={SurveyModalHandler}
            feedbackData={feedbackData}
          />

          <RemindersModel
            openReminders={openReminders}
            RemindersModalHandler={RemindersModalHandler}
            confirmAlertReminderHandler={confirmAlertReminderHandler}
            Color={selectedData.color}
            CourseCode={selectedData.CourseCode}
            ItemNo={selectedData.ItemNo}
            Desc={selectedData.Desc}
            Deadline={selectedData.Deadline}
            type={selectedData.Type}
            link={selectedData.link}
            assignmentID={selectedData.assignmentID}
            assignedDate={selectedData.assignedData}
            status={selectedData.status}
          />
          <ConfirmAlert
            assignDetailInstance={detailConfirmAlert}
            confirmAlertReminderHandler={confirmAlertReminderHandler}
            RemindersModalHandler={RemindersModalHandler}
            openConfirmAlert={openConfirmAlert}
          />

          <AllSemModal
            openAllSemModal={openAllSemModal}
            AllSemModalHandler={AllSemModalHandler}
          />

          <TimeTableModal
            openTimeTableModal={openTimeTableModal}
            TimeTableModalHandler={TimeTableModalHandler}
          />
          <FeedBackModal
            openFeedbackModal={openFeedbackModal}
            FeedbackModalHandler={FeedbackModalHandler}
            chatBoxDetail={chatBoxDetail}
          />

          <ELearningModal
            openEearningModal={openEearningModal}
            ElearningModalHandler={ElearningModalHandler}
            courseName={courseName}
            eLearningDetail={eLearningDetail}
            eLearningName={eLearningName}
          />

          <ViewFileModal />

          <OnlineclassModal
          openJoinMeeting={openJoinMeeting}
          JoinMeetingHandler={JoinMeetingHandler}
          />

          <SelfLearning
          SelfLearningHandler={SelfLearningHandler}
          selfLearningDetail={selfLearningDetail}
          openSelfLearning={openSelfLearning}
          courseName={courseName}
          />

          {openAssignment || openCoursePlan || openQuiz || openFinal || openAttendance || openClasses || openPaid || openPending || openReminders || openAcademic || openAllSemModal || openTimeTableModal || openFeedbackModal || openEearningModal || openSurvey || viewFile.openModal || openJoinMeeting ||openSelfLearning ? (
            <div
              className={`fixed inset-0 opacity-80 z-10  transition-opacity`}
              style={{ backgroundColor: "#202020" }}
            ></div>
          ) : (
            ""
          )}
          {openConfirmAlert ? (
            <div
              className={`fixed inset-0 opacity-80 z-30  transition-opacity`}
              style={{ backgroundColor: "#202020" }}
            ></div>
          ) : (
            ""
          )}

        </div>
      )}
    </>
  )
}

export default EmployeeDashboard