import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears, faWrench } from "@fortawesome/free-solid-svg-icons";

import TitleLogo from './Configurations/TitleLogo';
import AuditTrailMsg from './Configurations/AuditTrailMsg';
import ResponseMessages from './Configurations/ResponseMessages';

function Configurations() {
    const [selectedStatus, setSelectedStatus] = useState("TitleLogo");

    const handleButtonClick = (buttonName) => {
        setSelectedStatus(buttonName);
    };


    return (
        <div className='flex flex-col items-start justify-start w-full
    5xl:px-[1vw] 5xl:py-[1vh] 5xl:h-full
    4md:px-[1vw] 4md:py-[1vh] 4md:h-full
    px-[1vw] py-[1vh] h-auto
    '>
            <div className='w-full flex flex-col justify-between bg-PrimaryDarkColor 
      5xl:py-[1vh] 5xl:px-[1vw] 5xl:rounded-[0.8vw] 5xl:h-[12%] 5xl:overflow-hidden 5xl:gap-y-[0vh]
      4md:py-[1vh] 4md:px-[1vw] 4md:rounded-[0.8vw] 4md:h-[15%] 4md:overflow-hidden 4md:gap-y-[0vh]
      py-[0.5vh] px-[2vw] rounded-[0.8vw] h-auto gap-y-[1vh]
      '>
                <label className='w-full text-white font-bold tracking-wide bg-tansparent
        5xl:text-2xl 5xl:text-start
        4md:text-2xl 4md:text-start
        text-md  text-center
        '>Configuration Management</label>
                <div className='flex justify-between w-full justify-center items-start 
        5xl:gap-x-[1vw] 5xl:flex-row 
        4md:gap-x-[1vw] 4md:flex-row 
        gap-x-[1vw] flex-col gap-y-[1vh] h-auto
        '>
                    <div className='grid justify-center items-center h-auto 
          5xl:w-[65%] 5xl:gap-x-[0.5vw] 5xl:flex-row 5xl:grid-cols-3 
          4md:w-[70%] 4md:gap-x-[0.5vw] 4md:flex-row 4md:grid-cols-3 
          w-full gap-x-[2vw] flex-col grid-cols-3 gap-y-[1vh] gap-x-[1vw]
          '>
                        <div className={`w-auto h-auto items-center flex justify-center
            5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
            4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
            max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "TitleLogo"
                                ? "bg-PrimaryColor text-white font-medium transitionAll"
                                : "bg-SecondaryColor text-black font-light"
                            }`}
                            onClick={() => handleButtonClick("TitleLogo")}
                        >
                            <div>
                                <FontAwesomeIcon icon={faGears} />
                            </div>
                            <div>
                                <button>Logo & Title</button>
                            </div>
                        </div>
                        <div className={`w-auto h-auto items-center flex justify-center
            5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
            4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
            max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "AuditTrail"
                                ? "bg-PrimaryColor text-white font-medium transitionAll"
                                : "bg-SecondaryColor text-black font-light"
                            }`}
                            onClick={() => handleButtonClick("AuditTrail")}
                        >
                            <div>
                                <FontAwesomeIcon icon={faWrench} />
                            </div>
                            <div>
                                <button>Audit Trail Messages</button>
                            </div>
                        </div>
                        <div className={`w-auto h-auto items-center flex justify-center
            5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
            4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
            max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "ResponseMessages"
                                ? "bg-PrimaryColor text-white font-medium transitionAll"
                                : "bg-SecondaryColor text-black font-light"
                            }`}
                            onClick={() => handleButtonClick("ResponseMessages")}
                        >
                            <div>
                                <FontAwesomeIcon icon={faWrench} />
                            </div>
                            <div>
                                <button>Response Messages</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='w-full
      5xl:py-[2vh] 5xl:h-[88%] 
      4md:py-[1vh] 4md:h-[85%] 
      py-[1vh] h-full 
      '>
                {selectedStatus === "TitleLogo" ? (
                    <TitleLogo />
                ) : selectedStatus === "AuditTrail" ? (
                    <AuditTrailMsg />
                ) : selectedStatus === "ResponseMessages" ? (
                    <ResponseMessages />
                ) : (
                    <p className='text-black text-26 text-center'>Working...</p>
                )}
            </div></div>
    )
}

export default Configurations