import { SET_ALL_COURSEPLAN } from '../action-creators/ActionTypes'; 

const AllCoursePlan = (state = [], action) => {
    switch (action.type) {
        case SET_ALL_COURSEPLAN:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllCoursePlan;
