// action-creators/index.js
import { 
    SET_SELECTED_ACTIVITY,
    SET_ATTENDANCE_DATA,
    SET_ALL_ATTENDANCE,
    SET_USER_INFO,
    SET_ALLStudent_INFO,
    SET_ALLFaculty_INFO,
    SET_Assignment,
    SET_SubmittedAssignments,
    SET_Quiz,
    SET_SubmittedQuizzes,
    SET_SubmittedExam,
    SET_Learning,
    SET_ALL_EXAM,
    SET_ALL_COURSEPLAN,
    SelectedMenuBtn,
    SET_ALL_AUDIT_TRAIL,
    SET_ONLINE_MEETING,
    //Admin Config states
    SET_ALLDEPT,
    SET_ALLCOURSES,
    SET_ALLPROGRAM,
    SET_ALLLINK,
    SET_ALLSESSION,
    SET_ALLCLASS,
    SET_ACTIVE_SESSION,
    SET_WEEKLY_SCHEDULE,
    SET_CONFIG_NAME,
    SET_ACADEMIC_CALENDER,
    SET_VIEW_FILE,
    //App.js Apis Data
    SET_RESPONSE_MESSAGE,
    //Trigger Apis
    SET_TRIGGER_NOTIFICATIONS_STUDENT,
} from './ActionTypes';

export const setSelectedActivity = (value) => {
    return {
        type: SET_SELECTED_ACTIVITY,
        payload: value,
    };
};

export const setOnlineMeeting = (value) => {
    return {
        type: SET_ONLINE_MEETING,
        payload: value,
    };
};

export const setAttendanceData = (value) => {
    return {
        type: SET_ATTENDANCE_DATA,
        payload: value,
    };
};

export const setAllAttendance = (value) => {
    return {
        type: SET_ALL_ATTENDANCE,
        payload: value,
    };
};

export const setUserInfo = (value) => {
    return {
        type: SET_USER_INFO,
        payload: value,
    };
};

export const setAllStudentInfo = (value) => {
    return {
        type: SET_ALLStudent_INFO,
        payload: value,
    };
};

export const setAllFacultyInfo = (value) => {
    return {
        type: SET_ALLFaculty_INFO,
        payload: value,
    };
};

export const setAssignmentDetails = (value) => {
    return {
        type: SET_Assignment,
        payload: value,
    };
};
export const setAfterAssignmentSubmitted = (value) => {
    return {
        type: SET_SubmittedAssignments,
        payload: value,
    };
};
export const setQuizDetails = (value) => {
    return {
        type: SET_Quiz,
        payload: value,
    };
};
export const setAfterQuizSubmitted = (value) => {
    return {
        type: SET_SubmittedQuizzes,
        payload: value,
    };
};
export const setAfterExamSubmitted = (value) => {
    return {
        type: SET_SubmittedExam,
        payload: value,
    };
};
export const setAllExam = (value) => {
    return {
        type: SET_ALL_EXAM,
        payload: value,
    };
};
export const setAllCoursePlan = (value) => {
    return {
        type: SET_ALL_COURSEPLAN,
        payload: value,
    };
};


export const setLearningDetails = (value) => {
    return {
        type: SET_Learning,
        payload: value,
    };
};

export const setSelectedMenuBtn = (value) => {
    return {
        type: SelectedMenuBtn,
        payload: value,
    };
};

export const setAuditTrail = (value) => {
    return {
        type: SET_ALL_AUDIT_TRAIL,
        payload: value,
    };
};


//Admin Config states
export const setAllDept = (value) => {
    return {
        type: SET_ALLDEPT,
        payload: value,
    };
};

export const setAllCourses = (value) => {
    return {
        type: SET_ALLCOURSES,
        payload: value,
    };
};

export const setAllProgram = (value) => {
    return {
        type: SET_ALLPROGRAM,
        payload: value,
    };
};

export const setAllLink = (value) => {
    return {
        type: SET_ALLLINK,
        payload: value,
    };
};
export const setAllSession = (value) => {
    return {
        type: SET_ALLSESSION,
        payload: value,
    };
};

export const setActiveSession = (value) => {
    return {
        type: SET_ACTIVE_SESSION,
        payload: value,
    };
};

export const setAllClass = (value) => {
    return {
        type: SET_ALLCLASS,
        payload: value,
    };
};
export const setWeeklySchedule = (value) => {
    return {
        type: SET_WEEKLY_SCHEDULE,
        payload: value,
    };
};

//Config   

export const setConfigName = (value) => {
    return {
        type: SET_CONFIG_NAME,
        payload: value,
    };
};
export const setAcademic = (value) => {
    return {
        type: SET_ACADEMIC_CALENDER,
        payload: value,
    };
};

export const setViewFile = (value) => {
    return {
        type: SET_VIEW_FILE,
        payload: value,
    };
};

 //App.js Apis Data
export const setResponseMessage = (value) => {
    return {
        type: SET_RESPONSE_MESSAGE,
        payload: value,
    };
};

//Trigger Apis
export const SetTriggerNotificationStudent = (value) => {
    return {
        type: SET_TRIGGER_NOTIFICATIONS_STUDENT,
        payload: value,
    };
};