import React, { useState, useEffect } from 'react'
import { setLearningDetails } from '../../../../state/action-creators';
import SelectCourse from './SelectCourse'
import Ebooks from './Ebooks'
import Audios from './Audios'
import HandsonVideos from './HandsonVideos'
import { useSelector,useDispatch } from 'react-redux';
import Apis from '../../../../Apis';

const LearningPlatforms = ({ ElearningModalHandler }) => {
    const [learnings, setLearnings] = useState([]);
    const userInfo = useSelector(state => state.userInfo);
    const [selectedCourse, setSelectedCourse] = useState('All courses');
    const [filteredArray, setFilteredArray] = useState([]);
    const [ebooks, setEbooks] = useState([]);
    const [audios, setAudios] = useState([]);
    const [videos, setVideos] = useState([]);

    function checkStatus(validFromDate, validToDate) {
        const currentDate = new Date().toDateString();
        const fromDate = new Date(validFromDate);
        const toDate = new Date(validToDate);
        const checkDate = new Date(currentDate);

        if (checkDate >= fromDate && checkDate <= toDate) {
            return true;
        } else {
            return false;
        }
    }
    const dispatch = useDispatch();

    const getLearningMaterials = async (classCode) => {
        try {
            const learnings = await Apis.getLearningByClass(classCode);
            dispatch(setLearningDetails(learnings || []));
            setLearnings(learnings || [])
        } catch (error) {
            console.error('Error fetching E-Learning data:', error);
        }
    };
    useEffect(() => {
        if(userInfo.studentDetails.class){
        getLearningMaterials(userInfo.studentDetails.class)
        }
    }, [userInfo]);

    function filterObjectsByStatus(objects) {
        return objects.filter(obj => {
            const status = checkStatus(obj.validFromDate, obj.validtoDate);
            return status;
        });
    }
    useEffect(() => {
        setFilteredArray(filterObjectsByStatus(learnings));
    }, [learnings]);

    useEffect(() => {
        const ebooksData = filteredArray.filter(item => item.category === 'E-books');
        const audiosData = filteredArray.filter(item => item.category === 'Audios');
        const videosData = filteredArray.filter(item => item.category === 'Videos & presentations');
        setEbooks(ebooksData);
        setAudios(audiosData);
        setVideos(videosData);
    }, [filteredArray,learnings, selectedCourse]);

    return (
        <div className='w-full h-full p-4 overflow-auto bg-transparent no-scrollbar'>
            <div className='h-[15%] flex flex-col gap-y-2'>
                <div className='flex items-center justify-center w-full h-auto text-white bg-blue-500 rounded-md'>
                    <p className='xl:text-[1vw] py-2  tracking-wider font-semibold whitespace-nowrap'>Learning Materials</p>
                </div>
                <SelectCourse
                    selectedCourse={selectedCourse}
                    setSelectedCourse={setSelectedCourse}
                />
            </div>
            <div className='grid grid-rows-3 w-full h-[82%] gap-y-4 my-2'>
                <Ebooks
                    selectedCourse={selectedCourse}
                    ebooksArr={ebooks}
                    ElearningModalHandler={ElearningModalHandler}
                />
                <Audios
                    selectedCourse={selectedCourse}
                    audiosArr={audios}
                    ElearningModalHandler={ElearningModalHandler}
                />
                <HandsonVideos
                    videosArr={videos}
                    selectedCourse={selectedCourse}
                    ElearningModalHandler={ElearningModalHandler}
                />
            </div>

        </div>
    )
}

export default LearningPlatforms