import { SET_TRIGGER_NOTIFICATIONS_STUDENT } from '../action-creators/ActionTypes';

const initialState = false;

const TriggerNotificationStudent = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRIGGER_NOTIFICATIONS_STUDENT:
            return !state; 
        default:
            return state;
    }
};

export default TriggerNotificationStudent;