// ActionTypes.js
export const SET_SELECTED_ACTIVITY = 'SET_SELECTED_ACTIVITY';
export const SET_ATTENDANCE_DATA = 'SET_Attendance_Data';
export const SET_ALL_ATTENDANCE = 'SET_All_Attendance';
export const SET_ALL_EXAM = 'SET_All_Exam';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_ALLStudent_INFO = 'SET_ALLStudent_INFO';
export const SET_ALLFaculty_INFO = 'SET_ALLFaculty_INFO';
export const SET_Assignment = 'SET_Assignment';
export const SET_SubmittedAssignments = 'SET_SubmittedAssignments';
export const SET_Quiz = 'SET_Quiz';
export const SET_SubmittedQuizzes = 'SET_SubmittedQuizzes';
export const SET_SubmittedExam = 'SET_SubmittedExam';
export const SET_Learning = 'SET_Learning';
export const SelectedMenuBtn = 'SelectedMenuBtn';
export const SET_ALL_COURSEPLAN = 'SET_ALL_COURSEPLAN';
export const SET_ALL_AUDIT_TRAIL = 'SET_ALL_AUDIT_TRAIL';
export const SET_ONLINE_MEETING = 'SET_ONLINE_MEETING';
//Admin Config states
export const SET_ALLDEPT = 'SET_ALLDEPT';
export const SET_ALLCOURSES = 'SET_ALLCOURSES';
export const SET_ALLPROGRAM = 'SET_ALLPROGRAM';
export const SET_ALLLINK = 'SET_ALLLINK';
export const SET_ALLSESSION = 'SET_ALLSESSION';
export const SET_ALLCLASS = 'SET_ALLCLASS';
export const SET_ACTIVE_SESSION = 'SET_ACTIVE_SESSION';
export const SET_WEEKLY_SCHEDULE = 'SET_WEEKLY_SCHEDULE';

//Confuration States
export const SET_CONFIG_NAME = 'SET_CONFIG_NAME';
export const SET_ACADEMIC_CALENDER = 'SET_ACADEMIC_CALENDER';
export const SET_VIEW_FILE = 'SET_VIEW_FILE';

//App.js Apis Data
export const SET_RESPONSE_MESSAGE = 'SET_RESPONSE_MESSAGE';

//Trigger Apis
export const SET_TRIGGER_NOTIFICATIONS_STUDENT = 'SET_TRIGGER_NOTIFICATIONS_STUDENT';
