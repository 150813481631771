import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Apis from "../../../../Apis";

const semester = [
  "Semester 1",
  "Semester 2",
  "Semester 3",
  "Semester 4",
  "Semester 5",
  "Semester 6",
  "Semester 7",
  "Semester 8",
]

const AddUpdateClass = ({ updateClass, setUpdateClass }) => {
  const userInfo = useSelector(state => state.userInfo);
  const [formData, setFormData] = useState({
    selectedProgram: '',
    selectedSession: '',
    selectedSection: '',
    dept: '',
    semester: 'Semester 1',
    refArray:[],
  });
  const [allExcutiveAdminInfo, setAllExcutiveAdminInfo] = useState([])
  const programs = useSelector(state => state.allProgram);
  const session = useSelector(state => state.allSession);
  const [ClassCode, setClassCode] = useState('');
  const [status, setStatus] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const backendBaseUrl = config.backendLocalhost;
  const [allDepartmentalAdminInfo, setAllDepartmentalAdminInfo] = useState([])

  const handleInputChange = () => {
    setStatus(!status);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchAllExcutiveAdmin = async () => {
    try {
      const AllExcutiveAdmin = await Apis.getAllExcutiveAdmin("");
      setAllExcutiveAdminInfo(AllExcutiveAdmin);
    } catch (error) {
      console.error('Error fetching All Excutive Admin:', error.message);
      toast.error('Error fetching All Excutive Admin:', error.message);
    }
  };

  const fetchAllExcutiveManagement = async () => {
    let userID;
    if (userInfo.role === 'admin') {
      userID = 'admin'
    }
    else {
      userID = userInfo._id
    }
    try {
      const AllExcutiveManagement = await Apis.getAllExcutiveManagement("", userID);
      setAllDepartmentalAdminInfo(AllExcutiveManagement);
    } catch (error) {
      console.error('Error fetching All Excutive Management:', error.message);
      toast.error('Error fetching All Excutive Management:', error.message);
    }
  };

  useEffect(() => {
    if(userInfo.role){
      fetchAllExcutiveAdmin();
      fetchAllExcutiveManagement();
    }
  }, [updateClass,userInfo]);


  useEffect(() => {
    if (!formData.selectedProgram && !formData.selectedSession && !formData.selectedSection) {
      return
    }
    if (!updateClass._id) {
      setClassCode(`${formData?.selectedProgram}-${formData?.selectedSession}-${formData?.selectedSection}`)
    }
  }, [formData.selectedProgram, formData.selectedSession, formData.selectedSection]);

  function getDepartmentCodeByProgramCode(progCode) {
    for (const item of programs) {
      if (item.programCode === progCode) {
        return item.departmentCode;
      }
    }
    return null;
  }

  const resetField = () => {
    setEditingId(null)
    setClassCode('');
    setStatus(true);
    setFormData({
      selectedProgram: '',
      selectedSession: '',
      selectedSection: '',
      dept: '',
      semester: 'Semester 1',
    })
    setUpdateClass();
  };

  const handleAddClasses = async () => {
    const dataToSend = {
      program: formData.selectedProgram,
      department: getDepartmentCodeByProgramCode(formData.selectedProgram),
      session: formData.selectedSession,
      section: formData.selectedSection,
      semester:formData.semester,
      classCode: ClassCode,
      status: status,
      refArray: formData.refArray,
      userID: userInfo._id,
    };
    try {
      const response = await axios.post(`${backendBaseUrl}/class/addClass`, dataToSend);
      resetField();
      toast.success(`Class code:${ClassCode} successfully`)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Class with the same classCode already exists');
        toast.error('Class with the same classCode already exists');
      }
      else if (error.response && error.response.status === 400) {
        console.error('Missing required fields in the request body');
        toast.error('Missing required fields in the request body');
      } else {
        console.error('Error adding Class:', error.message);
        toast.error('Error adding Class:', error.message);
      }
    }
  };

  useEffect(() => {
    if (updateClass && updateClass.length !== 0) {
      setFormData({
        selectedProgram: updateClass.program,
        selectedSession: updateClass.session,
        selectedSection: updateClass.section,
        semester: updateClass.semester,
        refArray: updateClass.refArray,
      })
      setClassCode(updateClass.classCode);
      setStatus(updateClass.status);
      setEditingId(updateClass._id);
    }
  }, [updateClass]);

  const handleUpdateClasses = async () => {
    try {
      const response = await axios.put(`${backendBaseUrl}/class/updatedClasses/${editingId}`, {
        program: formData.selectedProgram,
        session: formData.selectedSession,
        section: formData.selectedSection,
        semester:formData.semester,
        status,
        refArray: formData.refArray,
      });
      toast.success('Class updated successfully');
      resetField();
    } catch (error) {
      console.error('Error updating class:', error.message);
      toast.error(`Error updating class: ${error.message}`);
    }
  };

  return (
    <div className='flex flex-col items-center justify-start w-full h-auto overflow-y-auto lg:overflow-y-auto lg:style-scrollbar style-scrollbar'>
    <form className='w-full bg-gray-300 flex flex-col items-start justify-center lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh] text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh]'>
      <div className="w-full grid justify-center items-center gap-x-[1vw] gap-y-[1vh] 
      lg:grid-cols-2
      grid-cols-1">
          <div className='flex flex-col gap-y-[0.5vh]'>
            <label className='font-semibold'>
              Program:
            </label>
            <select className="w-full bg-white select select-primary"
              name="selectedProgram" value={formData.selectedProgram} onChange={handleChange}>
              <option value="" selected disabled>
                Select a Program
              </option>
              {programs.map((program) => (
                <option className='text-black' key={program.programCode} value={program.programCode}>
                  {`${program.programName}-(${program.programCode})`}
                </option>
              ))}
            </select>
          </div>
          <div className='flex flex-col gap-y-[0.5vh]'>
            <label className='font-semibold'>
              Session:
            </label>
            <select className="w-full bg-white select select-primary"
              name="selectedSession" value={formData.selectedSession} onChange={handleChange}>
              <option value="" selected disabled>
                Select a session
              </option>
              {session.map((s) => (
                <option className='text-black' key={s.code} value={s.code}>
                  {s.code}
                </option>
              ))}
            </select>
          </div>
          <div className='flex flex-col gap-y-[0.5vh]'>
            <label className='font-semibold'>
              Section:
            </label>
            <select className="w-full bg-white select select-primary"
              name="selectedSection" value={formData.selectedSection} onChange={handleChange}>
              <option value="" selected disabled>
                Select a section
              </option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="C">D</option>
            </select>
          </div>
          <div className=' flex flex-col gap-y-[0.5vh]'>
            <label htmlFor='deptCode' className='font-semibold'>Class Code:</label>
            <input
              type="text"
              placeholder="Code will auto-Generated "
              className="w-full font-normal bg-white input outline-PrimaryColor"
              id='deptCode'
              value={ClassCode}
              onChange={(e) => setClassCode(e.target.value)}
              disabled={editingId !== null}
            />
          </div>
          <div className='flex flex-col gap-y-[0.5vh]'>
            <label htmlFor='semester' className='font-semibold'>Select semester :</label>
            <select className="w-full bg-white select select-primary"
              name="semester"
              value={formData.semester}
              onChange={handleChange}
            >
              {semester.map((sem) => (
                <option className='text-black' key={sem} value={sem}>
                  {sem}
                </option>

              ))}
            </select>
          </div>
          {userInfo.role === "admin" &&
              <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm gap-y-[0.5vh] text-sm w-full">
                <label htmlFor="deptWebsiteLink" className="font-semibold">Assign Department to Executive Admins:</label>
                <Select
                  className="h-full input select-primary min-w-full bg-white p-1"
                  id="refArray"
                  name="refArray"
                  value={formData.refArray?.map(id => ({
                    value: id,
                    label: allExcutiveAdminInfo?.find(admin => admin._id === id)?.firstName || allDepartmentalAdminInfo?.find(admin => admin._id === id)?.firstName ||  '***'
                  }))}
                  onChange={(selectedOptions) => {
                    setFormData({
                      ...formData,
                      refArray: selectedOptions.map(option => option.value)
                    });
                  }}
                  placeholder="Select multiple Executive Admins"
                  options={allExcutiveAdminInfo.map(admin => ({
                    value: admin._id,
                    label: `${admin.firstName} ${admin.lastName}`
                  }))}
                  isMulti
                />
              </div>
            }
          <div className='flex flex-col justify-center items-start
            lg:gap-y-[0.5vh] lg:text-sm  lg:px-[0vw]
            gap-y-[0.5vh] text-sm w-full 
            '>
            <label htmlFor='deptCode'
              className='mb-0 font-semibold lg:mb-2 '>Status:</label>
            <div className='flex gap-x-[0.5vw] justify-start items-start'>
              <input
                className='checkbox'
                type="checkbox"
                id='status'
                name="status"
                checked={status}
                onChange={handleInputChange}
              />
              <label className={`text-center ${status ? "text-green-600" : "text-red-600"}`}> {status ? "Active" : "In-Active"} </label>
            </div>

          </div>
        </div>
        <button
          className='px-[1vw] py-[1vh] bg-PrimaryColor text-white rounded-[0.5vw] font-bold'
          type='button'
          onClick={editingId !== null ? handleUpdateClasses : handleAddClasses}
        >
          {editingId !== null ? 'Update Class' : 'Add Class'}
        </button>
      </form>
    </div>
  );
};

export default AddUpdateClass