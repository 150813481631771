import React from 'react'
import axios from 'axios';
import { toast } from "react-hot-toast";
import config from '../../../../config';
import Apis from '../../../../Apis';
import { useSelector,useDispatch } from 'react-redux';
import { SetTriggerNotificationStudent } from '../../../../state/action-creators';
import { MessageByName } from '../../../FacultyComp/Dashboard/Modals/AddNotification';

const ConfirmAlert = ({ assignDetailInstance, quizDetailInstance, RemindersModalHandler, confirmAlertReminderHandler, openConfirmAlert }) => {
    const backendBaseUrl = config.backendLocalhost;
    const ResponseMessages = useSelector(state => state.responseMessage);
    const dispatch = useDispatch();
    const confirmed = async () => {
        if (assignDetailInstance.type === "Assignment") {
            submitAssignment(assignDetailInstance);
        }
        else if (assignDetailInstance.type === "Quiz") {
            submitQuiz(assignDetailInstance);
        }
        else {
            toast.error('Please select a valid type for submission');
        }


    };
    const canceled = async () => {
        console.error('Assignment submission Cancelled');
        confirmAlertReminderHandler([]);
    }

    const submitAssignment = async (assignDetailInstance) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("status", assignDetailInstance.status);
            formDataToSend.append("submittedDetail", assignDetailInstance.submittedDetail);
            formDataToSend.append("studId", assignDetailInstance.studId);
            formDataToSend.append("studName", assignDetailInstance.studName);
            formDataToSend.append("obtainedMarks", assignDetailInstance.obtainedMarks);
            formDataToSend.append("studentFile", assignDetailInstance.studentFile);

            const response = await axios.put(`${backendBaseUrl}/assignment/submittedByStudent/${assignDetailInstance.assignmentID}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const ResponseMsg = MessageByName("Assignment submited by student", ResponseMessages);
            toast.success(ResponseMsg);
            Apis.addAuditTrail(assignDetailInstance.studId, "663485192327faa63b664ab0", `- [CourseID:${assignDetailInstance.course}, No:${assignDetailInstance.No}]`);
            confirmAlertReminderHandler([]);
            dispatch(SetTriggerNotificationStudent());
            RemindersModalHandler();
        } catch (error) {
            console.error('Error sending data to the backend:', error);
            toast.error('Error sending data to the backend:', error);
        }
    };

    const submitQuiz = async (quizDetailInstance) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("status", quizDetailInstance.status);
            formDataToSend.append("submittedDetail", quizDetailInstance.submittedDetail);
            formDataToSend.append("studId", quizDetailInstance.studId);
            formDataToSend.append("studName", quizDetailInstance.studName);
            formDataToSend.append("obtainedMarks", quizDetailInstance.obtainedMarks);
            formDataToSend.append("studentFile", quizDetailInstance.studentFile);

            const response = await axios.put(`${backendBaseUrl}/quiz/submittedByStudent/${quizDetailInstance.assignmentID}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const ResponseMsg = MessageByName("Quiz submitted by student", ResponseMessages);
            toast.success(ResponseMsg);
            Apis.addAuditTrail(assignDetailInstance.studId, "663491c04fba113615476c43", `- [CourseID:${assignDetailInstance.course}, No:${assignDetailInstance.No}]`);
            confirmAlertReminderHandler([]);
            dispatch(SetTriggerNotificationStudent());
            RemindersModalHandler();

        } catch (error) {
            toast.error('Error sending data to the backend. Please try again.');
        }
    };


    return (
        <div className={`fixed inset-0 z-[60] flex items-center justify-center text-black duration-300
        ${openConfirmAlert ? 'scale-100' : 'scale-0'}
        `}>
            <div className="bg-white w-[30vw] p-4 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold">Are you Sure !!</h2>
                <hr className="my-[0.5vw]" />
                <p className='text-justify'>
                    {"Are you certain you want to submit this assignment? Once confirmed, changes or resubmissions will not be possible."}
                </p>
                <div className="mt-[0.5vw] text-right">
                    <button
                        onClick={() => canceled()}
                        className="text-[1vw] bg-red-400 text-black font-medium py-[0.3vw] px-[0.8vw] rounded-lg hover:bg-red-900 mr-[0.2vw] hover:text-white duration-500"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => confirmed()}
                        className="text-[1vw] text-black font-medium  py-[0.3vw] px-[0.8vw] rounded-lg hover:bg-green-900 mr-[0.2vw] bg-green-400 hover:text-white duration-500"
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmAlert