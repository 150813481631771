import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from "react-hot-toast";
import config from '../../../../config';
import { formatDate } from '../../../../components/FormatDate';

const OnlineLectureSub = ({ OnlineLectureHandler }) => {
    const [selectedClass, setSelectedClass] = useState('');
    const [classes, setClasses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [courses, setCourses] = useState([]);
    const userInfo = useSelector(state => state.userInfo);
    const backendBaseUrl = config.backendLocalhost;
    const [lecturesData, setLecturesData] = useState([]);
    const [filteredLecturesData, setFilteredLecturesData] = useState([]);

    useEffect(() => {
        const filteredCourses = userInfo.facultyDetails.courseDetail
            .filter(info => info.class === selectedClass)
            .map(info => info.course);
        setCourses(filteredCourses);
    }, [selectedClass, userInfo.facultyDetails.courseDetail]);

    useEffect(() => {
        const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map(info => info.class)));
        setClasses(uniqueClasses);
    }, [userInfo.facultyDetails.courseDetail]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${backendBaseUrl}/onlineLecture/getbyFacultyID/${userInfo._id}`);
                setLecturesData(response.data);
            } catch (error) {
                console.error('Error fetching Online Lecture:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error('An error occurred while fetching Online Lecture.');
                }
            }
        };

        fetchData();
    }, [backendBaseUrl, userInfo._id]);

    useEffect(() => {
        const sortedAssignments = [...lecturesData].sort((a, b) => {
            if (a.status === b.status) {
                return new Date(b.date) - new Date(a.date);
            } else {
                return a.status ? -1 : 1;
            }
        });
        if (sortedAssignments.length !== 0) {
            let filtered = sortedAssignments.filter((val) => {
                if (!selectedCourse && !selectedClass) {
                    return true;
                }
                if (selectedCourse && selectedClass) {
                    return (
                        val.course === selectedCourse &&
                        val.class === selectedClass
                    );
                }
                if (selectedCourse) {
                    return val.course === selectedCourse;

                }
                if (selectedClass) {
                    return val.class === selectedClass;

                }
                return false;
            });
            if (selectedCourse && selectedClass) {
                setFilteredLecturesData(filtered);
            }
            else {
                setFilteredLecturesData([]);
            }
        }
    }, [selectedCourse, selectedClass, lecturesData]);



    return (
        <div className='w-full pb-[2vh] px-[1vw]'>
            <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh]'>
                {/* Select Class */}
                <select
                    id="classSelect"
                    className='w-auto h-full text-black text-left border border-gray-700 rounded-[1vw] px-[1vw] focus:border-gray-700 focus:outline-none cursor-pointer'
                    value={selectedClass}
                    onChange={(e) => setSelectedClass(e.target.value)}
                >
                    <option value="">Select a class</option>
                    {classes.map((className) => (
                        <option key={className} value={className}>
                            {className}
                        </option>
                    ))}
                </select>

                {/* Select Course */}
                <select
                    id="courseSelect"
                    className='w-auto h-full text-black text-left border border-gray-700 rounded-[1vw] px-[1vw] focus:border-gray-700 focus:outline-none cursor-pointer'
                    value={selectedCourse}
                    onChange={(e) => setSelectedCourse(e.target.value)}
                >
                    <option value="">Select a course</option>
                    {courses.map((course) => (
                        <option key={course} value={course}>
                            {course}
                        </option>
                    ))}
                </select>
                {/* Add Assignment */}
                <button className='w-auto h-full text-[1.2vw] px-[1vw] text-center border border-gray-700 rounded-[1vw] focus:border-gray-700 focus:outline-none
          bg-white text-black hover:bg-gray-700 hover:text-white duration-300'
                    onClick={() => OnlineLectureHandler(selectedCourse, selectedClass)}
                >
                    Create room
                </button>
            </div>
            <div className="w-full h-full min-h-[15vh] overflow-auto style-scrollbar text-black">
                <table className="min-w-full h-full bg-white border-collapse text-sm">
                    <thead>
                        <tr>
                            <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Sr.</th>
                            <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Date</th>
                            <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Time</th>
                            <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Duration</th>
                            <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Status</th>
                            <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Link</th>
                        </tr>
                    </thead>
                    <tbody className='relative'>
                        {lecturesData ?
                            <>
                                {filteredLecturesData && filteredLecturesData.map((item, index) => (
                                    <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`} key={index}>
                                        <td className="border-x border-gray-400 p-2 whitespace-nowrap">{index + 1}</td>
                                        <td className="border-x border-gray-400 p-2 whitespace-nowrap">{formatDate(item.date)}</td>
                                        <td className="border-x border-gray-400 p-2 whitespace-nowrap">{item.time}</td>
                                        <td className="border-x border-gray-400 p-2 whitespace-nowrap">{item.duration} minutes</td>
                                        <td className={`border-x border-gray-400 p-2 whitespace-nowrap ${item.status ? "text-green-600" : "text-red-600"}`}>{item.status ? "Completed" : "Pending"}</td>
                                        <td className="border-x border-gray-400 p-2 whitespace-nowrap">{item.link}</td>
                                    </tr>
                                ))}
                            </>
                            :
                            <div role="status" className='absolute top-[30%] left-[50%]'>
                                <svg aria-hidden="true" className="w-[20%] h-auto text-gray-300 animate-spin dark:text-gray-600 fill-blue-800" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                            </div>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default OnlineLectureSub