import React from "react";

const Grading = ({ range, setSurveyResult, surveyResult, quesNo }) => {
  const handleInputChange = (e) => {
    const { value } = e.target;

    setSurveyResult(prevSurveyResult =>
      prevSurveyResult.map(surveyItem =>
        surveyItem.quesNo === quesNo
          ? { ...surveyItem, Answer: [value] }
          : surveyItem
      )
    );
  };

  const currentAnswer = surveyResult.find(surveyItem => surveyItem.quesNo === quesNo)?.Answer[0] || "";

  return (
    <div className="flex flex-col w-[50%] h-full">
      <div className="flex w-full h-full ml-8">
        <div className="flex items-center mr-4 gap-x-2">
          <div className="font-semibold">
            <h1>Enter value from:</h1>
          </div>
          <div>
            <p>{range.from}</p>
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <div className="font-semibold">
            <h1>To:</h1>
          </div>
          <div>
            <p>{range.to}</p>
          </div>
        </div>
        <div className="flex w-[10%] bg-white ml-4">
          <input
            type="text"
            name="id"
            className="w-full bg-white input"
            onChange={handleInputChange}
            value={currentAnswer}
          />
        </div>
      </div>
    </div>
  );
};

export default Grading;
