import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {MessageByName} from '../../../FacultyComp/Dashboard/Modals/AddNotification';

const Add = ({ setUpdateExcutiveAdmin, updateExcutiveAdmin, handleButtonClick }) => {

  const [showPassword, setShowPassword] = useState(false);
  const ResponseMessages = useSelector(state => state.responseMessage);
  
	const handleTogglePasswordVisibility = () => {
		setShowPassword(prevState => !prevState);
	};

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNo: '',
    cnic: '',
    email: '',
    password: '',
    validity: true,
    role: 'ExcutiveAdmin',
  });

  const [editingId, setEditingId] = useState(null);
  const backendBaseUrl = config.backendLocalhost;


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };

  const resetField = () => {
    setEditingId(null);
    setFormData({
      firstName: '',
      lastName: '',
      phoneNo: '',
      cnic: '',
      email: '',
      password: '',
      validity:true,
      role: 'ExcutiveAdmin',
    })
    setUpdateExcutiveAdmin([]);
  };


  const handleAddExcutiveAdmin = async (e) => {
    e.preventDefault();

    const cnicRegex = /^\d{13}$/;
    if (!cnicRegex.test(formData.cnic)) {
      toast.error('Invalid CNIC format. Please enter a valid CNIC number.');
      return;
    }
    const phoneRegex = /^\d{11}$/;
    if (!phoneRegex.test(formData.phoneNo.replace(/-/g, ''))) {
      toast.error('Invalid phone number format. Please enter a valid 10-digit phone number.');
      return;
    }

    try {
      const response = await axios.post(`${backendBaseUrl}/api/users`, formData);
      toast.success(response.data.message);
      resetField();
      handleButtonClick('view');
    } catch (error) {
      const ResponseMsg = MessageByName(error.response?.data?.message,ResponseMessages);
      toast.error(ResponseMsg);
      //toast.error(`API Error: ${error.response?.data?.message || 'Server not responding'}`);
      console.error('API Error:', error.response ? error.response.data : error.message);
    }
  };


  useEffect(() => {
    if (updateExcutiveAdmin && updateExcutiveAdmin.length !== 0) {
      setEditingId(updateExcutiveAdmin._id);
      setFormData({
        firstName: updateExcutiveAdmin.firstName,
        lastName: updateExcutiveAdmin.lastName,
        phoneNo: updateExcutiveAdmin.phoneNo,
        cnic: updateExcutiveAdmin.cnic,
        email: updateExcutiveAdmin.email,
        validity:updateExcutiveAdmin.validity,
        password: '',
        role: 'ExcutiveAdmin',
      })
    }
  }, [updateExcutiveAdmin]);

  const handleUpdateExcutiveAdmin = async () => {
    try {
      const response = await axios.put(`${backendBaseUrl}/api/users/update/${updateExcutiveAdmin._id}`, formData);
      toast.success(response.data.message);
      resetField();
      handleButtonClick('view');
    } catch (error) {
      const ResponseMsg = MessageByName(error.response?.data?.message,ResponseMessages);
      toast.error(ResponseMsg);
      //toast.error(`Error: ${error.response?.data?.message || 'Server not responding'}`);
      console.error('API Error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className='flex flex-col items-center justify-start w-full h-auto overflow-y-auto lg:overflow-y-auto lg:style-scrollbar style-scrollbar'>
    <form className='w-full bg-gray-300 flex flex-col items-start justify-center lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh] text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh]'>
      <div className="w-full grid justify-center items-center gap-x-[1vw] gap-y-[1vh] 
      lg:grid-cols-2
      grid-cols-1">
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>First Name:</label>
              <input
                type="text"
                placeholder="Enter your First Name"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='firstName'
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>Last Name:</label>
              <input
                type="text"
                placeholder="Enter your Last Name"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='lastName'
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='phoneNo' className='font-semibold'>Phone No:</label>
              <input
                type="text"
                placeholder="Enter your Phone No"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='phoneNo'
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleInputChange}
              />
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='cnic' className='font-semibold'>CNIC No:</label>
              <input
                type="text"
                placeholder="Enter your CNIC without dashes"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='cnic'
                name="cnic"
                value={formData.cnic}
                onChange={handleInputChange}
              />
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>Email address:</label>
              <input
                type="text"
                placeholder="Email address will used as username"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='email'
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>Password:</label>
              <div className="relative w-full">
									<input
										type={showPassword ? "text" : "password"}
                    placeholder={`${updateExcutiveAdmin._id ? "Enter new Password" : "Enter your Password"}`}
                    className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                    id='password'
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
									/>
									<button
										type="button"
										className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
										onClick={handleTogglePasswordVisibility}
									>
										<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
									</button>
								</div>
            </div>
            <div className='w-[5%] flex flex-col gap-y-[0.5vh] justify-center items-start'>
            <label htmlFor='validity' className='font-semibold text-black'>Status:</label>
              <div className='flex gap-x-[0.5vw] justify-center items-center'>
                <input
                  className='checkbox'
                  type="checkbox"
                  id='validity'
                  name="validity"
                  checked={formData.validity}
                  onChange={handleCheckBoxChange}
                />
                <label className={`text-center ${formData.validity ? "text-green-600" : "text-red-600"}`}> {formData.validity ? "Valid" : "In-Valid"} </label></div>
            </div>
        </div>

        <button
          className='px-[1vw] py-[1vh] bg-PrimaryColor text-white rounded-[0.5vw] font-bold'
          type='button'
          onClick={editingId !== null ? handleUpdateExcutiveAdmin : handleAddExcutiveAdmin}
        >
          {editingId !== null ? 'Update Excutive Admin' : 'Add Excutive Admin'}
        </button>

      </form>
    </div>
  );
};

export default Add