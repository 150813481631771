import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import Apis from "../../../../Apis";
import { useDispatch, useSelector } from "react-redux";
import { setAllClass, setAllCourses } from "../../../../state/action-creators";
import axios from "axios";
import config from "../../../../config";
import { formatDate } from "../../../FormatDate";
import ViewAssignFeedback from "./ViewAssignFeedback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";

const AssignFeedback = ({ viewDetail, setViewDetail }) => {
  const [formData, setFormData] = useState({
    assigningName: "",
    templateID: "",
    classCode: "",
    courseCode: "",
    facultyID: "",
    analysis: "",
  });
  const dispatch = useDispatch();
  const allCourses = useSelector((state) => state.allCourses);
  const userInfo = useSelector(state => state.userInfo);

  const allClasses = useSelector((state) => state.allClass);
  const [template, setTemplate] = useState([]);
  const [assign, setAssign] = useState([]);
  const [analysis, setAnalysis] = useState([]);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const { backendLocalhost } = config;
  const [field, setField] = useState({
    courseCode: '',
    classCode: '',
    assigningDate: '',
    facultyID: '',
    assignTitle:'',
  });

  const fetchTemplete = async () => {
    try {
      const response = await axios.get(
        `${backendLocalhost}/survey/getAllTemplate`
      );
      setTemplate(response.data);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const fetchCourses = async () => {
    const user = {
      userID:userInfo._id,
      userRole:userInfo.role,
    }
    try {
      const response = await Apis.getAllCourses("",user);
      dispatch(setAllCourses(response || []));
    } catch (error) {
      console.error("Error fetching Courses:", error.message);
      toast.error("Error fetching Courses: " + error.message);
    }
  };

  const fetchProgram = async () => {
    const user = {
      userID:userInfo._id,
      userRole:userInfo.role,
    }
    try {
      const response = await Apis.getAllClasses("",user);
      dispatch(setAllClass(response || []));
    } catch (error) {
      console.error("Error fetching Program:", error.message);
      toast.error("Error fetching Program: " + error.message);
    }
  };

  const fetchFaculty = async (classCode, courseCode) => {
    try {
      const response = await Apis.getFacultyByClassandCourse(
        classCode,
        courseCode
      );
      setFormData({
        ...formData,
        facultyID: response?._id,
      });
      setSelectedFaculty(response);
    } catch (error) {
      console.error("Error fetching Faculty:", error.message);
      toast.error("Error fetching Faculty: " + error.message);
    }
  };

  const fetchAssign = async () => {
    try {
      const response = await axios.get(
        `${backendLocalhost}/survey/getAllAssignedTemplate`
      );
      setAssign(response.data);
    } catch (error) {
      console.error("Error fetching Assign:", error);
    }
  };

  const fetchAnalysis = async () => {
    try {
      const response = await axios.get(
        `${backendLocalhost}/survey/getAllAssignedTemplate`
      );
      setAnalysis(response.data);
    } catch (error) {
      console.error("Error fetching analysis:", error);
    }
  };

  useEffect(() => {
    fetchTemplete();
    fetchCourses();
    fetchProgram();
    fetchAssign();
    fetchAnalysis();
  }, []);

  useEffect(() => {
    if (formData.classCode !== "" && formData.courseCode !== "") {
      fetchFaculty(formData.classCode, formData.courseCode);
    }
  }, [formData.classCode, formData.courseCode]);

  useEffect(() => {
    if (formData.classCode) {
      const selectedClass = allClasses.find(
        (cls) => cls.classCode === formData.classCode
      );
      setAvailableCourses(getCoursesForClass(selectedClass));
    } else {
      setAvailableCourses([]);
    }
  }, [formData.classCode, allClasses]);

  const handleAddTemplate = async () => {
    try {
      const response = await axios.post(
        `${backendLocalhost}/survey/assignTemplate`,
        formData
      );

      setFormData({
        assigningName:"",
        templateID: "",
        classCode: "",
        courseCode: "",
        facultyID: "",
      });

      toast.success(`Assign Template added successfully`);
    } catch (error) {
      console.error("Error adding assign template", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        toast.error(error.response.data.errorMessage);
      } else {
        toast.error("Error adding assign template.");
      }
    }
  };

  const handleTempleteChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      templateID: value,
    });
  };

  const handleClassChange = (e) => {
    setFormData({
      ...formData,
      classCode: e.target.value,
      courseCode: "",
    });
  };

  const handleCourseChange = (e) => {
    setFormData({
      ...formData,
      courseCode: e.target.value,
    });
  };

  const getCoursesForClass = (selectedClass) => {
    return allCourses.filter(
      (course) => course.departmentcode === selectedClass.department
    );
  };

  const ViewDetailHandler = (obj, obj2) => {
    if (obj.length > 0) {
      setViewDetail(obj);
      setField({
        assignTitle:obj2.assigningName,
        courseCode: obj2.courseCode,
        classCode: obj2.classCode,
        assigningDate: obj2.assigningDate,
        facultyID: obj2.facultyName,
      });

    } else {
      setViewDetail([]);
      setField()
      toast.error("No Detail for this Learning Material");
    }
  };
  return (
    <div className="flex flex-col justify-start w-full h-auto overflow-y-auto gap-y-2 lg:h-full lg:overflow-y-auto lg:style-scrollbar style-scrollbar">
      <form
        className="text-black w-full grid justify-center items-center gap-x-[1vw] gap-y-[1vh] 
        lg:grid-cols-6
        grid-cols-1"
      >
        <div className="w-full flex flex-col gap-y-[0.5vh]">
          <label className="font-semibold">Template:</label>
          <select
            className="w-full bg-white select"
            name="selectedTemplate"
            value={formData.selectedTemplate}
            onChange={handleTempleteChange}
          >
            <option value="">Select a Template</option>
            {template.map((templateItem) => (
              <option
                className="text-black"
                key={templateItem._id}
                value={templateItem._id}
              >
                {templateItem.templateName}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full flex flex-col gap-y-[0.5vh]">
          <label className="font-semibold">Class:</label>
          <select
            className="w-full bg-white select"
            name="selectedClass"
            value={formData.selectedClass}
            onChange={handleClassChange}
          >
            <option value="" disabled>
              Select a Class
            </option>
            {allClasses.map((classItem) => (
              <option
                className="text-black"
                key={classItem.classCode}
                value={classItem.classCode}
              >
                {`${classItem.classCode}`}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full flex flex-col gap-y-[0.5vh]">
          <label className="font-semibold">Course:</label>
          <select
            className="w-full bg-white select"
            name="selectedCourse"
            value={formData.selectedCourse}
            onChange={handleCourseChange}
          >
            <option value="" disabled selected>
              Select a Course
            </option>
            {availableCourses.map((course) => (
              <option
                className="text-black"
                key={course.courseCode}
                value={course.courseCode}
              >
                {`${course.courseName} (${course.courseCode})`}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full flex flex-col gap-y-[0.5vh]">
          <label className="font-semibold">Faculty:</label>
          <label className="flex items-center justify-center w-full bg-white input">
            {selectedFaculty?.firstName &&
              `${selectedFaculty.firstName} ${selectedFaculty.lastName}`}
          </label>
        </div>
        <div className="w-full flex flex-col gap-y-[0.5vh]">
          <label className="font-semibold">Title :</label>
          <input
            className="px-3 py-2 border rounded input"
            type="text"
            name="assigningName"
            value={formData.assigningName}
            onChange={(e) => {
              setFormData({
                ...formData,
                assigningName: e.target.value,
              });
            }}
            placeholder="Enter Assigning Name"
          />
        </div>
        <button
          className="px-16 py-3 mt-6 bg-PrimaryColor hover:bg-PrimaryDarkColor text-white rounded-[0.5vw] font-bold"
          type="button"
          onClick={() => handleAddTemplate()}
        >
          Assign
        </button>
      </form>

      <div className="flex flex-col w-full h-auto overflow-x-auto bg-gray-300 border border-gray-900 rounded-b-lg style-scrollbar lg:max-h-full lg:overflow-auto max-h-auto">
        {viewDetail.length > 0 ? (
          <ViewAssignFeedback viewDetail={viewDetail} field={field} />
        ) : (
          <div className="w-full h-full overflow-auto text-black style-scrollbar rounded-2xl">
            <table className="h-full min-w-full text-sm bg-white border-collapse rounded-2xl">
              <thead>
                <tr>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Sr.
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Title
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Template
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Class
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Courses
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Faculty
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Forwarded
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Due Date
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Students
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Analysis
                  </th>
                </tr>
              </thead>
              <tbody>
                {assign.map((feedback, index) => (
                  <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`} key={index}>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {index + 1}
                    </td>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {feedback.assigningName}
                    </td>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {feedback.templateName}
                    </td>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {feedback.classCode}
                    </td>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {feedback.courseCode}
                    </td>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {feedback.facultyName}
                    </td>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {formatDate(feedback.assigningDate)}
                    </td>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {formatDate(feedback.assigningDate)}
                    </td>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {feedback.submittedStud.length}/
                      {feedback.submittedStud.length +
                        feedback.pendingStud.length}
                    </td>
                    <td className="p-2 text-center border-gray-400 border-x whitespace-nowrap">
                      <button
                        className="text-blue-600 cursor-pointer text-bold"
                        onClick={() => ViewDetailHandler(feedback.submittedStud, feedback)}
                      >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignFeedback;
