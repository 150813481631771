import React, { useState, useEffect } from 'react';
import config from '../../../../../config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassHalf, faCircleCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Apis from '../../../../../Apis';

const View = ({ selectedMaterialID }) => {
    const [viewDetail, setViewDetail] = useState([]);

    const SelfLearningByID = async (SelfLearningID) => {
        try {
            const LearningMaterials = await Apis.getSelfLearningByID(SelfLearningID);
            setViewDetail(LearningMaterials);
        } catch (error) {
            console.error('Error fetching Learning Material data:', error);
        }
    };

    useEffect(() => {
        SelfLearningByID(selectedMaterialID);
    }, [selectedMaterialID]);


    const [selectedFile, setSelectedFile] = useState({
        unit: "",
        topic: "",
        fileLink: "",
        fileExtension: "",
        duration: null,
        completed: [],
    });

    const selectFileHandler = (obj) => {
        setSelectedFile(
            {
                unit: obj.unit,
                topic: obj.topic,
                fileLink: obj.attachment,
                fileExtension: obj.fileExtension,
                duration: obj.duration,
                completed: obj.completed,
            }
        )
    }

    const extractNameFromFileName = (filename) => {
        const parts = filename.split('-');

        if (parts.length > 2) {
            const nameWithCV = parts.slice(2).join('-').trim();
            const nameWithoutExtension = nameWithCV.replace(/\.[^/.]+$/, "");
            return nameWithCV;
        }
        return filename;
    }

    const [docs, setDocs] = useState([]);
    let srcURL = `${config.backendLocalhost}/selfLearning/${selectedFile.fileLink}`;

    useEffect(() => {
        if (selectedFile) {
            const updatedDocs = [
                {
                    uri: srcURL,
                    fileType: selectedFile.fileExtension,
                    fileName: extractNameFromFileName(selectedFile.fileLink),
                },
            ];
            setDocs(updatedDocs);
        }
    }, [selectedFile, srcURL]);

    const [timer, setTimer] = useState(0);

    useEffect(() => {
        setApiCalled(false);
        setTimer(0);
        const interval = setInterval(() => {
            setTimer((prev) => prev + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [selectedFile]);

    const formatTime = (seconds) => {
        const hrs = String(Math.floor(seconds / 3600)).padStart(2, '0');
        const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
        const secs = String(seconds % 60).padStart(2, '0');
        return `${hrs}:${mins}:${secs}`;
    };

    const userInfo = useSelector(state => state.userInfo);

    const updateLearningMaterialCompletion = async (selfLearningID, selfLearningUnit, duration, timer, UserID) => {
        try {
            const response = await axios.put(`${config.backendLocalhost}/selfLearning/updateLearningMaterialCompletion`, {
                selfLearningID,
                selfLearningUnit,
                duration,
                timer,
                UserID,
            });
            console.log(response.data);
            toast.success(`Unit: ${selfLearningUnit} completed.`);
            SelfLearningByID(selectedMaterialID);
        } catch (error) {
            console.error('Error updating Completion of module:', error.message);
            toast.error('Error Updating Completion of Module: ' + error.message);
        }
    };

    const [apiCalled, setApiCalled] = useState(false);

    useEffect(() => {
        if (!selectedFile.duration) {
            return;
        }

        const durationInSeconds = parseInt(selectedFile.duration, 10) * 60;
        const timerInSeconds = parseInt(timer, 10);

        if (userInfo.role === "student" && timerInSeconds >= durationInSeconds && !apiCalled && !selectedFile.completed?.some(entry => entry.studID === userInfo._id)) {
            updateLearningMaterialCompletion(selectedMaterialID, selectedFile.unit, durationInSeconds, timerInSeconds, userInfo._id);
            setApiCalled(true);
        }
    }, [userInfo.role, timer, selectedFile.duration, selectedFile.completed, selectedMaterialID, userInfo._id, apiCalled, selectedFile.unit]);

    const checkIfClickable = (index) => {
        if (index === 0) return true;
        const previousItem = viewDetail[index - 1];
        const previousCompleted = previousItem?.completed?.some(entry => entry.studID === userInfo._id);
        return previousItem && (previousCompleted || previousItem.attachment === null);
    };

    let clickableFound = false;
    let localClickableFound = false;

    return (
        <div className='w-full h-full rounded-xl flex justify-center items-center'>
            <div className='w-[30%] h-full flex flex-col gap-y-2 items-start justify-start border rounded-l-xl p-2 overflow-auto style-scrollbar'>
                {viewDetail && viewDetail.map((item, index) => {
                    const hasAttachment = item.attachment !== null;
                    const isCompleted = item.completed?.some(entry => entry.studID === userInfo._id);
                    let icon;

                    if (userInfo.role === "student") {
                        if (isCompleted) {
                            icon = <FontAwesomeIcon icon={faCircleCheck} className='text-green-600' />;
                        } else if (hasAttachment && !localClickableFound) {
                            if (checkIfClickable(index)) {
                                icon = <FontAwesomeIcon icon={faHourglassHalf} className='text-yellow-500' />;
                                localClickableFound = true;
                                clickableFound = index;
                            } else {
                                icon = <FontAwesomeIcon icon={faXmark} className='text-red-600' />;
                            }
                        } else {
                            icon = <FontAwesomeIcon icon={faXmark} className='text-red-600' />;
                        }
                    }

                    const clickableClass = clickableFound === index || (hasAttachment && isCompleted) || (userInfo.role !== "student" && hasAttachment) ? "cursor-pointer hover:underline hover:text-blue-600 duration-300" : "";

                    return (
                        <div key={index} className='w-full flex items-center justify-between px-2'>
                            <div className={`font-bold text-lg text-black text-normal ${clickableClass}`}
                                onClick={clickableClass || (userInfo.role !== "student" && hasAttachment) ? () => {
                                    selectFileHandler(item);
                                } : undefined}
                            >
                                <p className={`${selectedFile.unit === item.unit && selectedFile.attachment !== null ? "text-blue-600 text-semibold" : ""}`}>{`${item.unit}) ${item.topic}`}</p>
                            </div>
                            {icon}
                        </div>
                    );
                })}
            </div>
            <div className='w-[70%] h-full flex flex-col items-start justify-start gap-y-4 p-4 border rounded-r-xl'>
                {selectedFile.unit &&
                    <div className='w-full flex justify-between items-center px-4'>
                        <p className='text-md text-bold'>{`${selectedFile.unit}) ${selectedFile.topic}`}</p>
                        <p className='text-sm'>{`Duration: ${selectedFile.duration} ${selectedFile.duration > 1 ? "minutes" : "minute"}`}</p>
                        {userInfo.role === "student" && (
                            selectedFile.completed?.some(entry => entry.studID === userInfo._id) ? (
                                <div className='text-sm flex justify-center items-center'>
                                    <FontAwesomeIcon icon={faCircleCheck} className='text-green-600' />
                                    <p>Completed</p>
                                </div>
                            ) : (
                                <p className='text-sm'>{`Timer: ${formatTime(timer)}`}</p>
                            )
                        )}
                    </div>
                }
                <div className='w-full h-full rounded-xl flex justify-center border overflow-hidden'>
                    {selectedFile.fileExtension === "docx" || selectedFile.fileExtension === "pptx" || selectedFile.fileExtension === "xlsx" ?
                        <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
                        :
                        selectedFile.fileExtension === "mp4" || selectedFile.fileExtension === "mov" || selectedFile.fileExtension === "avi" || selectedFile.fileExtension === "wmv" || selectedFile.fileExtension === "mkv" ?
                            <div className="w-full h-full bg-transparent rounded-xl overflow-hidden">
                                <video className="inset-0 object-fill w-full h-full" controls autoPlay loop>
                                    <source src={srcURL} type={`video/${selectedFile.fileExtension}`}></source>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            : selectedFile.fileExtension === "mp3" || selectedFile.fileExtension === "amr" || selectedFile.fileExtension === "wav" || selectedFile.fileExtension === "m4a" ?
                                <div className="min-w-full min-h-full bg-transparent rounded-xl overflow-hidden">
                                    <video className="inset-0 object-fill w-full h-full" controls autoPlay loop>
                                        <source src={srcURL} type={`audio/${selectedFile.fileExtension}`} ></source>
                                        Your browser does not support the audio tag.
                                    </video>
                                </div>
                                :
                                selectedFile.fileExtension === "jpg" || selectedFile.fileExtension === "png" || selectedFile.fileExtension === "jpeg" || selectedFile.fileExtension === "gif" || selectedFile.fileExtension === "tiff" ?
                                    <img className="object-contain" src={srcURL} alt="Images" />
                                    :

                                    selectedFile.fileExtension === "pdf"?
                                    <div className="w-full h-full">
                                    <iframe src={srcURL} title="ViewPDF" className="w-full h-full" ></iframe>
                                </div>
                                        :
                                        <>
                                            {selectedFile.fileExtension &&
                                                <p className='text-md text-black text-center'>{`${selectedFile.fileExtension} file extension can not be opened here`}
                                                    <span
                                                        className='ml-4 cursor-pointer hover:text-blue-600 hover:underline duration-300 text-black font-bold'
                                                        onClick={() => window.open(srcURL, '_blank')}
                                                    >
                                                        Download here</span></p>}
                                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default View