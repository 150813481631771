import React, { useState, useEffect } from 'react';
import { setAllCourses } from '../../../state/action-creators';
import { useDispatch, useSelector } from 'react-redux';
import Apis from '../../../Apis';
import { toast } from 'react-hot-toast';

const MainActivity = () => {
    const dispatch = useDispatch();

    const userInfo = useSelector(state => state.userInfo);
    const allStudentInfo = useSelector(state => state.allStudentInfo);
    const allAssignments = useSelector(state => state.assignmentDetails);
    const allQuizs = useSelector(state => state.quizDetails);
    const AllExam = useSelector(state => state.allExam);
    const allAttendance = useSelector(state => state.allAttendance);
    const [weeklySchedule, setWeeklySchedule] = useState([]);

    function countMatchingStudentsByClass(targetClass) {
        const matchingStudents = allStudentInfo.filter((student) =>
            student.studentDetails && student.studentDetails.class === targetClass
        )
        return matchingStudents.length;
    }
    const [courses, setCourses] = useState([]);
    const [activeAessions, setActiveSessions] = useState();

    const fetchCourses = async () => {
        const user = {
            userID:userInfo._id,
            userRole:'admin',
          }
        try {
          const AllCourses = await Apis.getAllCourses("", user);
          dispatch(setAllCourses(AllCourses || []));
          setCourses(AllCourses);
        } catch (error) {
          console.error('Error fetching Courses:', error.message);
          toast.error('Error fetching Courses:', error.message);
        }
      };
      const currentSession = async () => {
        try {
          const session = await Apis.getActiveSessions();
          setActiveSessions(session[0]);
        } catch (error) {
          console.error('Error fetching Courses:', error.message);
          toast.error('Error fetching Courses:', error.message);
        }
      };
      const getAllWeeklySchedule = async () => {
        try {
          const session = await Apis.getAllWeeklySchedule();
          setWeeklySchedule(session);
        } catch (error) {
          console.error('Error fetching Courses:', error.message);
        }
      };

    useEffect(() => {
        currentSession();
        if(userInfo._id && userInfo.role){
        fetchCourses();
        }
        getAllWeeklySchedule();
    }, [userInfo]);

    function getCourseDetailsByCode(courseCode, defaultValue = { course: "", creditHour: "" }) {
        const foundCourse = courses.find(course => course.courseCode === courseCode);

        return foundCourse
            ? { course: foundCourse.courseName, creditHour: foundCourse.credithour, courseCode: foundCourse.courseCode  }
            : defaultValue;
    }

    function countAssignmentsByClassAndCourse(val, classFilter, courseFilter) {
        const filteredAssignments = val.filter((assignment) => {
            return assignment.class === classFilter && assignment.course === courseFilter;
        });

        return filteredAssignments.length;
    }

    function rearrangeAndFilterSchedule() {
        const rearrangedSchedule = [];
        userInfo.facultyDetails.courseDetail.forEach(courseDetail => {
          const filteredSchedules = weeklySchedule.filter(schedule => schedule.class === courseDetail.class && schedule.course === courseDetail.course);
          if (filteredSchedules.length > 0) {
            rearrangedSchedule.push(...filteredSchedules);
          } else {
            rearrangedSchedule.push({ class: courseDetail.class, course: courseDetail.course, schedules: [] });
          }
        });
        return rearrangedSchedule;
      }

   const filteredAndRearrangedSchedule = rearrangeAndFilterSchedule();

    const getPeriods = (schedules, day) => {
        const schedule = schedules.find(schedule => schedule.dayOfWeek === day);
        if (schedule) {
            return schedule.periods.join(', ');
        } else {
            return '-';
        }
    };
    return (
        <div className='w-full h-full bg-transparent rounded-[2vw] shadow-lg shadow-blue-700'>

            <div className='w-full h-full bg-transparent rounded-[2vw] shadow-lg shadow-blue-700'>
                <div className='w-full h-full bg-gray-100 shadow-md shadow-blue-500 rounded-[1vw]'>
                    {/* Table Header */}
                    <div className='w-full h-[5vh] flex bg-blue-100 rounded-t-[1.2vw]'>
                        <div className='w-[75%] h-full bg-blue-500 text-white flex justify-center items-center gap-x-[1vw] rounded-tl-[1vw]'>
                            <div className='w-[65%] h-full flex justify-start items-center gap-x-[1vw] text-[1.2vw] px-[1vw]'>
                                <p className=' tracking-wider font-semibold'>{`Session: ${activeAessions?.code}`}</p>
                            </div>

                            <div className='w-[34%] h-full bg-blue-500 text-white flex justify-around items-center rounded-r-md rounded-lr-xl border-l-[0.1vw]'>
                                <p className='xl:text-[1.2vw] tracking-wider font-semibold'>Completed & Planned Activities</p>
                            </div>
                        </div>
                        <div className='w-[26%] h-full bg-blue-500 text-white flex justify-center items-center border-l-[0.1vw] rounded-tr-[1vw]'>
                            <p className='text-[1.2vw] tracking-wider font-semibold'>Weekly Class Schedule (Periods)</p>
                        </div>
                    </div>
                    {/* Table Body */}
                    <div className='w-full h-[25vh] flex overflow-x-auto style-scrollbar rounded-b-[1vw]'>
                        <div className="w-[75%]">
                            <table className="min-w-full bg-white border table-fixed">
                                <thead>
                                    <tr>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-[1vw] w-[16%] flex-shrink-0 border border-gray-400 text-left">
                                            Class
                                        </th>
                                        <th className="sticky top-0 bg-gray-200 px-[0.5vw] py-[1vh] text-[1vw] w-[30%] flex-shrink-0 text-left border border-gray-400">
                                            Course
                                        </th>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-[1vw] w-[10%] flex-shrink-0 border border-gray-400">
                                            Credits Hour
                                        </th>
                                        <th className="sticky top-0 bg-gray-200 px-[0.5vw] py-[1vh] text-[1vw] w-[10%] flex-shrink-0 border border-gray-400">
                                            Students
                                        </th>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-[1vw] w-[10%] flex-shrink-0 border border-gray-400">
                                            Lecture
                                        </th>
                                        <th className="sticky top-0 bg-gray-200 px-[0.5vw] py-[1vh] text-[1vw] w-[10%] flex-shrink-0 border border-gray-400">
                                            Assignment
                                        </th>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-[1vw] w-[7%] flex-shrink-0 border border-gray-400">
                                            Quiz
                                        </th>
                                        <th className="sticky top-0 bg-gray-200 px-[0.5vw] py-[1vh] text-[1vw] w-[7%] flex-shrink-0 border border-gray-400">
                                            Exam
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userInfo.facultyDetails && userInfo.facultyDetails.courseDetail.map((facultyDetail, index) => (
                                        <tr className='divide-y divide-gray-200' key={index}>
                                            <td className="px-[0.5vw] py-[1vh] text-[1vw] flex-shrink-0 text-left">
                                                {facultyDetail.class}
                                            </td>
                                            <td className="px-[0.5vw] py-[1vh] text-[1vw] flex-shrink-0 truncate text-left">
                                            {getCourseDetailsByCode(facultyDetail.course).courseCode} - {getCourseDetailsByCode(facultyDetail.course).course} </td>
                                            <td className="px-[0.5vw] py-[1vh] text-[1vw] flex-shrink-0 text-center ">{getCourseDetailsByCode(facultyDetail.course).creditHour}</td>
                                            <td className="px-[0.5vw] py-[1vh] text-[1vw] flex-shrink-0 text-center ">{countMatchingStudentsByClass(facultyDetail.class)}</td>
                                            <td className="px-[0.5vw] py-[1vh] text-[1vw] flex-shrink-0 text-center bg-blue-100">{countAssignmentsByClassAndCourse(allAttendance, facultyDetail.class, facultyDetail.course)}</td>
                                            <td className="px-[0.5vw] py-[1vh] text-[1vw] flex-shrink-0 text-center bg-gray-200">{countAssignmentsByClassAndCourse(allAssignments, facultyDetail.class, facultyDetail.course)}</td>
                                            <td className="px-[0.5vw] py-[1vh] text-[1vw] flex-shrink-0 text-center bg-blue-100">{countAssignmentsByClassAndCourse(allQuizs, facultyDetail.class, facultyDetail.course)}</td>
                                            <td className="px-[0.5vw] py-[1vh] text-[1vw] flex-shrink-0 text-center bg-blue-100">{countAssignmentsByClassAndCourse(AllExam, facultyDetail.class, facultyDetail.course)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="w-[25%]">
                            <table className="min-w-full bg-gray-100 border table-fixed">
                                <thead>
                                    <tr>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-[1vw] w-[16.67%] flex-shrink-0 border border-gray-400">
                                            Mon
                                        </th>
                                        <th className="sticky top-0 bg-gray-200 px-[0.5vw] py-[1vh] text-[1vw] w-[16.67%] flex-shrink-0 border border-gray-400">
                                            Tue
                                        </th>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-[1vw] w-[16.67%] flex-shrink-0 border border-gray-400">
                                            Wed
                                        </th>
                                        <th className="sticky top-0 bg-gray-200 px-[0.5vw] py-[1vh] text-[1vw] w-[16.67%] flex-shrink-0 border border-gray-400">
                                            Thu
                                        </th>
                                        <th className="sticky top-0 bg-blue-100 px-[0.5vw] py-[1vh] text-[1vw] w-[16.67%] flex-shrink-0 border border-gray-400">
                                            Fri
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredAndRearrangedSchedule && filteredAndRearrangedSchedule.map((item, index) => (
                                        <tr key={index} className="bg-gray-100">
                                            <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center">{getPeriods(item.schedules, 'Mon')}</td>
                                            <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center">{getPeriods(item.schedules, 'Tue')}</td>
                                            <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center">{getPeriods(item.schedules, 'Wed')}</td>
                                            <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center">{getPeriods(item.schedules, 'Thu')}</td>
                                            <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center">{getPeriods(item.schedules, 'Fri')}</td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainActivity