import React from 'react'
import { useSelector } from 'react-redux';
import Complaints from './compoonents/Complaints';
import Courses from './compoonents/Course';
import Program from './compoonents/Program';
import Link from './compoonents/Link';
import Session from './compoonents/Session';
import Class from './compoonents/Class';
import Student from './compoonents/Student';
import Faculty from './compoonents/Faculty';
import WeeklySchedule from './compoonents/WeeklySchedule';
import Configurations from './compoonents/Configurations';
import Academic from './compoonents/Academic';
import AuditTrail from './compoonents/AuditTrail';
import Feedback from './compoonents/Feedback';
import ExecutiveAdmin from './compoonents/ExecutiveAdmin'
import SelfLearning from './compoonents/SelfLearning';
const DefaultLayout = ({ menuToggle }) => {
    const selectedMenuBtn = useSelector(state => state.selectedMenuBtn);

    return (
        <div className={`text-white  bg-transparent transitionAll 
        5xl::rounded-r-[0.8vw] 5xl:h-full 5xl:overflow-hidden
        rounded-r-[0.8vw] h-full overflow-auto
        ${menuToggle
                ? `5xl:w-[82vw]
                   4md:w-[82vw]
                   w-[0vw]`
                : `w-[100vw]`
            }`}
        >
            {selectedMenuBtn === "Department" ? <Complaints /> :
                selectedMenuBtn === "Courses" ? <Courses /> :
                    selectedMenuBtn === "Program" ? <Program /> :
                        selectedMenuBtn === "LinkProgCourses" ? <Link /> :
                            selectedMenuBtn === "Session" ? <Session /> :
                                selectedMenuBtn === "Class" ? <Class /> :
                                    selectedMenuBtn === "Student" ? <Student /> :
                                        selectedMenuBtn === "Faculty" ? <Faculty /> :
                                            selectedMenuBtn === "WeeklySchedule" ? <WeeklySchedule /> :
                                                selectedMenuBtn === "Academic" ? <Academic /> :
                                                    selectedMenuBtn === "Paid" ? <Complaints /> :
                                                        selectedMenuBtn === "Pending" ? <Complaints /> :
                                                            selectedMenuBtn === "Configurations" ? <Configurations /> :
                                                                selectedMenuBtn === "auditTrail" ? <AuditTrail /> :
                                                                  selectedMenuBtn === "Feedback" ? <Feedback /> :
                                                                  selectedMenuBtn === "ExcecutiveAdmin" ? <ExecutiveAdmin /> :
                                                                  selectedMenuBtn === "SelfLearning" ? <SelfLearning /> :
                                                                    <></>}
        </div>
    )
}

export default DefaultLayout