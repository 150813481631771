import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from "react-hot-toast";
import { useSelector } from 'react-redux';
import config from '../../../../config';

function TitleLogoForm() {
    const backendBaseUrl = config.backendLocalhost;
    const configName = useSelector(state => state.configName);

    const [title, setTitle] = useState('');
    const [logo, setLogo] = useState(null);
    const [error, setError] = useState('');

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogo(file);
            setError('');
        } else {
            setLogo(null);
            setError('Please select a file.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append("Title", title);
        formDataToSend.append("LogoFile", logo);
        const loadingToast = toast.loading('Loading...');
        try {
            const response = await axios.put(`${backendBaseUrl}/configuration/uploadTileLogo/${configName[0]._id}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success(`Tilte and Logo Config successfully`);
            setTitle('');
            setLogo(null);
        } catch (error) {
            console.error('Error! Tilte and Logo Config:', error);
            toast.error('Error! Tilte and Logo Config:', error.message || 'Unknown error');
        } finally {
            toast.dismiss(loadingToast);
        }
    };

    useEffect(() => {
        setTitle(configName[0].nameConfiguration.Title)
    }, []);


    return (
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-[2vw] bg-SecondaryColor rounded-2xl">
            <div className="mb-4">
                <label htmlFor="title" className="block text-nd font-medium text-gray-700">Title</label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    value={title}
                    onChange={handleTitleChange}
                    className="mt-1 p-2 block w-full rounded-md text-black border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="logo" className="block text-md font-medium text-gray-700 text-black">Logo</label>
                <input
                    type="file"
                    id="logo"
                    name="logo"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleLogoChange}
                    className="mt-1 p-2 block w-full rounded-md border-gray-300 text-black shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            </div>
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            <button
                type="submit"
                className="bg-PrimaryColor text-white py-2 px-4 rounded-md hover:bg-PrimaryDarkColor focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50 duration-300"
            >
                Submit
            </button>
        </form>
    );
}

export default TitleLogoForm;
