import React from 'react'
import { useSelector } from 'react-redux';
import Dashboard from './Pages/Dashboard';
import Notification from './Pages/Notification';
import Result from './Pages/Result'
import Online from './Pages/Online';
import Chatbox from './Pages/Chatbox';
import LearningMaterial from './Pages/LearningMaterial'
import Schedule from './Pages/Schedule'
import Calender from './Pages/Calender'
import Payment from './Pages/Payment'
import ActionOption from './Pages/SurveyForms/ActionOption';
import ActionOption2 from './Pages/SurveyForms/ActionOption2';
import TextOption from './Pages/SurveyForms/TextOption';
import NumericOption from './Pages/SurveyForms/NumericOption';
import MultipleOption from './Pages/SurveyForms/MultipleOption';


const DefaultLayout = ({ menuToggle,AssignmentModalHandler, AttendanceModalHandler, QuizModalHandler, FinalModalHandler,CoursePlanModalHandler,ClassesModalHandler,PaidModalHandler,PendingModalHandler,AcademicModalHandler,SelectedRemindersHandler,AllSemModalHandler,TimeTableModalHandler,FeedbackModalHandler,ElearningModalHandler,openAcademic,openAllSemModal,SurveyModalHandler,openSurvey}) => {
    const selectedMenuBtn = useSelector(state => state.selectedMenuBtn);
    
    return (
        <div className={`text-white  bg-transparent transitionAll 
        5xl::rounded-r-[0.8vw] 5xl:h-full 5xl:overflow-hidden
        rounded-r-[0.8vw] h-full overflow-y-auto no-scrollbar
        ${menuToggle
                ? `5xl:w-[82vw]
                   4md:w-[82vw]
                   w-[0vw]`
                : `w-[100vw]`
            }`}>
            {
            selectedMenuBtn === "Dashboard" ? <Dashboard
            AssignmentModalHandler={AssignmentModalHandler}
            AttendanceModalHandler={AttendanceModalHandler}
            QuizModalHandler={QuizModalHandler}
            FinalModalHandler={FinalModalHandler}
            CoursePlanModalHandler={CoursePlanModalHandler} /> :
            selectedMenuBtn === "Notification" ? <Notification
            SelectedRemindersHandler={SelectedRemindersHandler} /> :
            selectedMenuBtn === "Chatbox" ? <Chatbox
            FeedbackModalHandler={FeedbackModalHandler}/> :
            selectedMenuBtn === "LearningMaterial" ? <LearningMaterial
            ElearningModalHandler={ElearningModalHandler} /> :
            selectedMenuBtn === "Schedule" ? <Schedule 
            TimeTableModalHandler={TimeTableModalHandler}/> :
            selectedMenuBtn === "Online" ? <Online/> :
            selectedMenuBtn === "AcademicCalender" ? <Calender
            openAcademic={openAcademic}
            AcademicModalHandler={AcademicModalHandler}/> :
            selectedMenuBtn === "Result" ? <Result
            openAllSemModal={openAllSemModal}
            AllSemModalHandler={AllSemModalHandler} /> :
            selectedMenuBtn === "Payment" ? <Payment
            PaidModalHandler={PaidModalHandler}
            PendingModalHandler={PendingModalHandler}/> :
            selectedMenuBtn === "Survey" ? <ActionOption2
            SurveyModalHandler={SurveyModalHandler}
            openSurvey={openSurvey}/> :
            
             <></>}
        </div>
    )
}

export default DefaultLayout