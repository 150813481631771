// reducers/FacultyActivityListToggle.js
import { SET_ATTENDANCE_DATA } from '../action-creators/ActionTypes';

const AttendanceData = (state = [] , action) => {
    switch (action.type) {
        case SET_ATTENDANCE_DATA:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AttendanceData;
