import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from "react-hot-toast";
import { useSelector } from 'react-redux';
import config from '../../../../config';
import { formatDate } from '../../../FormatDate';
import Apis from '../../../../Apis';
import { MessageByName } from './AddNotification';

const OnlineLectureModal = ({ OnlineLectureHandler, openOnlineLecture, selectedCourse, selectedClass }) => {
    const [maxScreen, setMaxScreen] = useState(false);
    const userInfo = useSelector(state => state.userInfo);
    const backendBaseUrl = config.backendLocalhost;
    const [date, setDate] = useState('');
    const [roomLink, setRoomLink] = useState('');
    const [time, setTime] = useState('');
    const [duration, setDuration] = useState('');
    const [status, setStatus] = useState(false);
    const ResponseMessages = useSelector(state => state.responseMessage);

    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        setRoomLink(`${selectedClass}_${selectedCourse}`)
    }, [selectedClass, selectedCourse]);

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setCurrentDate(today);
    }, []);

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    }

    const resetValues = () => {
        setDate('');
        setTime('');
        setDuration('');
        setStatus(false);
        OnlineLectureHandler('', '')
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!userInfo._id) {
            toast.error("ERROR! Faculty Info is not present");
            return;
        }
        if (selectedCourse === '') {
            toast.error("ERROR! Course is not selected");
            return;
        }
        if (selectedClass === '') {
            toast.error("ERROR! Class is not selected");
            return;
        }
        if (date === '') {
            toast.error("ERROR! Date is not selected");
            return;
        }
        if (time === '') {
            toast.error("ERROR! Time is not selected");
            return;
        }
        if (typeof status !== 'boolean') {
            toast.error("ERROR! Status is not selected");
            return;
        }
        if (duration === '') {
            toast.error("ERROR! Class Duration is not selected");
            return;
        }

        const formData = {
            facultyID: userInfo._id,
            facultyName: `${userInfo.firstName} ${userInfo.lastName}`,
            course: selectedCourse,
            className: selectedClass,
            date: new Date(date).toISOString(),
            link: roomLink,
            time,
            status,
            duration: parseFloat(duration),
        };
        try {
            const response = await axios.post(`${backendBaseUrl}/onlineLecture/add`, formData);
            resetValues();
            const ResponseMsg = MessageByName("Room created by faculty", ResponseMessages);
            Apis.addAuditTrail(userInfo._id, "6656022dbc30d91079b1907e", `- [CourseID:${selectedCourse}, ClassID:${selectedClass}]`);
            toast.success(ResponseMsg);
        } catch (error) {
            console.error('Error Adding Online Lecture:', error);
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error('An error occurred while Adding Online Lecture.');
            }
        }

    };

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
${openOnlineLecture
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0"
                    : "w-[70vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
                }
`}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
 ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="text-[2vw] font-semibold">Create Online Lecture</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
                    </button>
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    {/* Provide the implementation for AddProjectHandler */}
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => OnlineLectureHandler('', '')}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col justify-center items-center
    ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
    `}>
                <div className='w-[90%] h-[90%] bg-gray-100 rounded-[1vw] flex flex-col gap-y-[1vh]'>
                    <div className='flex flex-row justify-center items-center px-[3vw] py-[1vh] gap-x-[1vw]'>
                        {/* Date Input */}
                        <div className='flex flex-col items-start w-1/4 h-full'>
                            <p className='text-[1vw]'>Select Date</p>
                            <input
                                type="date"
                                id="assigningDateInput"
                                className="w-full h-full text-[1vw] text-black text-center border border-blue-300 rounded-[1vw] focus:border-blue-900 focus:outline-none"
                                placeholder="Today's Date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                min={currentDate}
                                required
                            />
                        </div>

                        {/* Time Input */}
                        <div className='flex flex-col items-start w-1/4 h-full'>
                            <p className='text-[1vw]'>Select Time</p>
                            <input
                                type="time"
                                id="dueDateInput"
                                className="w-full h-full text-[1vw] text-black text-center border border-blue-300 rounded-[1vw] focus:border-blue-900 focus:outline-none"
                                placeholder="Due Date"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                                required
                            />
                        </div>

                        {/* Duration Input */}
                        <div className='flex flex-col items-start w-1/4 h-full'>
                            <p className='text-[1vw]'>Duration (minutes)</p>
                            <input
                                type="number"
                                id="totalMarksInput"
                                className="w-full h-full text-[1vw] p-[0.5vw] bg-white text-black border border-blue-300 rounded-[1vw] bg-transparent focus:outline-none hover:cursor-pointer focus:border-blue-900 text-center transition-all duration-300"
                                placeholder="Duration of Class"
                                value={duration}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value >= 0) {
                                        setDuration(value);
                                    }
                                }}
                                required
                                min="0"
                                step="0.1"
                            />
                        </div>

                        {/* Status Input */}
                        <div className='flex flex-col items-start w-1/4 h-full'>
                            <p className='text-[1vw]'>Status</p>
                            <div className='flex gap-x-[0.5vw] justify-center items-center'>
                                <input
                                    className='checkbox'
                                    type="checkbox"
                                    id='status'
                                    name="status"
                                    checked={status}
                                    onChange={(e) => setStatus(e.target.checked)}
                                />
                                <label htmlFor='status' className={`text-center ${status ? "text-green-600" : "text-red-600"}`}>
                                    {status ? "Active" : "In-Active"}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-y-1 justify-start items-start px-[3vw]'>
                        <p className='text-2xl font-bold text-black'>Room Link:</p>
                        <p className='text-lg text-black'>{roomLink}</p>
                    </div>
                    <div className='flex flex-row justify-center items-center py-[1vh] gap-x-[1vw]'>
                        <button
                            onClick={handleSubmit}
                            className='h-full py-2 w-[20%] text-[1vw] font-semibold rounded-[1vw] text-black bg-green-400 hover:text-white hover:bg-green-700 duration-300'>
                            Upload
                        </button>
                        <button
                            onClick={() => OnlineLectureHandler('', '')}
                            className='h-full py-2 w-[20%] text-[1vw] font-semibold rounded-[1vw] text-black bg-red-400 hover:text-white hover:bg-red-700 duration-300'>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnlineLectureModal