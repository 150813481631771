import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import View from '../../../employeeComp/Dashboard/GradeReport/SelfLearning/View';

const SelfLearningDetailView = ({ SelfLearningDetailHandler, SelfLearningDetail, openSelfLearningDetail }) => {
    const [maxScreen, setMaxScreen] = useState(false);

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    };

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
                ${openSelfLearningDetail
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
                ${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[70vw] h-[60vh] rounded-t-[1vw] top-[15vh] left-[15vw]"
                }
            `}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-red-900 via-red-600 to-red-300
                 ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"}
                `}
            >
                <div>
                    <h1 className="text-[2vw] font-semibold">{`Self Learning`}</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff" }} />
                    </button>
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => SelfLearningDetailHandler('')}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col justify-center items-center
                ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
                {openSelfLearningDetail &&
                    <View
                        selectedMaterialID={SelfLearningDetail} />}
            </div>
        </div>
    )
}

export default SelfLearningDetailView