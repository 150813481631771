import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import Apis from "../../../../Apis";
import { formatDate } from "../../../FormatDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faCaretLeft } from "@fortawesome/free-solid-svg-icons";

const FeedbackDetail = () => {
  const [assignedTemplates, setAssignedTemplates] = useState([]);
  const [selectedAssignedTemplateID, setSelectedAssignedTemplateID] = useState("");
  const [assignedStudList, setAssignedStudList] = useState([]);
  const [selctedAssignedStudList, setSelectedAssignedStudList] = useState({});

  const AssignedTemplateNames = async () => {
    try {
      const AssignedArray = await Apis.getAllAssignedSurveysNameOnly();
      setAssignedTemplates(AssignedArray)
    } catch (error) {
      console.error('Error fetching Assigned Surveys Name:', error.message);
      toast.error('Error fetching Assigned Surveys Name:', error.message);
    }
  };
  const AssignedTemplateStudList = async () => {
    try {
      const AssignedArray = await Apis.getStudentsInfoByAssignedSurveyID(selectedAssignedTemplateID);
      setAssignedStudList(AssignedArray)
    } catch (error) {
      console.error('Error fetching Assigned Surveys Name:', error.message);
      toast.error('Error fetching Assigned Surveys Name:', error.message);
    }
  };

  useEffect(() => {
    if (selectedAssignedTemplateID !== "") {
      AssignedTemplateStudList();
    }
  }, [selectedAssignedTemplateID]);

  useEffect(() => {
    AssignedTemplateNames();
  }, []);

  return (
    <div className="flex flex-col w-full h-full px-4 gap-y-2">
      <div className="flex items-center justify-between w-full">
        <div className=" flex flex-col gap-y-[0.5vh] text-black">
          <label className="font-semibold">Select Template through Assigned Name:</label>
          <select
            className="w-full bg-white select"
            name="selectedAssignedTemplate"
            value={selectedAssignedTemplateID}
            onChange={(e) => setSelectedAssignedTemplateID(e.target.value)}
          >
            <option value="" disabled>
              Select a Survey Title
            </option>
            {assignedTemplates && assignedTemplates.map((val) => (
              <option
                className="text-black"
                key={val._id}
                value={val._id}
              >
                {`${val.assigningName}`}
              </option>
            ))}
          </select>
        </div>
        {selctedAssignedStudList.surveyResult &&
          <button className='flex justify-center items-centertext-sm px-6 py-[1vh] bg-blue-900 text-white font-semibold rounded-[0.5vw] hover:underline duration-300 cursor-pointer'
            onClick={() => setSelectedAssignedStudList({})}
          >
            <p className='mr-2 text-sm text-white'>Back to Student List</p>
            <FontAwesomeIcon icon={faCaretLeft} className='text-lg text-white' /></button>
        }
      </div>
      {selctedAssignedStudList.surveyResult ?
        (
          <div className="flex flex-col w-full max-h-full p-4 overflow-auto text-black bg-white style-scrollbar gap-y-2 rounded-xl">
            <div className="flex items-center justify-start w-full p-4 mb-4 bg-blue-100 shadow-lg gap-x-4 rounded-xl">
              <div className="flex gap-x-2 text-md">
                <p className="text-bold">Student Name:</p>
                <p>{selctedAssignedStudList.studName}</p>
              </div>
              <div className="flex gap-x-2 text-md">
                <p className="text-bold">Submitted Date:</p>
                <p>{formatDate(selctedAssignedStudList.surveyResult[0].submittedDate)}</p>
              </div>
            </div>
            {selctedAssignedStudList.surveyResult.map((item, index) => {
              let answerText = item.Answer.join(', ');
              if (
                (item.questionType === 'singleSelectWithOtherOption' || item.questionType === 'multipleSelectWithOtherOption') &&
                item.Answer.includes('Other')
              ) {
                answerText = item.Answer.map(answer => (answer === 'Other' ? item.otherOptionText : answer)).join(', ');
              }
              return (
                <div key={index} className="flex flex-col mb-4 gap-y-2">
                  <p className="text-black text-md">
                    <label className="text-lg text-bold">{`Ques: ${item.quesNo} `}</label>{item.question}
                  </p>
                  <p className="text-gray-800 text-md">
                    <label className="text-lg text-bold">{"Answer: "}</label>{answerText}
                  </p>
                </div>
              );
            })}
          </div>
        )
        : (
          <div className="w-full max-h-full overflow-auto text-black style-scrollbar">
            <table className="h-full min-w-full text-sm bg-white border-collapse">
              <thead>
                <tr>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Sr.
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Student Name
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    Submitted Date
                  </th>
                  <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                    View Detail
                  </th>
                </tr>
              </thead>
              <tbody>
                {assignedStudList && assignedStudList.map((val, index) => (
                  <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`} key={index}>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {index + 1}
                    </td>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {val.studName}
                    </td>
                    <td className="p-2 border-gray-400 border-x whitespace-nowrap">
                      {formatDate(val.surveyResult[0].submittedDate)}
                    </td>
                    <td className="p-2 text-center border-gray-400 border-x whitespace-nowrap">
                      <button
                        className="text-blue-600 cursor-pointer text-bold"
                        onClick={() => setSelectedAssignedStudList(val)}
                      >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>)}
    </div>
  );
};

export default FeedbackDetail;
