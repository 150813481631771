import React, { useState, useEffect } from 'react';
import Apis from '../../../../Apis';
import axios from 'axios';
import { toast } from "react-hot-toast";
import config from '../../../../config';
import EditButton from '../EditButton';


function ResponseMessages() {

    const backendBaseUrl = config.backendLocalhost;
    const [allAuditMessages, setAllAuditMessages] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState(true);
    const [name, setName] = useState('');
    const [Id, setId] = useState("");
    const [searchId, setSearchId] = useState(null);

    const handleStatusChange = (e) => {
        setStatus(e.target.checked);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const loadingToast = toast.loading('Loading...');
        try {
            const response = await axios.put(`${backendBaseUrl}/configuration/updateResponseMsg`, {
                message,
                name,
                status,
                Id: Id,
            });
            toast.success(`Configured successfully`);
            setMessage('')
            setId("")
            setStatus(true);
            setName('');
            setSearchId(response)
        } catch (error) {
            console.error("Error Configuring Message:", error);
            toast.error("Error! Configuring Message:", error.message || 'Unknown error');
        } finally {
            toast.dismiss(loadingToast);
        }
    };

    const handleEdit = (value) => {
        if (value) {
            setId(value._id);
            setMessage(value.Message)
            setStatus(value.Status);
            setName(value.Name)
        }
    }

    const fetchAllRepsonseMessages = async () => {
        try {
            const AllResponseMessages = await Apis.fetchAllRepsonseMessages(searchId);
            setAllAuditMessages(AllResponseMessages);
        } catch (error) {
            console.error('Error fetching Response Messages:', error.message);
            toast.error('Error fetching Response Messages:', error.message);
        }
    };

    useEffect(() => {
        fetchAllRepsonseMessages();
    }, [searchId]);

    return (
        <div className='flex flex-col items-center w-full h-full jusityf-start gap-y-4'>
        <div className='grid items-start w-full gap-2 px-4 py-6 jusitfy-center rounded-3xl bg-SecondaryColor lg:grid-cols-3 grids-rows-3'>
            <div className='flex flex-col items-start justify-center w-full gap-y-2'>
                <label htmlFor="message" className="block font-medium text-gray-700 text-nd">Message:</label>
                <input
                    type="text"
                    id="message"
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    className="block w-full p-2 text-black border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            </div>
            <div className='flex flex-col items-start justify-center w-full gap-y-2'>
                <label htmlFor="message" className="block font-medium text-gray-700 text-nd">Function Name:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    disabled={Id === "" ? false:true}
                    className="block w-full p-2 text-black border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            </div>
            <div className='flex w-full '>
            <div className='flex flex-col items-start justify-center w-10 h-auto gap-y-2'>
                <label htmlFor="message" className="block font-medium text-gray-700 text-nd">Status:</label>
                <input
                    className=' w-full h-[5vh]'
                    type="checkbox"
                    id='status'
                    name="status"
                    checked={status}
                    onChange={handleStatusChange}
                />
            </div>
            <button
                onClick={handleSubmit}
                className="px-4 py-2 ml-2 text-white duration-300 rounded-md w-[80%] mt-7 bg-PrimaryColor hover:bg-PrimaryDarkColor focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
                {Id ? "Update" : "Add new message"}
            </button>
            </div>
        </div>
            <div className='h-[80%] w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg overflow-auto'>
                <table className="table min-w-full text-black rounded-lg table-auto bg-SecondaryColor" style={{ width: '100%' }}>
                    <thead className=''>
                        <tr className='text-sm font-semibold'>
                            <th className="w-[5%] px-4 py-3 text-center border border-gray-400">Sr.</th>
                            <th className="w-[60%] px-4 py-3 text-left border border-gray-400 ">Response Message</th>
                            <th className="w-[25%] px-4 py-3 text-left border border-gray-400 ">Name</th>
                            <th className="w-[5%] px-4 py-3 text-left border border-gray-400 ">Status</th>
                            <th className="w-[5%] px-4 py-3 text-center border border-gray-400">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allAuditMessages && allAuditMessages.map((val, index) => (
                            <tr key={val._id}
                                className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}
                            >
                                <td className="px-4 py-3 text-center ">{index + 1}</td>
                                <td className="px-4 py-3 text-left truncate">{val.Message}</td>
                                <td className="px-4 py-3 text-left truncate">{val.Name}</td>
                                <td className={`px-4 py-3 truncate text-left ${val.Status ? "text-green-600" : "text-red-600"}`}>{val.Status ? "Active" : "In-Active"}</td>
                                <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center ">
                                    <button onClick={() => handleEdit(val)}>
                                        <EditButton />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default ResponseMessages