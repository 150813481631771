import React, { useEffect, useState } from 'react';
import EditButton from '../EditButton';
import axios from 'axios';
import config from '../../../../config';

const ViewFeedback = () => {
  const [templates, setTemplates] = useState([]);
  const { backendLocalhost } = config;
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendLocalhost}/survey/getAllTemplate`);
        setTemplates(response.data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };
    
    fetchData();
  }, []);

  const handleEditTemplate = (template) => {
    console.log('Editing template:', template);
  };

  return (
    <div className='flex flex-col w-full h-auto overflow-x-auto bg-gray-300 border border-gray-900 rounded-b-lg style-scrollbar lg:max-h-full lg:overflow-auto max-h-auto'>
      <table className="table min-w-full text-black rounded-lg table-auto bg-SecondaryColor" style={{ width: '100%' }}>
        <thead>
          <tr className='text-sm font-semibold'>
            <th className="px-4 py-3 text-center border border-gray-400">Sr.</th>
            <th className="px-4 py-3 text-left border border-gray-400">Title</th>
            <th className="px-4 py-3 text-left border border-gray-400">Template Type</th>
            <th className="px-4 py-3 text-center border border-gray-400">Actions</th>
          </tr>
        </thead>
        <tbody>
          {templates.map((template, index) => (
            <tr key={template._id} className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}>
              <td className="px-4 py-3 text-center">{index + 1}</td>
              <td className="px-4 py-3 text-left truncate">{template.templateName}</td>
              <td className="px-4 py-3 text-left">{template.templateType}</td>
              <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center">
                <button onClick={() => handleEditTemplate(template)}>
                  <EditButton />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewFeedback;
