import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from "react-hot-toast";
import { useSelector } from 'react-redux';
import config from '../../../../config';
import Apis from '../../../../Apis';

const ChatBoxModal = ({ openChatBoxModal, ChatBoxDetailHandler, chatBoxDetail }) => {
    let loadingToast ="";
    const [maxScreen, setMaxScreen] = useState(false);
    const backendBaseUrl = config.backendLocalhost;
    const [content, setContent] = useState('');
    const [data, setData] = useState(null);

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    };

    const closeScreen = () => {
        setData(null);
        ChatBoxDetailHandler([]);
        setContent('');
        toast.dismiss(loadingToast);
    };

    const userInfo = useSelector(state => state.userInfo);

    const FacultyID = userInfo._id;
    const StudID = chatBoxDetail._id;

    const fetchData = async (FacultyID, StudID) => {
        try {
            const response = await axios.get(`${backendBaseUrl}/chatBox/get/${FacultyID}/${StudID}`);
            setData(response.data);
            toast.dismiss(loadingToast);
        } catch (error) {
            console.error('API Error:', error.response ? error.response.data : 'Unknown Error');
            if (error.response && error.response.status === 404) {
                toast.success('Starting a new conversation.');
            } else {
                toast.error('Failed to fetch data');
            }
            toast.dismiss(loadingToast);
        }
    };

    useEffect(() => {
        if (FacultyID && StudID) {
            fetchData(FacultyID, StudID);
            loadingToast = toast.loading('Fetching chat...');
        }
    }, [FacultyID, StudID]);

    const handleSubmit = async (e) => {
        const loadingToastSend = toast.loading('Sending...');
        e.preventDefault();
        try {
            const response = await axios.post(`${backendBaseUrl}/chatBox/send`, {
                FacultyID: userInfo._id,
                StudID: chatBoxDetail._id,
                content: content,
                owner: "Faculty",
            });
            setContent('');
            fetchData(FacultyID, StudID);
            Apis.addAuditTrail(userInfo._id,"6634931d4fba113615476d1b",`- [ClassCode:${chatBoxDetail.studentDetails.class}, Member:${chatBoxDetail.firstName} ${chatBoxDetail.lastName}]`);
            toast.dismiss(loadingToastSend);
        } catch (error) {
            toast.error('API Error:', error.response ? error.response.data : 'Unknown Error');
            toast.dismiss(loadingToastSend);
        }
    };

    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [data]);

    function formatDateString(inputDateString) {
        const inputDate = new Date(inputDateString);

        const hours = String(inputDate.getUTCHours()).padStart(2, '0');
        const minutes = String(inputDate.getUTCMinutes()).padStart(2, '0');
        const timeFormatted = `${hours}:${minutes}`;

        const day = String(inputDate.getUTCDate()).padStart(2, '0');
        const monthNames = [
            'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
            'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
        ];
        const month = monthNames[inputDate.getUTCMonth()];
        const year = String(inputDate.getUTCFullYear()).slice(-2);
        const dateFormatted = `${day}-${month}-${year}`;

        const result = `${timeFormatted},${dateFormatted}`;

        return result;
    }

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
${openChatBoxModal
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[70vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
                }
`}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="xl:text-[2vw] font-semibold">{`Chat with ${chatBoxDetail.firstName + " " + chatBoxDetail.lastName} [${chatBoxDetail.phoneNo}]`}</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className="w-5 h-5 duration-300 hover:scale-110" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    <button className="w-5 h-5 duration-300 hover:scale-110" onClick={() => closeScreen()}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col justify-center items-center p-[1vw]
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
                <div className="flex flex-col rounded-2xl bg-gray-600 h-full p-4 w-[60%]">
                    <div className="flex flex-col h-full mb-4 overflow-x-auto style-scrollbar" ref={containerRef}>
                        <div className="flex flex-col gap-y-[1vh]">
                            {data && data?.Communication.map((message) => (
                                <div key={message.id} className="col-start-1 col-end-8 p-3 rounded-lg">
                                    {message.owner === "Student" ? (
                                        <div className="flex flex-row items-center justify-start">
                                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-gray-800 rounded-full">
                                                {"S"}
                                            </div>
                                            <div className="relative px-4 py-2 ml-3 text-sm bg-gray-100 shadow rounded-xl">
                                                <div className='text-black'>{message.content}</div>
                                                <label className='text-right text-black'>{formatDateString(message.dateTime)}</label>
                                            </div>
                                        </div>
                                    ) : message.owner === "Faculty" ? (
                                        <div className="flex flex-row items-center justify-end">
                                            <div className="relative px-4 py-2 mr-3 text-sm bg-green-700 shadow rounded-xl">
                                                <div className='text-white'>{message.content}</div>
                                                <label className='text-right text-white'>{formatDateString(message.dateTime)}</label>
                                            </div>
                                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-green-700 rounded-full">
                                                {"F"}
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            ))}

                        </div>
                    </div>

                    <div className="flex flex-row items-center w-full h-10 bg-white rounded-xl">
                        <div className="flex w-full h-full">
                            <div className="relative w-full h-full">
                                <input
                                    type="text"
                                    className="flex w-full h-full pl-4 border rounded-xl focus:outline-none focus:border-indigo-300"
                                    placeholder="Type a message..."
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                                <button
                                    className="absolute gap-x-[0.5vw] flex items-center justify-center bg-blue-800 h-full w-auto right-0 top-0 px-[1vw] rounded-lg text-white hover:bg-blue-600 duration-300"
                                    onClick={handleSubmit}
                                >
                                    Send
                                    <FontAwesomeIcon icon={faPaperPlane} style={{ color: "#ffffff", }} />
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ChatBoxModal;