import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const LineChart = ({ lineChartData}) => {

  useEffect(() => {
    const lectureCount = lineChartData.Lecture; 
    const assignmentCount = lineChartData.Assignment;
    const quizCount = lineChartData.Quiz;
    const examCount = lineChartData.Exam;

    const chartConfig = {
      series: [
        {
          name: 'Count',
          data: [lectureCount, assignmentCount, quizCount, examCount], 
        },
      ],
      chart: {
        type: 'bar',
        height: 240,
        toolbar: {
          show: false,
        },
      },
      title: {
        text: 'Activity Count',
        align: 'left',
      },
      xaxis: {
        categories: ['Lecture', 'Assignment', 'Quiz', 'Exam'],
      },
      yaxis: {
        title: {
          text: 'Count',
        },
      },
      tooltip: {
        theme: 'dark',
        x: {
          formatter: (val) => 'Activity: ' + val,
        },
        y: {
          formatter: (val) => 'Count: ' + val,
        },
      },
    };

    const chart = new ApexCharts(document.querySelector('#bar-chart'), chartConfig);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [lineChartData.Assignment, lineChartData.Exam, lineChartData.Lecture, lineChartData.Quiz]); // Add dependencies for useEffect

  return (
    <div className="relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
      <div className="pt-6 px-2 pb-0">
        <div id="bar-chart"></div>
      </div>
    </div>
  );
};

export default LineChart;
