import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';

const MultiSelection = ({ setLocalFormData, localFormData }) => {
    const [questions, setQuestions] = useState([{ quesNo: '', question: '' }]);
    const [options, setOptions] = useState(['']);
    const backendBaseUrl = config.backendLocalhost;

    const handleOptionChange = (index, event) => {
        const { value } = event.target;
        const updatedOptions = [...options];
        updatedOptions[index] = value;
        setOptions(updatedOptions);
    };

    const addMoreQuestions = () => {
        setQuestions([...questions, { quesNo: '', question: '' }]);
    };

    const removeQuestion = (index) => {
        const newQuestions = questions.filter((_, qIndex) => qIndex !== index);
        setQuestions(newQuestions);
    };
    const addMoreOption = () => {
        setOptions([...options, '']);
    };

    const removeOption = (index) => {
        const newOptions = options.filter((_, qIndex) => qIndex !== index);
        setOptions(newOptions);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newQuestions = questions.map((q, i) => {
            if (i === index) {
                return { ...q, [name]: value };
            }
            return q;
        });
        setQuestions(newQuestions);
    };

    const handleCancel = () => {
        const confirmCancel = window.confirm("Are you sure you want to cancel all your changes?");
        if (confirmCancel) {
            setQuestions([{ quesNo: '', question: '' }]);
            setOptions(['']);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {

            const formData = {                                    
                ...localFormData,

                    questionArray:questions,
                    optionArray:options
                
            };
            console.log(formData,'multi')
            const response = await axios.post(`${backendBaseUrl}/survey/createTemplate`, formData);
            setLocalFormData(formData);

            toast.success(`Template added successfully`);
        } catch (error) {
            console.error('Error adding template:', error);
            if (error.response && error.response.data && error.response.data.errorMessage) {
                toast.error(error.response.data.errorMessage);
            } else {
                toast.error('An error occurred while adding the template.');
            }
        }
    };

    return (
        <form className="w-full h-full p-[2vw] bg-SecondaryColor rounded-2xl" onSubmit={handleSubmit}>
            <div className='justify-start w-full text-xl text-black'>
                <h1>Define Number of Options and Labels</h1>
            </div>
            <div className='flex flex-col'>
                {options.map((cls, index) => (
                    <div key={index} className='flex items-center justify-center w-full h-full gap-2'>
                        <div className="flex w-[100%] gap-x-2 justify-center items-center">
                            <label htmlFor={`cls-${index}`} className="block gap-3 font-medium text-center text-black whitespace-nowrap text-md">
                                Option: {index + 1}
                            </label>
                            <input
                                type="text"
                                id={`cls-${index}`}
                                name={`cls-${index}`}
                                value={cls.text}
                                placeholder="Enter Option Here"
                                onChange={(event) => handleOptionChange(index, event)}
                                className="block w-full p-2 mt-2 text-black border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                        </div>
                        {options.length > 1 && (
                            <div className='h-full w-[10%]'>
                                <button
                                    type="button"
                                    onClick={() => removeOption(index)}
                                    className="px-4 py-2 text-white duration-300 bg-red-500 rounded-md hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50"
                                >
                                    Remove
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className='flex items-center justify-end w-full mt-2'>
                <button
                    type="button"
                    onClick={addMoreOption}
                    className="px-4 py-2 text-white duration-300 rounded-md bg-PrimaryColor hover:bg-PrimaryDarkColor focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                    Add More Option
                </button>
            </div>

            <div className='flex flex-col gap-2'>
                <div className='justify-start w-full text-xl text-black'>
                    <h1>Enter All Questions</h1>
                </div>
                {questions.map((q, index) => (
                    <div className='flex items-center justify-center w-full h-full gap-2' key={index}>
                        <div className="flex w-[10%] text-black">
                            <input
                                type="text"
                                name="quesNo"
                                placeholder="No."
                                className="w-full input"
                                onChange={(e) => handleInputChange(index, e)}
                                value={q.quesNo}
                            />
                        </div>
                        <div className="flex w-full text-black">
                            <input
                                type="text"
                                name="question"
                                placeholder="Enter Question Here"
                                className="w-full input"
                                value={q.question}
                                onChange={(e) => handleInputChange(index, e)}
                                style={{ whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }}
                            />
                        </div>
                        {questions.length > 1 && (
                            <div className='h-full w-[10%]'>
                                <button
                                    type="button"
                                    onClick={() => removeQuestion(index)}
                                    className="px-4 py-2 text-white duration-300 bg-red-500 rounded-md hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50"
                                >
                                    Remove
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className='flex items-center justify-end w-full mt-2'>
                <button
                    type="button"
                    onClick={addMoreQuestions}
                    className="px-4 py-2 text-white duration-300 rounded-md bg-PrimaryColor hover:bg-PrimaryDarkColor focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                    Add More Question
                </button>
            </div>
            <div className='flex w-full gap-3'>
                <button
                    type="submit"
                    className="w-full px-4 py-2 mt-3 text-white duration-300 bg-green-600 rounded-md hover:bg-green-900 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                    Submit
                </button>
                <button
                    type="button" 
                    onClick={handleCancel} 
                    className="w-full px-4 py-2 mt-3 text-white duration-300 rounded-md bg-slate-600 hover:bg-slate-400 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                    Cancel
                </button>
            </div>
        </form>
    );
};


export default MultiSelection;
