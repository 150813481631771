import React from 'react';
import EditButton from '../EditButton';
import { useSelector } from 'react-redux';

const ViewSession = ({setUpdateSession,handleButtonClick}) => {
  const allSession = useSelector(state => state.allSession);
  const handleEditDepartment = (dept) => {
    setUpdateSession(dept);
    handleButtonClick('create');
  };

  return (
    <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
    <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
        <thead className=''>
          <tr className='text-sm font-semibold'>
            <th className="px-4 py-3 text-center border border-gray-400">Sr.</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">Session</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Session Year</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Session Code</th>
            <th className="px-4 py-3 text-center border  border-gray-400">Status</th>
            <th className="px-4 py-3 text-center border  border-gray-400 ">Actions</th>
          </tr>
        </thead>
        <tbody>
          {allSession && allSession.map((dept, index) => (
            <tr key={dept._id}
              className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}
            >
              <td className="px-4 py-3 text-center ">{index + 1}</td>
              <td className="px-4 py-3 truncate text-left">{dept.session}</td>
              <td className="px-4 py-3 text-left">{dept.year}</td>
              <td className="px-4 py-3 text-left">{dept.code}</td>
              <td className={`px-4 py-3 border-b text-center ${dept.status ? "text-green-600" :"text-red-600"}`}> {dept.status ? "Active":"In-Active"} </td>

              <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center  ">
                <button onClick={() => handleEditDepartment(dept)}>
                  <EditButton />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewSession