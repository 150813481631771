import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from '../../../FormatDate';

const ViewReport = ({ openReportModal, ReportIndividualDetailHandler, individualReportDetail }) => {
    const [maxScreen, setMaxScreen] = useState(true);

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    }

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
        ${openReportModal
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
        ${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[80vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[10vw]"
                }
      `}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
         ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="xl:text-[2vw] font-semibold">{`Academic Report of ${individualReportDetail.Name}`}</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-5 h-5 duration-300 hover:scale-110'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
                    </button>
                    <button className="w-5 h-5 duration-300 hover:scale-110" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    {/* Provide the implementation for AddProjectHandler */}
                    <button className="w-5 h-5 duration-300 hover:scale-110" onClick={() => ReportIndividualDetailHandler({})}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
                <div className='w-full flex flex-col justify-start items-start gap-y-4 px-6 py-2 overflow-y-auto'>
                    <div className='w-full flex flex-col jusitfy-start items-start gap-y-2'>
                        <p className='text-black text-2xl text-semibold'>Summary</p>
                        <div className='w-full p-4 rounded-lg border grid grid-cols-3 grid-rows-2 gap-y-4'>
                            <div className='text-black text-sm flex gap-x-2'>
                                <p className='text-bold'>{"Name"}</p>
                                <p>{individualReportDetail.Name}</p>
                            </div>
                            <div className='text-black text-sm flex gap-x-2'>
                                <p className='text-bold'>{"Roll No."}</p>
                                <p>{individualReportDetail.RollNo}</p>
                            </div>
                            <div className='text-black text-sm flex gap-x-2'>
                                <p className='text-bold'>{"Class"}</p>
                                <p>{individualReportDetail.ClassCode}</p>
                            </div>
                            <div className='text-black text-sm flex gap-x-2'>
                                <p className='text-bold'>{"Course"}</p>
                                <p>{`${individualReportDetail.CourseName} - [${individualReportDetail.CourseCode}]`}</p>
                            </div>
                            <div className='text-black text-sm flex gap-x-2'>
                                <p className='text-bold'>{"Generation Date"}</p>
                                <p>{formatDate(individualReportDetail.GenerationDate)}</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-col jusitfy-start items-start gap-y-2'>
                        <p className='text-black text-2xl text-semibold'>Attendance Detail</p>
                        <div className='h-auto w-full flex flex-col bg-gray-300 border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto overflow-x-auto style-scrollbar'>
                            <table className="min-w-full h-auto bg-white border-collapse rounded-2xl text-sm">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Sr.</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap" >Date</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap" >Time</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap" >Period</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap" >Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {openReportModal && individualReportDetail.Attendance.AttendanceDetail && individualReportDetail.Attendance.AttendanceDetail.map((attend, index) => (
                                        <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`} key={index}>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{index + 1}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{attend.Date}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{attend.Time}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{attend.Period}</td>
                                            <td className={`border-x border-gray-400 p-2 whitespace-nowrap ${attend.Attendance === "Absent" ? "text-red-700" : "text-green-700"}`}>{attend.Attendance}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='w-full flex flex-col jusitfy-start items-start gap-y-2'>
                        <p className='text-black text-2xl text-semibold'>Assignment Detail</p>
                        <div className='h-auto w-full flex flex-col bg-gray-300 border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto overflow-x-auto style-scrollbar'>
                            <table className="min-w-full h-auto bg-white border-collapse rounded-2xl text-sm">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Sr.</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Assignment No.</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Assigned Date</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Due Date</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Submitted Date-Time</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Obtained</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Total Marks</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Result (%)</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Weightage</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Weighted Marks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {openReportModal && individualReportDetail.Assignment.AssignmentDetail && individualReportDetail.Assignment.AssignmentDetail.map((assignment, index) => (
                                        <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                                       ${(assignment.obtainedMarks / assignment.totalMarks) * 100 < 40 ? 'text-red-700' : 'text-green-700'}}`
                                        } key={index}>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{index + 1}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{assignment.No}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{assignment.AssignedDate}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{assignment.DueDate}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{assignment.SubmittedDate_Time}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{assignment.Obtained}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{assignment.TotalMarks}</td>
                                            <td className={`border-x border-gray-400 p-2 whitespace-nowrap ${(assignment.obtainedMarks / assignment.totalMarks) * 100 < 40 ? 'text-red-700' : 'text-green-700'}`}>{assignment && `${((assignment.Obtained / assignment.TotalMarks) * 100).toFixed(0)}%`}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{assignment.weightage}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{assignment.weightedMarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='w-full flex flex-col jusitfy-start items-start gap-y-2'>
                        <p className='text-black text-2xl text-semibold'>Quiz Detail</p>
                        <div className='h-auto w-full flex flex-col bg-gray-300 border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto overflow-x-auto style-scrollbar'>
                            <table className="min-w-full h-auto bg-white border-collapse rounded-2xl text-sm">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Sr.</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Quiz No.</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Assigned Date</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Due Date</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Submitted Date-Time</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Obtained</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Total Marks</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Result (%)</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Weightage</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Weighted Marks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {openReportModal && individualReportDetail.Quiz.QuizDetail && individualReportDetail.Quiz.QuizDetail.map((quiz, index) => (
                                        <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                                          ${(quiz.obtainedMarks / quiz.totalMarks) * 100 < 40 ? 'text-red-700' : 'text-green-700'}}`
                                        } key={index}>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{index + 1}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{quiz.No}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{quiz.AssignedDate}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{quiz.DueDate}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{quiz.SubmittedDate_Time}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{quiz.Obtained}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{quiz.TotalMarks}</td>
                                            <td className={`border-x border-gray-400 p-2 whitespace-nowrap ${(quiz.obtainedMarks / quiz.totalMarks) * 100 < 40 ? 'text-red-700' : 'text-green-700'}`}>{quiz && `${((quiz.Obtained / quiz.TotalMarks) * 100).toFixed(0)}%`}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{quiz.weightage}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{quiz.weightedMarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='flex flex-col items-start w-full jusitfy-start gap-y-2'>
                        <p className='text-2xl text-black text-semibold'>Exam Detail</p>

                        <div className='h-auto w-full flex flex-col bg-gray-300 border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto overflow-x-auto style-scrollbar'>
                            <table className="min-w-full h-auto bg-white border-collapse rounded-2xl text-sm">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Sr.</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Exam No.</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Assigned Date</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Due Date</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Submitted Date-Time</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Obtained</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Total Marks</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Result (%)</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Weightage</th>
                                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Weighted Marks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {openReportModal && individualReportDetail.Exam.ExamDetail && individualReportDetail.Exam.ExamDetail.map((exam, index) => (
                                        <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                                          ${(exam.obtainedMarks / exam.totalMarks) * 100 < 40 ? 'text-red-700' : 'text-green-700'}}`
                                        } key={index}>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{index + 1}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{exam.No}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{exam.AssignedDate}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{exam.DueDate}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{exam.SubmittedDate_Time}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{exam.Obtained}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{exam.TotalMarks}</td>
                                            <td className={`border-x border-gray-400 p-2 whitespace-nowrap ${(exam.obtainedMarks / exam.totalMarks) * 100 < 40 ? 'text-red-700' : 'text-green-700'}`}>{exam && `${((exam.Obtained / exam.TotalMarks) * 100).toFixed(0)}%`}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{exam.weightage}</td>
                                            <td className="border-x border-gray-400 p-2 whitespace-nowrap">{exam.weightedMarks}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ViewReport;