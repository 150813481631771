import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const BarGraph = ({ data }) => {

  useEffect(() => {
    const instructorNames = data.map(instructor => instructor.name);
    const courseCounts = data.map(instructor => instructor.courses);

    const chartConfig = {
      series: [
        {
          name: 'Courses',
          data: courseCounts,
        },
      ],
      chart: {
        type: 'bar',
        height: 300,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top', // place data labels on the bars
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#000'],
        },
      },
      title: {
        text: 'Workload of Instructor (No. of courses)',
        align: 'center',
      },
      xaxis: {
        categories: instructorNames,
        title: {
          text: 'Courses',
        },
      },
      yaxis: {
        title: {
          text: 'Instructors',
        },
      },
      tooltip: {
        theme: 'dark',
        y: {
          formatter: (val) => `Courses: ${val}`,
        },
      },
    };

    const chart = new ApexCharts(document.querySelector('#bar-chart'), chartConfig);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div className="h-[90%] w-full relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md top-10">
      <div className="top-0 h-full px-0 pb-0 overflow-auto">
        <div id="bar-chart" className='h-full'></div>
      </div>
    </div>
  );
};

export default BarGraph;
