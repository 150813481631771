// reducers/FacultyActivityListToggle.js
import { SET_ALLDEPT } from '../action-creators/ActionTypes';

const AllDepartment= (state = [] , action) => {
    switch (action.type) {
        case SET_ALLDEPT:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllDepartment;
