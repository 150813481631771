import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import config from "../../../../config";
import { SetTriggerNotificationStudent } from "../../../../state/action-creators";

const TextOption = ({ templete, SurveyModalHandler }) => {
  const [surveyResult, setSurveyResult] = useState(
    templete?.template?.textOption?.questionArray.map((q) => ({
      quesNo: q.quesNo,
      question: q.question,
      questionType: "textOption",
      Answer: [],
    }))
  );
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const { backendLocalhost } = config;

  const handleInputChange = (index, e) => {
    const newSurveyResult = [...surveyResult];
    newSurveyResult[index].Answer = [e.target.value];
    setSurveyResult(newSurveyResult);
  };

  const validateSurveyResult = () => {
    for (const item of surveyResult) {
      if (item.Answer.length === 0 || item.Answer[0].trim() === "") {
        return false;
      }
    }
    return true;
  };

  const handleAddTemplate = async () => {
    if (!validateSurveyResult()) {
      toast.error("Please answer all questions before submitting.");
      return;
    }

    const formData = {
      templateID: templete.template._id,
      studID: userInfo._id,
      classCode: templete.classCode,
      courseCode: templete.courseCode,
      surveyResult: surveyResult,
    };
    try {
      const response = await axios.post(
        `${backendLocalhost}/survey/createSurveyForm`,
        formData
      );
      console.log(response);
      dispatch(SetTriggerNotificationStudent());
      SurveyModalHandler();
      setSurveyResult(
        templete?.template?.textOption?.questionArray.map((q) => ({
          quesNo: q.quesNo,
          question: q.question,
          questionType: "textOption",
          Answer: [],
        }))
      );
      toast.success(`Assign Template added successfully`);
    } catch (error) {
      console.error("Error adding assign template", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        toast.error(error.response.data.errorMessage);
      } else {
        toast.error("Error adding assign template.");
      }
    }
  };


  return (
    <div className="flex flex-col justify-start w-full h-auto overflow-y-auto lg:h-full lg:overflow-y-auto lg:style-scrollbar style-scrollbar">
    <form
      className="w-full bg-gray-300 flex flex-col items-start justify-center 
      lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh]
      text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh] gap-2"
    >
      <div className='flex w-full gap-x-2 '>
      <div className='flex w-full '>
        <label className='font-semibold'>
          Class:
        </label>
        <p className='mx-1 font-semibold'>({templete?.classCode && templete.classCode})</p>
      </div>
      <div className='flex w-full '>
        <label className='font-semibold'>Course:</label>
        <p className='mx-1 font-semibold'>({templete?.courseCode && `${templete.courseCode} - ${templete.courseName}`})</p>
      </div>
      </div>
      <div className='flex w-full '>
        <label className='font-semibold '>Faculty:</label>
        <p className='mx-1 font-semibold'>({templete?.facultyName && templete.facultyName})</p>
      </div>
      <div className='flex w-full '>
        <label className='font-semibold '>Survey Title:</label>
        <p className='mx-1 font-semibold'>({templete?.template?.templateName && templete.template?.templateName})</p>
      </div>
      <div className="flex flex-col w-full h-full border border-gray-400 rounded-lg">
        <div className="flex w-full h-full">
          <div className="w-full h-full text-center">
            <h1 className="font-semibold">Questions/Response</h1>
          </div>
        </div>
        <div className="flex flex-col h-full gap-2 w-50%">
        {templete?.template?.textOption?.questionArray.map((q, index) => (
              <div
                className="flex flex-col w-full h-full gap-y-2"
                key={index}
              >
                <div className="flex items-start justify-start w-full h-full gap-x-2">
                  <p>{q.quesNo}</p>
                  <p className="w-full h-full whitespace-normal">
                    {q.question}
                  </p>
                </div>
                <div className="flex w-full">
            <textarea
              name="question"
              className="w-full h-full bg-white input"
              value={surveyResult[index].Answer[0] || ""}
              onChange={(e) => handleInputChange(index, e)}
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div className="flex w-full h-full space-x-2 ">
        <button
          type="button"
          onClick={handleAddTemplate}
          className="self-center w-full px-4 py-2 text-white duration-300 bg-green-500 rounded-md hover:bg-green-700 focus:outline-none focus:ring focus:ring-green-200 focus:ring-opacity-50"
        >
          Submit
        </button>
        <button
          type="button"
          className="self-center w-full px-4 py-2 text-white duration-300 rounded-md bg-slate-500 hover:bg-slate-700 focus:outline-none focus:ring focus:ring-green-200 focus:ring-opacity-50"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
);
};

export default TextOption;
