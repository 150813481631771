import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';
import { useSelector } from 'react-redux';

const AddAcademic = ({ updateAcademic, setUpdateAcademic }) => {
  const [editingId, setEditingId] = useState(null);
  const allSession = useSelector(state => state.allSession);
  const backendBaseUrl = config.backendLocalhost;

  const [formData, setFormData] = useState({
    sessionCode: '',
    academicActivity: [
      {
        activity: '',
        startDate: '',
        endDate: '',
        remarks: '',
      }
    ],
  });

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedAcademicActivity = [...prevFormData.academicActivity];
      updatedAcademicActivity[index] = {
        ...updatedAcademicActivity[index],
        [name]: value,
      };

      return {
        ...prevFormData,
        academicActivity: updatedAcademicActivity,
      };
    });
  };

  const resetFields = () => {
    setUpdateAcademic({
      sessionCode: '',
      academicActivity: [
        {
          activity: '',
          startDate: '',
          endDate: '',
          remarks: '',
        }
      ],
    });
  };

  const handleAddActivity = async () => {
    try {
      const response = await axios.post(`${backendBaseUrl}/academic/addFormData`, formData);
      resetFields();
      toast.success(`Academic added successfully`);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred while adding academic.');
      }
    }
  };

  const handleAddAcademicDetails = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicActivity: [
        ...prevFormData.academicActivity,
        {
          activity: '',
          startDate: '',
          endDate: '',
          remarks: '',
        },
      ],
    }));
  };

  const handleRemoveAcademicDetails = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      academicActivity: prevFormData.academicActivity.filter((_, i) => i !== index),
    }));
  };

  const handleSession = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleUpdateActivity = async () => {
    try {
      const response = await axios.put(`${backendBaseUrl}/academic/update/${editingId}`, formData);  
      resetFields();
      toast.success('Academic updated successfully');
    } catch (error) {
      console.error('Error updating academic:', error);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred while updating academic.');
      }
    }
  };

  useEffect(() => {
    if (updateAcademic && updateAcademic.length !== 0) {
      setEditingId(updateAcademic._id);
      setFormData({
        sessionCode: updateAcademic.sessionCode,
        academicActivity: updateAcademic.academicActivity,
      })
    }
  }, [updateAcademic]);

  return (
    <div className='flex flex-col items-center justify-start'>
      <form
        className='w-full h-[60vh] rounded-[1vw] bg-gray-300 px-[2vw] flex flex-col items-start justify-start gap-y-[2vh] p-[1vw]
   text-black text-sm shadow-2xl'
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className='flex flex-col gap-y-[0.5vh] w-full'>
          <label className='font-semibold'>Session: </label>
          <select
            className="w-full max-w-xs bg-white select select-primary"
            name="sessionCode"
            value={formData.sessionCode}
            onChange={handleSession}
          >
            <option selected disabled value="">
              Select a Session
            </option>
            {/* Assuming sessions is an array containing session objects */}
            {allSession.map(session => (
              <option key={session._id} value={session.code}>
                {`${session.session} (${session.code})`}
              </option>
            ))}
          </select>
        </div>

        <div className='w-full h-[50vh] flex flex-col justify-start items-center gap-y-4 overflow-auto no-scrollbar'>
          {formData.academicActivity?.map((activity, index) => (
            <>
              <div key={index} className="w-full grid justify-center items-center gap-x-[1vw] gap-y-[1vh] 
        lg:grid-cols-2
        grid-cols-1">
                <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start '>
                  <label htmlFor={`activity-${index}`} className='font-semibold'>Activity:</label>
                  <input
                    type="text"
                    placeholder="Enter your activity"
                    className="input w-full outline-PrimaryColor font-normal text-[1vw] bg-white"
                    id={`activity-${index}`}
                    name="activity"
                    value={activity.activity}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
                <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start '>
                  <label htmlFor={`remarks-${index}`} className='font-semibold'>Remarks:</label>
                  <select
                    className="w-full bg-white select select-primary"
                    name="remarks"
                    value={activity.remarks}
                    onChange={(e) => handleChange(e, index)}
                  >
                    <option value="" disabled>
                      Select a Remarks
                    </option>
                    <option value="Start">Start</option>
                    <option value="End">End</option>
                  </select>
                </div>
                <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
                  <label htmlFor={`startDate-${index}`} className='font-semibold'>Starting Date:</label>
                  <input
                    type="date"
                    id={`startDate-${index}`}
                    name="startDate"
                    className='w-full h-full text-black text-left border border-green-700 rounded-[1vw] px-[1vw] focus:border-green-700 focus:outline-none cursor-pointer '
                    value={activity.startDate}
                    onChange={(e) => handleChange(e, index)}
                    placeholder="dd-mm-yyyy"
                  />
                </div>
                <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start  '>
                  <label htmlFor={`endDate-${index}`} className='font-semibold'>Ending Date:</label>
                  <input
                    type="date"
                    id={`endDate-${index}`}
                    name="endDate"
                    className='w-full h-full input input-bordered  text-black text-left border border-green-700 rounded-[1vw] px-[1vw]  cursor-pointer '
                    value={activity.endDate}
                    onChange={(e) => handleChange(e, index)}
                    placeholder="dd-mm-yyyy"
                    min={activity.startDate}
                  />
                </div>
              </div>
              {index > 0 && (
                <div className='flex justify-end w-full'>
                  <button
                    className='w-auto px-[1vw] py-[1vh] bg-red-800 text-white rounded-[1vw]'
                    type="button"
                    onClick={() => handleRemoveAcademicDetails(index)}
                  >
                    Remove
                  </button>
                </div>
              )}
              <div class="border-b border-gray-700 border-opacity-60 w-[90%] h-px"></div>
            </>
          ))}

          <div className='flex justify-end w-full mt-3'>
            <button className='w-auto px-[1vw] py-[1vh] bg-purple-800 text-white rounded-[1vw]' type="button" onClick={handleAddAcademicDetails}>
              Assign more academic Activities
            </button>
          </div>
        </div>

        <button
          className='px-[1vw] py-[1vh] bg-PrimaryColor text-white rounded-[0.5vw] font-bold'
          type='button'
          onClick={editingId !== null ? handleUpdateActivity : handleAddActivity}
        >
          {editingId !== null ? 'Update Academic' : 'Add Academic'}
        </button>
      </form>
    </div>
  );
};

export default AddAcademic