import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import config from "../../../../config";
import { SetTriggerNotificationStudent } from "../../../../state/action-creators";

const NumericOption = ({ templete, SurveyModalHandler }) => {
  const [surveyResult, setSurveyResult] = useState(
    templete?.template?.gradingOption?.questionArray.map((q) => ({
      quesNo: q.quesNo,
      question: q.question,
      questionType: "gradingOption",
      Answer: [],
    }))
  );
  const [questions, setQuestions] = useState([{ text: "" }]);
  const userInfo = useSelector((state) => state.userInfo);
  const { backendLocalhost } = config;
  const dispatch = useDispatch();

  const validateSurveyResult = () => {
    const from = templete?.template?.gradingOption?.range?.from;
    const to = templete?.template?.gradingOption?.range?.to;

    for (const item of surveyResult) {
      const answer = item.Answer[0];
      if (
        !answer ||
        isNaN(answer) ||
        answer.trim() === "" ||
        Number(answer) < from ||
        Number(answer) > to
      ) {
        return false;
      }
    }
    return true;
  };

  const handleAddTemplate = async () => {
    if (!validateSurveyResult()) {
      toast.error("Please enter valid numeric answers for all questions.");
      return;
    }

    const formData = {
      templateID: templete.template._id,
      studID: userInfo._id,
      classCode: templete.classCode,
      courseCode: templete.courseCode,
      surveyResult: surveyResult,
    };
    try {
      const response = await axios.post(
        `${backendLocalhost}/survey/createSurveyForm`,
        formData
      );
      console.log(response);
      dispatch(SetTriggerNotificationStudent());
      SurveyModalHandler();
      setSurveyResult(
        templete?.template?.gradingOption?.questionArray.map((q) => ({
          quesNo: q.quesNo,
          question: q.question,
          questionType: "gradingOption",
          Answer: [],
        }))
      );
      toast.success(`Assign Template added successfully`);
    } catch (error) {
      console.error("Error adding assign template", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        toast.error(error.response.data.errorMessage);
      } else {
        toast.error("Error adding assign template.");
      }
    }
  };

  const handleInputChange = (index, e) => {
    const newSurveyResult = [...surveyResult];
    newSurveyResult[index].Answer = [e.target.value];
    setSurveyResult(newSurveyResult);
  };
  return (
    <div className="flex flex-col justify-start w-full h-auto overflow-y-auto lg:h-full lg:overflow-y-auto lg:style-scrollbar style-scrollbar">
      <form
        className="w-full bg-gray-300 flex flex-col items-start justify-center 
lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh]
text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh] gap-2"
      >
        <div className="flex w-full gap-x-2 ">
          <div className="flex w-full ">
            <label className="font-semibold">Class:</label>
            <p className="mx-1 font-semibold">
              ({templete?.classCode && templete.classCode})
            </p>
          </div>
          <div className="flex w-full ">
            <label className="font-semibold">Course:</label>
            <p className="mx-1 font-semibold">
              (
              {templete?.courseCode &&
                `${templete.courseCode} - ${templete.courseName}`}
              )
            </p>
          </div>
        </div>
        <div className="flex w-full ">
          <label className="font-semibold ">Faculty:</label>
          <p className="mx-1 font-semibold">
            ({templete?.facultyName && templete.facultyName})
          </p>
        </div>
        <div className="flex w-full ">
          <label className="font-semibold ">Survey Title:</label>
          <p className="mx-1 font-semibold">
            (
            {templete?.template?.templateName &&
              templete.template?.templateName}
            )
          </p>
        </div>
        <div className="w-full h-full p-4 border border-gray-400 rounded-lg">
          <div className="text-xl text-center">
            <h1>Instruction</h1>
          </div>
          <div className="mt-4 font-semibold text-center">
            <h1>
              Please read each question carefully and then enter the appropriate
              value within the defined range. Reply to all questions is
              mandatory. You can change any option until you press the SUBMIT
              button.
            </h1>
          </div>
        </div>
        <div className="flex flex-col w-full h-full border border-gray-400 rounded-lg">
          <div className="flex w-full h-full">
            <div className="flex items-center mr-4 gap-x-2">
              <div className="font-semibold">
                <h1>Enter value from:</h1>
              </div>
              <div>
                <p>{templete?.template?.gradingOption?.range.from}</p>
              </div>
            </div>
            <div className="flex items-center gap-x-2">
              <div className="font-semibold">
                <h1>To:</h1>
              </div>
              <div>
                <p>{templete?.template?.gradingOption?.range.to}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full h-full">
            <div className="flex w-full h-full ">
              <div className="w-full h-full text-start ">
                <h1 className="font-semibold">Questions</h1>
              </div>
              <div className="w-full h-full mr-9 text-end">
                <h1 className="font-semibold">Response</h1>
              </div>
            </div>
            <div className="flex flex-col space-y-4 gap-x-4">
              {templete?.template?.gradingOption?.questionArray.map(
                (q, index) => (
                  <div
                    className="flex items-center justify-center w-full h-full gap-2"
                    key={index}
                  >
                    <div className="flex w-[10%] text-black">
                      <p>{q.quesNo}</p>
                    </div>
                    <div className="flex w-full text-black">
                      <p>{q.question}</p>
                    </div>
                    <div className="flex w-[10%] bg-white mr-2">
                      <input
                        type="text"
                        name="id"
                        className="w-full bg-white input"
                        onChange={(e) => handleInputChange(index, e)}
                        value={surveyResult[index].Answer[0] || ""}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="flex w-full h-full space-x-2 ">
              <button
                type="button"
                onClick={handleAddTemplate}
                className="self-center w-full px-4 py-2 text-white duration-300 bg-green-500 rounded-md hover:bg-green-700 focus:outline-none focus:ring focus:ring-green-200 focus:ring-opacity-50"
              >
                Submit
              </button>
              <button
                type="button"
                className="self-center w-full px-4 py-2 text-white duration-300 rounded-md bg-slate-500 hover:bg-slate-700 focus:outline-none focus:ring focus:ring-green-200 focus:ring-opacity-50"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NumericOption;
