import React, { useState, useEffect } from "react";

const OtherMulti = ({ optionsArray, setSurveyResult, surveyResult, quesNo }) => {
  const [otherOptionText, setOtherOptionText] = useState("");

  useEffect(() => {
    const existingOtherText = surveyResult.find(item => item.quesNo === quesNo)?.otherOptionText || "";
    setOtherOptionText(existingOtherText);
  }, [surveyResult, quesNo]);

  const handleChangeCheckbox = (option) => {
    setSurveyResult(prevSurveyResult => {
      const updatedResult = prevSurveyResult.map(item => {
        if (item.quesNo === quesNo) {
          let newAnswer;
          if (option === 'Other') {
            newAnswer = item.Answer.includes('Other')
              ? item.Answer.filter(ans => ans !== 'Other')
              : [...item.Answer, 'Other'];
          } else {
            newAnswer = item.Answer.includes(option)
              ? item.Answer.filter(ans => ans !== option)
              : [...item.Answer, option];
          }
          return { ...item, Answer: newAnswer };
        }
        return item;
      });
      return updatedResult;
    });

    if (option === 'Other') {
      setOtherOptionText('');
    }
  };

  const handleOtherOptionTextChange = (e) => {
    const text = e.target.value;
    setOtherOptionText(text);
    setSurveyResult(prevSurveyResult => {
      const updatedResult = prevSurveyResult.map(item => {
        if (item.quesNo === quesNo) {
          return { ...item, otherOptionText: text };
        }
        return item;
      });
      return updatedResult;
    });
  };

  const currentAnswer = surveyResult.find(item => item.quesNo === quesNo)?.Answer || [];

  return (
    <div className="flex flex-col w-[50%] h-full">
      {optionsArray.map((option, index) => (
        <div key={index} className="flex items-center">
          <input
            type="checkbox"
            id={`${option}-${index}`}
            name={`option-${index}`}
            value={option}
            checked={currentAnswer.includes(option)}
            onChange={() => handleChangeCheckbox(option)}
            className="form-checkbox"
          />
          <label htmlFor={`${option}-${index}`} className="ml-2 text-gray-700">
            {option}
          </label>
        </div>
      ))}
      <div className="flex items-center">
        <input
          type="checkbox"
          id="Other-0"
          name="option-0"
          value="Other"
          checked={currentAnswer.includes("Other")}
          onChange={() => handleChangeCheckbox("Other")}
          className="form-checkbox"
        />
        <label htmlFor="Other-0" className="ml-2 text-gray-700">
          Other
        </label>
        {currentAnswer.includes("Other") && (
          <input
            type="text"
            name="otherOptionText-0"
            placeholder="Please specify"
            value={otherOptionText}
            onChange={handleOtherOptionTextChange}
            className="w-full ml-2 bg-white input"
          />
        )}
      </div>
    </div>
  );
};

export default OtherMulti;
