import { SET_ALL_EXAM } from '../action-creators/ActionTypes';

const AllExam = (state = [] , action) => {
    switch (action.type) {
        case SET_ALL_EXAM:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
            
    }
};

export default AllExam;
