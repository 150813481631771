import React, { useState } from "react";

const OtherSingle = ({ optionsArray, setSurveyResult, surveyResult, quesNo }) => {
  const [otherOptionText, setOtherOptionText] = useState("");

  const handleChangeRadioButton = (e) => {
    const { value } = e.target;
    if (value !== "Other") {
      setSurveyResult((prevResult) =>
        prevResult.map((item) =>
          item.quesNo === quesNo ? { ...item, Answer: [value], otherOptionText: "" } : item
        )
      );
      setOtherOptionText("");
    } else {
      setSurveyResult((prevResult) =>
        prevResult.map((item) =>
          item.quesNo === quesNo ? { ...item, Answer: [value] } : item
        )
      );
    }
  };

  const handleOtherOptionTextChange = (e) => {
    const { value } = e.target;
    setOtherOptionText(value);
    setSurveyResult((prevResult) =>
      prevResult.map((item) =>
        item.quesNo === quesNo ? { ...item, otherOptionText: value } : item
      )
    );
  };

  const currentAnswer = surveyResult.find((item) => item.quesNo === quesNo)?.Answer[0] || "";
  const currentOtherText = surveyResult.find((item) => item.quesNo === quesNo)?.otherOptionText || "";

  return (
    <div className="flex flex-col w-[50%] h-full">
      {optionsArray.map((option, index) => (
        <div key={index} className="flex items-center">
          <input
            type="radio"
            id={`${option}-${quesNo}`}
            name={`option-${quesNo}`}
            value={option}
            checked={currentAnswer === option}
            onChange={handleChangeRadioButton}
            className="form-radio"
          />
          <label htmlFor={`${option}-${quesNo}`} className="ml-2 text-gray-700">
            {option}
          </label>
        </div>
      ))}
      <div className="flex items-center w-full h-full">
        <input
          type="radio"
          id={`Other-${quesNo}`}
          name={`option-${quesNo}`}
          value="Other"
          checked={currentAnswer === "Other"}
          onChange={handleChangeRadioButton}
          className="form-radio"
        />
        <label htmlFor={`Other-${quesNo}`} className="ml-2 text-gray-700">
          Other
        </label>
        {currentAnswer === "Other" && (
          <input
            type="text"
            name={`otherOptionText-${quesNo}`}
            placeholder="Please specify"
            value={otherOptionText || currentOtherText}
            onChange={handleOtherOptionTextChange}
            className="w-full ml-2 bg-white input"
          />
        )}
      </div>
    </div>
  );
};

export default OtherSingle;
