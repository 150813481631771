import React from "react";

const Text = ({ setSurveyResult, surveyResult, quesNo }) => {
  const handleInputChange = (e) => {
    const { value } = e.target;

    setSurveyResult((prevSurveyResult) =>
      prevSurveyResult.map((item) =>
        item.quesNo === quesNo ? { ...item, Answer: [value] } : item
      )
    );
  };

  const currentAnswer = surveyResult.find((item) => item.quesNo === quesNo)?.Answer[0] || "";

  return (
    <div className="flex flex-col h-full gap-2 w-[50%]">
      <div className="flex w-full">
        <textarea
          name="question"
          className="w-full h-full bg-white input"
          value={currentAnswer}
          onChange={handleInputChange}
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            overflowWrap: "break-word",
          }}
        />
      </div>
    </div>
  );
};

export default Text;
