import React from 'react'

function Cards({Title,heading1,val1,heading2,val2,heading3,val3}) {
  return (
    <div className="w-full h-full flex flex-col justify-start items-start bg-white shadow-lg rounded-xl">
    <div className="w-full p-2 text-sm text-center font-bold tracking-wider bg-PrimaryColor text-white rounded-t-xl">
      {Title}
    </div>
    <div className="w-full flex flex-col justify-center items-start p-4 gap-y-2 text-base bg-transparent text-gray-800 overflow-auto scrollbar-hide">
      <p className="mb-2">{heading1} <span className='font-bold text-green-800'>{val1}</span></p>
      <p className="mb-2">{heading2} <span className='font-bold text-green-800'>{val2}</span></p>
      {heading3 && <p className="mb-2">{heading3} <span className='font-bold text-green-800'>{val3}</span></p>}
    </div>
    </div>

  )
}

export default Cards