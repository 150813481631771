import React, { useState } from 'react'
import Sidebar from './Sidebar'
import DefaultLayout from './DefaultLayout';
import Header from '../../components/employeeComp/Header';

function MainDashboard({AssignmentModalHandler, AttendanceModalHandler, QuizModalHandler, FinalModalHandler,CoursePlanModalHandler,ClassesModalHandler,PaidModalHandler,PendingModalHandler,AcademicModalHandler,SelectedRemindersHandler,AllSemModalHandler,TimeTableModalHandler,FeedbackModalHandler,ElearningModalHandler,openAcademic,openAllSemModal,SurveyModalHandler,openSurvey }) {
  const [menuToggle, setMenuToggle] = useState(true);

  const btnHAndle = () => {
    setMenuToggle(!menuToggle)
  }
  return (
    <div className='bg-blue-100'>
      <Header/>
      <div className='flex h-[90vh] overflow-hidden'>
      <Sidebar btnHAndle={btnHAndle} menuToggle={menuToggle}/>
      <DefaultLayout menuToggle={menuToggle}
        AssignmentModalHandler={AssignmentModalHandler}
        QuizModalHandler={QuizModalHandler}
        CoursePlanModalHandler={CoursePlanModalHandler}
        FinalModalHandler={FinalModalHandler}
        AttendanceModalHandler={AttendanceModalHandler}
        ClassesModalHandler={ClassesModalHandler}
        PaidModalHandler={PaidModalHandler}
        PendingModalHandler={PendingModalHandler}
        AcademicModalHandler={AcademicModalHandler}
        SelectedRemindersHandler={SelectedRemindersHandler}
        AllSemModalHandler={AllSemModalHandler}
        TimeTableModalHandler={TimeTableModalHandler}
        FeedbackModalHandler={FeedbackModalHandler}
        ElearningModalHandler={ElearningModalHandler}
        openAcademic={openAcademic}
        openAllSemModal={openAllSemModal}
        SurveyModalHandler={SurveyModalHandler}
        openSurvey={openSurvey}   
      />
      </div>
    </div>
  )
}
export default MainDashboard