import React, { useState ,useEffect} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";

const Attendance = ({ openAttendance, AttendanceModalHandler, courseName, courseDetail }) => {
  const [maxScreen, setMaxScreen] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);

  const maxScreenHandler = () => {
    setMaxScreen(!maxScreen);
  }

  useEffect(() => {
    if(courseDetail.AttendanceDetail && openAttendance){
      setAttendanceData(courseDetail.AttendanceDetail)
    }
  }, [courseDetail.AttendanceDetail]);


  return (
    <div
      className={`absolute flex-1 duration-300 rounded-lg bg-white z-20
        ${openAttendance
          ? "scale-100 duration-300"
          : "scale-0 -z-10"
        }
        ${maxScreen
          ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
          : "w-[70vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
        }
      `}
    >
      <header
        className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
         ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
          }`}
      >
        <div>
          <h1 className="font-semibold xl:text-1">Attendance Detail</h1>
        </div>
        <div className="flex gap-x-[1vw]">
          <button className='w-3 h-3 duration-300 hover:scale-110'>
            <CSVLink data={attendanceData} filename={"StudentAttendanceDetail.csv"}>  <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} /></CSVLink>

          </button>
          <button className="w-3 h-3 duration-300 hover:scale-110 " onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full " icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full " icon={faExpand} />}
                    </button>
          {/* Provide the implementation for AddProjectHandler */}
          <button className="w-3 h-3 duration-300 hover:scale-110" onClick={() => AttendanceModalHandler([])}>
            <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
          </button>
        </div>
      </header>
      <div className={`w-full flex flex-col
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
        <div className='h-[15%] w-full p-[1vw] flex justify-center items-center'>
          <p className='xl:text-[1.5vw] text-black'>
            Course:
            <span className='font-semibold ml-[0.5vw]'>
              {courseName}
            </span>
          </p>
        </div>
        <div className='w-full h-[75%] p-[1vw] overflow-auto style-scrollbar'>
          <table className="min-w-full bg-white border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
            <thead>
              <tr>
                <th className="px-4 py-2 border border-b border-gray-400">Date</th>
                <th className="px-4 py-2 border border-b border-gray-400">Time</th>
                <th className="px-4 py-2 border border-b border-gray-400">Period</th>
                <th className="px-4 py-2 border border-b border-gray-400">Attendance</th>
              </tr>
            </thead>
            <tbody>
              {openAttendance && courseDetail && courseDetail.AttendanceDetail.map((attendance, index) => (
                <tr key={index}
                  className={`${attendance.Attendance === "Present" ? "bg-green-400" :
                    attendance.Attendance === "Late" ? "bg-yellow-400" :
                      attendance.Attendance === "Absent" ? "bg-red-400" :
                      attendance.Attendance === "Medical Leave" ? "bg-blue-200" :
                        "bg-gray-100"}`}>
                  <td className="px-4 py-2 text-center border-b">{attendance.Date}</td>
                  <td className="px-4 py-2 text-center border-b">{attendance.Time}</td>
                  <td className="px-4 py-2 text-center border-b">{attendance.Period}</td>
                  <td className="px-4 py-2 text-left border-b">{attendance.Attendance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='h-[10%] w-full rounded-t-[0.5vw] p-[1vw] flex justify-evenly items-center
                text-white bg-gradient-to-r from-blue-900 via-blue-700 to-blue-900'>
          <p className='text-[2vw]'>
            Total Lectures =
            <span className='font-semibold ml-[0.5vw]'>
              {courseDetail.Total}
            </span>
          </p>
          <p className='text-[2vw]'>
            Attended Lectures =
            <span className='font-semibold ml-[0.5vw]'>
              {courseDetail.Present}
            </span>
          </p>
          <p className='text-[2vw]'>
            Attendace Percentage =
            <span className='font-semibold ml-[0.5vw]'>
              {(courseDetail.Present/courseDetail.Total*100).toFixed(2) + "%"}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Attendance;
