import { SET_ONLINE_MEETING } from '../action-creators/ActionTypes';

const OnlineMeeting = (state = [] , action) => {
    switch (action.type) {
        case SET_ONLINE_MEETING:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
            
    }
};

export default OnlineMeeting;