import React from 'react';
import config from '../../../../config';
import { useDispatch } from 'react-redux';
import { setViewFile } from '../../../../state/action-creators';

const HandsonVideos = ({ selectedCourse, videosArr, ElearningModalHandler }) => {
  const dispatch = useDispatch();
  const backendBaseUrl = config.backendLocalhost;
  const filteredVideos = selectedCourse === "All courses" ? videosArr : videosArr.filter(Video => Video.course === selectedCourse);
  
  const OpenModal = (val) => {
    dispatch(setViewFile({
      openModal: true,
      FileData: val,
    }));
  }

  return (
    <div className='w-full h-full overflow-hidden text-black bg-gray-100 border border-gray-500 rounded-lg'>
      <div className="h-auto flex items-center justify-between py-[1vh] px-4 bg-gray-300">
        <h1 className="xl:text-[1vw] font-bold leading-none text-gray-900 dark:text-black hover:text-blue-600 hover:underline cursor-pointer"
          onClick={() => ElearningModalHandler(filteredVideos, selectedCourse,"Videos & Presentations")}
        >Videos & Presentations</h1>
        <button onClick={() => { }} className='text-[0.8vw] h-[1.5vw] w-[1.5vw] bg-blue-900 flex justify-center items-center rounded-[0.5vw] text-white font-semibold hover:bg-blue-300 hover:text-black duration-300'>
          {filteredVideos.length}
        </button>
      </div>
      <div className="h-full py-4 overflow-auto style-scrollbar">
        <ul className="h-full divide-y divide-gray-200 flex flex-col gap-y-[0.5vh]">
          {filteredVideos && filteredVideos.map((item, index) => (
            <li key={index} className="flex gap-x-[0.5vw] px-[1vw]">
              <p className='xl:text-[0.8vw]'>{index + 1})</p>
              <p className='xl:text-[0.8vw] truncate cursor-pointer hover:text-blue-600 hover:underline'>
              {item.FileType === "docx" || item.FileType === "pptx" || item.FileType === "xlsx" || item.FileType === "mp4" || item.FileType === "mov" || item.FileType === "avi" || item.FileType === "wmv" || item.FileType === "mkv" || item.FileType === "mp3" || item.FileType === "amr" || item.FileType === "wav" || item.FileType === "jpg" || item.FileType === "png" || item.FileType === "jpeg" || item.FileType === "gif" || item.FileType === "tiff" || item.FileType === "m4a" ?
                <p className='xl:text-[0.8vw] truncate cursor-pointer hover:text-blue-600 hover:underline'
                  onClick={() => OpenModal(item)}
                >
                  {item.description}
                </p>
                :
                <a
                  href={`${backendBaseUrl}/learning/${item.learningFile}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.description}
                </a>
              }
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HandsonVideos;
