import React from 'react'

const AllSemesterResult = ({AllSemModalHandler}) => {
  return (
    <div className='flex items-center justify-center w-full h-full text-white bg-SecondaryColor rounded-xl'>
    <p className='text-[1vw] text-black tracking-wider font-semibold hover:text-blue-600 hover:underline cursor-pointer transition-all duration-300 '
    onClick={() => AllSemModalHandler()}>
      Result of All Semester</p>
    </div>
  )
}

export default AllSemesterResult