import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators'; 
import config from '../../../../config';

const ViewQuiz = ({ QuizModalHandler, QuizDetailHandler }) => {
  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);
  const [maxScreen, setMaxScreen] = useState(false);
  const [filteyellowQuizzes, setFilteyellowQuizzes] = useState([]);
  const dispatch = useDispatch();
  const backendBaseUrl = config.backendLocalhost;
  const selectedActivity = useSelector((state) => state.selectedActivity);
  const userInfo = useSelector((state) => state.userInfo);
  const handleActivityList = (value) => {
    dispatch(setSelectedActivity(value));
  };

  useEffect(() => {
    const filteyellowCourses = userInfo.facultyDetails.courseDetail
      .filter((info) => info.class === selectedClass)
      .map((info) => info.course);
    setCourses(filteyellowCourses);
  }, [selectedClass]);

  useEffect(() => {
    const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map((info) => info.class)));
    setClasses(uniqueClasses);
  }, [userInfo.facultyDetails]);

  const quizzes = useSelector((state) => state.quizDetails);

  useEffect(() => {
    const sortedQuizzes = [...quizzes].sort((b, a) => new Date(a.uploadDate) - new Date(b.uploadDate));
    if (sortedQuizzes.length !== 0) {
      let filteyellow = sortedQuizzes.filter((quiz) => {
        if (!selectedCourse && !selectedClass) {
          return true;
        }
        if (selectedCourse && selectedClass) {
          return (
            quiz.course === selectedCourse &&
            quiz.class === selectedClass
          );
        }
        if (selectedCourse) {
          return quiz.course === selectedCourse;
        }
        if (selectedClass) {
          return quiz.class === selectedClass;
        }
        return false;
      });

      if (selectedCourse && selectedClass) {
        setFilteyellowQuizzes(filteyellow);
      }
      else {
        setFilteyellowQuizzes([])
      }

    }
  }, [selectedCourse, selectedClass, quizzes]);

  function countStatus(quizDetail, countVariable) {
    let count = 0;
    for (const quiz of quizDetail) {
      if (quiz.status === countVariable) {
        count++;
      }
      else if (countVariable === "submitted") {
        count++;
      }
    }
    return count;
  }
  const maxScreenHandler = () => {
    setMaxScreen(!maxScreen);
    if(maxScreen){
      handleActivityList("Quiz")
    }
    else{
      handleActivityList("")
    }
};


  return (
    <div className='w-full pb-[2vh] px-[1vw]'>
          <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh] px-[1vw]'>
            {/* Select Class */}
            <select
              id='classSelect'
              className='w-auto h-full text-black text-left border border-yellow-700 rounded-[1vw] px-[1vw] focus:border-yellow-700 focus:outline-none cursor-pointer'
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option value=''>Select a Class</option>
              {classes.map((className) => (
                <option key={className} value={className}>
                  {className}
                </option>
              ))}
            </select>

            {/* Select Course */}
            <select
              id='courseSelect'
              className='w-auto h-full text-black text-left border border-yellow-700 rounded-[1vw] px-[1vw] focus:border-yellow-700 focus:outline-none cursor-pointer'
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              <option value=''>Select a Course</option>
              {courses.map((course) => (
                <option key={course} value={course}>
                  {course}
                </option>
              ))}
            </select>
            {/* Add Quiz */}
            <button
              className='w-auto px-[1vw] h-full text-1 text-center border border-yellow-700 rounded-[1vw] focus:border-yellow-700 focus:outline-none
          bg-white text-black hover:bg-yellow-700 hover:text-white duration-300 text-0.5xl'
              onClick={() => QuizModalHandler(selectedCourse, selectedClass)}
            >
              Upload Quiz
            </button>
          </div>
          <div className='flex flex-col w-full h-auto overflow-x-auto bg-gray-300 border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto'>
            <table className="min-w-full bg-white text-black border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">No</th>
                  <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">Quiz Type</th>
                  <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">Due Date</th>
                  <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Total Marks</th>
                  <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Weightage</th>
                  <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Submitted</th>
                  <th className="px-[0.5vw] text-left  py-[1vh] border border-gray-400 whitespace-nowrap">Marked</th>
                  <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">File</th>
                  <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">View Detail</th>
                </tr>
              </thead>
              <tbody>
                {filteyellowQuizzes && filteyellowQuizzes.map((row, index) => (
                  <tr key={row.sr} className={`${index % 2 === 0 ? 'bg-blue-50' : 'bg-blue-200'}`}
                  >
                    <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.quizNo}</td>
                    <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.category}</td>
                    <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.dueDate}</td>
                    <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{row.totalMarks}</td>
                    <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{row.weightage}</td>
                    <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{countStatus(row.quizDetail, "submitted")}</td>
                    <td className="px-[0.5vw] text-left  py-[1vh] border-b whitespace-nowrap">{countStatus(row.quizDetail, "marked")}</td>
                    <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">
                      <a
                        className={`text-[0.8vw] px-[0.5vw] py-[1vh] bg-red-900 text-white font-semibold rounded-[0.5vw] hover:text-blue-600 hover:underline duration-300 cursor-pointer
                ${row.facultyFile === "no file" ? "hidden" : ""}
              `}
                        href={`${backendBaseUrl}/files/${row.facultyFile}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Attachment
                      </a>
                    </td>
                    <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">
                      <button className='text-[0.8vw] px-[0.5vw] py-[1vh] bg-red-900 text-white font-semibold rounded-[0.5vw] hover:text-blue-600 hover:underline duration-300 cursor-pointer'
                        onClick={() => QuizDetailHandler(row)}
                      >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
  );
};

export default ViewQuiz;