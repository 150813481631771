import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import Logo from '../../../../assets/images/LogoLCWU.png';

const generateCourseName = (courses, courseCode) => {
    if (!courses || !Array.isArray(courses)) {
        console.error('Invalid courses array.');
        return "";
    }
    
    const course = courses.find(course => course.courseCode === courseCode);
    
    return course ? course.courseName : "";
};

// function countMatchingStudentsByClass(targetClass,allStudentInfo) {
//     const matchingStudents = allStudentInfo.filter((student) =>
//         student.studentDetails && student.studentDetails.class === targetClass
//     )
//     return matchingStudents.length;
// }

function findFacultyName(allFacultyInfo, targetCourse, targetClass) {
    for (const faculty of allFacultyInfo) {
        for (const courseDetail of faculty.facultyDetails.courseDetail) {
            if (courseDetail.class === targetClass && courseDetail.course === targetCourse) {
                return `${faculty.firstName} ${faculty.lastName}`;
            }
        }
    }
    return "";
}

const Item = ({ department,allCourse,allFacultyInfo, index }) => (
    <View style={styles.itemContainer}>
        <View style={styles.header}>
            <View style={styles.startHeader}>
                <Text style={styles.subHeading}>{index + 1}.  {department.firstName} {department.lastName}      </Text>
            </View>
            <View style={styles.middleHeader}>
                <Text style={styles.subHeading}>{department.cnic}</Text>
            </View>
            <View style={styles.endHeader}>
                <Text style={styles.subHeading}>{department.studentDetails?.rollNo}</Text>
            </View>
        </View>
        <View style={styles.containerBody}>
            <View style={styles.containerFirst}>
                <View style={styles.textAlign}>
                    <Text style={styles.paraText}>1. Email: </Text>
                    {department.email && <Text style={styles.contentText}>{department.email}</Text>}
                </View>
                <View style={styles.textAlign}>
                    <Text style={styles.paraText}>2. Phone No: </Text>
                    {department.phoneNo && <Text style={styles.contentText}>{department.phoneNo}</Text>}
                </View>
            </View>

            <View style={styles.headerend}>
                    <View style={styles.textfirst}>
                        <Text style={styles.paraText}>Course Code</Text>
                        {department.courses && department?.courses.map((courseCode, index) => (
                            <Text key={index} style={styles.contentText}>{`${index + 1}. ${courseCode}`}</Text>
                        ))}
                    </View>

                    <View style={styles.textsecond}>
                        <Text style={styles.paraText}>Title</Text>
                        {department.courses && department?.courses.map((courseCode, index) => (
                            <Text key={index} style={styles.contentText}>
                                {generateCourseName(allCourse, courseCode)}
                            </Text>
                        ))}
                    </View>

                    <View style={styles.textend}>
                           <Text style={styles.paraText}>Faculty</Text>
                           {department.courses && department?.courses.map((courseCode, index) => (
                           <Text key={index} style={styles.contentText}>{findFacultyName(allFacultyInfo,courseCode, department.studentDetails.class)}</Text>
                        ))}
                    </View>
                </View>
        </View>
    </View>
);

const getCurrentDateTime = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
    const formattedTime = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    return { date: formattedDate, time: formattedTime };
};


const PDFDocument = ({ data,allCourse,allFacultyInfo}) => {
    const { date, time } = getCurrentDateTime();

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.headerContainer}>
                    <View style={styles.logoContainer}>
                        <Image style={styles.logo} src={Logo} />
                    </View>
                    <View style={styles.middleHeadings}>
                        <Text style={styles.heading}>Lahore College for Women University</Text>
                        <Text style={styles.subHeading}>Student List</Text>
                    </View>

                    <View style={styles.dateTimeContainer}>
                        <Text style={styles.subHeading}>{date}</Text>
                        <Text style={styles.subHeading}>{time}</Text>
                    </View>

                </View>
                <View style={styles.departmentsContainer}>
                    {data.map((department, index) => (
                        <Item key={index} department={department} index={index} allCourse={allCourse} allFacultyInfo={allFacultyInfo} />
                    ))}
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    page: {
        padding: 20,
        backgroundColor: '#ffffff',
    },
    headerContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-center',
        justifyContent: 'space-between',
        marginBottom: 30,
    },
    logoContainer: {
        width: '20%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-center',
    },
    logo: {
        width: 110,
        height: 70,
    },
    middleHeadings: {
        width: '60%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dateTimeContainer: {
        width: '20%',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    heading: {
        fontSize: 16,
        fontWeight: '800',
    },
    subHeading: {
        fontSize: 12,
        fontWeight: '600',
    },
    paraText: {
        fontSize: 10,
        fontWeight: '800',
    },
    departmentsContainer: {
        marginTop: 20,
    },
    itemContainer: {
        marginBottom: 20,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        padding: 10,
        backgroundColor: '#f2f2f2',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    startHeader: {
        width: '35%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-center',
    },
    middleHeader: {
        width: '30%',
        alignItems: 'start',
        justifyContent: 'center',
    },
    endHeader: {
        width: '35%',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
    },
    meta: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    containerBody: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '5px',
        padding: '10px',
    },
    containerFirst: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    textAlign: {
        width: '35%',
        flexDirection: 'row',
        rowGap: '5px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    contentText: {
        fontSize: 8,
        fontWeight: '400',
        textAlign: 'left',
    },
    containerThird: {
        flexDirection: 'row',
        rowGap: '5px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    headerend: {
        padding: 10,
        // backgroundColor: '#f2f2f2',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    textfirst: {
        width: '30%',
        flexDirection:'column',
        rowGap: '5px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    textsecond: {
        width: '40%',
        flexDirection: 'column',
        rowGap: '5px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    textend: {
        width: '30%',
        flexDirection:'column',
        rowGap: '5px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },


});


export default PDFDocument;
