import { SET_ALLCOURSES } from '../action-creators/ActionTypes'; // Assuming you have SET_ALLCOURSES action type defined

const AllCourses = (state = [], action) => {
    switch (action.type) {
        case SET_ALLCOURSES:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllCourses;
