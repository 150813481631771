import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from "react-hot-toast";
import config from '../../../../config';
import Apis from '../../../../Apis';
import {MessageByName} from "../Modals/AddNotification"

const AssignmentDetail = ({ openAssignmentDetail, AssignmentDetailHandler, assignmentDetail }) => {
    const [maxScreen, setMaxScreen] = useState(false);
    const backendBaseUrl = config.backendLocalhost;
    const userInfo = useSelector(state => state.userInfo);
    const ResponseMessages = useSelector(state => state.responseMessage);

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    }

    const assignmentID = assignmentDetail._id;

    const [assignmentStudDetail, setAssignmentStudDetail] = useState([]);
    
    useEffect(() => {
        setAssignmentStudDetail(assignmentDetail.assignDetail);
    }, [assignmentDetail]);


    const UpdateAssignmentMarks = async (name, mark, id, total) => {
        let loadingToast = "";
        try {
            if (parseInt(mark) < -1 || !mark) {
                toast.error("ERROR! Obtained Marks must be greater than -1");
                toast.dismiss(loadingToast);
                return;
            }
            if (parseInt(total) < parseInt(mark)) {
                toast.error("ERROR! Obtained Marks must be less than Total Marks");
                toast.dismiss(loadingToast);
                return;
            }
            
            const updatedAssignmentStudDetail = assignmentStudDetail.map((detail) => {
                // Check if the id matches the specific value you are looking for
                if (detail.studId === id) {
                    return { ...detail, status: "marked" };
                }
                return detail;
            });
            setAssignmentStudDetail(updatedAssignmentStudDetail);
            const formDataToSend = new FormData();
            formDataToSend.append("assignDetail", JSON.stringify(updatedAssignmentStudDetail));
            loadingToast = toast.loading('Loading...');
            const response = await axios.put(`${backendBaseUrl}/assignment/markedByFaculty/${assignmentID}`, formDataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const ResponseMsg = MessageByName("Assignment marked by instructor",ResponseMessages);
            toast.success(`${ResponseMsg}-${name}: ${mark}`);
            Apis.addAuditTrail(userInfo._id,"6634927f4fba113615476ca7",`- [ClassID:${assignmentDetail.class}, CourseID:${assignmentDetail.course}, No:${assignmentDetail.assignmentNo}, StudID:${id}]`);
            toast.dismiss(loadingToast);
        } catch (error) {
            console.error('Error sending data to the backend:', error);
            toast.error(`Error Assignment ${name} marked successfully:`, error);
            toast.dismiss(loadingToast);
        }
    };

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
${openAssignmentDetail
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[70vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
                }
`}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-red-900 via-red-600 to-red-300
 ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="text-[2vw] font-semibold">{`Assignment: ${assignmentDetail.assignmentNo}`}</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
                    </button>
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    {/* Provide the implementation for AddProjectHandler */}
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => AssignmentDetailHandler(assignmentDetail)}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col justify-start items-center
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
                <div className='w-full p-[1vw] flex flex-col gap-y-[1vh]'>
                    <div className='w-full flex gap-x-[1vw]'>

                        <div className='rounded-[0.5vw] w-[25%] bg-red-500 flex justify-center items-center'>
                            <p className='text-[1.2vw] text-white'>Uploaded Date{": "}
                                <span className='text-[1vw] text-white font-semibold'>{assignmentDetail.uploadDate}</span>
                            </p>
                        </div>
                        <div className='rounded-[0.5vw] w-[25%] bg-red-500 flex justify-center items-center'>
                            <p className='text-[1.2vw] text-white'>Due Date{": "}
                                <span className='text-[1vw]  text-white font-semibold'>{assignmentDetail.dueDate}</span>
                            </p>
                        </div>
                        <div className='rounded-[0.5vw] w-[25%] bg-red-500 flex justify-center items-center'>
                            <p className='text-[1.2vw] text-white'>Total Marks{": "}
                                <span className='text-[1vw]  text-white font-semibold'>{assignmentDetail.totalMarks}</span>
                            </p>
                        </div>
                        <div className='rounded-[0.5vw] w-[25%] bg-red-500 flex justify-center items-center'>
                            <p className='text-[1.2vw] text-white'>Assignment File{": "}
                                {assignmentDetail.facultyFile === "no file" ? "No File" :
                                    <a className='text-[1vw] text-white font-semibold hover:underline hover:text-blue-600'
                                        href={`${backendBaseUrl}/files/${assignmentDetail.facultyFile}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >Download</a>
                                }
                            </p>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className='flex items-center justify-start w-full h-full bg-red-500 rounded-[0.5vw] px-[1vw]'>
                            <p className='text-[1.2vw] text-white'>Description {":"}
                                <span className='text-[1vw] px-[1vw] py-[1vh] text-white font-semibold'>
                                {assignmentDetail.description}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='w-full bg-gray-50'>
                    <div className='w-full h-full p-[1vw] shadow-2xl overflow-auto style-scrollbar'>
                        <table className="min-w-full bg-white border rounded-[1vw] border-gray-300">
                            <thead className='text-white bg-red-900'>
                                <tr>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center border border-red-400">Sr.</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-left border border-red-400">Student Name</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-left border border-red-400">Date - Time</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal border border-red-400 text-center">File</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal border border-red-400 text-left">Marks Obtained</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal border border-red-400 text-left">Weighted Marks</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal border border-red-400">Submit</th>
                                </tr>
                            </thead>

                            <tbody>
                                {assignmentStudDetail && assignmentStudDetail.map((detail, index) => (
                                    <tr key={index} className="bg-gray-200">
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center">{index + 1}</td>
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-left">{detail.studName}</td>
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-left">{detail.submittedDetail}</td>
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center hover:underline hover:text-blue-600">
                                            <a
                                                href={`${backendBaseUrl}/files/${detail.studentFile}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >Download</a>
                                        </td>
                                        <td className="px-[1vw] border-b text-[1vw] font-normal text-left">
                                            <input
                                                className='w-full h-full py-[1vh] '
                                                type="text"
                                                value={detail.obtainedMarks || null}
                                                onChange={(e) => {
                                                    const updatedDetail = { ...detail, obtainedMarks: e.target.value };
                                                    const updatedStudDetail = [...assignmentStudDetail];
                                                    updatedStudDetail[index] = updatedDetail;
                                                    setAssignmentStudDetail(updatedStudDetail);
                                                }}
                                                disabled={detail.status === 'marked'}
                                            />
                                        </td>
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-left">{detail.obtainedMarks && ((detail.obtainedMarks * assignmentDetail.weightage) / assignmentDetail.totalMarks)?.toFixed(2)}</td>
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center">
                                            <button
                                                className={`px-[0.5vw] py-[0.5vh] border font-bold border-red-900 rounded-[0.5vw] ${detail.status === 'marked' ? "bg-green-100" : "bg-red-100 hover:text-white hover:bg-red-900 hover:border-red-400 duration-300"} `}
                                                onClick={() => { UpdateAssignmentMarks(detail.studName, detail.obtainedMarks, detail.studId, assignmentDetail.totalMarks) }}
                                                disabled={detail.status === 'marked'}
                                            >
                                                {detail.status === 'marked' ? "Marked" : "Mark"}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignmentDetail