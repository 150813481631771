import { useDispatch } from 'react-redux';
import { useEffect } from "react";
import { setUserInfo } from './state/action-creators';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import Apis from './Apis';
import config from "./config"

export function WebSocketComponent({ user }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserId = user._id;
  const SOCKET_URL = `${config.backendLocalhost}`;

  useEffect(() => {
    const socket = io(SOCKET_URL, {
      query: { userId: UserId },
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      console.log('Socket connected');
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
      Apis.addAuditTrail(UserId, "6646e477071f4ac0239b9f54", "");
      // localStorage.clear();
      // dispatch(setUserInfo([]));
      // navigate("/");
    });

    socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
    });

    socket.on('reconnect', (attempt) => {
      console.log(`Reconnected after ${attempt} attempts`);
    });

    socket.on('reconnect_attempt', (attempt) => {
      console.log(`Reconnect attempt ${attempt}`);
    });

    socket.on('reconnecting', (attempt) => {
      console.log(`Reconnecting attempt ${attempt}`);
    });

    socket.on('reconnect_error', (error) => {
      console.error('Reconnect Error:', error);
    });

    socket.on('reconnect_failed', () => {
      console.error('Reconnect Failed');
    });

    return () => {
      socket.disconnect();
    };
  }, [UserId, dispatch, navigate]);

  return null;
}
