import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators';
import config from '../../../../config';


const ViewLearningMaterial = ({ LearningMaterialHandler }) => {
  // State variables to manage selected values
  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [maxScreen, setMaxScreen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);
  const userInfo = useSelector((state) => state.userInfo);
  const backendBaseUrl = config.backendLocalhost;

  const dispatch = useDispatch();

  const handleActivityList = (value) => {
    dispatch(setSelectedActivity(value));
  };

  useEffect(() => {
    const filteyellowCourses = userInfo.facultyDetails.courseDetail
      .filter((info) => info.class === selectedClass)
      .map((info) => info.course);
    setCourses(filteyellowCourses);
  }, [selectedClass, userInfo.facultyDetails.courseDetail]);

  const learnings = useSelector(state => state.learningDetails);

  useEffect(() => {
    const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map((info) => info.class)));
    setClasses(uniqueClasses);
  }, [userInfo.facultyDetails]);

  const Categories = ['E-books', 'Audios', 'Videos & presentations', 'All'];
  const [selectedCategory, setSelectedCategory] = useState('All');

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const filterData = (selectedClass, selectedCourse, selectedCategory) => {

    const categoryData = selectedCategory === 'All'
      ? learnings || []
      : learnings.filter(row => row.category === selectedCategory) || [];

    const filteredData = categoryData.filter(row => {
      if (selectedClass !== '' && selectedCourse !== '') {
        return row.course === selectedCourse && row.class === selectedClass;
      } else {
        return false; // No filtering criteria specified
      }
    });

    return {
      [selectedCategory]: filteredData,
    };
  };

  const filteredData = filterData(selectedClass, selectedCourse, selectedCategory);

  function checkStatus(validFromDate, validToDate, currentDate) {
    const fromDate = new Date(validFromDate);
    const toDate = new Date(validToDate);
    const checkDate = new Date(currentDate);

    if (checkDate >= fromDate && checkDate <= toDate) {
      return true;
    } else {
      return false;
    }
  }
  const currentDate = new Date().toDateString();

  const maxScreenHandler = () => {
    setMaxScreen(!maxScreen);
    if (maxScreen) {
      handleActivityList("LearningMaterial")
    }
    else {
      handleActivityList("")
    }

  };
  return (
    <div className='w-full pb-[2vh] px-2'>
      <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh] px-[1vw]'>
        {/* Select Class */}
        <select
          id='classSelect'
          className='w-auto h-full text-black text-left border border-purple-700 rounded-[1vw] px-[1vw] focus:border-purple-700 focus:outline-none cursor-pointer'
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
        >
          <option value=''>Select a class</option>
          {classes.map((className) => (
            <option key={className} value={className}>
              {className}
            </option>
          ))}
        </select>

        {/* Select Course */}
        <select
          id='courseSelect'
          className='w-auto h-full text-black text-left border border-purple-700 rounded-[1vw] px-[1vw] focus:border-purple-700 focus:outline-none cursor-pointer'
          value={selectedCourse}
          onChange={(e) => setSelectedCourse(e.target.value)}
        >
          <option value=''>Select a course</option>
          {courses.map((course) => (
            <option key={course} value={course}>
              {course}
            </option>
          ))}
        </select>
        {/* Add Learning Material */}
        <button className='w-auto px-[1vw] h-full text-1 text-center border border-purple-700 rounded-[1vw] focus:border-purple-700 focus:outline-none
    bg-white text-black hover:bg-purple-700 hover:text-white duration-300 text-0.5xl'
          onClick={() => LearningMaterialHandler(selectedCourse, selectedClass)}>
          Add Document
        </button>
      </div>
      <div className="w-full h-full overflow-auto text-black style-scrollbar">
        <table className="h-full min-w-full text-sm bg-white border-collapse">
          <thead>
            <tr>
              <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                <span>SR</span>
              </th>
              <th className="relative p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                <select
                  id="select categories"
                  className="w-full h-full transition-all duration-300 bg-transparent border border-gray-300 rounded-md focus:outline-none hover:cursor-pointer focus:border-purple-900 text-start"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  {Categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </th>
              <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                <span>Title/Description</span>
              </th>
              <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                <span>Document Type</span>
              </th>
              <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                <span>Size</span>
              </th>
              <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                <span>Attachment</span>
              </th>
              <th className="p-2 bg-blue-100 border border-gray-400 text-start whitespace-nowrap">
                <span>Status</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(filteredData).map(([category, items]) => (
              items.map((item, index) => (
                <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`} key={index}>
                  <td className="p-2 border-gray-400 border-x whitespace-nowrap text-start">{index + 1}</td>
                  <td className="p-2 border-gray-400 border-x whitespace-nowrap text-start">{item.category}</td>
                  <td className="p-2 border-gray-400 border-x whitespace-nowrap text-start">{item.description}</td>
                  <td className="p-2 border-gray-400 border-x whitespace-nowrap text-start">{item.FileType}</td>
                  <td className="p-2 border-gray-400 border-x whitespace-nowrap text-start">{`${item.FileSize}MB`}</td>
                  <td className="p-2 text-center border-gray-400 cursor-pointer border-x whitespace-nowrap hover:text-blue-600 hover:underline">
                    <a
                      href={`${backendBaseUrl}/learning/${item.learningFile}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </a>
                  </td>
                  <td className={`border-x border-gray-400 p-2 whitespace-nowrap text-start ${checkStatus(item.validFromDate, item.validtoDate, currentDate) ? "text-purple-800" : "text-red-800"}`}>
                    {checkStatus(item.validFromDate, item.validtoDate, currentDate) ? "Active" : "Inactive"}
                  </td>
                </tr>
              ))
            ))}
          </tbody>

        </table>
      </div>
    </div>
  )
}

export default ViewLearningMaterial