import { SET_Quiz } from '../action-creators/ActionTypes';

const QuizDetails = (state = [] , action) => {
    switch (action.type) {
        case SET_Quiz:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
            
    }
};

export default QuizDetails;
