import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faFolder, faUpload, faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import View from "./ExecutiveManagement/View";
import Add from "./ExecutiveManagement/Add";
import { setAllDept } from '../../../state/action-creators';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { CSVLink } from "react-csv";
import Apis from '../../../Apis';

const ExecutiveManagement = () => {
    const [selectedStatus, setSelectedStatus] = useState("view");
    const [searchExcutiveManagement, setSearchExcutiveManagement] = useState("");
    const [allExcutiveManagementInfo, setAllExcutiveManagementInfo] = useState([]);
    const [updateExcutiveManagement, setUpdateExcutiveManagement] = useState({});
    const userInfo = useSelector(state => state.userInfo);

    const dispatch = useDispatch();

    const fetchAllExcutiveManagement = async () => {
        let userID;
        if (userInfo.role === 'admin') {
            userID = 'admin'
        }
        else {
            userID = userInfo._id
        }
        try {
            const AllExcutiveManagement = await Apis.getAllExcutiveManagement(searchExcutiveManagement, userID);
            setAllExcutiveManagementInfo(AllExcutiveManagement);
        } catch (error) {
            console.error('Error fetching All Excutive Management:', error.message);
            toast.error('Error fetching All Excutive Management:', error.message);
        }
    };

    const fetchDepartments = async () => {
        const user = {
            userID:userInfo._id,
            userRole:userInfo.role,
          }
        try {
            const AllDepartment = await Apis.getAllDepartment("", user);
            dispatch(setAllDept(AllDepartment || []));
        } catch (error) {
            console.error('Error fetching departments:', error.message);
            toast.error('Error fetching departments:', error.message);
        }
    };


    useEffect(() => {
        fetchDepartments();
    }, [userInfo]);
    useEffect(() => {
        fetchAllExcutiveManagement();
    }, [searchExcutiveManagement, userInfo, selectedStatus]);

    const handleButtonClick = (buttonName) => {
        setSelectedStatus(buttonName);
    };

    const reset = () => {
        setUpdateExcutiveManagement({
            _id: null,
            firstName: '',
            lastName: '',
            phoneNo: '',
            cnic: '',
            email: '',
            password: '',
            department: '',
            validity: true,
            role: 'ExcutiveManagement',
            refArray: [],
        })
        handleButtonClick("create");
    }

    const rearrangedData = allExcutiveManagementInfo.map(excutiveManagement => {
        return {
            Name: `${excutiveManagement.firstName} ${excutiveManagement.lastName}`,
            CNIC: excutiveManagement.cnic,
            Email: excutiveManagement.email,
            "Phone Number": excutiveManagement.phoneNo,
            department: excutiveManagement.department,
        };
    });

    return (
        <div className='flex flex-col items-start justify-start w-full
    5xl:px-[1vw] 5xl:py-[1vh] 5xl:h-full
    4md:px-[1vw] 4md:py-[1vh] 4md:h-full
    px-[1vw] py-[1vh] h-auto
    '>
            <div className='w-full flex flex-col justify-between bg-PrimaryDarkColor 
      5xl:py-[1vh] 5xl:px-[1vw] 5xl:rounded-[0.8vw] 5xl:h-[12%] 5xl:overflow-hidden 5xl:gap-y-[0vh]
      4md:py-[1vh] 4md:px-[1vw] 4md:rounded-[0.8vw] 4md:h-[15%] 4md:overflow-hidden 4md:gap-y-[0vh]
      py-[0.5vh] px-[2vw] rounded-[0.8vw] h-auto gap-y-[1vh]
      '>
                <label className='w-full text-white font-bold tracking-wide bg-tansparent
        5xl:text-2xl 5xl:text-start
        4md:text-2xl 4md:text-start
        text-md  text-center
        '>Departmental Admins Management</label>
                <div className='flex justify-between w-full justify-center items-start 
        5xl:gap-x-[1vw] 5xl:flex-row 
        4md:gap-x-[1vw] 4md:flex-row 
        gap-x-[1vw] flex-col gap-y-[1vh] h-auto
        '>
                    <div className='grid justify-center items-center h-auto 
          5xl:w-[65%] 5xl:gap-x-[0.5vw] 5xl:flex-row 5xl:grid-cols-5 
          4md:w-[70%] 4md:gap-x-[0.5vw] 4md:flex-row 4md:grid-cols-5 
          w-full gap-x-[2vw] flex-col grid-cols-3 gap-y-[1vh] gap-x-[1vw]
          '>
                        <div className={`w-auto h-auto items-center flex justify-center
            5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
            4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
            max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "view"
                                ? "bg-PrimaryColor text-white font-medium transitionAll"
                                : "bg-SecondaryColor text-black font-light"
                            }`}
                            onClick={() => handleButtonClick("view")}
                        >
                            <div>
                                <FontAwesomeIcon icon={faFolder} />
                            </div>
                            <div>
                                <button>Show List</button>
                            </div>
                        </div>
                        <div className={`w-auto h-auto items-center flex justify-center
             5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
             4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
             max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "create"
                                ? "bg-PrimaryColor text-white font-medium transitionAll"
                                : "bg-SecondaryColor text-black font-light"
                            }`}
                            onClick={() => reset()}
                        >
                            <div>
                                <FontAwesomeIcon icon={faCirclePlus} />
                            </div>
                            <div>
                                <button>Add New</button>
                            </div>
                        </div>
                        <div className={`w-auto h-auto items-center flex justify-center
             5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
             4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
             max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "Import"
                                ? "bg-PrimaryColor text-white font-medium transitionAll"
                                : "bg-SecondaryColor text-black font-light"
                            }`}
                            onClick={() => handleButtonClick("Import")}
                        >
                            <div>
                                <FontAwesomeIcon icon={faUpload} />
                            </div>
                            <div>
                                <button>Import</button>
                            </div>
                        </div>
                        <div className={`w-auto h-auto items-center flex justify-center
              5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
              4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
              max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "download"
                                ? "bg-PrimaryColor text-white font-medium transitionAll"
                                : "bg-SecondaryColor text-black font-light"
                            }`}
                        >
                            <div>
                                <FontAwesomeIcon icon={faFileCsv} />
                            </div>
                            <div>
                                <CSVLink data={rearrangedData} filename={"ExcutiveManagementList.csv"}>Download</CSVLink>
                            </div>
                        </div>
                        <div className={`w-auto h-auto items-center flex  justify-center
             5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
             4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
             max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "print"
                                ? "bg-PrimaryColor text-white font-medium transitionAll"
                                : "bg-SecondaryColor text-black font-light"
                            }`}
                        >
                            <div>
                                <FontAwesomeIcon icon={faFilePdf} />
                            </div>
                            <div>
                                Print
                            </div>
                        </div>
                    </div>
                    <label className={`${selectedStatus === "view" ? "block" : "hidden"}  input h-auto items-center flex text-black
                      5xl:w-[35%] 5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-md 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
                      4md:w-[30%] 4md:max-h-[5vh] 4md:rounded-lg 4md:text-md 4md:px-[1vw] 4md:gap-x-[0.7vw] 4md:py-[1vh]
                      w-full max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[0.5vh]`}>
                        <input type="text" className="grow" placeholder="Search by first name"
                            value={searchExcutiveManagement}
                            onChange={(e) => setSearchExcutiveManagement(e.target.value)}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd" /></svg>
                    </label>
                </div>
            </div>

            <div className='w-full
      5xl:py-[2vh] 5xl:h-[88%] 
      4md:py-[1vh] 4md:h-[85%] 
      py-[1vh] h-full 
      '>
                {selectedStatus === "view" ? (
                    <View
                        handleButtonClick={handleButtonClick}
                        setUpdateExcutiveManagement={setUpdateExcutiveManagement}
                        allExcutiveManagementInfo={allExcutiveManagementInfo}
                    />
                ) : selectedStatus === "create" ? (
                    <Add
                        handleButtonClick={handleButtonClick}
                        updateExcutiveManagement={updateExcutiveManagement}
                        setUpdateExcutiveManagement={setUpdateExcutiveManagement}
                    />
                ) : (
                    <>
                        <p className='text-black text-2xl'>Working.....</p>
                    </>
                )}
            </div>

        </div>
    )
}

export default ExecutiveManagement