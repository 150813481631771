import React, { useState } from 'react';
import MultiSubOption from './MultiSubOption';
import SubNumericField from './SubNumericField';
import SubOption from './SubOption'
import SingleSelectionOther from './SingleSelectionOther';
import MultiSelectionOther from './MultiSelectionOther';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';

const MultipleOption = ({ setLocalFormData, localFormData }) => {
  const [questions, setQuestions] = useState([{ id: '1', question: '', type: '' }]);
  const [options, setOptions] = useState(['']);
  const [range, setRange] = useState({ from: '', to: '' });
  
  const backendBaseUrl = config.backendLocalhost;

  const addQuestion = () => {
    const newId = (questions.length + 1).toString();
    setQuestions([...questions, { id: newId, question: '', type: '' }]);
  };

  const deleteQuestion = (id) => {
    setQuestions(questions.filter((q) => q.id !== id));
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newQuestions = questions.map((q, i) => {
      if (i === index) {
        return { ...q, [name]: value };
      }
      return q;
    });
    setQuestions(newQuestions);
  };

  const handleCancel = () => {
    const confirmCancel = window.confirm("Are you sure you want to cancel all your changes?");
    if (confirmCancel) {
    setQuestions([{ id: '1', question: '', type: '' }]);
    }
  };

  const handleOptionChange = (index, event) => {
    const { value } = event.target;
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
};

const addMoreOption = () => {
    setOptions([...options, '']);
};

const removeOption = (index) => {
    const newOptions = options.filter((_, qIndex) => qIndex !== index);
    setOptions(newOptions);
};

const handleRangeChange = (event) => {
  const { name, value } = event.target;
  setRange((prevRange) => {
    const newRange = { ...prevRange, [name]: value };

    if (name === 'from' && parseInt(value) > parseInt(newRange.to)) {
      
      return prevRange; 
    }
    if (name === 'to' && parseInt(value) < parseInt(newRange.from)) {
     
      return prevRange; 
    }

    return newRange;
  });
};

const handleAddTemplate = async (event) => {
  event.preventDefault();
  try {
      const formattedQuestions = questions.map((q, index) => {
          let formattedQuestion = {
              quesNo: (index + 1).toString(),
              question: q.question,
              questionType: q.type
          };
          
          if (q.type === 'singleSelectWithOtherOption' || q.type === 'multipleSelectOption' || q.type === 'singleSelectOption'|| q.type === 'multipleSelectWithOtherOption'){
              formattedQuestion.optionsArray = options; 
          }
          
          if (q.type === 'numericValueOption') {
              formattedQuestion.range = range;
          }
          
          return formattedQuestion;
      });

      const formData = {
        ...localFormData,
        differentOption: {
          questionArray: formattedQuestions
      }
      };
      const response = await axios.post(`${backendBaseUrl}/survey/createTemplate`, formData);
      setLocalFormData(formData);

      toast.success(`Template added successfully`);
  } catch (error) {
      console.error('Error adding template:', error);
      if (error.response && error.response.data && error.response.data.errorMessage) {
          toast.error(error.response.data.errorMessage);
      } else {
          toast.error('An error occurred while adding the template.');
      }
  }
};

  return (
    <form
      className="w-full h-full p-[2vw] bg-SecondaryColor rounded-2xl overflow-y-auto style-scrollbar flex flex-col justify-center items-start" onSubmit={handleAddTemplate}
      
    >
      {questions.map((q, index) => (
        <div key={index} className="flex flex-col items-start justify-center w-full">
          <div className="flex items-center justify-center w-full gap-x-2 ">
            <div className="flex w-[10%] text-black">
              <input
                type="text"
                name="id"
                placeholder="No."
                className="w-full input"
                onChange={(e) => handleInputChange(index, e)}
                value={q.id}
              />
            </div>
            <div className="flex w-[70%] text-black">
              <input
                type="text"
                name="question"
                placeholder="Enter Question Here"
                className="w-full input"
                value={q.question}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
            <div className="w-[20%] flex flex-col min-w-[25%] items-center">
              <select
                className="w-full text-black select select-primary"
                name="type"
                value={q.type}
                onChange={(e) => handleInputChange(index, e)}
              >
                <option value="" disabled>
                  Option Types
                </option>
                <option value="singleSelectOption">Response as Single Selection option</option>
                <option value="multipleSelectOption">Response as Multiple Selection option</option>
                <option value="singleSelectWithOtherOption">Response as Single Selection option with Others</option>
                <option value="multipleSelectWithOtherOption">Response as Multiple Selection option with Others</option>
                <option value="textFiledOption">Response as Text Field</option>
                <option value="numericValueOption">Response as Numeric Value</option>
              </select>
            </div>
            {questions.length > 1 && (
              <button
                type="button"
                onClick={() => deleteQuestion(q.id)}
                className="px-3 py-1 ml-2 text-white duration-300 bg-red-600 rounded-md hover:bg-red-800 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50"
              >
                Delete
              </button>
            )}
          </div>
          <div className="w-full">
            {q.type === 'singleSelectOption' && <SubOptions options={options} handleOptionChange={handleOptionChange} addMoreOption={addMoreOption} removeOption={removeOption}/>}
            {q.type === 'multipleSelectOption' && <MultipleSubOptions options={options} handleOptionChange={handleOptionChange} addMoreOption={addMoreOption} removeOption={removeOption}/>}
            {q.type === 'singleSelectWithOtherOption' && <SingleOptionOther options={options} handleOptionChange={handleOptionChange} addMoreOption={addMoreOption} removeOption={removeOption}/>}
            {q.type === 'multipleSelectWithOtherOption' && <MultiOptionOther options={options} handleOptionChange={handleOptionChange} addMoreOption={addMoreOption} removeOption={removeOption}/>}
            {q.type === 'numericValueOption' && <NumericField range={range} handleRangeChange={handleRangeChange}/>}
          </div>
          <hr className="w-full my-4 border-t border-gray-600" />
        </div>
      ))}
      <div className="flex min-w-full gap-3">
        <button
          type="submit"
          className="w-full px-4 py-2 mt-3 text-white duration-300 bg-green-600 rounded-md hover:bg-green-900 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          Submit
        </button>
        <button
          type="button" 
          onClick={handleCancel}
          className="w-full px-4 py-2 mt-3 text-white duration-300 rounded-md bg-slate-600 hover:bg-slate-400 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
          Cancel
         </button>
        <button
          type="button"
          onClick={addQuestion}
          className="w-full px-4 py-2 mt-3 text-white duration-300 bg-blue-600 rounded-md hover:bg-green-900 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          Add More Question
        </button>
      </div>
    </form>
  );
};

const SubOptions = ({options,handleOptionChange,addMoreOption,removeOption}) => <div><SubOption options={options} handleOptionChange={handleOptionChange} addMoreOption={addMoreOption} removeOption={removeOption}/></div>;
const MultipleSubOptions = ({options,handleOptionChange,addMoreOption,removeOption}) => <div><MultiSubOption options={options} handleOptionChange={handleOptionChange} addMoreOption={addMoreOption} removeOption={removeOption}/></div>;
const SingleOptionOther = ({options,handleOptionChange,addMoreOption,removeOption}) => <div><SingleSelectionOther options={options} handleOptionChange={handleOptionChange} addMoreOption={addMoreOption} removeOption={removeOption}/></div>;
const MultiOptionOther = ({options,handleOptionChange,addMoreOption,removeOption}) => <div><MultiSelectionOther options={options} handleOptionChange={handleOptionChange} addMoreOption={addMoreOption} removeOption={removeOption}/></div>;
const NumericField = ({range, handleRangeChange }) => <div><SubNumericField range={range} handleRangeChange={handleRangeChange}/></div>;

export default MultipleOption;
