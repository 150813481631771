import React from 'react';
import EditButton from '../EditButton';

const View = ({ setUpdateVal, handleButtonClick}) => {

    const handleEditDepartment = (val) => {
      setUpdateVal(val);
      handleButtonClick('create');
    };

  return (
    <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
    <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
      <thead className=''>
        <tr className='text-sm font-semibold'>
          <th className="px-4 py-3 text-center border border-gray-400">Sr.</th>
          <th className="px-4 py-3 text-left border border-gray-400 ">Name</th>
          <th className="px-4 py-3 text-left border border-gray-400 ">Class</th>
          <th className="px-4 py-3 text-left border border-gray-400">Course</th>
          <th className="px-4 py-3 text-left border border-gray-400">Status</th>
          <th className="px-4 py-3 text-left border border-gray-400">View</th>
          <th className="px-4 py-3 text-left border border-gray-400">Edit</th>
        </tr>
      </thead>
      <tbody>
        {/* {departments.map((dept, index) => (
          <tr key={dept._id}
            className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}
          >
            <td className="px-4 py-3 text-center ">{index + 1}</td>
            <td className="px-4 py-3 truncate text-left">{dept.department}</td>
            <td className="px-4 py-3 text-left">{dept.deptCode}</td>
            <td className="px-4 py-3 text-left">{dept.email}</td>
            <td className="px-4 py-3 text-left">{dept.contact}</td>
            <td className="px-4 py-3 text-left">
              <a
                className={`cursor-pointer hover:text-blue-600 hover:underline duration-300 ${dept.website ? "" : "hidden"}`}
                href={dept.website}
                target="_blank"
                rel="noopener noreferrer">
                {"Click here"}
              </a>
            </td>
            <td className="px-4 py-3 text-left">
              <a
                className={`cursor-pointer hover:text-blue-600 hover:underline duration-300 ${dept.location ? "" : "hidden"}`}
                href={dept.location}
                target="_blank"
                rel="noopener noreferrer">
                {"Click here"}
              </a>
            </td>
            <td className="px-4 py-3 text-left">
              <label className='truncate'>{dept.address}</label>
            </td>
            <td className={`px-4 py-3 border-b text-center ${dept.status ? "text-green-600" : "text-red-600"}`}> {dept.status ? "Active" : "In-Active"} </td>
            <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center  ">
              <button onClick={() => handleEditDepartment(dept)}>
                <EditButton />
              </button>
            </td>
            <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center  ">
              <button onClick={() => handleEditDepartment(dept)}>
                <EditButton />
              </button>
            </td>
          </tr>
        ))} */}
      </tbody>
    </table>
  </div>  
  )
}

export default View