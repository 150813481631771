import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {MessageByName} from '../../../FacultyComp/Dashboard/Modals/AddNotification';
import Select from 'react-select';
import Apis from "../../../../Apis";


const AddUpdateStudent = ({ setUpdateStudInfo, updateStudInfo, handleButtonClick }) => {

  const [showPassword, setShowPassword] = useState(false);
  const ResponseMessages = useSelector(state => state.responseMessage);
  const [allDepartmentalAdminInfo, setAllDepartmentalAdminInfo] = useState([])
  
	const handleTogglePasswordVisibility = () => {
		setShowPassword(prevState => !prevState);
	};

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNo: '',
    cnic: '',
    email: '',
    password: '',
    validity: true,
    studentDetails: {
      rollNo: '',
      class: '',
    },
    role: 'student',
    refArray: [],
  });

  const [editingId, setEditingId] = useState(null);
  const backendBaseUrl = config.backendLocalhost;
  const userInfo = useSelector(state => state.userInfo);
  const allClasses = useSelector(state => state.allClass);
  const allStudentInfo = useSelector(state => state.allStudentInfo);
  const [allExcutiveAdminInfo, setAllExcutiveAdminInfo] = useState([]);

  const fetchAllExcutiveAdmin = async () => {
    try {
      const AllExcutiveAdmin = await Apis.getAllExcutiveAdmin("");
      setAllExcutiveAdminInfo(AllExcutiveAdmin);
    } catch (error) {
      console.error('Error fetching All Excutive Admin:', error.message);
      toast.error('Error fetching All Excutive Admin:', error.message);
    }
  };
  const fetchAllExcutiveManagement = async () => {
    let userID;
    if (userInfo.role === 'admin') {
      userID = 'admin'
    }
    else {
      userID = userInfo._id
    }
    try {
      const AllExcutiveManagement = await Apis.getAllExcutiveManagement("", userID);
      setAllDepartmentalAdminInfo(AllExcutiveManagement);
    } catch (error) {
      console.error('Error fetching All Excutive Management:', error.message);
      toast.error('Error fetching All Excutive Management:', error.message);
    }
  };

  useEffect(() => {
    if(userInfo.role){
      fetchAllExcutiveAdmin();
      fetchAllExcutiveManagement();
    }
  }, [updateStudInfo,userInfo]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClassCodeChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      studentDetails: {
        ...prevFormData.studentDetails,
        [name]: value,
      },
    }));
  };

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };

  const resetField = () => {
    setEditingId(null);
    setFormData({
      firstName: '',
      lastName: '',
      phoneNo: '',
      cnic: '',
      email: '',
      password: '',
      validity:true,
      studentDetails: {
        rollNo: '',
        class: '',
      },
      role: 'student',
      refArray: [],
    })
    setUpdateStudInfo([]);
  };

  useEffect(() => {
    if (editingId === null) {
      if (formData.studentDetails?.class) {
        const matchingStudents = allStudentInfo.filter((student) =>
          student.studentDetails && student.studentDetails.class === formData.studentDetails.class
        );
        setFormData((formData) => ({
          ...formData,
          studentDetails: {
            ...formData.studentDetails,
            rollNo: `${formData.studentDetails.class}-${matchingStudents.length + 1}`,
          },
        }));
      }
    }
  }, [formData.studentDetails.class, allStudentInfo, updateStudInfo.id, editingId]);


  const handleAddStudent = async (e) => {
    e.preventDefault();

    const cnicRegex = /^\d{13}$/;
    if (!cnicRegex.test(formData.cnic)) {
      toast.error('Invalid CNIC format. Please enter a valid CNIC number.');
      return;
    }
    const phoneRegex = /^\d{11}$/;
    if (!phoneRegex.test(formData.phoneNo.replace(/-/g, ''))) {
      toast.error('Invalid phone number format. Please enter a valid 10-digit phone number.');
      return;
    }
    setFormData({
      ...formData,
      userID: userInfo._id,
    });
    try {
      const response = await axios.post(`${backendBaseUrl}/api/users`, formData);
      toast.success(response.data.message);
      resetField();
      handleButtonClick('view');
    } catch (error) {
      const ResponseMsg = MessageByName(error.response?.data?.message,ResponseMessages);
      toast.error(ResponseMsg);
      //toast.error(`API Error: ${error.response?.data?.message || 'Server not responding'}`);
      console.error('API Error:', error.response ? error.response.data : error.message);
    }
  };


  useEffect(() => {
    if (updateStudInfo && updateStudInfo.length !== 0) {
      setEditingId(updateStudInfo._id);
      setFormData({
        firstName: updateStudInfo.firstName,
        lastName: updateStudInfo.lastName,
        phoneNo: updateStudInfo.phoneNo,
        cnic: updateStudInfo.cnic,
        email: updateStudInfo.email,
        validity:updateStudInfo.validity,
        studentDetails: {
          rollNo: updateStudInfo.studentDetails?.rollNo,
          class: updateStudInfo.studentDetails?.class,
        },
        role: 'student',
        refArray: updateStudInfo.refArray,
      })
    }
  }, [updateStudInfo]);

  const handleUpdateStudent = async () => {
    try {
      const response = await axios.put(`${backendBaseUrl}/api/users/update/${updateStudInfo._id}`, formData);
      toast.success(response.data.message);
      resetField();
      handleButtonClick('view');
    } catch (error) {
      const ResponseMsg = MessageByName(error.response?.data?.message,ResponseMessages);
      toast.error(ResponseMsg);
      //toast.error(`Error: ${error.response?.data?.message || 'Server not responding'}`);
      console.error('API Error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className='flex flex-col items-center justify-start w-full h-auto overflow-y-auto lg:overflow-y-auto lg:style-scrollbar style-scrollbar'>
    <form className='w-full bg-gray-300 flex flex-col items-start justify-center lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh] text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh]'>
      <div className="w-full grid justify-center items-center gap-x-[1vw] gap-y-[1vh] 
      lg:grid-cols-2
      grid-cols-1">
          
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>First Name:</label>
              <input
                type="text"
                placeholder="Enter your First Name"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='firstName'
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>Last Name:</label>
              <input
                type="text"
                placeholder="Enter your Last Name"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='lastName'
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>
       
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='phoneNo' className='font-semibold'>Phone No:</label>
              <input
                type="text"
                placeholder="Enter your Phone No"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='phoneNo'
                name="phoneNo"
                value={formData.phoneNo}
                onChange={handleInputChange}
              />
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='cnic' className='font-semibold'>CNIC No:</label>
              <input
                type="text"
                placeholder="Enter your CNIC without dashes"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='cnic'
                name="cnic"
                value={formData.cnic}
                onChange={handleInputChange}
              />
            </div>
         
        
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>Email address:</label>
              <input
                type="text"
                placeholder="Email address will used as username"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='email'
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>Password:</label>
              <div className="relative w-full">
									<input
										type={showPassword ? "text" : "password"}
                    placeholder={`${updateStudInfo._id ? "Enter new Password" : "Enter your Password"}`}
                    className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                    id='password'
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
									/>
									<button
										type="button"
										className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
										onClick={handleTogglePasswordVisibility}
									>
										<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
									</button>
								</div>
            </div>
     
          <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>Select Class Code:</label>
              <select className="w-full bg-white select select-primary"
                id='class'
                name="class"
                value={formData.studentDetails?.class}
                onChange={handleClassCodeChange}
              >

                <option selected disabled value="">
                  Select a class code
                </option>
                {allClasses && allClasses.map((val) => (
                  <option className='text-black' key={val.classCode} value={val.classCode}>
                    {val.classCode}
                  </option>
                ))}
              </select>
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
              <label htmlFor='deptCode' className='font-semibold'>Roll No:</label>
              <input
                type="text"
                placeholder="It will be auto generated Code"
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='rollNo'
                name="rollNo"
                value={formData.studentDetails?.rollNo}
                onChange={handleClassCodeChange}
              />
            </div> 
          </div>
          {userInfo.role === "admin" &&
              <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm gap-y-[0.5vh] text-sm w-full">
                <label htmlFor="deptWebsiteLink" className="font-semibold">Assign Department to Executive Admins:</label>
                <Select
                  className="h-full input select-primary min-w-full bg-white p-1"
                  id="refArray"
                  name="refArray"
                  value={formData.refArray?.map(id => ({
                    value: id,
                    label: allExcutiveAdminInfo?.find(admin => admin._id === id)?.firstName || allDepartmentalAdminInfo?.find(admin => admin._id === id)?.firstName ||  '***'
                  }))}
                  onChange={(selectedOptions) => {
                    setFormData({
                      ...formData,
                      refArray: selectedOptions.map(option => option.value)
                    });
                  }}
                  placeholder="Select multiple Executive Admins"
                  options={allExcutiveAdminInfo.map(admin => ({
                    value: admin._id,
                    label: `${admin.firstName} ${admin.lastName}`
                  }))}
                  isMulti
                />

              </div>
            }
          <div className='w-[5%] flex flex-col gap-y-[0.5vh] justify-center items-start'>
            <label htmlFor='validity' className='font-semibold text-black'>Status:</label>
              <div className='flex gap-x-[0.5vw] justify-center items-center'>
                <input
                  className='checkbox'
                  type="checkbox"
                  id='validity'
                  name="validity"
                  checked={formData.validity}
                  onChange={handleCheckBoxChange}
                />
                <label className={`text-center ${formData.validity ? "text-green-600" : "text-red-600"}`}> {formData.validity ? "Valid" : "In-Valid"} </label></div>
            </div>

        <button
          className='px-[1vw] py-[1vh] bg-PrimaryColor text-white rounded-[0.5vw] font-bold'
          type='button'
          onClick={editingId !== null ? handleUpdateStudent : handleAddStudent}
        >
          {editingId !== null ? 'Update Student' : 'Add Student'}
        </button>

      </form>
    </div>
  );
};

export default AddUpdateStudent