import React from 'react'
import GradesReport from '../../../components/employeeComp/Dashboard/GradesReport'


const Dashboard = ({ AssignmentModalHandler, AttendanceModalHandler, QuizModalHandler, FinalModalHandler,CoursePlanModalHandler }) => {
  return (
    <div>
      <GradesReport
      AssignmentModalHandler={AssignmentModalHandler}
      AttendanceModalHandler={AttendanceModalHandler}
      QuizModalHandler={QuizModalHandler}
      FinalModalHandler={FinalModalHandler}
      CoursePlanModalHandler={CoursePlanModalHandler}/>
    </div>
  )
}

export default Dashboard
