import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import config from "../../../../config";
import Single from "./MultiOption/Single";
import Grading from "./MultiOption/Grading";
import Multi from "./MultiOption/Multi";
import Text from "./MultiOption/Text";
import OtherSingle from "./MultiOption/OtherSingle";
import OtherMulti from "./MultiOption/OtherMulti";
import { useSelector, useDispatch } from "react-redux";
import { SetTriggerNotificationStudent } from "../../../../state/action-creators";

const MultipleOption = ({ templete, SurveyModalHandler }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const [surveyResult, setSurveyResult] = useState(
    templete?.template?.differentOption?.questionArray.map((q) => ({
      quesNo: q.quesNo,
      question: q.question,
      questionType: q.questionType,
      Answer: [],
    }))
  );
  const { backendLocalhost } = config;
  const dispatch = useDispatch();

  const handleAddTemplate = async () => {
    const allQuestionsAnswered = surveyResult.every((q) => q.Answer.length > 0);
    if (!allQuestionsAnswered) {
      toast.error("Please answer all questions before submitting.");
      return;
    }

    const formData = {
      templateID: templete.template._id,
      studID: userInfo._id,
      classCode: templete.classCode,
      courseCode: templete.courseCode,
      surveyResult: surveyResult,
    };

    console.log("formData", formData);
    try {
      const response = await axios.post(
        `${backendLocalhost}/survey/createSurveyForm`,
        formData
      );
      dispatch(SetTriggerNotificationStudent());
      SurveyModalHandler();
      setSurveyResult(
        templete?.template?.differentOption?.questionArray.map((q) => ({
          quesNo: q.quesNo,
          question: q.question,
          questionType: q.questionType,
          Answer: [],
        }))
      );
      toast.success(`Assign Template added successfully`);
    } catch (error) {
      console.error("Error adding assign template", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessage
      ) {
        toast.error(error.response.data.errorMessage);
      } else {
        toast.error("Error adding assign template.");
      }
    }
  };

  return (
    <div className="flex flex-col justify-start w-full h-auto overflow-y-auto lg:h-full lg:overflow-y-auto lg:style-scrollbar style-scrollbar">
      <form
        className="w-full bg-gray-300 flex flex-col items-start justify-center 
        lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh]
        text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh] gap-2"
      >
        <div className="flex w-full gap-x-2 ">
          <div className="flex w-full ">
            <label className="font-semibold">Class:</label>
            <p className="mx-1 font-semibold">
              ({templete?.classCode && templete.classCode})
            </p>
          </div>
          <div className="flex w-full ">
            <label className="font-semibold">Course:</label>
            <p className="mx-1 font-semibold">
              (
              {templete?.courseCode &&
                `${templete.courseCode} - ${templete.courseName}`}
              )
            </p>
          </div>
        </div>
        <div className="flex w-full ">
          <label className="font-semibold ">Faculty:</label>
          <p className="mx-1 font-semibold">
            ({templete?.facultyName && templete.facultyName})
          </p>
        </div>
        <div className="flex w-full ">
          <label className="font-semibold ">Survey Title:</label>
          <p className="mx-1 font-semibold">
            (
            {templete?.template?.templateName &&
              templete.template?.templateName}
            )
          </p>
        </div>
        <div className="w-full h-full p-3 border border-gray-400 rounded-lg">
          <div className="text-xl text-center">
            <h1>Instruction</h1>
          </div>
          <div className="mt-4 font-semibold text-center">
            <h1>
              Please read each question carefully and then enter the appropriate
              reply. Reply to all questions is mandatory. You can change any
              option until you press the SUBMIT button.
            </h1>
          </div>
        </div>
        <div className="flex flex-col w-full h-full border border-gray-400 rounded-lg">
          <div className="flex justify-center w-full h-full">
            <h1 className="font-semibold">Questions/Response</h1>
          </div>
          <div className="flex flex-col w-full h-full gap-2">
            {templete?.template?.differentOption?.questionArray.map(
              (q, index) => (
                <>
                  <div
                    className="flex flex-col w-full h-full gap-y-2"
                    key={index}
                  >
                    <div className="flex items-start justify-start w-full h-full gap-x-2">
                      <p>{q.quesNo}</p>
                      <p className="w-full h-full whitespace-normal">
                        {q.question}
                      </p>
                    </div>
                  </div>

                  {q.questionType === "singleSelectOption" ? (
                    <Single
                      key={q.quesNo}
                      optionsArray={q.optionsArray}
                      setSurveyResult={setSurveyResult}
                      surveyResult={surveyResult}
                      quesNo={q.quesNo}
                    />
                  ) : q.questionType === "numericValueOption" ? (
                    <Grading
                      key={q.quesNo}
                      range={q.range}
                      setSurveyResult={setSurveyResult}
                      surveyResult={surveyResult}
                      quesNo={q.quesNo}
                    />
                  ) : q.questionType === "multipleSelectOption" ? (
                    <Multi
                      key={q.quesNo}
                      optionsArray={q.optionsArray}
                      setSurveyResult={setSurveyResult}
                      surveyResult={surveyResult}
                      quesNo={q.quesNo}
                    />
                  ) : q.questionType === "textFiledOption" ? (
                    <Text
                      key={q.quesNo}
                      setSurveyResult={setSurveyResult}
                      surveyResult={surveyResult}
                      quesNo={q.quesNo}
                    />
                  ) : q.questionType === "singleSelectWithOtherOption" ? (
                    <OtherSingle
                      key={q.quesNo}
                      optionsArray={q.optionsArray}
                      setSurveyResult={setSurveyResult}
                      surveyResult={surveyResult}
                      quesNo={q.quesNo}
                    />
                  ) : q.questionType === "multipleSelectWithOtherOption" ? (
                    <OtherMulti
                      key={q.quesNo}
                      optionsArray={q.optionsArray}
                      setSurveyResult={setSurveyResult}
                      surveyResult={surveyResult}
                      quesNo={q.quesNo}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )
            )}
          </div>
        </div>
        <div className="flex w-full h-full space-x-2 ">
          <button
            type="button"
            onClick={handleAddTemplate}
            className="self-center w-full px-4 py-2 text-white duration-300 bg-green-500 rounded-md hover:bg-green-700 focus:outline-none focus:ring focus:ring-green-200 focus:ring-opacity-50"
          >
            Submit
          </button>
          <button
            type="button"
            className="self-center w-full px-4 py-2 text-white duration-300 rounded-md bg-slate-500 hover:bg-slate-700 focus:outline-none focus:ring focus:ring-green-200 focus:ring-opacity-50"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default MultipleOption;
