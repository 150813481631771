import React from 'react'
import Reminders from '../../../components/employeeComp/Dashboard/Reminders'

const Notification = ({SelectedRemindersHandler}) => {
  return (
    <div>
      <Reminders
      SelectedRemindersHandler={SelectedRemindersHandler}/>
    </div>
  )
}

export default Notification
