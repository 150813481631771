// reducers/FacultyActivityListToggle.js
import { SET_ALLStudent_INFO } from '../action-creators/ActionTypes';

const AllStudentInfo = (state = [] , action) => {
    switch (action.type) {
        case SET_ALLStudent_INFO:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllStudentInfo;
