import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Apis from '../Apis';
import Navbar from './Admin/compoonents/Navbar'
import FacultyHeader from './FacultyComp/Header';
import EmployeeHeader from './employeeComp/Header';

export function getDateTimeParts(dateStr) {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(dateStr);
    const day = ("0" + date.getDate()).slice(-2);
    const monthIndex = date.getMonth();
    const month = months[monthIndex];
    const year = date.getFullYear();

    // Get 12-hour time with AM/PM
    const hours = date.getHours() % 12 || 12;  // 12 for midnight
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const period = date.getHours() < 12 ? 'AM' : 'PM';
    const formattedTime = `${hours}:${minutes} ${period}`;

    return {
        date: `${day}-${month}-${year}`,
        time: formattedTime,
    };
}


const AuditTrail = () => {
    const userInfo = useSelector(state => state.userInfo);
    const [auditTrail, setAuditTrail] = useState([]);

    const fetchAuditTrail = async () => {
        try {
            const AuditTrail = await Apis.getAuditTrailByUserID(userInfo._id);
            setAuditTrail(AuditTrail || []);
        } catch (error) {
            console.error('Error fetching audit Trails:', error);
        }
    };

    useEffect(() => {
        if (userInfo._id) {
            fetchAuditTrail();
        }
    }, [userInfo._id]);

    return (
        <div className=' w-[100vw] h-[100vh] bg-blue-100'>
            {userInfo.role === "admin" ? <Navbar /> :
                userInfo.role === "faculty" ? <FacultyHeader /> :
                    userInfo.role === "student" ? <EmployeeHeader /> :
                        <Navbar />
            }
            <div className='flex flex-col h-[90vh] justify-start items-center overflow-hidden px-32 py-4'>
                <p className='text-2xl text-black py-6 text-center w-full'>Audit Trail</p>
                <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
                    <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
                        <thead className=''>
                            <tr className='text-sm font-semibold'>
                                <th className="px-4 py-3 text-center border border-gray-400">Sr.</th>
                                <th className="px-4 py-3 text-left border border-gray-400 ">Message</th>
                                <th className="px-4 py-3 text-left border border-gray-400 ">Action</th>
                                <th className="px-4 py-3 text-left border border-gray-400">Date</th>
                                <th className="px-4 py-3 text-left border border-gray-400">Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {auditTrail.Trail && auditTrail.Trail.map((val, index) => (
                                <tr key={val._id}
                                    className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}
                                >
                                    <td className="w-[5%] px-4 py-3 text-center ">{index + 1}</td>
                                    <td className="w-[55%] px-4 py-3 truncate text-left">{val.Message}</td>
                                    <td className="w-[20%] px-4 py-3 text-left">{val.Action}</td>
                                    <td className="w-[10%] px-4 py-3 text-left">{getDateTimeParts(val.Time).date}</td>
                                    <td className="w-[10%] px-4 py-3 text-left">{getDateTimeParts(val.Time).time}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AuditTrail