// dateFormatter.js

export function formatDate(dateStr) {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const date = new Date(dateStr);
    const day = ("0" + date.getDate()).slice(-2);
    const monthIndex = date.getMonth();
    const month = months[monthIndex];
    const year = date.getFullYear(-2);
  
    if(dateStr){
      return `${day}-${month}-${year}`;
    }
    else{
      return "No Date"
    }

  }
  