import React from 'react'
import { useSelector } from 'react-redux';
import Complaints from '../Admin/compoonents/Complaints';
import Courses from '../Admin/compoonents/Course';
import Program from '../Admin/compoonents/Program';
import Link from '../Admin/compoonents/Link';
import Session from '../Admin/compoonents/Session';
import Class from '../Admin/compoonents/Class';
import Student from '../Admin/compoonents/Student';
import Faculty from '../Admin/compoonents/Faculty';
import Dashboard from "../ExecutiveAdmin/components/Dashboard"

const DefaultLayout = ({ menuToggle }) => {
    const selectedMenuBtn = useSelector(state => state.selectedMenuBtn);

    return (
        <div className={`text-white  bg-transparent transitionAll 
        5xl::rounded-r-[0.8vw] 5xl:h-full 5xl:overflow-hidden
        rounded-r-[0.8vw] h-full
        ${menuToggle
                ? `5xl:w-[82vw]
                   4md:w-[82vw]
                   w-[0vw]`
                : `w-[100vw]`
            }`}
        >
            {selectedMenuBtn === "Dashboard" ? <Dashboard /> :
                  selectedMenuBtn === "Department" ? <Complaints /> :
                  selectedMenuBtn === "Courses" ? <Courses /> :
                  selectedMenuBtn === "Program" ? <Program /> :
                      selectedMenuBtn === "LinkProgCourses" ? <Link /> :
                          selectedMenuBtn === "Session" ? <Session /> :
                              selectedMenuBtn === "Class" ? <Class /> :
                                  selectedMenuBtn === "Student" ? <Student /> :
                                      selectedMenuBtn === "Faculty" ? <Faculty /> :
                <></>}
        </div>
    )
}

export default DefaultLayout