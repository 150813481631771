import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import config from "../../../../config";
import Textarea from "react-expanding-textarea";
import Select from 'react-select';
import { useSelector } from 'react-redux';
import Apis from "../../../../Apis";

const AddUpdateDept = ({ updateDept, setUpdateDept }) => {

  const userInfo = useSelector(state => state.userInfo);
  const [allExcutiveAdminInfo, setAllExcutiveAdminInfo] = useState([]);
  const [allDepartmentalAdminInfo, setAllDepartmentalAdminInfo] = useState([])

  const [formdata, setFormData] = useState({
    name: "",
    code: "",
    status: true,
    address: "",
    email: "",
    contactNo: "",
    website: "",
    gps: "",
    refArray: [],
  });

  const fetchAllExcutiveAdmin = async () => {
    try {
      const AllExcutiveAdmin = await Apis.getAllExcutiveAdmin("");
      setAllExcutiveAdminInfo(AllExcutiveAdmin);
    } catch (error) {
      console.error('Error fetching All Excutive Admin:', error.message);
      toast.error('Error fetching All Excutive Admin:', error.message);
    }
  };
  const fetchAllExcutiveManagement = async () => {
    let userID;
    if (userInfo.role === 'admin') {
      userID = 'admin'
    }
    else {
      userID = userInfo._id
    }
    try {
      const AllExcutiveManagement = await Apis.getAllExcutiveManagement("", userID);
      setAllDepartmentalAdminInfo(AllExcutiveManagement);
    } catch (error) {
      console.error('Error fetching All Excutive Management:', error.message);
      toast.error('Error fetching All Excutive Management:', error.message);
    }
  };

  useEffect(() => {
    if(userInfo.role){
      fetchAllExcutiveAdmin();
      fetchAllExcutiveManagement();
    }
  }, [updateDept,userInfo]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formdata,
      [name]: value,
    });
  };

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formdata,
      [name]: checked,
    });
  };

  const [editingId, setEditingId] = useState(null);
  const backendBaseUrl = config.backendLocalhost;

  const resetField = () => {
    setFormData({
      name: "",
      code: "",
      status: true,
      address: "",
      email: "",
      contactNo: "",
      website: "",
      gps: "",
      refArray: [],
    });
    setUpdateDept({
      _id: null,
      department: "",
      deptCode: "",
      status: true,
      address: "",
      email: "",
      contactNo: "",
      website: "",
      gps: "",
      refArray: [],
    });
  };

  const generateDeptCode = (name) => {
    if (!updateDept._id) {
      const deptInitials = name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("");
      const generatedCode = "Dept-" + deptInitials;
      setFormData({
        ...formdata,
        code: generatedCode,
      });
    }
  };

  useEffect(() => {
    generateDeptCode(formdata.name);
  }, [formdata.name]);

  const handleAddDepartment = async () => {
    if (!formdata.name && formdata.name === "") {
      toast.error("Department name can't be empty");
      return;
    }
    if (!formdata.code && formdata.code === "") {
      toast.error("Department code can't be empty");
      return;
    }
    const loadingToast = toast.loading("Loading...");

    const phoneRegex = /^\d{11}$/;
    if (formdata.contactNo && !phoneRegex.test(formdata.contactNo.replace(/-/g, ""))) {
      toast.error(
        "Invalid phone number format. Please enter a valid 10-digit phone number."
      );
      return;
    }
    try {
      const response = await axios.post(
        `${backendBaseUrl}/department/addDepartments`,
        {
          department: formdata.name,
          deptCode: formdata.code,
          status: formdata.status,
          contact: formdata.contactNo,
          email: formdata.email,
          website: formdata.website,
          location: formdata.gps,
          address: formdata.address,
          refArray: formdata.refArray,
          userID: userInfo._id,
        }
      );
      resetField();
      toast.dismiss(loadingToast);
      toast.success(`Department code: ${formdata.code} added successfully`);
    } catch (error) {
      console.error("Error adding department:", error);
      toast.dismiss(loadingToast);
      toast.error(error.response.data.error);
    }
  };

  useEffect(() => {
    if (updateDept._id && updateDept) {
      setEditingId(updateDept?._id);
      setFormData({
        name: updateDept?.department,
        code: updateDept?.deptCode,
        status: updateDept?.status,
        address: updateDept?.address,
        email: updateDept?.email,
        contactNo: updateDept?.contact,
        website: updateDept?.website,
        gps: updateDept?.location,
        refArray: updateDept?.refArray,
      });
    }
  }, [updateDept, updateDept?._id]);

  const handleUpdateDepartment = async () => {
    if (!formdata.name && formdata.name === "") {
      toast.error("Department name can't be empty");
      return;
    }
    const loadingToast = toast.loading("Loading...");
    try {
      const response = await axios.put(
        `${backendBaseUrl}/department/Department/${editingId}`,
        {
          department: formdata.name,
          status: formdata.status,
          contact: formdata.contactNo,
          email: formdata.email,
          website: formdata.website,
          location: formdata.gps,
          address: formdata.address,
          refArray: formdata.refArray,
        }
      );
      resetField();
      toast.dismiss(loadingToast);
      toast.success(`Department code: ${formdata.code} updated successfully`);
    } catch (error) {
      console.error("Error updating department:", error.message);
      toast.dismiss(loadingToast);
      toast.error(error.response.data.error);
    }
  };

  return (
    <div className='flex flex-col items-center justify-start w-full h-auto overflow-y-auto lg:h-full lg:overflow-y-auto lg:style-scrollbar style-scrollbar'>
      <form className='w-full bg-gray-300 flex flex-col items-start justify-center lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh] text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh]'>
        <div className="w-full flex flex-col justify-center items-center lg:gap-y-[1vh] gap-y-[1vh]">
          <div className="w-full flex justify-start lg:gap-x-[1vw] lg:flex-row lg:items-center gap-x-[1vw] flex-col gap-y-[1vh] items-start">
            <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[65%] gap-y-[0.5vh] text-sm w-full">
              <label htmlFor='deptName' className='font-semibold'>Department Name:</label>
              <input
                type="text"
                placeholder="Write department name"
                className="w-full font-normal input outline-PrimaryColor"
                id="name"
                name="name"
                value={formdata.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[25%] gap-y-[0.5vh] text-sm w-full">
              <label htmlFor="deptCode" className="font-semibold">Department Code:</label>
              <input
                type="text"
                placeholder="Code will auto-Generated"
                className="w-full input outline-PrimaryColor font-noraml"
                id="code"
                name="code"
                value={formdata.code}
                onChange={handleInputChange}
                disabled={editingId !== null}
              />
            </div>
            <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[10%] lg:px-[0vw] gap-y-[0.5vh] text-sm w-full px-[6vw]">
              <label htmlFor='deptStatus' className='mb-0 font-semibold lg:mb-2'>Status:</label>
              <div className='flex gap-x-[0.5vw] justify-center items-center'>
                <input
                  className="checkbox"
                  type="checkbox"
                  id="status"
                  name="status"
                  checked={formdata.status}
                  onChange={handleCheckBoxChange}
                />
                <label className={`text-center ${formdata.status ? "text-green-600" : "text-red-600"}`}>
                  {formdata.status ? "Active" : "In-Active"}
                </label>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-start lg:gap-x-[1vw] lg:flex-row lg:items-center gap-x-[1vw] flex-col gap-y-[1vh] items-start">
            <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[50%] gap-y-[0.5vh] text-sm w-full">
              <label htmlFor='deptContactNo' className='font-semibold'>Contact No:</label>
              <input
                type="text"
                placeholder="Enter your contact no."
                className="w-full input outline-PrimaryColor font-noraml"
                id="contactNo"
                name="contactNo"
                value={formdata.contactNo}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[50%] gap-y-[0.5vh] text-sm w-full">
              <label htmlFor="deptEmail" className="font-semibold">Email:</label>
              <input
                type="text"
                placeholder="Enter your email adress"
                className="w-full input outline-PrimaryColor font-noraml"
                id="email"
                name="email"
                value={formdata.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="w-full flex justify-start lg:gap-x-[1vw] lg:flex-row lg:items-center gap-x-[1vw] flex-col gap-y-[1vh] items-start">
            <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[50%] gap-y-[0.5vh] text-sm w-full">
              <label htmlFor="deptWebsiteLink" className="font-semibold">Website Link:</label>
              <input
                type="text"
                placeholder="Enter your website link"
                className="w-full input outline-PrimaryColor font-noraml"
                id="website"
                name="website"
                value={formdata.website}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[50%] gap-y-[0.5vh] text-sm w-full">
              <label htmlFor="deptLocationLink" className="font-semibold">Location Link:</label>
              <input
                type="text"
                placeholder="Link of your location"
                className="w-full input outline-PrimaryColor font-noraml"
                id="gps"
                name="gps"
                value={formdata.gps}
                onChange={handleInputChange}
              />
            </div>
            {userInfo.role === "admin" &&
              <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[50%] gap-y-[0.5vh] text-sm w-full">
                <label htmlFor="deptWebsiteLink" className="font-semibold">Assign Department to Executive Admins:</label>
                <Select
                  className="h-full input select-primary min-w-full bg-white p-1"
                  id="refArray"
                  name="refArray"
                  value={formdata.refArray?.map(id => ({
                    value: id,
                    label:  allExcutiveAdminInfo.find(admin => admin._id === id)?.firstName || allDepartmentalAdminInfo.find(admin => admin._id === id)?.firstName || '***'
                  }))}
                  onChange={(selectedOptions) => {
                    setFormData({
                      ...formdata,
                      refArray: selectedOptions.map(option => option.value)
                    });
                  }}
                  placeholder="Select multiple Executive Admins"
                  options={allExcutiveAdminInfo.map(admin => ({
                    value: admin._id,
                    label: `${admin.firstName} ${admin.lastName}`
                  }))}
                  isMulti
                />
              </div>
            }
            {/* {userInfo.role === "ExcutiveAdmin" &&
              <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm lg:w-[50%] gap-y-[0.5vh] text-sm w-full">
                <label htmlFor="deptWebsiteLink" className="font-semibold">Assign Department to Departmental Admins:</label>
                <Select
                  className="h-full input select-primary min-w-full bg-white p-1"
                  id="refArray"
                  name="refArray"
                  value={formdata.refArray?.map(id => ({
                    value: id,
                    label: allDepartmentalAdminInfo.find(admin => admin._id === id)?.firstName || allDepartmentalAdminInfo.find(admin => admin._id === id)?.firstName || ''
                  }))}
                  onChange={(selectedOptions) => {
                    setFormData({
                      ...formdata,
                      refArray: selectedOptions.map(option => option.value)
                    });
                  }}
                  placeholder="Select multiple Departmental Admins"
                  options={allDepartmentalAdminInfo.map(admin => ({
                    value: admin._id,
                    label: `${admin.firstName} ${admin.lastName}`
                  }))}
                  isMulti
                />

              </div>
            } */}
          </div>
          <div className="w-full flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm gap-y-[0.5vh] text-sm">
            <label htmlFor="deptAddress" className="font-semibold">Address:</label>
            <Textarea
              type="text"
              placeholder="Write full address.."
              className="input w-full outline-PrimaryColor font-noraml lg:py-[1vh] py-[1vh]"
              id="address"
              name="address"
              value={formdata.address}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <button
          className="bg-PrimaryColor text-white font-bold lg:px-[1vw] lg:py-[1vh] lg:rounded-[0.5vw] lg:text-md px-[4vw] py-[1.5vh] rounded-[1vw] text-sm"
          type="button"
          onClick={editingId !== null ? handleUpdateDepartment : handleAddDepartment}
        >
          {editingId !== null ? "Update Department" : "Add Department"}
        </button>
      </form>
    </div>

  );
};

export default AddUpdateDept;
