import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { MessageByName } from '../../../FacultyComp/Dashboard/Modals/AddNotification';

const Add = ({ setUpdateExcutiveManagement, updateExcutiveManagement, handleButtonClick }) => {

    const [showPassword, setShowPassword] = useState(false);
    const ResponseMessages = useSelector(state => state.responseMessage);
    const departments = useSelector(state => state.allDept);
    const userInfo = useSelector(state => state.userInfo);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNo: '',
        cnic: '',
        email: '',
        password: '',
        department: '',
        validity: true,
        role: 'ExcutiveManagement',
        refArray: [],
    });

    const [editingId, setEditingId] = useState(null);
    const backendBaseUrl = config.backendLocalhost;


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleCheckBoxChange = (event) => {
        const { name, checked } = event.target;
        setFormData({
            ...formData,
            [name]: checked
        });
    };

    const resetField = () => {
        setEditingId(null);
        setFormData({
            firstName: '',
            lastName: '',
            phoneNo: '',
            cnic: '',
            email: '',
            password: '',
            department: '',
            validity: true,
            role: 'ExcutiveManagement',
            refArray: [],
        })
        setUpdateExcutiveManagement([]);
    };


    const handleAddExcutiveManagement = async (e) => {
        e.preventDefault();

        const cnicRegex = /^\d{13}$/;
        if (!cnicRegex.test(formData.cnic)) {
            toast.error('Invalid CNIC format. Please enter a valid CNIC number.');
            return;
        }
        const phoneRegex = /^\d{11}$/;
        if (!phoneRegex.test(formData.phoneNo.replace(/-/g, ''))) {
            toast.error('Invalid phone number format. Please enter a valid 10-digit phone number.');
            return;
        }
        setFormData({
            ...formData,
            userID: userInfo._id,
          });
        try {
            const response = await axios.post(`${backendBaseUrl}/api/users`, formData);
            toast.success(response.data.message);
            resetField();
            handleButtonClick('view');
        } catch (error) {
            const ResponseMsg = MessageByName(error.response?.data?.message, ResponseMessages);
            toast.error(ResponseMsg);
            //toast.error(`API Error: ${error.response?.data?.message || 'Server not responding'}`);
            console.error('API Error:', error.response ? error.response.data : error.message);
        }
    };


    useEffect(() => {
        if (updateExcutiveManagement && updateExcutiveManagement.length !== 0) {
            setEditingId(updateExcutiveManagement._id);
            setFormData({
                firstName: updateExcutiveManagement.firstName,
                lastName: updateExcutiveManagement.lastName,
                phoneNo: updateExcutiveManagement.phoneNo,
                cnic: updateExcutiveManagement.cnic,
                email: updateExcutiveManagement.email,
                validity: updateExcutiveManagement.validity,
                department: updateExcutiveManagement.department,
                password: '',
                role: 'ExcutiveManagement',
                refArray: updateExcutiveManagement.refArray,
            })
        }
    }, [updateExcutiveManagement]);

    const handleUpdateExcutiveManagement = async () => {
        try {
            const response = await axios.put(`${backendBaseUrl}/api/users/update/${updateExcutiveManagement._id}`, formData);
            toast.success(response.data.message);
            resetField();
            handleButtonClick('view');
        } catch (error) {
            const ResponseMsg = MessageByName(error.response?.data?.message, ResponseMessages);
            toast.error(ResponseMsg);
            console.error('API Error:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className='h-full w-full flex flex-col items-center justify-start overflow-y-auto style-scrollbar'>
            <form className='w-full rounded-[1vw] bg-SecondaryColor px-[2vw] py-[4vh] flex flex-col items-start justify-center gap-y-[2vh]
    text-black text-sm shadow-2xl
    '>

                <div className='w-full flex flex-col justify-center items-start gap-y-[1vh]'>
                    <div className='w-full flex items-center justify-start gap-x-[1vw]'>
                        <div className='w-[45%] flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='deptCode' className='font-semibold'>First Name:</label>
                            <input
                                type="text"
                                placeholder="Enter your First Name"
                                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                                id='firstName'
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-[45%] flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='deptCode' className='font-semibold'>Last Name:</label>
                            <input
                                type="text"
                                placeholder="Enter your Last Name"
                                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                                id='lastName'
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-[5%] flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='validity' className='text-black font-semibold'>Status:</label>
                            <div className='flex gap-x-[0.5vw] justify-center items-center'>
                                <input
                                    className='checkbox'
                                    type="checkbox"
                                    id='validity'
                                    name="validity"
                                    checked={formData.validity}
                                    onChange={handleCheckBoxChange}
                                />
                                <label className={`text-center ${formData.validity ? "text-green-600" : "text-red-600"}`}> {formData.validity ? "Valid" : "In-Valid"} </label></div>
                        </div>
                    </div>
                    <div className='w-full flex items-center justify-start gap-x-[1vw]'>
                        <div className='w-[45%] flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='phoneNo' className='font-semibold'>Phone No:</label>
                            <input
                                type="text"
                                placeholder="Enter your Phone No"
                                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                                id='phoneNo'
                                name="phoneNo"
                                value={formData.phoneNo}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-[45%] flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='cnic' className='font-semibold'>CNIC No:</label>
                            <input
                                type="text"
                                placeholder="Enter your CNIC without dashes"
                                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                                id='cnic'
                                name="cnic"
                                value={formData.cnic}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className='w-full flex items-center justify-start gap-x-[1vw]'>
                        <div className='w-[45%] flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='deptCode' className='font-semibold'>Email address:</label>
                            <input
                                type="text"
                                placeholder="Email address will used as username"
                                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                                id='email'
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-[45%] flex flex-col gap-y-[0.5vh] justify-center items-start'>
                            <label htmlFor='deptCode' className='font-semibold'>Password:</label>
                            <div className="w-full relative">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder={`${updateExcutiveManagement._id ? "Enter new Password" : "Enter your Password"}`}
                                    className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                                    id='password'
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
                                    onClick={handleTogglePasswordVisibility}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-y-[0.5vh] justify-center items-start'>
                        <label htmlFor='deptCode' className='font-semibold'>Select Department :</label>
                        <select className="select select-primary w-full max-w-xs  bg-white"
                        name='department'
                        value={formData.department}
                        onChange={handleInputChange}
                            >
                            <option selected disabled value="">
                                Select a Department
                            </option>
                            {departments.map((dept) => (
                                <option className='text-black' key={dept.deptCode} value={dept.deptCode}>
                                    {`${dept.department}-(${dept.deptCode})`}
                                </option>

                            ))}
                        </select>
                    </div>
                </div>

                <button
                    className='px-[1vw] py-[1vh] bg-PrimaryColor text-white rounded-[0.5vw] font-bold'
                    type='button'
                    onClick={editingId !== null ? handleUpdateExcutiveManagement : handleAddExcutiveManagement}
                >
                    {editingId !== null ? 'Update Excutive Management' : 'Add Excutive Management'}
                </button>

            </form>
        </div>
    );
};

export default Add