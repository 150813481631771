import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"

function EditButton() {
  return (
    <div className={`flex justify-center items-center bg-[#6ECB32]
    5xl:w-[4vh] 5xl:h-[3vh] 5xl:rounded-[0.3vw] 5xl:text-sm
    4md:p-[0.75vh] 4md:rounded-[0.3vw]  4md:text-sm
    p-[1vh] rounded-[0.7vw] text-lg`}> 
      <FontAwesomeIcon icon={faPenToSquare} style={{ color: "#fafcff", }} />
    </div>
  )
}

export default EditButton