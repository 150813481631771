import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, requiredRole }) => {
  const currentRole = localStorage.getItem("role");
  
  if (!currentRole) {
    return <Navigate to="/" />;
  }

  if (requiredRole.includes(currentRole)) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
