import React, { useState,useEffect } from 'react'
import { getDateTimeParts } from "../../../AuditTrail"


const IndividualTrail = ({ individualAudit, searchDepartment,setSearchDepartment ,selectedStatus}) => {

    const Detail = individualAudit.Trail;
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [filteredDetail, setFilteredDetail] = useState([]);

    useEffect(() => {
        filterDetail();
      }, [fromDate, toDate,searchDepartment]);

      useEffect(() => {
        setSearchDepartment("")
    }, [selectedStatus]);
    
      const filterDetail = () => {
        let filteredData = Detail;
    
        if (fromDate || toDate) {
          filteredData = filteredData.filter(item => {
            if (fromDate && toDate) {
              return item.Time >= fromDate && item.Time <= toDate;
            } else if (fromDate) {
              return item.Time >= fromDate;
            } else if (toDate) {
              return item.Time <= toDate;
            }
          });
        }
    
        if (searchDepartment) {
            const searchTerm = searchDepartment.toLowerCase(); 
            filteredData = filteredData.filter(item => item.Action.toLowerCase().includes(searchTerm));
          }        
    
        setFilteredDetail(filteredData);
      };

    return (
        <div className='h-full w-full flex flex-col gap-y-2 py-2'>
            <div className='w-full flex justify-between items-center bg-PrimaryDarkColor rounded-3xl px-6 py-2'>
                <p className='text-xl text-white text-bold tracking-wider capitalize'>{`Detail Audit Trail for ${individualAudit.UserName}`}</p>
                <div className='flex gap-x-2 mt-6'>
                    <div className="relative">
                        <label htmlFor="fromDate" className="w-full absolute  py-1 px-3 -mt-6 rounded-t-2xl bg-PrimaryColor text-white font-semibold text-left">From Date:</label>
                        <input
                            type="date"
                            id="fromDate"
                            name="fromDate"
                            value={fromDate}
                            onChange={e => setFromDate(e.target.value)}
                            className="input input-bordered w-full text-black"
                        />
                    </div>
                    <div className="relative">
                    <label htmlFor="fromDate" className="w-full absolute  py-1 px-3 -mt-6 rounded-t-2xl bg-PrimaryColor text-white font-semibold text-left">To Date:</label>
                        <input
                            type="date"
                            id="toDate"
                            name="toDate"
                            value={toDate}
                            onChange={e => setToDate(e.target.value)}
                            className="input input-bordered w-full text-black"
                        />
                    </div>
                </div>

            </div>
            <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
                <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
                    <thead className=''>
                        <tr className='text-sm font-semibold'>
                            <th className="w-[5%] px-4 py-3 text-center border border-gray-400">Sr.</th>
                            <th className="w-[20%] px-4 py-3 text-left border border-gray-400 ">Action</th>
                            <th className="w-[57%] px-4 py-3 text-left border border-gray-400 ">Message</th>
                            <th className="w-[18%] px-4 py-3 text-left border border-gray-400 ">Date - Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDetail && filteredDetail.map((val, index) => (
                            <tr key={val._id}
                                className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}
                            >
                                <td className="px-4 py-3 text-center ">{index + 1}</td>
                                <td className="px-4 py-3 truncate text-left">{val.Action}</td>
                                <td className="px-4 py-3 truncate text-left">{val.Message}</td>
                                <td className="px-4 py-3 text-left">{`${getDateTimeParts(val.Time).date} - ${getDateTimeParts(val.Time).time}`}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default IndividualTrail