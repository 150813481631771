import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const GradingAssign = ({ result }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: 'bar',
        height: 250
      },
      xaxis: {
        categories: []
      }
    },
    series: [{
      name: 'Count',
      data: []
    }]
  });

  useEffect(() => {
    const countOccurrences = (arr) => {
      return arr.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {});
    };

    const resultCounts = countOccurrences(result);
    const categories = Object.keys(resultCounts);
    const data = Object.values(resultCounts);

    setChartData({
      options: {
        chart: {
          type: 'bar',
          height: 250
        },
        xaxis: {
          categories: categories
        }
      },
      series: [{
        name: 'Count',
        data: data
      }]
    });
  }, [result]);

  return (
    <div className="chart-container">
      <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height="250" />
    </div>
  );
};

export default GradingAssign;
