// reducers/FacultyActivityListToggle.js
import { SET_ALLFaculty_INFO } from '../action-creators/ActionTypes';

const AllFacultyInfo = (state = [], action) => {
    switch (action.type) {
        case SET_ALLFaculty_INFO:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllFacultyInfo;
