import React, { useState } from 'react';
import Text from './Text';
import Option from './Option';
import Numeric from './Numeric';
import MultipleOption from './MultipleOption';
import MultiSelection from './MultiSelection';

const AddTemplate = () => {
  const [localFormData, setLocalFormData] = useState({
    templateName: "",
    templateType: "",
  });

  return (
    <div className="h-full flex flex-col gap-y-[0.5vh]">
      <div className="w-full grid justify-center items-center gap-x-[1vw] gap-y-[1vh] 
        lg:grid-cols-2
        grid-cols-1">
        <div className="flex flex-col w-full">
          <label className="block text-xl text-black">Template Name:</label>
          <input
            className="w-full text-black input input-primary"
            type="text"
            name="templateName"
            value={localFormData.templateName || ''}
            onChange={(e) => setLocalFormData({
              ...localFormData,
              templateName: e.target.value,
            })}
          />
        </div>

        <div className="flex flex-col w-full">
          <label className="block text-xl text-black">Template Type:</label>
          <select
            className="w-full text-black select select-primary"
            name="templateType"
            value={localFormData.templateType || ''}
            onChange={(e) => setLocalFormData({
              ...localFormData,
              templateType: e.target.value,
            })}
          >
            <option value="" disabled>
            Select Survey Template from drop-down list		
            </option>
        <option value="singleSelection">Single Selection option for all Question</option>
        <option value="multipleSelection">Multiple Selection option for all Question</option>
        <option value="textOption">Text Option for all Questions</option>
        <option value="gradingOption">Grading Option for all Questions</option>
        <option value="differentOption">Different Option for each Question</option>
          </select>
        </div>
      </div>

      <div className="flex flex-col w-full h-auto overflow-x-auto bg-gray-300 border border-gray-900 rounded-b-lg style-scrollbar lg:max-h-full lg:overflow-auto max-h-auto">
        {localFormData.templateType === 'singleSelection' && <FixedOptionsComponent setLocalFormData={setLocalFormData} localFormData={localFormData} />}
        {localFormData.templateType === 'multipleSelection' && <MultiSelectionComponent setLocalFormData={setLocalFormData} localFormData={localFormData}/>}
        {localFormData.templateType === 'textOption' && <TextOptionsComponent setLocalFormData={setLocalFormData} localFormData={localFormData}/>}
        {localFormData.templateType === 'gradingOption' && <NumberOptionsComponent setLocalFormData={setLocalFormData} localFormData={localFormData}/>}
        {localFormData.templateType === 'differentOption' && <MultipleOptionsComponent setLocalFormData={setLocalFormData} localFormData={localFormData}/>}
      </div>
    </div>
  );
};
const FixedOptionsComponent = ({ setLocalFormData, localFormData }) => <div><Option localFormData={localFormData} setLocalFormData={setLocalFormData}/></div>;
const MultiSelectionComponent = ({ setLocalFormData, localFormData }) => <div><MultiSelection localFormData={localFormData} setLocalFormData={setLocalFormData}/></div>;
const TextOptionsComponent = ({ setLocalFormData, localFormData }) => <div><Text localFormData={localFormData} setLocalFormData={setLocalFormData}/></div>;
const NumberOptionsComponent = ({ setLocalFormData, localFormData }) => <div><Numeric localFormData={localFormData} setLocalFormData={setLocalFormData}/></div>;
const MultipleOptionsComponent = ({ setLocalFormData, localFormData }) => <div><MultipleOption localFormData={localFormData} setLocalFormData={setLocalFormData}/></div>;

export default AddTemplate;
