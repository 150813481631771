import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';
import { useSelector } from 'react-redux';

const semester = [
  "Semester 1",
  "Semester 2",
  "Semester 3",
  "Semester 4",
  "Semester 5",
  "Semester 6",
  "Semester 7",
  "Semester 8",
]

const AddUpdateLink = ({ updateData, setupdateData }) => {
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedProgram, setSelectedProgram] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('Semester 1');
  const [selectedCourses, setSelectedCourses] = useState({});
  const [editingId, setEditingId] = useState(null);
  const backendBaseUrl = config.backendLocalhost;

  const [filterCourses, setFilterCourses] = useState([]);
  const departments = useSelector(state => state.allDept);
  const program = useSelector(state => state.allProgram);
  const courses = useSelector(state => state.allCourses);

  const [programList, setProgramList] = useState([]);

  const resetField = () => {
    setEditingId(null);
    setSelectedDepartment('');
    setSelectedProgram('')
    setSelectedCourses([])
  };

  const handleLnkProgram = async () => {
    try {
      const response = await axios.put(`${backendBaseUrl}/program/updateProgram`, {
        selectedProgram,
        selectedCourses,
      });
      resetField();
      toast.success(`Program code: ${selectedProgram} Linked successfully`);
    } catch (error) {
      console.error('Error Linking Program:', error.message);
      toast.error('Error Linking Program:', error.message);
    }
  };

  useEffect(() => {
    const filteredCourses = courses.filter(course => course.departmentcode === selectedDepartment && course.status);
    const updatedFilterCourses = filteredCourses.map(course => ({
      ...course,
      selected: selectedCourses[selectedSemester]?.includes(course.courseCode) || false
    }));
    setFilterCourses(updatedFilterCourses);
  }, [courses, selectedDepartment, selectedCourses, selectedSemester]);
  
  const handleCheckboxChange = ({ target }) => {
    const { value, checked } = target;
  
    setSelectedCourses(prevSelectedCourses => {
      const updatedSelectedCourses = { ...prevSelectedCourses };
      const semesterCourses = updatedSelectedCourses[selectedSemester] || [];
  
      if (checked) {
        updatedSelectedCourses[selectedSemester] = [...semesterCourses, value];
      } else {
        updatedSelectedCourses[selectedSemester] = semesterCourses.filter(courseCode => courseCode !== value);
      }
  
      return updatedSelectedCourses;
    });
  
    setFilterCourses(prevCourses => {
      return prevCourses.map(course => {
        if (course.courseCode === value) {
          return { ...course, selected: checked };
        }
        return course;
      });
    });
  };

  useEffect(() => {
    if (updateData.length !== 0 && updateData) {
      setEditingId(updateData?._id);
      setSelectedProgram(updateData?.programCode)
      setSelectedCourses(updateData?.programCourses)
    }
  }, [updateData]);

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await axios.get(`${backendBaseUrl}/program/getunLinkedProgram`)
        if (!updateData._id) {
          setProgramList(response.data);
        }
        else {
          setProgramList(program);
        }
      } catch (error) {
        console.error('Error fetching Program:', error.message);
      }
    };
    fetchProgram();
  }, [backendBaseUrl, updateData._id]);


  return (
    <div className='flex flex-col items-center justify-start w-full h-auto overflow-y-auto lg:overflow-y-auto lg:style-scrollbar style-scrollbar'>
    <form className='w-full bg-gray-300 flex flex-col items-start justify-center lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh] text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh]'>
      <div className="w-full grid justify-center items-center gap-x-[1vw] gap-y-[1vh] 
      lg:grid-cols-2
      grid-cols-1">
        
            <div className='w-full flex flex-col gap-y-[0.5vh]'>
              <label htmlFor='progCode' className='font-semibold'>Select Program :</label>
              <select className="w-full bg-white select select-primary"
                value={selectedProgram}
                onChange={(e) => setSelectedProgram(e.target.value)}
                disabled={updateData?._id ? true : false}
              >
                <option selected disabled value="">
                  Select a Program
                </option>
                {programList.map((prog) => (
                  <option className='text-black' key={prog.programCode} value={prog.programCode}>
                    {`${prog.programName}-${prog.programCode}`}
                  </option>

                ))}
              </select>
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh]'>
              <label htmlFor='deptCode' className='font-semibold'>Select Department :</label>
              <select className="w-full bg-white select select-primary"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}>

                <option selected disabled value="">
                  Select a Department
                </option>
                {departments.map((dept) => (
                  <option className='text-black' key={dept.deptCode} value={dept.deptCode}>
                    {`${dept.department} (${dept.deptCode})`}
                  </option>

                ))}
              </select>
            </div>
            <div className='w-full flex flex-col gap-y-[0.5vh]'>
              <label htmlFor='semester' className='font-semibold'>Select semester :</label>
              <select className="w-full bg-white select select-primary"
                value={selectedSemester}
                onChange={(e) => setSelectedSemester(e.target.value)}
              >
                {semester.map((prog) => (
                  <option className='text-black' key={prog} value={prog}>
                    {prog}
                  </option>

                ))}
              </select>
            </div>
          <label htmlFor='deptCode' className='mb-2 font-semibold'>Select Department to see the courses</label>
          <div className='w-full grid grid-cols-3 flex-col gap-y-[0.5vh] justify-center items-center'>
      {filterCourses.length === 0 ? (
        <label className={`text-sm text-black text-semibold tracking-wide text-left ${selectedDepartment === "" ? "hidden" : ""}`}>No courses related to <span className='font-bold text-red-600'>{selectedDepartment}</span> Department</label>
      ) : (
        filterCourses.map(course => (
          <div key={course.courseCode} className="flex items-center justify-start">
            <input
              type="checkbox"
              id={course.courseCode}
              name={`courseCheckbox-${course.courseCode}`}
              value={course.courseCode}
              checked={course.selected}
              onChange={handleCheckboxChange}
              className="mr-2 bg-white checkbox"
            />
            <label htmlFor={course.courseCode} className='text-center'>{`${course.courseName}  [${course.courseCode}]`}</label>
          </div>
        ))
      )}
    </div>
        </div>
        <button
          className='px-[1vw] py-[1vh] bg-PrimaryColor text-white rounded-[0.5vw] font-bold'
          type='button'
          onClick={handleLnkProgram}
        >
          {editingId !== null ? 'Update Programs linking with courses' : 'Link Programs with courses'}
        </button>
      </form>
    </div>
  );
};

export default AddUpdateLink