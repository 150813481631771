import React, { useState } from 'react';
import axios from 'axios';
import config from '../../../../config';
import studentFile from "./FormatStudentFile.csv"
import { toast } from 'react-hot-toast';

const Importcsv = ({ userRole }) => {
    const backendBaseUrl = config.backendLocalhost;
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.csv')) {
            setSelectedFile(file);
            setError('');
        } else {
            setSelectedFile(null);
            setError('Please select a valid CSV file.');
        }
    };

    const ResetFunc = () => {
        setSelectedFile(null)
        setError('')
    }

    const handleDownload = async () => {
        const response = await fetch(studentFile);
        const blob = await response.blob();
        const fileName = "FormatFile.csv";
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const handleUpload = async () => {
        const loader = toast.loading('Uploading file...');
        try {
            const formData = new FormData();
            formData.append('deptCSV', selectedFile);
            formData.append('userRole', userRole);
            const response = await axios.post(`${backendBaseUrl}/api/users/importUser`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success(response.data.message);
            ResetFunc();
        } catch (error) {
            console.error('Error uploading file:', error);
            if (error.response && error.response.data) {
                console.log('Error response data:', error.response.data);
                if (error.response.data.errors) {
                    const errorMessages = error.response.data.errors.map(error => error).join('\n');
                    alert(`Upload failed:\n${errorMessages}`);
                } else if (error.response.data.error) {
                    alert(`Upload failed: ${error.response.data.error}`);
                } else {
                    alert('An unexpected error occurred.');
                }
            } else {
                alert('An unexpected error occurred.');
            }
        } finally {
            toast.dismiss(loader);
        }
    };

    return (
        <div className='h-full w-full flex flex-col p-[1vw] gap-y-[1vh] bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full 5xl::overflow-auto max-h-auto overflow-x-auto'>
            <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center
        5xl:w-[25%]
        4md:w-[25%]
        w-full"
                onClick={handleDownload}
            >
                <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                </svg>
                <span>Download Formatted file</span>
            </button>
            <div className='flex flex-col w-full
      5xl:px-[20%]
      4md:px-[20%]
      px-[1vw]'>
                <div className="flex items-center justify-center">
                    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                            </svg>
                            {selectedFile ? (
                                <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">{selectedFile.name}</span> - {selectedFile.size} bytes</p>
                                </div>
                            ) : (
                                <div>
                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p className="text-xs text-gray-500 dark:text-gray-400 text-center">.csv format only.</p>
                                </div>
                            )}
                            {error && <p className="text-xs text-red-500 dark:text-red-400">{error}</p>}
                        </div>
                        <input id="dropzone-file" type="file" className="hidden"
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                    </label>
                </div>
                <button
                    onClick={handleUpload}
                    disabled={!selectedFile}
                    className={`bg-SecondaryColor text-black hover:bg-PrimaryDarkColor hover:text-white font-bold py-2 px-4 rounded ${!selectedFile && 'opacity-50 cursor-not-allowed'}`}
                >
                    {selectedFile ? "Upload" : "Select a file"}
                </button>
            </div>
            <div className="collapse bg-PrimaryDarkColor text-white">
                <input type="checkbox" />
                <div className="collapse-title text-xl font-medium">
                    Click to read the Guidlines for preparing file
                </div>
                <div className="collapse-content bg-SecondaryColor text-black text-md p-[1vw] gap-y-[0.5vh] flex flex-col">
                    <p className='text-lg text-bold p-2 text-center'>{"*You can only upload basic Information in bulk*"}</p>
                    <p>firstName: <span className='text-green-700'>Value must be string and <span className='text-red-700 text-bold'>compulsory</span> to add</span></p>
                    <p>lastName:  <span className='text-green-700'>Value must be string and <span className='text-red-700 text-bold'>compulsory</span> to add</span></p>
                    <p>phoneNo:  <span className='text-green-700'>{"Value must be Number,Formatted [11 Digit] and "} <span className='text-red-700 text-bold'>compulsory</span> to add</span></p>
                    <p>cnic:   <span className='text-green-700'>{"Value must be Number,Formatted [13 Digit without dashes] and "} <span className='text-red-700 text-bold'>compulsory</span> to add</span></p>
                    <p>email: <span className='text-green-700'>{"Value must be Number,Unique,Formatted and "}<span className='text-red-700 text-bold'>compulsory</span> to add</span></p>
                    <p>password: <span className='text-green-700'>{"Value must be Number,Formatted and "}<span className='text-red-700 text-bold'>compulsory</span> to add</span></p>
                </div>
            </div>

        </div>
    )
}

export default Importcsv