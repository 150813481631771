import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { Toaster } from 'react-hot-toast';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <StrictMode>
    <BrowserRouter>
    <Provider store ={store}>
    <Toaster/>
    <App/>
    </Provider>
    </BrowserRouter>
  </StrictMode>


);