import React, { useState } from 'react';
import DeleteButton from '../DeleteButton';
import { useSelector } from 'react-redux';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-hot-toast';


const ViewWeeklySchedule = ({ fetchWeeklySchedule }) => {
  const [isLoading, setIsLoading] = useState(false);
  const weeklySchedule = useSelector(state => state.weeklySchedule);
  const backendBaseUrl = config.backendLocalhost;
  const [error, setError] = useState(null);

  const DeleteWeeklySchedule = async (Id) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.delete(`${backendBaseUrl}/schedules/delete/${Id}`);
      toast.success('Schedule deleted successfully!');
      fetchWeeklySchedule();
      toast.dismiss(isLoading)
    } catch (error) {
      console.error(error);
      setError('An error occurred while deleting the schedule');
      toast.error(error.message || error, { autoClose: 3000 });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
      <div className="join join-vertical 
      w-full bg-SecondaryColor px-[2vw] py-[4vh] flex flex-col items-start justify-center gap-y-[2vh]
      text-black text-sm shadow-2xl">
        {weeklySchedule && weeklySchedule.map((schedule, index) => (
          <div className="collapse collapse-arrow join-item border border-PrimaryDarkColor">
            <input type="radio" name="my-accordion-4" />
            <div className="collapse-title text-md font-medium flex justify-between text-PrimaryColor text-bold">
              <label className='w-[85%] text-start'>
                {index + 1} {") "}
                <span className="font-bold text-black">Class:</span> {schedule.class} <span className="ml-[2vw] font-bold text-black">Course:</span> {schedule.course}
              </label>

              <button className={`w-[5%] text-center z-10`} onClick={() => {
                DeleteWeeklySchedule(schedule._id);
              }}>
                <DeleteButton />
              </button>

            </div>
            <div className="collapse-content flex flex-col">
              {schedule.schedules && schedule.schedules.length > 0 ? (
                schedule.schedules.map((scheduleItem, index) => (
                  <div key={index}>
                    {scheduleItem.dayOfWeek ? (
                      <>
                        <p className='text-md font-semibold text-black'>Day:   <span className='text-sm font-medium'>{scheduleItem.dayOfWeek}</span></p>
                        <ul className='flex gap-x-[1vw]'>
                          <p className='text-md font-semibold text-black'>Periods:</p>
                          {scheduleItem.periods && scheduleItem.periods.length > 0 ? (
                            scheduleItem.periods.map((period, periodIndex) => (
                              <li key={periodIndex} className='text-sm font-medium'>{`${periodIndex + 1}) ${period}`}</li>
                            ))
                          ) : (
                            <p>No periods scheduled for this day</p>
                          )}
                        </ul>
                      </>
                    ) : (
                      <p>No Schedule present for this Class and Course</p>
                    )}
                  </div>
                ))
              ) : (
                <p>No Schedule present for this Class and Course</p>
              )}
            </div>


          </div>
        ))}
      </div>
    </div>
  );
};
export default ViewWeeklySchedule