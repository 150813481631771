import React, { useEffect } from 'react';
import ApexCharts from 'apexcharts';

const PieCharts = ({ data }) => {
  useEffect(() => {
    const CoursesNames = data.map(instructor => instructor.course);
    const studCounts = data.map(instructor => instructor.stud);

    var options = {
      series: studCounts,
      chart: {
        width: '100%',
        type: 'pie',
      },
      labels: CoursesNames,
      legend: {
        position: 'bottom',
        horizontalAlign: 'center', // Center align the legend horizontally
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      }],
    };

    var chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();

    // Cleanup on component unmount
    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div className="h-[90%] w-full relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md top-10">
      <h2 className='p-4 text-sm font-bold text-center text-black'>No. of students registered in each course</h2>
      <div className="px-0 pb-0 overflow-auto">
      <div id="chart"></div>
     </div> 
    </div>
  );
};

export default PieCharts;
