/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Avatar from '../../../assets/images/avatar.png';
import { useSelector, useDispatch } from 'react-redux';
import { setUserInfo } from '../../../state/action-creators';
import config from '../../../config';
import Apis from '../../../Apis';
import {MessageByName} from '../../FacultyComp/Dashboard/Modals/AddNotification';
import { toast } from "react-hot-toast";

const Navbar = () => {
  const backendBaseUrl = config.backendLocalhost;
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuLinks = ['Profile','Audit Trail', 'Settings', 'Logout'];
  const dispatch = useDispatch();
  const configName = useSelector(state => state.configName);
  const ResponseMessages = useSelector(state => state.responseMessage);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const userInfo = useSelector(state => state.userInfo);

  const Logout = async (ID) => {
    try {
      const Logout = await Apis.Logout(ID);
      const ResponseMsg = MessageByName(Logout,ResponseMessages);
      toast.success(ResponseMsg);
      Apis.addAuditTrail(userInfo._id,"66339b5bf2d22493c51a5006","");
    } catch (error) {
      console.error("Error! Logout log's can't be login", error);
    }
  };

  const handleMenuClick = (menuItem) => {
    if (menuItem === 'Logout') {
      Logout(userInfo._id);
      localStorage.clear();
      dispatch(setUserInfo([]));
      navigate("/");
    }
    else if (menuItem === 'Profile') {
      navigate(`/${userInfo.role}/profile`);
    }
    else if (menuItem === 'Audit Trail') {
      navigate(`/${userInfo.role}/AuditTrail`);
    }
    toggleDropdown()
  };

  return (
    <div className="bg-SecondaryColor navbar h-[10vh]">
      <div className="flex-1">
        <img src={`${backendBaseUrl}/ConfigurtionFiles/${configName[0]?.nameConfiguration?.Logo}`}
          className="object-cover
                    4xl:w-[4vw] 4xl:h-[9vh]
                    4md:w-[5vw] 4md:h-[8vh]
                    w-[14vw] h-[8vh]"
        />
        <Link
          to={`/${userInfo.role}`}
          className="btn btn-ghost 
                4xl:text-4xl 4xl:block
                4md:text-2xl 4md:block 
                text-lg tracking-tighter hidden
                "> <div className='h-full flex justify-center items-center text-center'>
            {configName[0]?.nameConfiguration.Title}
          </div></Link>
      </div>
      <div className="flex-none">
        <div className="flex flex-col items-end justify-center text-[1vw]">
          <div className="text-base font-semibold 
          4xl:text-lg 
          4md:text-md 
          text-md 
          ">{userInfo.firstName + " " + userInfo.lastName}</div>
          <div className="font-normal text-gray-500 
          4xl:text-lg 
          4md:text-md 
          text-md 
          ">{userInfo.email}</div>
        </div>
        <div className='relative flex items-start justify-center w-auto h-full'>
          <img
            className="cursor-pointer animate rounded-full
            4xl:w-[10vh] 4xl:h-[10vh] 4xl:p-[0.5vw] 4xl:hover:p-[0.3vw]
            4md:w-[12vh] 4md:h-[12vh] 4md:p-[0.5vw] 4md:hover:p-[0.3vw]
            w-[10vh] h-[10vh] p-[0.5vw] hover:p-[0.3vw]
            "
            src={Avatar}
            alt=""
            onClick={toggleDropdown}
          />
        </div>
        {isOpen && (
          <div className={`z-10 absolute text-black bg-gray-100 rounded-lg shadow-2xl shadow-black animate ${isOpen ? "scale-100" : "scale-0"}
          4xl:right-[2vw] 4xl:top-[10vh] 4xl:w-[16vw] 4xl:mt-[0.1vh]
          4md:right-[2vw] 4md:top-[10vh] 4md:w-[14vw] 4md:mt-[0.1vh]
          right-[1vw] top-[10vh] w-[30vw] mt-[0vh]`}>
            <ul className='divide-y divide-slate-400
            4xl:p-[0.5vw] 
            4md:p-[0.5vw]
            p-[0.5vw] '>
              {menuLinks.map((menuItem, index) => (
                <li
                  key={index}
                  className='cursor-pointer hover:bg-gray-200 hover:text-blue-600 hover:underline text-left
                  4xl:p-2 
                  4md:p-2 
                  p-1 
                  '
                  onClick={() => handleMenuClick(menuItem)}
                >
                  {menuItem}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar 