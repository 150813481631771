import React from 'react';

const TextAssign = ({ result }) => {
  return (
    <div className='w-full flex flex-col jusitfy-start items-start gap-y-2 px-4 py-2 bg-white rounded-md'>
      {result && result.map((val, index) => (
        <p key={index} className="text-black text-md">
          {`${index+1}:  ${val}`}
        </p>
      ))}
    </div>

  );
};

export default TextAssign;
