import React, { useState, useEffect } from 'react';
import Img from "../../../assets/images/Onlinelecture.png"
import axios from 'axios';
import { toast } from "react-hot-toast";
import { useSelector, useDispatch } from 'react-redux';
import { setOnlineMeeting } from '../../../state/action-creators';
import config from '../../../config';

const OnlineClass = ({ JoinMeetingHandler }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.userInfo);
  const backendBaseUrl = config.backendLocalhost;
  const [lecturesData, setLecturesData] = useState([]);

  const fetchData = async (classCode) => {
    try {
      const response = await axios.get(`${backendBaseUrl}/onlineLecture/getpendingLecturebyStudID/${classCode}`);
      setLecturesData(response.data)
      dispatch(setOnlineMeeting(response.data));
    } catch (error) {
      console.error('Error fetching Online Lecture:', error);
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred while fetching Online Lecture.');
      }
    }
  };

  useEffect(() => {
    if (userInfo.studentDetails.class) {
      fetchData(userInfo.studentDetails.class);
    }
  }, [userInfo.studentDetails.class]);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = lecturesData.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, lecturesData]);

  // Autoslide
  useEffect(() => {
    const slider = setInterval(() => {
      setIndex((prevIndex) => prevIndex + 1);
    }, 3000);
    return () => clearInterval(slider);
  }, []);

  return (
    <div className='w-full h-full cursor-pointer'
      onClick={() => JoinMeetingHandler(true)}
    >
      <div className='w-full h-[20%] bg-gray-300 flex justify-center items-center rounded-t-xl overflow-hidden px-[1vw]'>
        <p className='w-[90%] text-center xl:text-[1vw] text-black whitespace-nowrap tracking-wide font-semibold'
        >Online Class</p>
        <p className='w-[10%] text-center text-[1vw] text-white whitespace-nowrap rounded-md tracking-wide font-semibold bg-PrimaryDarkColor'
        >{lecturesData.length}</p>
      </div>
      <div className='w-full h-[80%] overflow-auto style-scrollbar rounded-b-xl flex justify-center items-center bg-SecondaryColor'>
        <div className="mx-auto w-full h-full text-center relative flex overflow-hidden">
          {lecturesData?.map((item, itemIndex) => {
            const { courseName, course, facultyName } = item;
            let position = 'nextSlide';
            if (itemIndex === index) {
              position = 'activeSlide';
            } else if (
              itemIndex === index - 1 ||
              (index === 0 && itemIndex === lecturesData.length - 1)
            ) {
              position = 'lastSlide';
            }

            return (
              <article key={itemIndex} className={`${position} absolute w-full h-full transition-transform duration-500 ease-in-out flex flex-col items-start justify-center p-4`}>
                <p className="text-black text-sm font-bold text-start">
                  Faculty: <span className="text-xs font-normal">{facultyName}</span>
                </p>
                <p className="text-black text-sm font-bold text-start">
                  Course: <span className="text-xs font-normal">{`${courseName} [${course}]`}</span>
                </p>
              </article>
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default OnlineClass