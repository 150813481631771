import React, { useState } from 'react';

const MultiSelectionOther = ({options,handleOptionChange,addMoreOption,removeOption}) => {
   
    return (
        <form className="flex flex-col w-full h-full p-[1vw] bg-SecondaryColor rounded-2xl overflow-y-auto style-scrollbar">
            <div className='flex flex-col'>
                {options.map((cls, index) => (
                    <div key={index} className='flex items-center justify-center w-full h-full gap-2'>
                        <div className="flex w-[100%] gap-x-2 justify-center items-center">
                            <label htmlFor={`cls-${index}`} className="block gap-2 font-medium text-center text-gray-700 whitespace-nowrap">
                                Option: {index + 1}
                            </label>
                            <input
                                type="text"
                                id={`cls-${index}`}
                                name={`cls-${index}`}
                                value={cls.text}
                                placeholder="Enter Option Here"
                                onChange={(event) => handleOptionChange(index, event)}
                                className="block w-full p-2 mt-2 text-black border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                        </div>
                        {options.length > 1 && (
                            <div className='h-full w-[10%]'>
                                <button
                                    type="button"
                                    onClick={() => removeOption(index)}
                                    className="px-4 py-2 text-white duration-300 bg-red-500 rounded-md hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50"
                                >
                                    Remove
                                </button>
                            </div>
                        )}
                    </div>
                ))}
                
            </div>
            <div className='flex items-center justify-end w-full mt-2'>
                <button
                    type="button"
                    onClick={addMoreOption}
                    className="px-4 py-2 text-white duration-300 rounded-md bg-PrimaryColor hover:bg-PrimaryDarkColor focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                    Add More Option
                </button>
            </div>
            <div className='flex items-start w-full h-full gap-2 mt-2 justify-Start'>
                    <label htmlFor="other-option" className="block gap-2 font-medium text-center text-gray-700 whitespace-nowrap">
                        Others
                    </label>
                    
                </div>
        </form>
    );
};
export default MultiSelectionOther
