import React from 'react'
import Forums from '../../../components/employeeComp/Dashboard/Forums'

const Chatbox = ({FeedbackModalHandler}) => {
  return (
    <div>
     <Forums
     FeedbackModalHandler={FeedbackModalHandler}/>
    </div>
  )
}

export default Chatbox
