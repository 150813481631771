import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from "react-hot-toast";
import { useSelector } from 'react-redux';
import config from '../../../../config';
import {MessageByName} from './AddNotification';

function CoursePlanModal({ openCoursePlanModal, CoursePlanModalHandler, selectedCourse, selectedClass, updateCoursePlan, setUpdateCoursePlan }) {
    const [maxScreen, setMaxScreen] = useState(false);
    const userInfo = useSelector(state => state.userInfo);
    const backendBaseUrl = config.backendLocalhost;
    const [editingId, setEditingId] = useState(null);
    const ResponseMessages = useSelector(state => state.responseMessage);

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    }

    const type = ['MidTerm', 'Final', 'Lecture', 'Assignment', 'Quiz',];

    const [formData, setFormData] = useState({
        course: selectedCourse,
        className: selectedClass,
        facultyID: userInfo._id,
        Detail: [{
            weekNo: null,
            type: '',
            topic: '',
            CLO: '',
            weightage: '',
            status: false,
        }],
    });

    const resetForm = () => {
        setEditingId(null);
        setUpdateCoursePlan({
            course: selectedCourse,
            className: selectedClass,
            facultyID: userInfo._id,
            Detail: [{
                weekNo: 0,
                type: '',
                topic: '',
                CLO: '',
                weightage: '',
                status: false,
            }],
        });
        CoursePlanModalHandler('', '');
    };

    const handleInputChange = (e, index) => {
        const { id, value, type, checked } = e.target;
        const [field, fieldIndex] = id.split('-');

        // Update the form data based on the field type
        if (type === 'checkbox') {
            setFormData(prevState => ({
                ...prevState,
                Detail: prevState.Detail.map((detail, idx) => {
                    if (idx === index) {
                        return {
                            ...detail,
                            [field]: checked
                        };
                    }
                    return detail;
                })
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                Detail: prevState.Detail.map((detail, idx) => {
                    if (idx === index) {
                        return {
                            ...detail,
                            [field]: value
                        };
                    }
                    return detail;
                })
            }));
        }
    };

    const addCoursePlan = async () => {
        let loadingToast = "";
        try {
            console.log(formData, "add___setFormData")
            loadingToast = toast.loading('Loading...');
            const response = await axios.post(`${backendBaseUrl}/courseplan/create`, formData);
            const ResponseMsg = MessageByName("Course plan is added",ResponseMessages);
            toast.success(ResponseMsg);
            toast.dismiss(loadingToast);
            resetForm(); // Reset the form after successful submission
        } catch (error) {
            console.error('Error sending data to the backend:', error);
            toast.error(error.response.data.message);
            toast.dismiss(loadingToast);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        addCoursePlan();
    };

    const addField = () => {
        setFormData(prevState => ({
            ...prevState,
            Detail: [
                ...prevState.Detail,
                {
                    weekNo: null,
                    type: '',
                    topic: '',
                    CLO: '',
                    weightage: '',
                    status: false,
                }
            ]
        }));
    };

    const removeField = (index) => {
        setFormData(prevState => ({
            ...prevState,
            Detail: prevState.Detail.filter((_, i) => i !== index)
        }));
    };

    const handleUpdateActivity = async () => {
        try {
            const response = await axios.put(`${backendBaseUrl}/courseplan/update/${editingId}`, {
                course: formData.course,
                className: formData.className,
                Detail: formData.Detail
            });
            resetForm();
            const ResponseMsg = MessageByName("Course plan is updated",ResponseMessages);
            toast.success(ResponseMsg);
        } catch (error) {
            console.error('Error updating course plan:', error);
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error('An error occurred while updating course plan.');
            }
        }
    };

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            course: selectedCourse,
            className: selectedClass,
            facultyID: userInfo._id.toString(),
        }));
    }, [selectedClass, selectedCourse, userInfo._id]);



    useEffect(() => {
        if (updateCoursePlan && Object.keys(updateCoursePlan).length !== 0) {
            setEditingId(updateCoursePlan._id);
            setFormData({
                course: updateCoursePlan.course,
                className: updateCoursePlan.class,
                Detail: updateCoursePlan.Detail
            })
        }
    }, [updateCoursePlan]);

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
${openCoursePlanModal
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[70vw] h-[70vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
                }
`}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
 ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="text-sm font-semibold">  {editingId !== null ? 'Update' : 'Create'} Course plan</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-3 h-3 duration-300 hover:scale-110'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
                    </button>
                    <button className="w-3 h-3 duration-300 hover:scale-110" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    {/* Provide the implementation for AddProjectHandler */}
                    <button className="w-3 h-3 duration-300 hover:scale-110" onClick={() => resetForm()}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>

            </header>

            <div className={`w-full flex flex-col justify-center items-center
             ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
          `}>
                <div className='w-full h-[90%] overflow-auto bg-gray-200 rounded-[1vw] flex flex-col gap-y-[1vh] px-[1vw] py-4'>
                    {formData.Detail.map((detail, index) => (
                        <div key={index} className='flex flex-col justify-center items-center gap-y-[1vh]'>
                            <p className='w-full text-2xl underline text-start'>Sr. <span className='font-bold'>{index + 1}</span></p>
                            <div className='w-full flex flex-col h-auto justify-start gap-x-[1vw] xl:flex-row'>
                                {/* week no Input */}
                                <div className='flex flex-col items-start w-full h-full'>
                                    <p className='text-sm text-left text-black '>Week No.</p>
                                    <input
                                        type="number"
                                        id={`weekNo-${index}`}
                                        className="w-full py-[1vh] text-sm p-[0.5vw] bg-white text-black border border-blue-300 rounded-[1vw] bg-transparent focus:outline-none hover:cursor-pointer focus:border-blue-900 text-center transition-all duration-300"
                                        placeholder="Weak number"
                                        value={detail.weekNo}
                                        onChange={(e) => handleInputChange(e, index)}
                                        min="0"
                                    />
                                </div>
                                {/* Type */}
                                <div className='flex flex-col items-start w-full h-full'>
                                    <p className='text-sm text-left text-black'>Type</p>
                                    {/* Select type */}
                                    <select
                                        id={`type-${index}`}
                                        className="w-full py-[1vh] text-sm px-[0.5vw] py-[1.5vh] bg-white text-black border border-blue-300 rounded-[1vw] bg-transparent focus:outline-none hover:cursor-pointer focus:border-blue-900 text-left transition-all duration-300"
                                        value={detail.type}
                                        onChange={(e) => handleInputChange(e, index)}>
                                        <option value="" disabled>Select Type</option>
                                        {type.map((type, idx) => (
                                            <option key={idx} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* CLO */}
                                <div className='flex flex-col items-start w-full h-full'>
                                    <p className='text-sm text-black'>CLO</p>
                                    <input
                                        type="number"
                                        id={`CLO-${index}`}
                                        className="w-full py-[1vh] text-sm p-[0.5vw] bg-white text-black border border-blue-300 rounded-[1vw] bg-transparent focus:outline-none hover:cursor-pointer focus:border-blue-900 text-center transition-all duration-300"
                                        placeholder='Write the CLO'
                                        value={detail.CLO}
                                        min="0"
                                        onChange={(e) => handleInputChange(e, index)} />

                                </div>
                                {/* weightage Input */}
                                <div className='flex flex-col items-start w-full h-full'>
                                    <p className='text-sm text-black'>Weightage (%)</p>
                                    <input
                                        type="number"
                                        id={`weightage-${index}`}
                                        className="w-full py-[1vh] text-sm p-[0.5vw] bg-white text-black border border-blue-300 rounded-[1vw] bg-transparent focus:outline-none hover:cursor-pointer focus:border-blue-900 text-center transition-all duration-300"
                                        placeholder="Weightage"
                                        value={detail.weightage}
                                        min="0"
                                        onChange={(e) => handleInputChange(e, index)} />
                                </div>
                                {/* status */}
                                <div className='flex flex-col items-start w-full h-full'>
                                    <label htmlFor={`status-${index}`} className='text-sm text-black'>Status</label>
                                    <div className='flex gap-x-[0.5vw] justify-center items-center mt-2'>
                                        <input
                                            className='checkbox'
                                            type="checkbox"
                                            id={`status-${index}`}
                                            name={`status-${index}`}
                                            checked={detail.status}
                                            onChange={(e) => handleInputChange(e, index)} />
                                        <label htmlFor={`status-${index}`} className={`text-center ${detail.status ? "text-green-600" : "text-red-600"}`}>
                                            {detail.status ? "Active" : "In-Active"}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/*  Topic */}
                            <div className='flex flex-col items-center justify-center w-full'>
                                <div className='flex items-end w-full'>
                                    <p className='text-sm text-left text-black'>Topic:</p>
                                </div>
                                <textarea
                                    id={`topic-${index}`}
                                    className='h-auto w-full text-sm p-[1vw] style-scrollbar border border-blue-300 focus:border-blue-900 outline-blue-900'
                                    placeholder='Write the topic here...'
                                    value={detail.topic}
                                    onChange={(e) => handleInputChange(e, index)} />
                            </div>
                            {index > 0 && (
                                <div className='flex justify-end w-full'>
                                    <button
                                        className='w-auto px-[1vw] py-[1vh] bg-red-800 text-white rounded-[1vw]'
                                        type="button"
                                        onClick={() => removeField(index)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            )}
                            <div class="border-b border-gray-700 border-opacity-60 w-[90%] h-px"></div>
                        </div>
                    ))}
                    {/* Add More Button */}
                    <div className='flex justify-end w-full mt-1'>
                        <button className='w-auto px-[1vw] py-[1vh] bg-purple-800 text-white rounded-[1vw]' type="button" onClick={addField}>
                            Assign more academic Activities
                        </button>
                    </div>
                </div>

                {/* button */}
                <div className='flex flex-row w-full justify-center items-center py-[1vh] p-[7vh] gap-x-[1vw]'>
                    <button
                        className='h-full w-full py-[1vh] text-sm font-semibold rounded-[1vw] text-black bg-green-400 hover:text-white hover:bg-green-700 duration-300'
                        type='button'
                        onClick={editingId !== null ? handleUpdateActivity : handleSubmit}
                    >
                        {editingId !== null ? 'Update Course Plan' : 'Add Course Plan'}
                    </button>
                    <button
                        onClick={() => CoursePlanModalHandler('', '')}
                        className='h-full w-full text-sm font-semibold rounded-[1vw] text-black bg-red-400 hover:text-white hover:bg-red-700 duration-300'>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CoursePlanModal