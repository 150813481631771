import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const SingalAssign = ({ result }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'pie',
      },
      labels: [],
    },
  });

  useEffect(() => {
    const counts = result.reduce((acc, answer) => {
      acc[answer] = (acc[answer] || 0) + 1;
      return acc;
    }, {});
    const labels = Object.keys(counts);
    const series = Object.values(counts);

    setChartData({
      series,
      options: {
        ...chartData.options,
        labels,
      },
    });
  }, [result]);

  return (
    <div style={{ width: '400px', height: '250px' }}>
      <ReactApexChart options={chartData.options} series={chartData.series} type="pie" width="100%" height="100%" />
    </div>
  );
};

export default SingalAssign;
