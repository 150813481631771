import { SET_WEEKLY_SCHEDULE } from '../action-creators/ActionTypes';

const WeeklySchedule = (state = [] , action) => {
    switch (action.type) {
        case SET_WEEKLY_SCHEDULE:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default WeeklySchedule;
