import React, { useEffect } from 'react';
import Dashboard from '../components/Admin/Dashboard';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../state/action-creators';
import Apis from '../Apis';

const Admin = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userInfo = await Apis.getUserInfo();
        dispatch(setUserInfo(userInfo || []));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [dispatch]);
  return (
    <div className=' w-[100vw] h-[100vh]'>
      <Dashboard />
    </div>
  )

};

export default Admin;