import React from 'react';
import AssignmentActivity from './ActivityLists/AssignmentActivity';
import AttendanceActivity from './ActivityLists/AttendanceActivity';
import QuizActivity from './ActivityLists/QuizActivity';
import ExamActivity from './ActivityLists/ExamActivity';
import LearningMaterialActivity from './ActivityLists/LearningMaterialActivity';
import ReportsActivity from './ActivityLists/ReportsActivity';
import Chatbox from './ActivityLists/Chatbox';
import Notifications from './ActivityLists/Notifications';
import CoursePlanActivity from './ActivityLists/CoursePlanActivity';
import OnlineLecture from "./ActivityLists/OnlineLecture";
import SelfLearning from './ActivityLists/SelfLearning';

const ActivityList = ({ LearningMaterialHandler, AssignmentModalHandler, QuizModalHandler, AssignmentDetailHandler, 
    NotificationModalHandler, QuizDetailHandler, ExamModalHandler, ExamDetailHandler, ChatBoxDetailHandler, 
    CoursePlanModalHandler, setOpenCoursePlanModal, setUpdateCoursePlan, ReportIndividualDetailHandler,
   OnlineLectureHandler,SelfLearningHandler,SelfLearningDetailHandler,SelfLearningStudentHandler }) => {

    return (
        <div className='flex flex-col h-full  w-full gap-y-[1vh] bg-gray-100 rounded-[1vw] p-[1vw] overflow-auto style-scrollbar'>
            <AttendanceActivity />
            <AssignmentActivity
                AssignmentModalHandler={AssignmentModalHandler}
                AssignmentDetailHandler={AssignmentDetailHandler}
            />
            <QuizActivity
                QuizModalHandler={QuizModalHandler}
                QuizDetailHandler={QuizDetailHandler}
            />

            <Chatbox
                ChatBoxDetailHandler={ChatBoxDetailHandler}
            />
            <Notifications
                NotificationModalHandler={NotificationModalHandler} />

            <OnlineLecture
                OnlineLectureHandler={OnlineLectureHandler}
            />
            <LearningMaterialActivity
                LearningMaterialHandler={LearningMaterialHandler}
            />
            <ExamActivity
                ExamModalHandler={ExamModalHandler}
                ExamDetailHandler={ExamDetailHandler}
            />
            <ReportsActivity
                ReportIndividualDetailHandler={ReportIndividualDetailHandler}
            />

            <CoursePlanActivity
                CoursePlanModalHandler={CoursePlanModalHandler}
                setUpdateCoursePlan={setUpdateCoursePlan}
                setOpenCoursePlanModal={setOpenCoursePlanModal}
            />
            <SelfLearning
                SelfLearningHandler={SelfLearningHandler}
                SelfLearningDetailHandler={SelfLearningDetailHandler}
                SelfLearningStudentHandler={SelfLearningStudentHandler}
            />
        </div>
    );
}

export default ActivityList;
