import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { useSelector } from 'react-redux';

const typeOfMaterialList = [
    "Video",
    "Audio",
    "Image",
    "Pdf",
    "Word",
    "Ppt",
    "excel",
]

const Add = ({ FacultyName, ClassCode, CourseCode, formData, setFormData, resetForm, handleSubmit,currentIndex,setCurrentIndex }) => {
    
    const allCourse = useSelector(state => state.allCourses);

    function getCourseDetails(courseCode) {
        for (let course of allCourse) {
            if (course.courseCode === courseCode) {
                return `${course.courseName} - ${course.courseCode}`;
            }
        }
        return courseCode;
    }

    const handleLeftClick = () => {
        const indexParts = currentIndex.split('.');
        if (indexParts.length === 1) {
            toast.error("Can't go further left");
        } else {
            setCurrentIndex(indexParts.slice(0, -1).join('.'));
        }
    };

    const handleRightClick = () => {
        if (currentIndex.endsWith('.0')) {
            toast.error("Can't go further right");
        } else {
            setCurrentIndex(currentIndex + '.0');
        }
    };
    const handleAddRow = () => {
        const lastRow = formData[formData.length - 1];
    
        if (lastRow.unit !== '' && lastRow.topic !== '') {
            if (lastRow.attachment === '' || (lastRow.attachment !== '' && lastRow.type !== '' && lastRow.duration !== '')) {
                if (getNextIndex(currentIndex).split('.').length === lastRow?.unit.split('.').length) {
                    if (lastRow.attachment === '') {
                        toast.error("Previous row at the same level must have an attachment.");
                        return
                    }
                }
                const newRow = { unit: getNextIndex(currentIndex), topic: '', type: '', duration: '', attachment: '' };
                setFormData([...formData, newRow]);
                setCurrentIndex(getNextIndex(currentIndex));
            } else {
                toast.error('Please fill in the duration and type fields before adding a new row.');
            }
        } else {
            toast.error('Please fill in the topic fields before adding a new row.');
        }
    };
    

    const getNextIndex = (index) => {
        const indexParts = index.split('.');
        const lastPart = parseInt(indexParts[indexParts.length - 1]) + 1;
        indexParts[indexParts.length - 1] = lastPart;
        return indexParts.join('.');
    };

    const handleInputChange = (index, field, value) => {
        const updatedFormData = formData.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        setFormData(updatedFormData);
    };

    return (
        <div className='w-full h-full flex flex-col gap-y-3 overflow-auto style-scrollbar'>
            {/* Header */}
            <div className='flex justify-evenly items-center gap-x-2 p-4'>
                <div className='flex justify-start items-center gap-x-2 text-black text-md'>
                    <p className='text-bold'>Faculty:</p>
                    <p className='text-normal'>{FacultyName}</p>
                </div>
                <div className='flex justify-start items-center gap-x-2 text-black text-md'>
                    <p className='text-bold'>Class:</p>
                    <p className='text-normal'>{ClassCode}</p>
                </div>
                <div className='flex justify-start items-center gap-x-2 text-black text-md'>
                    <p className='text-bold'>Course:</p>
                    <p className='text-normal'>{getCourseDetails(CourseCode)}</p>
                </div>
            </div>
            {/* Main */}
            <table className="min-w-full h-auto bg-white border-collapse rounded-2xl text-sm">
                <thead>
                    <tr>
                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Unit</th>
                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Topic</th>
                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Attachment</th>
                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Type of Material</th>
                        <th className="border border-gray-400 bg-blue-100 p-2 text-start whitespace-nowrap">Duration</th>
                    </tr>
                </thead>
                <tbody>
                    {formData.map((item, index) => (
                        <tr className={`${index % 2 === 0 ? "bg-white" : "bg-gray-100"} w-full`} key={index}>
                            <td className="w-[5%] border-x border-gray-400 p-2 whitespace-nowrap">
                                {item.unit}
                            </td>
                            <td className="min-w-[70%] border-x border-gray-400 p-2 whitespace-nowrap">
                                <input className='w-full text-black text-sm border p-2 rounded-md'
                                    type='text'
                                    value={item.topic}
                                    onChange={(e) => handleInputChange(index, 'topic', e.target.value)}
                                ></input>
                            </td>
                            <td className="w-[10%] border-x border-gray-400 p-2 whitespace-nowrap">
                                <input className='text-black text-sm'
                                    type='file'
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            const updatedFormData = formData.map((item, i) =>
                                                i === index ? { ...item, attachment: file } : item
                                            );
                                            setFormData(updatedFormData);
                                        }
                                    }}
                                />
                            </td>
                            <td className="w-[10%] border-x border-gray-400 p-2 whitespace-nowrap">
                                <select
                                    id="classSelect"
                                    className='text-black text-sm border p-2 rounded-md'
                                    value={item.type}
                                    onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                                >
                                    <option value="" disabled>Select a type of Material</option>
                                    {typeOfMaterialList.map((val) => (
                                        <option key={val} value={val}>
                                            {val}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td className="w-[5%] border-x border-gray-400 p-2 whitespace-nowrap">
                                <input
                                    className='w-auto max-w-12 text-black text-sm border remove-arrow rounded-sm'
                                    type='number'
                                    value={item.duration}
                                    onChange={(e) => handleInputChange(index, 'duration', e.target.value)}
                                    style={{ width: `${Math.max(2, item.duration.toString().length + 1)}ch` }}
                                />
                                <span className='ml-2'>Minutes</span>
                            </td>
                        </tr>


                    ))}
                </tbody>
            </table>
            {/* Footer */}
            <div className='w-full flex justify-start items-center'>
                <div className='w-[50%] flex justify-center items-center gap-x-2'>
                    <button className='py-1 px-8 text-md text-black font-semibold hover:font-bold hover:text-white bg-green-400 hover:bg-green-800 rounded-lg duration-300 border-green-900 border-2'
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </button>
                    <button className='py-1 px-8 text-md text-black font-semibold hover:font-bold hover:text-white bg-red-400 hover:bg-red-800 rounded-lg duration-300 border-red-900 border-2'
                        onClick={() => resetForm()}
                    >
                        Cancel
                    </button>
                </div>
                <div className='w-[50%] flex justify-start items-center gap-x-2'>
                    <button className='py-1 px-8 text-md text-black font-semibold hover:font-bold hover:text-white bg-blue-300 hover:bg-blue-800 rounded-lg duration-300 border-blue-900 border-2'
                        onClick={handleLeftClick}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <div className='py-1 px-8 text-md text-black bg-white font-semibold hover:text-bold rounded-lg duration-300 border-blue-900 border-2'>
                        {currentIndex}
                    </div>
                    <button className='py-1 px-8 text-md text-black font-semibold hover:font-bold hover:text-white bg-blue-300 hover:bg-blue-800 rounded-lg duration-300 border-blue-900 border-2'
                        onClick={handleRightClick}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                    <button className='py-1 px-4 text-md text-black font-semibold hover:font-bold hover:text-white bg-blue-300 hover:bg-blue-800 rounded-lg duration-300 border-blue-900 border-2'
                        onClick={handleAddRow}
                    >
                        <FontAwesomeIcon icon={faPlus} className='mr-2' />
                        {"Add Row"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Add