import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux';
import Apis from '../../../../Apis';
import { formatDate } from '../../../../components/FormatDate';

const View = ({ SelfLearningHandler, SelfLearningDetailHandler, SelfLearningStudentHandler }) => {

  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);
  const userInfo = useSelector(state => state.userInfo);
  const [selfLearning, setSelfLearning] = useState();

  useEffect(() => {
    const filteredCourses = userInfo.facultyDetails.courseDetail
      .filter(info => info.class === selectedClass)
      .map(info => info.course);
    setCourses(filteredCourses);
  }, [selectedClass]);

  useEffect(() => {
    const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map(info => info.class)));
    setClasses(uniqueClasses);
  }, []);

  const getAllSelfLearning = async (classCode, CourseCode) => {
    try {
      const SelfLearning = await Apis.getAllSelfLearning(classCode, CourseCode);
      setSelfLearning(SelfLearning);
    } catch (error) {
      console.error('Error fetching All Self Learning:', error.message);
    }
  };

  useEffect(() => {
    if (selectedClass !== "" && selectedCourse !== "") {
      getAllSelfLearning(selectedClass, selectedCourse);
    }
  }, [selectedClass, selectedCourse]);

  return (
    <div className='w-full pb-[2vh] px-[1vw]'>
      <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh]'>
        {/* Select Class */}
        <select
          id="classSelect"
          className='w-auto h-full text-black text-left border border-red-700 rounded-[1vw] px-[1vw] focus:border-red-700 focus:outline-none cursor-pointer'
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
        >
          <option value="">Select a class</option>
          {classes.map((className) => (
            <option key={className} value={className}>
              {className}
            </option>
          ))}
        </select>

        {/* Select Course */}
        <select
          id="courseSelect"
          className='w-auto h-full text-black text-left border border-red-700 rounded-[1vw] px-[1vw] focus:border-red-700 focus:outline-none cursor-pointer'
          value={selectedCourse}
          onChange={(e) => setSelectedCourse(e.target.value)}
        >
          <option value="">Select a course</option>
          {courses.map((course) => (
            <option key={course} value={course}>
              {course}
            </option>
          ))}
        </select>
        {/* Add Assignment */}
        <button className='w-auto h-full text-[1.2vw] px-[1vw] text-center border border-red-700 rounded-[1vw] focus:border-red-700 focus:outline-none
      bg-white text-black hover:bg-red-700 hover:text-white duration-300'
          onClick={() => SelfLearningHandler(selectedCourse, selectedClass)}>
          Upload New Self Learning Material
        </button>
      </div>
      <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto overflow-x-auto'>
        <table className="min-w-full bg-white text-black border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
          <thead className="sticky top-0 bg-white">
            <tr>
              <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">No</th>
              <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">Assigning Date</th>
              <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">No. of Material</th>
              <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">Completed Student</th>
              <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">View Detail</th>
            </tr>
          </thead>
          <tbody>
            {selfLearning && selfLearning.selfLearningData.map((row, index) => (
              <tr key={row.sr} className={`${index % 2 === 0 ? 'bg-blue-50' : 'bg-blue-200'}`}
              >
                <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{index + 1}</td>
                <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{formatDate(row.submittedDate)}</td>
                <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.learningMaterial && row.learningMaterial.length}</td>
                <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap"
                  onClick={() => SelfLearningStudentHandler(row.completedStudents)}
                >
                  <p className='text-black hover:text-bold hover:text-blue-600 hover:underline cursor-pointer duration-300'>{`${row.completedStudents.length}/${selfLearning.totalStudents}`}</p>
                </td>
                <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">
                  <button className='text-[0.8vw] px-[0.5vw] py-[1vh] bg-red-900 text-white font-semibold rounded-[0.5vw] hover:text-blue-600 hover:underline duration-300 cursor-pointer'
                    onClick={() => SelfLearningDetailHandler(row._id)}
                  >
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}


export default View