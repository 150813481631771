import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from "react-hot-toast";
import { useSelector } from 'react-redux';
import config from '../../../../config';
import { formatDate } from '../../../FormatDate';
import Apis from '../../../../Apis';
import { MessageByName } from './AddNotification';
import Add from '../../../../components2/Faculty/Pages/SelfLearning/Add';

const AddSelfLearning = ({ openSelfLearningModal, setOpenSelfLearningModal, selectedCourse, selectedClass }) => {
    const [maxScreen, setMaxScreen] = useState(false);
    const userInfo = useSelector(state => state.userInfo);
    const backendBaseUrl = config.backendLocalhost;
    const ResponseMessages = useSelector(state => state.responseMessage);
    const [currentIndex, setCurrentIndex] = useState('1');


    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    }
    const [formData, setFormData] = useState([
        { unit: '1', topic: '', type: '', duration: '', attachment: '' }
    ]);

    const resetForm = () => {
        setFormData([
            { unit: '1', topic: '', type: '', duration: '', attachment: '' }
        ]);
        setCurrentIndex('1');
        setOpenSelfLearningModal('', '')
    };

    const handleSubmit = async () => {
        if (!userInfo._id || !selectedCourse || !selectedClass) {
            toast.error('Faculty ID, Course Code, and Class Code are required.');
            return;
        }
    
        if (formData.length === 0) {
            toast.error('At least one learning material is required.');
            return;
        }

        if (formData[formData.length - 1].attachment === '') {
            toast.error(`Attachment is required in unit: ${formData[formData.length - 1].unit}`);
            return;
        }
    
        const formDatas = new FormData();
        formDatas.append('facultyID', userInfo._id);
        formDatas.append('courseCode', selectedCourse);
        formDatas.append('classCode', selectedClass);
    
        let hasError = false;
    
        formData.forEach((material, index) => {
            if (!material.unit || !material.topic) {
                toast.error('Unit and Topic are required for all learning materials.');
                hasError = true;
                return;
            }
    
            const learningMaterial = {
                unit: material.unit,
                topic: material.topic,
            };
    
            if (material.attachment) {
                if (!material.type || !material.duration) {
                    toast.error('Type and Duration are required when an attachment is present.');
                    hasError = true;
                    return;
                }
                learningMaterial.type = material.type;
                learningMaterial.duration = material.duration;
                formDatas.append(`attachments_${index}`, material.attachment);
            }
    
            formDatas.append('learningMaterials', JSON.stringify(learningMaterial));
        });
    
        if (hasError) {
            return;
        }
    
        const Loading = toast.loading('Submitting form data...');
        try {
            const response = await axios.post(`${backendBaseUrl}/selfLearning/create`, formDatas, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.success('Self Learning Material added successfully');
            toast.dismiss(Loading);
            resetForm();
        } catch (error) {
            console.error('Error submitting Self Learning Material:', error);
            toast.error('Error submitting Self Learning Material');
            toast.dismiss(Loading);
        }
    };
    

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
        ${openSelfLearningModal
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
        ${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[70vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
                }
      `}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
         ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="text-[2vw] font-semibold">Upload Self Learning Material</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
                    </button>
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    {/* Provide the implementation for AddProjectHandler */}
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => setOpenSelfLearningModal('', '')}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col justify-center items-center overflow-hidden
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
                <Add
                    CourseCode={selectedCourse}
                    ClassCode={selectedClass}
                    FacultyName={`${userInfo.firstName} ${userInfo.lastName}`}
                    formData={formData}
                    setFormData={setFormData}
                    resetForm={resetForm}
                    handleSubmit={handleSubmit}
                    setCurrentIndex={setCurrentIndex}
                    currentIndex={currentIndex}
                />
            </div>
        </div>
    );
}


export default AddSelfLearning