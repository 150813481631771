import React from 'react'
import MainActivity from './Dashboard/MainActivity'
import ActivityList from './Dashboard/ActivityList'
import OnlineLecture from './Dashboard/OnlineLecture'

const Dashboard = ({ LearningMaterialHandler, AssignmentModalHandler, QuizModalHandler,
     AssignmentDetailHandler, NotificationModalHandler, ExamModalHandler, ExamDetailHandler, 
     QuizDetailHandler, ChatBoxDetailHandler, CoursePlanModalHandler, setUpdateCoursePlan, setOpenCoursePlanModal,
      ReportIndividualDetailHandler, OnlineLectureHandler,JoinMeetingHandler, 
      SelfLearningHandler,SelfLearningDetailHandler,SelfLearningStudentHandler
    }) => {
    return (
        <div className='flex flex-col w-full h-[90vh] p-[1vw] gap-y-[2vh]'>
            <div className='flex w-full h-[30vh] gap-x-[1vw]'>
                <MainActivity />
            </div>
            <div className='w-full h-[55vh] flex gap-x-4'>
                <div className='w-[75%] h-full'>
                    <ActivityList
                        LearningMaterialHandler={LearningMaterialHandler}
                        AssignmentModalHandler={AssignmentModalHandler}
                        QuizModalHandler={QuizModalHandler}
                        AssignmentDetailHandler={AssignmentDetailHandler}
                        NotificationModalHandler={NotificationModalHandler}
                        ExamModalHandler={ExamModalHandler}
                        QuizDetailHandler={QuizDetailHandler}
                        ChatBoxDetailHandler={ChatBoxDetailHandler}
                        ExamDetailHandler={ExamDetailHandler}
                        CoursePlanModalHandler={CoursePlanModalHandler}
                        setUpdateCoursePlan={setUpdateCoursePlan}
                        setOpenCoursePlanModal={setOpenCoursePlanModal}
                        ReportIndividualDetailHandler={ReportIndividualDetailHandler}
                        OnlineLectureHandler={OnlineLectureHandler}
                        SelfLearningHandler={SelfLearningHandler}
                        SelfLearningDetailHandler={SelfLearningDetailHandler}
                        SelfLearningStudentHandler={SelfLearningStudentHandler}
                    />
                </div>
                <div className='w-[25%] h-full flex flex-col gap-y-4'>
                    <div className='w-full h-1/2 bg-SecondaryColor rounded-2xl'>
                        <OnlineLecture
                        JoinMeetingHandler={JoinMeetingHandler}
                        />
                    </div>
                    <div className='w-full h-1/2 bg-SecondaryColor rounded-2xl'>
                        <div className='w-full h-[20%] bg-gray-300 flex justify-center items-center rounded-t-xl'>
                            <p className='text-[1vw] text-black whitespace-nowrap tracking-wide font-semibold hover:text-blue-600 hover:underline hover:cursor-pointer duration-300'
                            >Notifications</p>
                        </div>
                        <div className='w-full h-[80%] overflow-auto style-scrollbar rounded-b-xl'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard