// reducers/FacultyActivityListToggle.js
import { SET_ALL_ATTENDANCE } from '../action-creators/ActionTypes';

const AllAttendance= (state = [] , action) => {
    switch (action.type) {
        case SET_ALL_ATTENDANCE:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllAttendance;
