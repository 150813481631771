import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../state/action-creators';
import Apis from '../Apis';
import { toast } from "react-hot-toast";

const ToggleBtn = () => {
    const userInfo = useSelector(state => state.userInfo);
    const dispatch = useDispatch();

    const fetchData = async () => {
        try {
            const userInfo = await Apis.getUserInfo();
            dispatch(setUserInfo(userInfo || []));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const ApiThemeChange = async () => {
        try {
            const response = await Apis.ThemeChanged(userInfo._id);
            fetchData();
            Apis.addAuditTrail(userInfo._id, "663cc659f70e7633edf2a350", ` ${!userInfo.Theme ? 'Menu With System' : 'Menu Less System'}`);
            toast.success(`Theme changed to ${!userInfo.Theme ? 'Menu View' : 'MLS View'}`);
        } catch (error) {
            toast.error(`Error: ${error.message}`);
            console.error('API Error:', error.message);
        }
    };


    return (
        <label className="w-32 inline-flex items-center cursor-pointer">
            <input type="checkbox" checked={userInfo.Theme} className="sr-only peer" onChange={ApiThemeChange} />
            <div className={`relative w-11 h-6 rounded-full bg-gray-300 focus:ring-4 focus:ring-blue-300`}>
                <div className={`absolute w-5 h-5 top-0.5 rounded-full duration-300 ${userInfo.Theme ? "translate-x-6 bg-PrimaryDarkColor" : "translate-x-0 bg-PrimaryColor"}`}></div>
            </div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{userInfo.Theme ? "Menu view" : "MLS view"}</span>
        </label>
    );
};

export default ToggleBtn;
