import { SET_ALLCLASS } from '../action-creators/ActionTypes'; // Assuming you have SET_ALL_CLASSES action type defined

const AllClass = (state = [], action) => {
    switch (action.type) {
        case SET_ALLCLASS:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllClass;
