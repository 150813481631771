import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from 'react-redux';

const ViewAuditTrail = ({ setIndividualAudit, handleButtonClick,}) => {
    const auditTrail = useSelector(state => state.auditTrail);

    const handleEditDepartment = (val) => {
        setIndividualAudit(val);
        handleButtonClick('DetailOverView');
    };

    return (
        <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
            <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
                <thead className=''>
                    <tr className='text-sm font-semibold'>
                        <th className="w-[5%] px-4 py-3 text-center border border-gray-400">Sr.</th>
                        <th className="w-[20%] px-4 py-3 text-left border border-gray-400 ">UserID</th>
                        <th className="w-[70%] px-4 py-3 text-left border border-gray-400 ">Name</th>
                        <th className="w-[5%] px-4 py-3 text-left border border-gray-400 ">Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {auditTrail.map((val, index) => (
                        <tr key={val._id}
                            className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}
                        >
                            <td className="px-4 py-3 text-center ">{index + 1}</td>
                            <td className="px-4 py-3 truncate text-left">{val.UserID}</td>
                            <td className="px-4 py-3 truncate text-left">{val.UserName}</td>
                            <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center">
                                <button className='text-[0.8vw] px-[0.5vw] py-[1vh] bg-red-900 text-white font-semibold rounded-[0.5vw] hover:text-blue-600 hover:underline duration-300 cursor-pointer'
                                    onClick={() => handleEditDepartment(val)}
                                >
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ViewAuditTrail