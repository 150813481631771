import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import config from '../../../../config';
import Apis from '../../../../Apis';

const SelectCourse = ({ selectedCourse, setSelectedCourse }) => {
  const [isOpen, setIsOpen] = useState(false);
  const backendBaseUrl = config.backendLocalhost;
  const [courses, setCourses] = useState([]);

  const userInfo = useSelector(state => state.userInfo);

  const getCoursesFromClassCode = async () => {
    try {
      const Courses = await Apis.getCoursesByClass(userInfo?.studentDetails?.class);
      setCourses(Courses || []);
    } catch (error) {
      console.error('Error fetching Courses:', error.message);
    }
  };
  
  useEffect(() => {
    if (userInfo.studentDetails.class) {
      getCoursesFromClassCode();
    }
  }, [backendBaseUrl, userInfo]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCourseSelect = (course) => {
    setSelectedCourse(course);
    setIsOpen(false);
  };

  return (
    <div className='relative inline-block'>
      <div
        className='w-full h-[5vh] bg-gray-100 text-black rounded-lg flex items-center justify-center gap-x-[0.5vw] cursor-pointer'
        onClick={toggleDropdown}
      >
        <p className='xl:text-[1vw]'>{selectedCourse}</p>
        <FontAwesomeIcon className='w-[1vw] h-[1vw]' icon={faCaretDown} style={{ color: '#000' }} />
      </div>

      <div className='relative'>
        {isOpen && (
          <div className={`absolute left-0 w-full max-h-[30vh] overflow-auto style-scrollbar mt-[0.1vh] overflow-auto text-black bg-gray-100 rounded-lg shadow-2xl shadow-black animate
        ${isOpen ? "scale-100" : "scale-0"}
        `}>
            <ul className='p-[0.5vw] divide-y divide-slate-400'>
              {courses && courses.map((course, index) => (
                <li
                  key={index}
                  className='p-2 cursor-pointer hover:bg-gray-200'
                  onClick={() => handleCourseSelect(course.courseCode)}
                >
                  {`${course.Name} [${course.courseCode}]`}
                </li>
              ))}
              <li
                className='p-2 cursor-pointer hover:bg-gray-200'
                onClick={() => handleCourseSelect("All courses")}
              >
                {"All courses"}
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectCourse;
