import React, { useState } from 'react'
import Header from '../../components/FacultyComp/Header';
import SideBar from './SideBar';
import DefaultLayout from './DefaultLayout';

function Dashboard({CoursePlanModalHandler,setUpdateCoursePlan,setOpenCoursePlanModal,AssignmentModalHandler,AssignmentDetailHandler,QuizModalHandler,QuizDetailHandler,NotificationModalHandler,LearningMaterialHandler,ExamModalHandler,ExamDetailHandler,ChatBoxDetailHandler,ReportIndividualDetailHandler}) {
  const [menuToggle, setMenuToggle] = useState(true);

  const btnHAndle = () => {
    setMenuToggle(!menuToggle)
  }
  return (
    <div className='bg-blue-100'>
      <Header/>
      <div className='flex h-[90vh] overflow-hidden'>
      <SideBar btnHAndle={btnHAndle} menuToggle={menuToggle}/>
      <DefaultLayout menuToggle={menuToggle}
     CoursePlanModalHandler={CoursePlanModalHandler}
     setUpdateCoursePlan={setUpdateCoursePlan}
     setOpenCoursePlanModal={setOpenCoursePlanModal}
     AssignmentModalHandler={AssignmentModalHandler}
     AssignmentDetailHandler={AssignmentDetailHandler}
     QuizModalHandler={QuizModalHandler}
     QuizDetailHandler={QuizDetailHandler}
     NotificationModalHandler={NotificationModalHandler}
     LearningMaterialHandler={LearningMaterialHandler}
     ExamModalHandler={ExamModalHandler}
     ExamDetailHandler={ExamDetailHandler}
     ChatBoxDetailHandler={ChatBoxDetailHandler}
     ReportIndividualDetailHandler={ReportIndividualDetailHandler}

     
      />
      </div>
    </div>
  )
}
export default Dashboard