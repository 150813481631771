import React from 'react'
import TimeTable from '../../../components/employeeComp/Dashboard/TimeTable'
const Schedule = ({TimeTableModalHandler}) => {
  return (
    <div>
      <TimeTable
      TimeTableModalHandler={TimeTableModalHandler}/>
    </div>
  )
}

export default Schedule
