import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faFolder, faUpload, faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import AddWeeklySchedule from './WeeklySchedule/AddWeeklySchedule';
import ViewWeeklySchedule from './WeeklySchedule/ViewWeeklySchedule';
import { toast } from 'react-hot-toast';
import { setAllClass, setAllCourses, setAllProgram, setWeeklySchedule } from '../../../state/action-creators';
import { useDispatch,useSelector } from 'react-redux';
import Apis from '../../../Apis';

const WeeklySchedule = () => {
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState("view");
  const [searchWeeklySchedule, setSearchWeeklySchedule] = useState("");
  const userInfo = useSelector(state => state.userInfo);

  const handleButtonClick = (buttonName) => {
    setSelectedStatus(buttonName);
  };

  const reset = () => {
    handleButtonClick("create")
  }

  const fetchClass = async () => {
    const user = {
      userID:userInfo._id,
      userRole:userInfo.role,
    }
    try {
      const AllClass = await Apis.getAllClasses("",user);
      dispatch(setAllClass(AllClass || []));
    } catch (error) {
      console.error('Error fetching Class:', error.message);
    toast.error('Error fetching Class:', error.message);
    }
  };
  const fetchCourses = async () => {
    const user = {
      userID:userInfo._id,
      userRole:userInfo.role,
    }
      try {
        const AllCourses = await Apis.getAllCourses("",user);
        dispatch(setAllCourses(AllCourses || []));
      } catch (error) {
        console.error('Error fetching Courses:', error.message);
      toast.error('Error fetching Courses:', error.message);
      }
  };
  const fetchProgram = async () => {
    const user = {
      userID:userInfo._id,
      userRole:userInfo.role,
    }
        try {
          const AllProgram = await Apis.getAllProgram("",user);
          dispatch(setAllProgram(AllProgram || []));
        } catch (error) {
          console.error('Error fetching Program:', error.message);
        toast.error('Error fetching Program:', error.message);
        }
  };
  const fetchWeeklySchedule = async () => {
    try {
      const AllWeeklySchedule = await Apis.getAllWeeklySchedule(searchWeeklySchedule);
      dispatch(setWeeklySchedule(AllWeeklySchedule || []));
    } catch (error) {
      console.error('Error fetching WeeklySchedule:', error.message);
      toast.error('Error fetching WeeklySchedule:', error.message);
    }
};
  useEffect(() => {
    fetchWeeklySchedule();
    fetchProgram();
    fetchCourses();
    fetchClass();
  }, [searchWeeklySchedule]);

  return (
    <div className='flex flex-col items-start justify-start w-full
    5xl:px-[1vw] 5xl:py-[1vh] 5xl:h-full
    4md:px-[1vw] 4md:py-[1vh] 4md:h-full
    px-[1vw] py-[1vh] h-auto
    '>
      <div className='w-full flex flex-col justify-between bg-PrimaryDarkColor 
      5xl:py-[1vh] 5xl:px-[1vw] 5xl:rounded-[0.8vw] 5xl:h-[12%] 5xl:overflow-hidden 5xl:gap-y-[0vh]
      4md:py-[1vh] 4md:px-[1vw] 4md:rounded-[0.8vw] 4md:h-[15%] 4md:overflow-hidden 4md:gap-y-[0vh]
      py-[0.5vh] px-[2vw] rounded-[0.8vw] h-auto gap-y-[1vh]
      '>
        <label className='w-full text-white font-bold tracking-wide bg-tansparent
        5xl:text-2xl 5xl:text-start
        4md:text-2xl 4md:text-start
        text-md  text-center
        '>Weekly Schedule Management</label>
        <div className='flex justify-between w-full justify-center items-start 
        5xl:gap-x-[1vw] 5xl:flex-row 
        4md:gap-x-[1vw] 4md:flex-row 
        gap-x-[1vw] flex-col gap-y-[1vh] h-auto
        '>
          <div className='grid justify-center items-center h-auto 
          5xl:w-[65%] 5xl:gap-x-[0.5vw] 5xl:flex-row 5xl:grid-cols-5 
          4md:w-[70%] 4md:gap-x-[0.5vw] 4md:flex-row 4md:grid-cols-5 
          w-full gap-x-[2vw] flex-col grid-cols-3 gap-y-[1vh] gap-x-[1vw]
          '>
            <div className={`w-auto h-auto items-center flex justify-center
            5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
            4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
            max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "view"
                ? "bg-PrimaryColor text-white font-medium transitionAll"
                : "bg-SecondaryColor text-black font-light"
              }`}
              onClick={() => handleButtonClick("view")}
            >
              <div>
                <FontAwesomeIcon icon={faFolder} />
              </div>
              <div>
                <button>Show List</button>
              </div>
            </div>
            <div className={`w-auto h-auto items-center flex justify-center
             5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
             4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
             max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "create"
                ? "bg-PrimaryColor text-white font-medium transitionAll"
                : "bg-SecondaryColor text-black font-light"
              }`}
              onClick={() => reset()}
            >
              <div>
                <FontAwesomeIcon icon={faCirclePlus} />
              </div>
              <div>
                <button>Add New</button>
              </div>
            </div>
            <div className={`w-auto h-auto items-center flex justify-center
             5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
             4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
             max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "Import"
                ? "bg-PrimaryColor text-white font-medium transitionAll"
                : "bg-SecondaryColor text-black font-light"
              }`}
              onClick={() => handleButtonClick("Import")}
            >
              <div>
                <FontAwesomeIcon icon={faUpload} />
              </div>
              <div>
                <button>Import</button>
              </div>
            </div>
            <div className={`w-auto h-auto items-center flex justify-center
              5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
              4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
              max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "download"
                ? "bg-PrimaryColor text-white font-medium transitionAll"
                : "bg-SecondaryColor text-black font-light"
              }`}
            >
              <div>
                <FontAwesomeIcon icon={faFileCsv} />
              </div>
              <div>
                {/* <CSVLink data={cleanedDepartments} filename={"DepartmentList.csv"}>Download</CSVLink> */}
              </div>
            </div>
            <div className={`w-auto h-auto items-center flex  justify-center
             5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-sm 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
             4md:max-h-[5vh] 4md:rounded-lg 4md:text-sm 4md:px-[1vw] 4md:gap-x-[0.5vw] 4md:py-[1vh]
             max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[1vh]
                        ${selectedStatus === "print"
                ? "bg-PrimaryColor text-white font-medium transitionAll"
                : "bg-SecondaryColor text-black font-light"
              }`}
            >
              <div>
                <FontAwesomeIcon icon={faFilePdf} />
              </div>
              <div>
                {/* <PDFDownloadLink document={<PDFDocument data={departments} />} fileName="departments.pdf">
                  {({ blob, url, loading, error }) => (loading ? 'Loading' : 'Print')}
                </PDFDownloadLink> */}
              </div>
            </div>
          </div>
          <label className={`${selectedStatus === "view" ? "block" : "hidden"}  input h-auto items-center flex text-black
                      5xl:w-[35%] 5xl:max-h-[5vh] 5xl:rounded-lg 5xl:text-md 5xl:px-[2vw] 5xl:gap-x-[0.7vw] 5xl:py-[1vh]
                      4md:w-[30%] 4md:max-h-[5vh] 4md:rounded-lg 4md:text-md 4md:px-[1vw] 4md:gap-x-[0.7vw] 4md:py-[1vh]
                      w-full max-h-[4vh] rounded-lg text-sm px-[2vw] gap-x-[0.7vw] py-[0.5vh]`}>
            <input type="text" className="grow" placeholder="Search by dept name"
              onChange={(e) => setSearchWeeklySchedule(e.target.value)}
            />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd" /></svg>
          </label>
        </div>
      </div>

      <div className='w-full
      5xl:py-[2vh] 5xl:h-[88%] 
      4md:py-[1vh] 4md:h-[85%] 
      py-[1vh] h-full 
      '>
        {selectedStatus === "view" ? (
          <ViewWeeklySchedule
          fetchWeeklySchedule={fetchWeeklySchedule}
          />
        ) : selectedStatus === "create" ? (
          <AddWeeklySchedule />
        ) : selectedStatus === "Import" ? (
          <div className='flex justify-center items-start'>
            {/* <Importcsv /> */}
          </div>
        ) : (
          <> </>

        )}
      </div>

    </div>
  )
}

export default WeeklySchedule