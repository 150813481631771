import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint, faArrowUpRightFromSquare, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from '../../../FormatDate';
import View from './SelfLearning/View';


function SelfLearning({ SelfLearningHandler, selfLearningDetail, openSelfLearning, courseName }) {
    const [maxScreen, setMaxScreen] = useState(false);
    const [selectedMaterialID, setSelectedMaterialID] = useState();

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    }

    const closeModalHandler = () => {
        SelfLearningHandler();
        setSelectedMaterialID();
    }

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-20
${openSelfLearning
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[70vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
                }
`}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
 ${maxScreen ? "h-[10%]" : "h-[15%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="text-[2vw] font-semibold">Self Learning</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
                    </button>
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    {/* Provide the implementation for AddProjectHandler */}
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => closeModalHandler()}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col
    ${maxScreen ? "h-[90%]" : "h-[85%] rounded-t-[1vw]"}
    `}>
                <div className='h-[10%] w-full p-[1vw] flex justify-between items-center'>
                    <p className='text-[1.5vw] text-black'>
                        Course Name:
                        <span className='font-semibold ml-[0.5vw]'>
                            {courseName}
                        </span>
                    </p>
                    {selectedMaterialID &&
                        <button className='flex justify-center items-centertext-sm px-6 py-[1vh] bg-blue-900 text-white font-semibold rounded-[0.5vw] hover:underline duration-300 cursor-pointer'
                            onClick={() => setSelectedMaterialID()}
                        >
                            <p className='text-white text-sm mr-2'>Back</p>
                            <FontAwesomeIcon icon={faCaretLeft} className='text-white text-lg' /></button>
                    }
                </div>
                <div className='w-full h-[90%] py-2 px-4'>
                    {selectedMaterialID ?
                        <View
                            selectedMaterialID={selectedMaterialID}
                        />
                        :
                        <table className="min-w-full bg-white text-black border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
                            <thead className="sticky top-0 bg-white">
                                <tr>
                                    <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">No</th>
                                    <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">Assigning Date</th>
                                    <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">No. of Material</th>
                                    <th className="px-[0.5vw] text-center  py-[1vh] border border-gray-400 whitespace-nowrap">View Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selfLearningDetail.length > 0 && selfLearningDetail.map((row, index) => (
                                    <tr key={index} className={`${index % 2 === 0 ? 'bg-blue-50' : 'bg-blue-200'}`}
                                    >
                                        <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{index + 1}</td>
                                        <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{formatDate(row.submittedDate)}</td>
                                        <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">{row.lengthOfMaterial}</td>
                                        <td className="px-[0.5vw] text-center  py-[1vh] border-b whitespace-nowrap">
                                            <button className='text-[0.8vw] px-[0.5vw] py-[1vh] bg-red-900 text-white font-semibold rounded-[0.5vw] hover:text-blue-600 hover:underline duration-300 cursor-pointer'
                                                onClick={() => setSelectedMaterialID(row._id)}
                                            >
                                                <FontAwesomeIcon icon={faArrowUpRightFromSquare} /></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    )
}

export default SelfLearning