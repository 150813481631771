// reducers/FacultyActivityListToggle.js
import { SET_SubmittedAssignments } from '../action-creators/ActionTypes';

const SubmittedAssignments = (state = "", action) => {
    switch (action.type) {
        case SET_SubmittedAssignments:
            return state === action.payload ? "" : action.payload;
        default:
            return state;
    }
};

export default SubmittedAssignments;
