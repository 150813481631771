import React from 'react'
import { useSelector } from 'react-redux';
import MainDashboard from './Pages/MainDashboard';
import Weekly from './Pages/Weekly';
import CoursePlan from './Pages/CoursePlan';
import Attendance from './Pages/Attendance';
import Exam from './Pages/Exam';
import Communiccation from './Pages/Communiccation';
import Notification from './Pages/Notification';
import Quizz from './Pages/Quizz';
import Reports from './Pages/Reports';
import LearningMaterial from './Pages/LearningMaterial';
import Assignment from './Pages/Assignment';


const DefaultLayout = ({ menuToggle,CoursePlanModalHandler,setUpdateCoursePlan,setOpenCoursePlanModal,AssignmentModalHandler,AssignmentDetailHandler,QuizModalHandler,QuizDetailHandler,NotificationModalHandler,LearningMaterialHandler,ExamModalHandler,ExamDetailHandler,ChatBoxDetailHandler,ReportIndividualDetailHandler}) => {
    const selectedMenuBtn = useSelector(state => state.selectedMenuBtn);
    
    return (
        <div className={`text-white  bg-transparent transitionAll 
        5xl::rounded-r-[0.8vw] 5xl:h-full 5xl:overflow-hidden
        rounded-r-[0.8vw] h-auto overflow-y-auto no-scrollbar
        ${menuToggle
                ? `5xl:w-[82vw]
                   4md:w-[82vw]
                   w-[0vw]`
                : `w-[100vw]`
            }`}
        >
            {
            selectedMenuBtn === "Dashboard" ? <MainDashboard /> :
            selectedMenuBtn === "Weekly" ? <Weekly /> :
            selectedMenuBtn === "CoursePlan" ? <CoursePlan 
            CoursePlanModalHandler={CoursePlanModalHandler}
            setUpdateCoursePlan={setUpdateCoursePlan}
            setOpenCoursePlanModal={setOpenCoursePlanModal}
            /> :
            selectedMenuBtn === "Attendance" ? <Attendance/> :
            selectedMenuBtn === "Assignment" ? <Assignment 
            AssignmentModalHandler={AssignmentModalHandler}
            AssignmentDetailHandler={AssignmentDetailHandler}/> :
            selectedMenuBtn === "Quizz" ? <Quizz
            QuizModalHandler={QuizModalHandler}
            QuizDetailHandler={QuizDetailHandler} /> :
            selectedMenuBtn === "Notification" ? <Notification
            NotificationModalHandler={NotificationModalHandler}/> :
            selectedMenuBtn === "LearningMaterial" ? <LearningMaterial
            LearningMaterialHandler={LearningMaterialHandler} /> :
            selectedMenuBtn === "Exam" ? <Exam
            ExamModalHandler={ExamModalHandler}
            ExamDetailHandler={ExamDetailHandler}/> :
            selectedMenuBtn === "Reports" ? <Reports 
            ReportIndividualDetailHandler={ReportIndividualDetailHandler}/> :
            selectedMenuBtn === "Communiccation" ? <Communiccation
            ChatBoxDetailHandler={ChatBoxDetailHandler} /> :
             <></>}
        </div>
    )
}

export default DefaultLayout