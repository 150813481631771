import { SET_ALLSESSION } from '../action-creators/ActionTypes'; // Assuming you have SET_ALL_SESSIONS action type defined

const AllSession = (state = [], action) => {
    switch (action.type) {
        case SET_ALLSESSION:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllSession;
