import { SET_Learning } from '../action-creators/ActionTypes';

const LearningDetails = (state = [] , action) => {
    switch (action.type) {
        case SET_Learning:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
            
    }
};

export default LearningDetails;