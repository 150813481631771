import React from 'react';
import GradesReport from './Dashboard/GradesReport';
import TimeTable from './Dashboard/TimeTable';
import Reminders from './Dashboard/Reminders';
import PaymentHistory from './Dashboard/PaymentHistory';
import Forums from './Dashboard/Forums';
import LearningPlatforms from './Dashboard/ELearningPlatforms/LearningPlatforms';
import AcademicCalender from './Dashboard/AcademicCalender';
import AllSemesterResult from './Dashboard/AllSemesterResult';
import OnlineClass from './Dashboard/OnlineClass';
import Survey from './Dashboard/Survey';

const Dashboard = ({
  AssignmentModalHandler, QuizModalHandler, FinalModalHandler, AttendanceModalHandler, ClassesModalHandler,
  PaidModalHandler, PendingModalHandler,
  AcademicModalHandler,
  SelectedRemindersHandler,
  AllSemModalHandler,
  TimeTableModalHandler,
  FeedbackModalHandler,
  CoursePlanModalHandler,
  ElearningModalHandler,
  SurveyModalHandler,
  JoinMeetingHandler,
  SelfLearningHandler,
}) => {
  return (
    <div className='flex w-full h-[90vh] p-2 gap-x-2 overflow-hidden'>
      <div className='w-[80%] h-full flex flex-col gap-y-2 '>
        <div className='w-full h-[40%] overflow-hidden'>
          <GradesReport
            AssignmentModalHandler={AssignmentModalHandler}
            QuizModalHandler={QuizModalHandler}
            FinalModalHandler={FinalModalHandler}
            AttendanceModalHandler={AttendanceModalHandler}
            ClassesModalHandler={ClassesModalHandler}
            CoursePlanModalHandler={CoursePlanModalHandler}
            SelfLearningHandler={SelfLearningHandler}
          />
        </div>
        <div className='w-full h-[35%] flex gap-x-2 overflow-hidden'>
          <div className='w-[40%] h-full bg-SecondaryColor rounded-2xl'>
            <TimeTable
              TimeTableModalHandler={TimeTableModalHandler}
            />
          </div>
          <div className='w-[60%] h-full bg-SecondaryColor rounded-2xl'>
            <Reminders
              SelectedRemindersHandler={SelectedRemindersHandler}
            />
          </div>
        </div>
        <div className='w-full h-[25%] flex gap-x-2 overflow-hidden'>
          <div className='w-[40%] h-full flex flex-col gap-y-2'>
            <div className='w-full h-1/2'>
              <AllSemesterResult
                AllSemModalHandler={AllSemModalHandler}
              />
            </div>
            <div className='w-full h-1/2'>
              <AcademicCalender
                AcademicModalHandler={AcademicModalHandler}
              />
            </div>
          </div>
          <div className='w-[60%] h-full flex gap-x-2'>
            <div className='w-[70%] h-full'>
              <Forums
                FeedbackModalHandler={FeedbackModalHandler}
              />
            </div>
            <div className='w-[30%] h-full'>
              <OnlineClass
                JoinMeetingHandler={JoinMeetingHandler}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='w-[20%] h-full bg-SecondaryColor rounded-2xl overflow-hidden'>
        <LearningPlatforms
          ElearningModalHandler={ElearningModalHandler}
        />
      </div>
    </div>
  );
};

export default Dashboard;
