import React, { useState } from 'react'
import Navbar from './compoonents/Navbar'
import SideBar from './compoonents/SideBar'
import DefaultLayout from './DefaultLayout';

function Dashboard() {
  const [menuToggle, setMenuToggle] = useState(true);

  const btnHAndle = () => {
    setMenuToggle(!menuToggle)
  }
  return (
    <div className='bg-blue-100'>
      <Navbar/>
      <div className='flex h-[90vh] overflow-hidden'>
      <SideBar btnHAndle={btnHAndle} menuToggle={menuToggle}/>
      <DefaultLayout menuToggle={menuToggle}/>
      </div>
    </div>
  )
}
export default Dashboard