import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../../config';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { MessageByName } from '../../../FacultyComp/Dashboard/Modals/AddNotification';
import Select from 'react-select';
import Apis from "../../../../Apis";

const AddUpdateFaculty = ({ updateFacultyInfo, setUpdateFacultyInfo, handleButtonClick }) => {
  const [showPassword, setShowPassword] = useState(false);
  const ResponseMessages = useSelector(state => state.responseMessage);
  const [allDepartmentalAdminInfo, setAllDepartmentalAdminInfo] = useState([])

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNo: '',
    cnic: '',
    email: '',
    password: '',
    validity: true,
    facultyDetails: {
      department: '',
      courseDetail: [
        {
          class: '',
          course: '',
        }
      ],
    },
    role: 'faculty',
    refArray: [],
  });

  const [editingId, setEditingId] = useState(null);
  const backendBaseUrl = config.backendLocalhost;
  const allFacultyInfo = useSelector(state => state.allFacultyInfo);
  const departments = useSelector(state => state.allDept);
  const [allExcutiveAdminInfo, setAllExcutiveAdminInfo] = useState([]);
  const userInfo = useSelector(state => state.userInfo);

  const fetchAllExcutiveAdmin = async () => {
    try {
      const AllExcutiveAdmin = await Apis.getAllExcutiveAdmin("");
      setAllExcutiveAdminInfo(AllExcutiveAdmin);
    } catch (error) {
      console.error('Error fetching All Excutive Admin:', error.message);
      toast.error('Error fetching All Excutive Admin:', error.message);
    }
  };

  const fetchAllExcutiveManagement = async () => {
    let userID;
    if (userInfo.role === 'admin') {
      userID = 'admin'
    }
    else {
      userID = userInfo._id
    }
    try {
      const AllExcutiveManagement = await Apis.getAllExcutiveManagement("", userID);
      setAllDepartmentalAdminInfo(AllExcutiveManagement);
    } catch (error) {
      console.error('Error fetching All Excutive Management:', error.message);
      toast.error('Error fetching All Excutive Management:', error.message);
    }
  };

  useEffect(() => {
    if(userInfo.role){
      fetchAllExcutiveAdmin();
      fetchAllExcutiveManagement();
    }
  }, [updateFacultyInfo,userInfo]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDepartmentChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      facultyDetails: {
        ...prevFormData.facultyDetails,
        [name]: value,
      },
    }));
  };

  const handleCourseDetailChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedCourseDetail = [...prevFormData.facultyDetails.courseDetail];
      updatedCourseDetail[index] = {
        ...updatedCourseDetail[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        facultyDetails: {
          ...prevFormData.facultyDetails,
          courseDetail: updatedCourseDetail,
        },
      };
    });
  };

  const handleAddFacultyDetails = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      facultyDetails: {
        ...prevFormData.facultyDetails,
        courseDetail: [
          ...prevFormData.facultyDetails.courseDetail,
          {
            class: '',
            course: '',
          },
        ],
      },
    }));
  };

  const handleRemoveFacultyDetails = (index) => {
    setFormData((prevFormData) => {
      const updatedCourseDetail = [...prevFormData.facultyDetails.courseDetail];
      updatedCourseDetail.splice(index, 1);
      return {
        ...prevFormData,
        facultyDetails: {
          ...prevFormData.facultyDetails,
          courseDetail: updatedCourseDetail,
        },
      };
    });
  };

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };

  const classes = useSelector(state => state.allClass);
  const courses = useSelector(state => state.allCourses);
  const program = useSelector(state => state.allProgram);


  function filterMatchingCourses(allFaculty, matchingCourses, classCode) {
    let facultyCourses = allFaculty.flatMap(faculty => {
      return faculty.facultyDetails && faculty.facultyDetails.courseDetail ?
        faculty.facultyDetails.courseDetail.map(course => ({
          class: course.class,
          course: course.course,
        })) :
        [];
    });

    if (updateFacultyInfo._id) {
      facultyCourses = facultyCourses.filter(Faculty => {
        return !updateFacultyInfo.facultyDetails.courseDetail.some(Update => {
          return Update.class === Faculty.class && Update.course === Faculty.course;
        });
      });
    }


    matchingCourses = matchingCourses.filter(course => {
      return !facultyCourses.some(facultyCourse => {
        return facultyCourse.class === classCode && facultyCourse.course === course.courseCode;
      });
    });

    return matchingCourses;
  }

  function getCoursesByClass(classCode) {
    const selectedClass = classes?.find((classObj) => classObj.classCode === classCode);
    if (!selectedClass) {
      return [];
    }
    const programCode = selectedClass.program;
    const selectedProgram = program.find((classObj) => classObj.programCode === programCode);
    const selectedProgramCode = selectedProgram.programCourses[selectedClass.semester] ? selectedProgram?.programCourses[selectedClass?.semester] : [];
    const matchingCourses = courses.filter((course) => selectedProgramCode.includes(course.courseCode));
    const filteredCourses = filterMatchingCourses(allFacultyInfo, matchingCourses, classCode);
    return filteredCourses;
  }

  const resetField = () => {
    setEditingId(null);
    setFormData({
      firstName: '',
      lastName: '',
      phoneNo: '',
      cnic: '',
      email: '',
      password: '',
      validity: true,
      facultyDetails: {
        department: '',
        courseDetail: [
          {
            class: '',
            course: '',
          }
        ],
      },
      role: 'faculty',
      refArray: [],
    })
    setUpdateFacultyInfo([]);
  };

  const handleAddFaculty = async (e) => {
    e.preventDefault();
    const cnicRegex = /^\d{13}$/;
    if (!cnicRegex.test(formData.cnic)) {
      toast.error('Invalid CNIC format. Please enter a valid CNIC number.');
      return;
    }
    const phoneRegex = /^\d{11}$/;
    if (!phoneRegex.test(formData.phoneNo.replace(/-/g, ''))) {
      toast.error('Invalid phone number format. Please enter a valid 10-digit phone number.');
      return;
    }
    setFormData({
      ...formData,
      userID: userInfo._id,
    });
    try {
      const response = await axios.post(`${backendBaseUrl}/api/users`, formData);
      toast.success(response.data.message);
      resetField();
      handleButtonClick('view');
    } catch (error) {
      const ResponseMsg = MessageByName(error.response?.data?.message, ResponseMessages);
      toast.error(ResponseMsg);
      console.error('API Error:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    if (updateFacultyInfo && updateFacultyInfo.length !== 0) {
      setEditingId(updateFacultyInfo._id);
      setFormData({
        firstName: updateFacultyInfo.firstName,
        lastName: updateFacultyInfo.lastName,
        phoneNo: updateFacultyInfo.phoneNo,
        cnic: updateFacultyInfo.cnic,
        email: updateFacultyInfo.email,
        validity: updateFacultyInfo.validity,
        password: '',
        facultyDetails: {
          department: updateFacultyInfo.facultyDetails?.department,
          courseDetail: updateFacultyInfo.facultyDetails?.courseDetail
        },
        role: 'faculty',
        refArray: updateFacultyInfo.refArray,
      })
    }
  }, [updateFacultyInfo]);


  const handleUpdateStudent = async () => {
    try {
      const response = await axios.put(`${backendBaseUrl}/api/users/update/${updateFacultyInfo._id}`, formData);
      toast.success(response.data.message);
      resetField();
      handleButtonClick('view');
    } catch (error) {
      const ResponseMsg = MessageByName(error.response?.data?.message, ResponseMessages);
      toast.error(ResponseMsg);
      console.error('API Error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className='flex flex-col items-center justify-start w-full h-auto overflow-y-auto lg:overflow-y-auto lg:style-scrollbar style-scrollbar'>
      <form className='w-full bg-gray-300 flex flex-col items-start justify-center lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh] text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh]'>
        <div className="w-full grid justify-center items-center gap-x-[1vw] gap-y-[1vh] 
      lg:grid-cols-2
      grid-cols-1">
          <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
            <label htmlFor='deptCode' className='font-semibold'>First Name:</label>
            <input
              type="text"
              placeholder="Enter your First Name"
              className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
              id='firstName'
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          </div>
          <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
            <label htmlFor='deptCode' className='font-semibold'>Last Name:</label>
            <input
              type="text"
              placeholder="Enter your Last Name"
              className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
              id='lastName'
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </div>
          <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
            <label htmlFor='deptCode' className='font-semibold'>Email address:</label>
            <input
              type="text"
              placeholder="Email address will used as username"
              className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
              id='email'
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
            <label htmlFor='deptCode' className='font-semibold'>Password:</label>
            <div className="relative w-full">
              <input
                type={showPassword ? "text" : "password"}
                placeholder={`${updateFacultyInfo._id ? "Enter new Password" : "Enter your Password"}`}
                className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
                id='password'
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
                onClick={handleTogglePasswordVisibility}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
          </div>
          <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
            <label htmlFor='deptCode' className='font-semibold'>Phone No:</label>
            <input
              type="text"
              placeholder="Enter your Phone No"
              className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
              id='phoneNo'
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleInputChange}
            />
          </div>
          <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
            <label htmlFor='deptCode' className='font-semibold'>CNIC No:</label>
            <input
              type="text"
              placeholder="Enter your CNIC without dashes"
              className="input w-full outline-PrimaryColor font-noraml text-[1vw]"
              id='cnic'
              name="cnic"
              value={formData.cnic}
              onChange={handleInputChange}
            />
          </div>
          {userInfo.role === "admin" &&
            <div className="flex flex-col justify-center items-start lg:gap-y-[0.5vh] lg:text-sm gap-y-[0.5vh] text-sm w-full">
              <label htmlFor="deptWebsiteLink" className="font-semibold">Assign Department to Executive Admins:</label>
              <Select
                className="h-full input select-primary min-w-full bg-white p-1"
                id="refArray"
                name="refArray"
                value={formData.refArray?.map(id => ({
                  value: id,
                  label: allExcutiveAdminInfo?.find(admin => admin._id === id)?.firstName || allDepartmentalAdminInfo?.find(admin => admin._id === id)?.firstName ||  '***'
                }))}
                onChange={(selectedOptions) => {
                  setFormData({
                    ...formData,
                    refArray: selectedOptions.map(option => option.value)
                  });
                }}
                placeholder="Select multiple Executive Admins"
                options={allExcutiveAdminInfo.map(admin => ({
                  value: admin._id,
                  label: `${admin.firstName} ${admin.lastName}`
                }))}
                isMulti
              />

            </div>
          }
          <div className='w-[10%] flex flex-col gap-y-[0.5vh] justify-center items-start'>
            <label htmlFor='validity' className='font-semibold text-black'>Status:</label>
            <div className='flex gap-x-[0.5vw] justify-center items-center'>
              <input
                className='checkbox'
                type="checkbox"
                id='validity'
                name="validity"
                checked={formData.validity}
                onChange={handleCheckBoxChange}
              />
              <label className={`text-center ${formData.validity ? "text-green-600" : "text-red-600"}`}> {formData.validity ? "Valid" : "In-Valid"} </label></div>
          </div>
          <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
            <label htmlFor='deptCode' className='font-semibold'>Select Department :</label>
            <select className="w-full bg-white select select-primary"
              id='department'
              name="department"
              value={formData.facultyDetails?.department}
              onChange={handleDepartmentChange}
            >
              <option selected disabled value="">
                Select a Department
              </option>
              {departments.map((dept) => (
                <option className='text-black' key={dept.deptCode} value={dept.deptCode}>
                  {`${dept.department}-(${dept.deptCode})`}
                </option>

              ))}
            </select>
          </div>

          <div className='flex flex-col gap-y-[1vh] w-full border px-[1vw] py-[1vh]'>
            {formData.facultyDetails?.courseDetail?.map((faculty, index) => (
              <div className='w-full flex flex-col gap-y-[1vw]'>
                <div className='w-full flex items-center justify-center gap-x-[1vw]'>
                  <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
                    <label htmlFor='deptCode' className='font-semibold'>Select Class Codes :</label>
                    <select className="w-full bg-white select select-primary"
                      id='class'
                      name="class"
                      value={faculty.class}
                      onChange={(e) => handleCourseDetailChange(e, index)}
                    >
                      <option selected disabled value="">
                        Select a Class code
                      </option>
                      {classes.map((clas) => (
                        <option className='text-black' key={clas.classCode} value={clas.classCode}>
                          {clas.classCode}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='w-full flex flex-col gap-y-[0.5vh] justify-center items-start'>
                    <label htmlFor='deptCode' className='font-semibold'>Select Course Code :</label>
                    <select className="w-full bg-white select select-primary"
                      id='course'
                      name="course"
                      value={faculty.course}
                      onChange={(e) => handleCourseDetailChange(e, index)}
                    >
                      <option selected disabled value="">
                        Select a course code
                      </option>
                      {getCoursesByClass(faculty.class) && getCoursesByClass(faculty.class).map((course) => (
                        <option className='text-black' key={course.courseCode} value={course.courseCode}>
                          {`${course.courseName}-(${course.courseCode})`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='w-[10%] h-full flex flex-col gap-y-[0.5vh] justify-end items-center'>
                    {index > 0 && (
                      <button className='px-[1vw] py-[1vh] bg-red-800 text-white rounded-[0.5vw] text-[0.8vw]' type="button" onClick={() => handleRemoveFacultyDetails(index)}>
                        Remove
                      </button>
                    )}
                  </div>
                </div>
                <div class="border-b border-black w-full"></div>
              </div>
            ))}
            <div className='flex justify-end w-full'>
              <button className='w-auto px-[1vw] py-[1vh] bg-purple-800 text-white rounded-[1vw]' type="button" onClick={handleAddFacultyDetails}>
                Assign more Courses
              </button>
            </div>
          </div>
        </div>

        <button
          className='px-[1vw] py-[1vh] bg-PrimaryColor text-white rounded-[0.5vw] font-bold'
          type='button'
          onClick={editingId !== null ? handleUpdateStudent : handleAddFaculty}
        >
          {editingId !== null ? 'Update Faculty' : 'Add Faculty'}
        </button>

      </form>
    </div>
  );
};

export default AddUpdateFaculty