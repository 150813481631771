import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faExpand, faCompress, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators';
import Apis from '../../../../Apis';
import { setAllCoursePlan } from '../../../../state/action-creators';
import EditButton from '../../../Admin/compoonents/EditButton';
import config from '../../../../config';

const CoursePlanActivity = ({ CoursePlanModalHandler, setUpdateCoursePlan, setOpenCoursePlanModal }) => {
  const selectedActivity = useSelector(state => state.selectedActivity);
  const userInfo = useSelector(state => state.userInfo);
  const backendBaseUrl = config.backendLocalhost;
  const dispatch = useDispatch();
  const [maxScreen, setMaxScreen] = useState(false);
  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [filterCoursePlan, setFilterCoursePlan] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);

  const handleActivityList = (value) => {
    dispatch(setSelectedActivity(value));
  };

  const maxScreenHandler = () => {
    setMaxScreen(!maxScreen);
    if (maxScreen) {
      handleActivityList("CoursePlan")
    }
    else {
      handleActivityList("")
    }

  };

  useEffect(() => {
    const filteyellowCourses = userInfo.facultyDetails.courseDetail
      .filter((info) => info.class === selectedClass)
      .map((info) => info.course);
    setCourses(filteyellowCourses);
  }, [selectedClass]);

  useEffect(() => {
    const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map((info) => info.class)));
    setClasses(uniqueClasses);
  }, [userInfo.facultyDetails]);

  const AllCoursePlan = useSelector(state => state.allCoursePlan);

  useEffect(() => {
    const sortedCoursePlan = [...AllCoursePlan].sort((b, a) => new Date(a.createdDate) - new Date(b.createdDate));
    if (sortedCoursePlan.length !== 0) {
      let filteyellow = sortedCoursePlan.filter((plan) => {
        if (!selectedCourse && !selectedClass) {
          return true;
        }
        if (selectedCourse && selectedClass) {
          return (
            plan.course === selectedCourse &&
            plan.class === selectedClass
          );
        }
        if (selectedCourse) {
          return plan.course === selectedCourse;
        }
        if (selectedClass) {
          return plan.class === selectedClass;
        }
        return false;
      });

      if (selectedCourse && selectedClass) {
        setFilterCoursePlan(filteyellow);
      } else {
        setFilterCoursePlan([]);
      }
    }
  }, [selectedCourse, selectedClass, AllCoursePlan]);


  const getAllCoursePlan = async () => {
    try {
      const CoursePlan = await Apis.getAllCoursePlan();
      dispatch(setAllCoursePlan(CoursePlan || []));
    } catch (error) {
      console.error('Error fetching CoursePlan:', error);
    }
  };

  useEffect(() => {
    getAllCoursePlan();
  }, []);

  const updateHandler = async (val) => {
    setOpenCoursePlanModal(true);
    setUpdateCoursePlan(val);
  };


  return (
    <div className={`${maxScreen ? "fixed w-full h-full top-0 left-0 z-20" : "w-full h-[5vh] rounded-[1vw] h-auto"} text-white bg-gray-200`}>
      <div
        className={`w-full h-[5vh] bg-gray-700 flex gap-x-[0.4vw] px-4 justify-between items-center cursor-pointer
    ${selectedActivity === "CoursePlan" ? `${maxScreen ? "" : "rounded-t-lg"}` : "rounded-lg"}
    `}
        onClick={!maxScreen ? (() => handleActivityList("CoursePlan")) : undefined}
      >
        <div className='flex gap-x-[1vw] justify-start items-center'>
          <FontAwesomeIcon
            className={`w-6 h-6 duration-300 ${selectedActivity === "CoursePlan" ? "rotate-180" : "rotate-0"}`}
            icon={faCaretDown}
            style={{ color: '#fff' }}
          />
          <p className='text-lg'>Course Plan</p>
        </div>
        <div>
          <button className="w-6 h-6 hover:scale-110 duration-300 text-white outline-none" onClick={() => maxScreenHandler()}>
            {maxScreen ? <FontAwesomeIcon className="w-full h-full " icon={faCompress} /> :
              <FontAwesomeIcon className="w-full h-full " icon={faExpand} />}
          </button>
        </div>
      </div>

      {selectedActivity === "CoursePlan" ?
        <div className='w-full pb-[2vh]'>
          <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh] px-[1vw]'>
            {/* Select Class */}
            <select
              id="classSelect"
              className='w-auto h-full text-black text-sm font-medium text-left border border-slate-700 rounded-[1vw] px-[1vw] focus:border-slate-700 focus:outline-none cursor-pointer'
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
            >
              <option value="">Select a class</option>
              {classes.map((className) => (
                <option key={className} value={className}>
                  {className}
                </option>
              ))}
            </select>
            {/* Select Course */}
            <select
              id="courseSelect"
              className='w-auto h-full text-black text-sm font-medium text-left border border-slate-700 rounded-[1vw] px-[1vw] focus:border-slate-700 focus:outline-none cursor-pointer'
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              <option value="">Select a course</option>
              {courses.map((course) => (
                <option key={course} value={course}>
                  {course}
                </option>
              ))}
            </select>
            {/* Add coursePlan */}
            <button className={`w-auto h-full text-sm font-medium px-[1vw] text-center border border-slate-700 rounded-[1vw] focus:border-slate-700 focus:outline-none
                         bg-white text-black hover:bg-slate-700 hover:text-white duration-300
                         ${filterCoursePlan.length === 0 ? "block" : "hidden"}`}
              onClick={() => CoursePlanModalHandler(selectedClass, selectedCourse)}
            >
              Add new
            </button>
          </div>
          <div className='h-auto w-full flex flex-col bg-gray-300 border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto overflow-x-auto style-scrollbar'>
            <table className="min-w-full bg-white text-black border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
              <thead className="sticky top-0 bg-white">
                <tr>
                  <th class="w-[5%] px-2 py-2 text-left border border-b border-gray-400 whitespace-nowrap">Week No.</th>
                  <th class="w-[75%] px-4 py-2 text-left border border-b border-gray-400">Topic</th>
                  <th class="w-[5%] px-4 py-2 text-left border border-b border-gray-400">Type</th>
                  <th class="w-[5%] px-4 py-2 text-left border border-b border-gray-400">CLO</th>
                  <th class="w-[5%] px-4 py-2 text-left border border-b border-gray-400">Weightage</th>
                  <th class="w-[5%] px-4 py-2 text-left border border-b border-gray-400">Status</th>
                  <th class="w-[5%] px-4 py-2 text-left border border-b border-gray-400">Action</th>
                </tr>
              </thead>

              <tbody>
                {filterCoursePlan && filterCoursePlan.map((dept, index) => (
                  dept.Detail.map((detailItem, detailIndex) => (
                    <tr key={`${dept._id}-${detailIndex}`} className={`text-sm font-normal divide-y divide-gray-200 ${detailIndex % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}>
                      <td className="px-4 py-2 text-left border-b whitespace-nowrap">{detailItem.weekNo}</td>
                      <td className="px-4 py-2 text-left border-b overflow-x-auto style-scrollbar">{detailItem.topic}</td>
                      <td className="px-4 py-2 text-left border-b whitespace-nowrap">{detailItem.type}</td>
                      <td className="px-4 py-2 text-left border-b whitespace-nowrap">{detailItem.CLO}</td>
                      <td className="px-4 py-2 text-left border-b whitespace-nowrap">{detailItem.weightage}</td>
                      <td class={`px-4 py-2 text-left border-b ${detailItem.status ? "text-green-800" : "text-red-800"}`}>{detailItem.status ? "Active" : "In-Active"}</td>
                      {detailIndex === 0 && (
                        <td className="px-4 py-3 text-[0.8vw] flex items-start justify-center" rowSpan={dept.Detail.length}>
                          <button onClick={() => updateHandler(dept)}>
                            <EditButton />
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                ))}

              </tbody>
            </table>
          </div>
        </div> : <></>
      }
    </div >
  )
}

export default CoursePlanActivity