import { SET_ACADEMIC_CALENDER } from '../action-creators/ActionTypes';

const AllAcademic = (state = [] , action) => {
    switch (action.type) {
        case SET_ACADEMIC_CALENDER:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllAcademic;
