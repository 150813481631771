import React from "react";

const Single = ({ optionsArray, setSurveyResult, surveyResult, quesNo }) => {

  const handleChangeRadio = (option) => {
    setSurveyResult(prevSurveyResult => {
      return prevSurveyResult.map(surveyItem => 
        surveyItem.quesNo === quesNo
          ? { ...surveyItem, Answer: [option] }
          : surveyItem
      );
    });
  };

  return (
    <div className="flex flex-col items-start justify-start w-full h-full">
      {optionsArray.map((option, index) => (
        <div key={option} className="flex items-center">
          <input
            type="radio"
            id={`${option}-${quesNo}`}
            value={option}
            checked={surveyResult.find(surveyItem => surveyItem.quesNo === quesNo)?.Answer[0] === option}
            onChange={() => handleChangeRadio(option)}
            className="text-blue-600 form-radio"
          />
          <label htmlFor={`${option}-${quesNo}`} className="ml-2 text-gray-700">{option}</label>
        </div>
      ))}
    </div>
  )
}

export default Single;
