import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import ActionOption from '../../../../components2/Employee/Pages/SurveyForms/ActionOption';
import ActionOption2 from '../../../../components2/Employee/Pages/SurveyForms/ActionOption2';
import TextOption from '../../../../components2/Employee/Pages/SurveyForms/TextOption';
import NumericOption from '../../../../components2/Employee/Pages/SurveyForms/NumericOption';
import MultipleOption from '../../../../components2/Employee/Pages/SurveyForms/MultipleOption';

const SurveyModal = ({ openSurvey, SurveyModalHandler ,feedbackData }) => {
    const [maxScreen, setMaxScreen] = useState(false);
    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    }
    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-20
        ${openSurvey
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
        ${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[70vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[15vw]"
                }
      `}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
         ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="text-[2vw] font-semibold">Survey</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
                    </button>
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    {/* Provide the implementation for AddProjectHandler */}
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => SurveyModalHandler("No Data")}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col justify-center items-center
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
                {feedbackData?.template.templateType === "singleSelection" ?
                <ActionOption
                templete={feedbackData}
                SurveyModalHandler={SurveyModalHandler}
                />
                :
                feedbackData?.template.templateType === "multipleSelection" ?
                <ActionOption2
                templete={feedbackData}
                SurveyModalHandler={SurveyModalHandler}
                />
                :
                feedbackData?.template.templateType === "textOption" ?
                <TextOption
                templete={feedbackData}
                SurveyModalHandler={SurveyModalHandler}
                />
                :
                feedbackData?.template.templateType === "gradingOption" ?
                <NumericOption
                templete={feedbackData}
                SurveyModalHandler={SurveyModalHandler}
                />
                :
                feedbackData?.template.templateType === "differentOption" ?
                <MultipleOption
                templete={feedbackData}
                SurveyModalHandler={SurveyModalHandler}
                />
                :
                <></>
                }
            </div>
        </div>
    );
}

export default SurveyModal