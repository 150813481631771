import React, { useState } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import config from "../../../../config";
import { useSelector, useDispatch } from 'react-redux';
import { SetTriggerNotificationStudent } from '../../../../state/action-creators';

const ActionOption2 = ({ templete, SurveyModalHandler }) => {
  const userInfo = useSelector(state => state.userInfo);
  const dispatch = useDispatch();

  const [surveyResult, setSurveyResult] = useState(
    templete?.template?.multipleSelection?.questionArray.map(q => ({
      quesNo: q.quesNo,
      question: q.question,
      questionType: "multipleSelection",
      Answer: []
    }))
  );

  const handleChangeCheckbox = (option, quesNo) => {
    setSurveyResult(prevResult =>
      prevResult.map(item =>
        item.quesNo === quesNo
          ? {
              ...item,
              Answer: item.Answer.includes(option)
                ? item.Answer.filter(ans => ans !== option)
                : [...item.Answer, option]
            }
          : item
      )
    );
  };

  const validateSurveyResult = () => {
    for (const item of surveyResult) {
      if (item.Answer.length === 0) {
        return false;
      }
    }
    return true;
  };

  const { backendLocalhost } = config;

  const handleAddTemplate = async () => {
    if (!validateSurveyResult()) {
      toast.error("Please answer all questions before submitting.");
      return;
    }

    const formData = {
      templateID: templete.template._id,
      studID: userInfo._id,
      classCode: templete.classCode,
      courseCode: templete.courseCode,
      surveyResult: surveyResult,
    };
    try {
      const response = await axios.post(`${backendLocalhost}/survey/createSurveyForm`, formData);
      toast.success(`Assign Template added successfully`);
      dispatch(SetTriggerNotificationStudent());
      SurveyModalHandler();
      setSurveyResult(templete?.template?.multipleSelection?.questionArray.map(q => ({
        quesNo: q.quesNo,
        question: q.question,
        questionType: "multipleSelection",
        Answer: []
      })));
    } catch (error) {
      console.error('Error adding assign template', error);
      if (error.response && error.response.data && error.response.data.errorMessage) {
        toast.error(error.response.data.errorMessage);
      } else {
        toast.error('Error adding assign template.');
      }
    }
  };

  return (
    <div className="flex flex-col justify-start w-full h-auto overflow-y-auto lg:h-full lg:overflow-y-auto lg:style-scrollbar style-scrollbar">
      <form className="w-full bg-gray-300 flex flex-col items-start justify-center 
        lg:text-black lg:text-sm lg:shadow-2xl lg:rounded-[1vw] lg:px-[2vw] lg:py-[4vh] lg:gap-y-[2vh]
        text-black text-sm shadow-2xl rounded-lg px-[2vw] py-[1vh] gap-y-[2vh] gap-2">
        <div className='flex w-full gap-x-2 '>
          <div className='flex w-full '>
            <label className='font-semibold'>
              Class:
            </label>
            <p className='mx-1 font-semibold'>({templete?.classCode && templete.classCode})</p>
          </div>
          <div className='flex w-full '>
            <label className='font-semibold'>Course:</label>
            <p className='mx-1 font-semibold'>({templete?.courseCode && `${templete.courseCode} - ${templete.courseName}`})</p>
          </div>
        </div>
        <div className='flex w-full '>
          <label className='font-semibold '>Faculty:</label>
          <p className='mx-1 font-semibold'>({templete?.facultyName && templete.facultyName})</p>
        </div>
        <div className='flex w-full '>
          <label className='font-semibold '>Survey Title:</label>
          <p className='mx-1 font-semibold'>({templete?.template?.templateName && templete.template?.templateName})</p>
        </div>
        <div className="w-full h-full p-4 border border-gray-400 rounded-lg">
          <div className="text-xl text-center">
            <h1>Instruction</h1>
          </div>
          <div className="mt-4 font-semibold text-center">
            <h1>
              Please read each question carefully and then select one or
              more options. Reply to all questions is mandatory. You can change
              any option until you press the SUBMIT button.
            </h1>
          </div>
        </div>

        <div className="flex flex-col w-full h-full p-3 border border-gray-400 rounded-lg">
          <div className="flex w-full h-full">
            <div className="w-[50%] text-black">
              <h1 className="font-semibold">Questions</h1>
            </div>
            <div className="w-[50%] text-black">
              <h1 className="font-semibold">Select one or more option</h1>
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            {templete?.template?.multipleSelection?.questionArray.map((q, index) => (
              <div className='flex items-center justify-center w-full h-full gap-2' key={index}>
                <div className="flex w-[5%] text-black">
                  <p>{q.quesNo}</p>
                </div>
                <div className="flex w-[50%] text-black">
                  <p>{q.question}</p>
                </div>
                <div className="flex flex-col w-[50%] h-full">
                  {templete?.template?.multipleSelection?.optionArray.map((option, optIndex) => (
                    <div key={optIndex} className="flex items-center">
                      <input
                        type="checkbox"
                        value={option}
                        checked={surveyResult.find(item => item.quesNo === q.quesNo).Answer.includes(option)}
                        onChange={() => handleChangeCheckbox(option, q.quesNo)}
                        className="form-checkbox"
                      />
                      <label
                        htmlFor={`${option}-${optIndex}`}
                        className="ml-2 text-gray-700"
                      >
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex w-full h-full space-x-2 ">
          <button
            type="button"
            onClick={handleAddTemplate}
            className="self-center w-full px-4 py-2 text-white duration-300 bg-green-500 rounded-md hover:bg-green-700 focus:outline-none focus:ring focus:ring-green-200 focus:ring-opacity-50"
          >
            Submit
          </button>
          <button
            type="button"
            className="self-center w-full px-4 py-2 text-white duration-300 rounded-md bg-slate-500 hover:bg-slate-700 focus:outline-none focus:ring focus:ring-green-200 focus:ring-opacity-50"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ActionOption2;
