import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Header/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import styles from "../Login/styles.module.css";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import config from '../../../config';

function ForgetPassword() {
    const BackendURL = config.backendLocalhost;
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const [newPassword, setNewPassword] = useState('');
    const [resetSuccess, setResetSuccess] = useState(false);
    const [resetting, setResetting] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');


    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const Loading = toast.loading("Resetting new password ....")

        try {
            setResetting(true);
            const response = await axios.post(`${BackendURL}/forgotPassword/reset`, {
                token: token,
                newPassword: newPassword,
            });
            if (response.status === 200) {
                setResetSuccess(true);
                toast.dismiss(Loading);
                toast.success("Success")
                setTimeout(() => {
                    return navigate("/");
                }, 2000);
            } else {
                toast.error('Password reset failed. Please try again.');
                toast.dismiss(Loading);
            }
        } catch (error) {
            toast.error(error.response.data.message)
            toast.dismiss(Loading);
        } finally {
            toast.dismiss(Loading);
        }
    };

    return (
        <div className="flex flex-col w-full h-full bg-blue-100">
            <Header />
            <div className={styles.login_container}>

                <div className={`${styles.login_form_container} flex items-center justify-center bg-white`}>
                    <div className='h-[40%] max-h-full flex flex-col items-center justify-start gap-y-6 bg-blue-300'>
                        <div className="bg-transparent p-8 rounded shadow-md w-96">
                            <h2 className="text-2xl font-semibold mb-4">Password Reset</h2>
                            {resetSuccess ? (
                                <div>
                                    <p>Password successfully reset!</p>
                                    <p>Redirecting to the login page...</p>
                                </div>
                            ) : (
                                <form onSubmit={handleSubmit}>
                                    <label htmlFor="newPassword">New Password:</label>
                                    <div className="w-full relative">

                                        <input
                                            type={showPassword ? "text" : "password"}
                                            id="newPassword"
                                            value={newPassword}
                                            onChange={handlePasswordChange}
                                            className="w-full p-2 border rounded focus:outline-none focus:border-blue-500"
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 flex items-center px-3 focus:outline-none"
                                            onClick={handleTogglePasswordVisibility}
                                        >
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </button>
                                    </div>
                                    <button
                                        type="submit"
                                        className={`mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 ease-in-out ${resetting ? 'opacity-50 cursor-not-allowed' : ''}`
                                        }
                                        disabled={resetting}
                                    >
                                        {resetting ? 'Resetting...' : 'Reset Password'}
                                    </button>
                                </form>
                            )}
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    );
}

export default ForgetPassword;
