import React from 'react'
import OnlineClass from '../../../components/employeeComp/Dashboard/OnlineClass'

const Online = () => {
  return (
    <div>
      <OnlineClass/>
    </div>
  )
}

export default Online
