import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const MultiAssign = ({ result }) => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: 'bar',
        height: 250
      },
      plotOptions: {
        bar: {
          horizontal: true 
        }
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        title: {
          text: 'Count' 
        }
      }
    },
    series: [{
      name: 'Count',
      data: []
    }]
  });

  useEffect(() => {
    const countOccurrences = (arr) => {
      return arr.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {});
    };

    const resultCounts = countOccurrences(result);
    const categories = Object.keys(resultCounts);
    const data = Object.values(resultCounts);

    setChartData({
      options: {
        chart: {
          type: 'bar',
          height: 250
        },
        plotOptions: {
          bar: {
            horizontal: true 
          }
        },
        xaxis: {
          categories: categories
        },
        yaxis: {
          title: {
            text: 'Count' 
          }
        }
      },
      series: [{
        name: 'Count',
        data: data
      }]
    });
  }, [result]);

  return (
    <div className="chart-container">
      <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height="250" />
    </div>
  );
};
export default MultiAssign;
