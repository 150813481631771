import { SET_ALLLINK} from '../action-creators/ActionTypes'; // Assuming you have SET_ALL_LINKS action type defined

const AllLinks = (state = [], action) => {
    switch (action.type) {
        case SET_ALLLINK:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default AllLinks;
