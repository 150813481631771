// reducers/index.js
import { combineReducers } from "redux";
import FacultySelectedActivityReducer from "./FacultyActivityListToggle";
import AttendanceDataReducer from "./AttendanceData";
import UserInfo from "./UserInfo";
import AllStudentInfo from "./AllStudentInfo"
import AssignmentDetails from "./AssignmentDetails"
import SubmittedAssignments from "./SubmittedAssignments";
import SubmittedQuizzes from "./SubmittedQuizzes"
import QuizDetails from "./QuizDetails"
import LearningDetails from "./LearningDetails";
import AllAttendance from "./AllAttendace";
import SelectedMenuButton from "./SelectedMenuButton";
import AllDepartment from "./AllDepartment";
import AllCourses from "./AllCourses"
import AllClass from "./AllClass"
import AllSession from "./AllSession"
import AllLink from "./AllLink"
import AllProgram from "./AllProgram"
import AllFacultyInfo from "./AllFacultyInfo";
import ActiveSession from "./ActiveSession";
import WeeklySchedule from "./WeeklySchedule";
import ConfigName from "./ConfigName";
import AllAcademic from "./AllAcademic"
import AllExam from "./AddExam";
import SubmittedExam from "./SubmittedExam";
import AllCoursePlan from "./AllCoursePlan"
import AuditTrail from "./AuditTrail";
import ViewFile from "./ViewFile";
import ResponseMessage from "./ResponseMessage";
import OnlineMeeting from "./OnlineMeeting";
//Trigger Apis
import TriggerNotificationStudent from "./TriggerNotificationStudent";

const reducers = combineReducers({
    selectedActivity: FacultySelectedActivityReducer,
    attendanceData: AttendanceDataReducer,
    allAttendance:AllAttendance,
    userInfo: UserInfo, 
    allStudentInfo: AllStudentInfo,
    allFacultyInfo:AllFacultyInfo,
    assignmentDetails:AssignmentDetails,
    afterAssignmentSubmitted:SubmittedAssignments,
    afterQuizSubmitted:SubmittedQuizzes,
    afterExamSubmitted:SubmittedExam,
    quizDetails:QuizDetails,
    allExam:AllExam,
    allCoursePlan:AllCoursePlan,
    learningDetails:LearningDetails,
    selectedMenuBtn:SelectedMenuButton,
    onlineMeeting:OnlineMeeting,
    auditTrail:AuditTrail,
    //Admin Config states
    allDept:AllDepartment,
    allCourses:AllCourses,
    allClass:AllClass,
    allSession:AllSession,
    allLink:AllLink,
    allProgram:AllProgram,
    activeSession:ActiveSession,
    weeklySchedule:WeeklySchedule,
    //Confuration States
    configName:ConfigName,
    allAcademic:AllAcademic,
    viewFile:ViewFile,
     //App.js Apis Data
    responseMessage:ResponseMessage,
    //Trigger Apis
    triggerNotificationStudent:TriggerNotificationStudent,
});

export default reducers;
