// reducers/FacultyActivityListToggle.js
import { SET_ACTIVE_SESSION } from '../action-creators/ActionTypes';

const ActiveSession= (state = '' , action) => {
    switch (action.type) {
        case SET_ACTIVE_SESSION:
            return state === action.payload ? [] : action.payload;
        default:
            return state;
    }
};

export default ActiveSession;
