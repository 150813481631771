import React from 'react';
import EditButton from '../EditButton';
import { useSelector } from 'react-redux';

const ViewStudent = ({setUpdateStudInfo,handleButtonClick}) => {

  const allStudentInfo = useSelector(state => state.allStudentInfo);

  const handleEditDepartment = (stud) => {
    setUpdateStudInfo(stud);
    handleButtonClick('create');
  };

  return (
    <div className='h-auto w-full flex flex-col bg-gray-300 style-scrollbar border border-gray-900 rounded-b-lg lg:max-h-full lg:overflow-auto max-h-auto overflow-x-auto'>
    <table className="min-w-full table table-auto text-black bg-SecondaryColor rounded-lg" style={{ width: '100%' }}>
        <thead className=''>
          <tr className='text-sm font-semibold'>
            <th className="px-4 py-3 text-center border border-gray-400">Sr.</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">First Name</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Last Name</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">Phone No</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">CNIC</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">Email</th>
            <th className="px-4 py-3 text-left border  border-gray-400 ">RollNo</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Class Code</th>
            <th className="px-4 py-3 text-left border border-gray-400 ">Status</th>
            <th className="px-4 py-3 text-center border  border-gray-400 ">Actions</th>
          </tr>
        </thead>
        <tbody>
          {allStudentInfo.map((stud, index) => (
            <tr key={stud._id}
              className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}
            >
              <td className="px-4 py-3 text-center ">{index + 1}</td>
              <td className="px-4 py-3 text-left">{stud.firstName}</td>
              <td className="px-4 py-3 text-left">{stud.lastName}</td>
              <td className="px-4 py-3 text-left">{stud.phoneNo}</td>
              <td className="px-4 py-3 text-left">{stud.cnic}</td>
              <td className="px-4 py-3 text-left">{stud.email}</td>
              <td className="px-4 py-3 text-left">{stud.studentDetails?.rollNo}</td>
              <td className="px-4 py-3 text-left">{stud.studentDetails?.class}</td>
              <td className={`px-4 py-3 text-center font-bold ${stud.validity ? "text-green-600" : "text-red-600"}`}>{stud.validity ? "Valid" : "In-Valid"}</td>
              <td className="px-4 py-3 text-[0.8vw] flex-shrink-0 text-center  ">
                <button onClick={() => handleEditDepartment(stud)}>
                  <EditButton />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewStudent