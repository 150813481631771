import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toast } from "react-hot-toast";
import { useSelector } from 'react-redux';
import config from '../../../../config';
import Apis from '../../../../Apis';
import {MessageByName} from "../Modals/AddNotification"

const QuizDetail = ({ openQuizDetail, QuizDetailHandler, quizDetail }) => {

    const [maxScreen, setMaxScreen] = useState(false);
    const [studFile, setStudFile] = useState(null);
    const [studIDFile, setStudIDFile] = useState("");
    const ResponseMessages = useSelector(state => state.responseMessage);
    const backendBaseUrl = config.backendLocalhost;
    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    };

    const allStudentInfo = useSelector(state => state.allStudentInfo);
    const userInfo = useSelector(state => state.userInfo);
    const quizID = quizDetail._id;
    const [quizStudDetail, setQuizStudDetail] = useState([]);

    const handleFileChange = (e, studID) => {
        const file = e.target.files[0];
        setStudFile(file);
        setStudIDFile(studID);
    };

    useEffect(() => {
        if (quizDetail.category === "Online") {
            setQuizStudDetail(quizDetail.quizDetail);
        }
        else if (quizDetail.category === "Verbal" || quizDetail.category === "Paper-based") {
            setQuizStudDetail(getMatchingStudents(quizDetail.class))
        }
    }, [quizDetail.category, quizDetail.class, quizDetail.quizDetail, openQuizDetail]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    function getMatchingStudents(classToMatch) {
        const currentDate = new Date();
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
    
        const date = new Date(currentDate);
        const day = ("0" + date.getDate()).slice(-2);
        const monthIndex = date.getMonth();
        const month = months[monthIndex];
        const year = date.getFullYear(-2);
        const optionsTime = {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit'
        };
    
        const formattedDate = `${day}-${month}-${year}`;
        const formattedTime = currentDate.toLocaleTimeString('en-US', optionsTime);
    
        const formattedDateTime = `${formattedDate} - ${formattedTime}`;
        
        let quizDetailed = [];
        if (quizDetail.quizDetail.length === 0) {
            const matchingStudents = allStudentInfo.filter((student) => {
                return (
                    student.role === 'student' &&
                    student.studentDetails.class === classToMatch
                );
            });

            const newQuizDetail = matchingStudents.map((student) => {
                return {
                    status: 'submitted',
                    studId: student._id,
                    studName: `${student.firstName} ${student.lastName}`,
                    submittedDetail: formattedDateTime,
                };
            });

            quizDetailed = newQuizDetail;
        } else {
            quizDetailed = quizDetail.quizDetail
        }

        return quizDetailed;
    }

    const closeModal = () => {
        QuizDetailHandler(quizDetail);
        setQuizStudDetail([])
    };


    const updateQuizMarks = async (name, mark, id, total) => {
        let loadingToast = toast.loading('Loading...');
        try {
            if (parseInt(mark) < -1 || !mark) {
                toast.error("ERROR! Obtained Marks must be greater than -1");
                toast.dismiss(loadingToast);
                return;
            }
            if (parseInt(total) < parseInt(mark)) {
                toast.error(`ERROR! Obtained Marks must be less than Total Marks for ${name}`);
                toast.dismiss(loadingToast);
                return;
            }
            if (!(parseInt(mark) >= 0)) {
                toast.error(`ERROR! Obtained Marks must be a positive integer for ${name}`);
                toast.dismiss(loadingToast);
                return;
            }
            if (quizDetail.category === "Online" || quizDetail.category === "Verbal") {
                const updatedQuizStudDetail = quizStudDetail.map((detail) => {
                    if (detail.studId === id) {
                        return { ...detail, status: "marked" };
                    }
                    return detail;
                });
                setQuizStudDetail(updatedQuizStudDetail);
                const formDataToSend = new FormData();
                formDataToSend.append("quizDetail", JSON.stringify(updatedQuizStudDetail));

                const response = await axios.put(
                    `${backendBaseUrl}/quiz/markedByFaculty/${quizID}`,
                    formDataToSend,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );
            }
            else {
                if (studIDFile !== id) {
                    toast.error(`ERROR! Please choose File for ${name}`);
                    return;
                }
                const updatedQuizStudDetail = quizStudDetail.map((detail) => {
                    if (detail.studId === id) {
                        return { ...detail, status: "marked" };
                    }
                    return detail;
                });
                setQuizStudDetail(updatedQuizStudDetail);
                const formDataToSend = new FormData();
                formDataToSend.append("quizDetail", JSON.stringify(updatedQuizStudDetail));
                formDataToSend.append("studIDFile", studIDFile);
                formDataToSend.append("studFiles", studFile);

                const response = await axios.put(
                    `${backendBaseUrl}/quiz/markedByFacultyPaperBased/${quizID}`,
                    formDataToSend,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
            }
            Apis.addAuditTrail(userInfo._id,"663492974fba113615476cc1",`- [ClassID:${quizDetail.class}, CourseID:${quizDetail.course}, No:${quizDetail.quizNo}, StudID:${id}]`);
            const ResponseMsg = MessageByName("Quiz marked by instructor",ResponseMessages);
            toast.success(`${ResponseMsg}-${name}: ${mark}`);
            toast.dismiss(loadingToast);
        } catch (error) {
            console.error('Error sending data to the backend:', error);
            toast.error(`Error marking quiz for ${name}:`, error);
            toast.dismiss(loadingToast);
        }
    };

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-40
                ${openQuizDetail
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
                ${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[70vw] h-[60vh] rounded-t-[1vw] top-[15vh] left-[15vw]"
                }
            `}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-yellow-900 via-yellow-600 to-yellow-300
                 ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"}
                `}
            >
                <div>
                    <h1 className="text-[2vw] font-semibold">{`Quiz: ${quizDetail.quizNo}`}</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff" }} />
                    </button>
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    <button className="w-[1.2vw] h-[1.2vw] hover:scale-110 duration-300" onClick={() => closeModal()}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col justify-center items-center
                ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>

                <div className='w-full h-[30%] p-[1vw] flex flex-col gap-y-[1vh]'>
                    <div className='w-full h-[50%] flex gap-x-[1vw]'>
                        <div className='rounded-[0.5vw] w-[25%] bg-yellow-600 flex justify-center items-center'>
                            <p className='text-[1.2vw] text-white'>Quiz Type{": "}
                                <span className='text-[1vw] text-white font-semibold'>{quizDetail.category}</span>
                            </p>
                        </div>
                        <div className='rounded-[0.5vw] w-[25%] bg-yellow-600 flex justify-center items-center'>
                            <p className='text-[1.2vw] text-white'>Due Date{": "}
                                <span className='text-[1vw]  text-white font-semibold'>{quizDetail.dueDate}</span>
                            </p>
                        </div>
                        <div className='rounded-[0.5vw] w-[25%] bg-yellow-600 flex justify-center items-center'>
                            <p className='text-[1.2vw] text-white'>Total Marks{": "}
                                <span className='text-[1vw]  text-white font-semibold'>{quizDetail.totalMarks}</span>
                            </p>
                        </div>
                        <div className='rounded-[0.5vw] w-[25%] bg-yellow-600 flex justify-center items-center'>
                            <p className='text-[1.2vw] text-white'>Quiz File{": "}
                                {quizDetail.facultyFile === "no file" ? "No File" :
                                    <a className='text-[1vw] text-white font-semibold hover:underline hover:text-blue-600'
                                        href={`${backendBaseUrl}/files/${quizDetail.facultyFile}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >Download</a>
                                }
                            </p>
                        </div>
                    </div>
                    <div className='w-full h-[50%]'>
                        <div className='flex items-center justify-start w-full h-full bg-yellow-600 rounded-[0.5vw] px-[1vw]'>
                            <p className='text-[1.2vw] text-white'>Description {":"}
                                <span className='text-[1vw] px-[1vw] py-[1vh] text-white font-semibold'>
                                    {quizDetail.description && quizDetail.description.length > 120 ? (
                                        `${quizDetail.description.slice(0, 120)}... `
                                    ) : (
                                        quizDetail.description
                                    )}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='w-full h-[70%] bg-gray-50'>
                    <div className='w-full h-full p-[1vw] shadow-2xl overflow-auto style-scrollbar'>
                        <table className="min-w-full bg-white border rounded-[1vw] border-gray-300">
                            <thead className='text-white bg-yellow-900'>
                                <tr>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center border border-yellow-400">Sr.</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-left border border-yellow-400">Student Name</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-left border border-yellow-400">Date - Time</th>
                                    <th className={`px-[1vw] py-[1vh] border-b text-[1vw] font-normal border border-yellow-400 text-center ${quizDetail.category === "Verbal" ? "hidden" : "block"}`}>File</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal border border-yellow-400 text-left">Obtained Marks</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal border border-red-400 text-left">Weighted Marks</th>
                                    <th className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal border border-yellow-400">Submit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quizStudDetail && quizStudDetail.map((detail, index) => (
                                    <tr key={index} className="bg-gray-200">
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center">{index + 1}</td>
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-left">{detail.studName}</td>
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-left">{detail.submittedDetail}</td>
                                        <td className={`px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center hover:underline hover:text-blue-600 ${quizDetail.category === "Verbal" ? "hidden" : "block"}`}>
                                            {detail.studentFile ?
                                                <a
                                                    href={`${backendBaseUrl}/files/${detail.studentFile}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >Download</a>
                                                :
                                                <input
                                                    id="file_input"
                                                    type="file"
                                                    className="px-2 py-2 w-[15vw] text-white transition-all duration-300 ease-in-out bg-blue-500 border border-blue-300 rounded-md cursor-pointer hover:bg-blue-600"
                                                    onChange={(e) => handleFileChange(e, detail.studId)}
                                                />
                                            }
                                        </td>
                                        <td className="px-[1vw] border-b text-[1vw] font-normal text-left">
                                            <input
                                                className='w-full h-full py-[1vh] '
                                                type="text"
                                                value={detail.obtainedMarks || null} // Set the value from the quizStudDetail array
                                                onChange={(e) => {
                                                    const updatedDetail = { ...detail, obtainedMarks: e.target.value };
                                                    const updatedStudDetail = [...quizStudDetail];
                                                    updatedStudDetail[index] = updatedDetail;
                                                    setQuizStudDetail(updatedStudDetail);
                                                }}
                                                disabled={detail.status === 'marked'}
                                            />
                                        </td>
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-left">{detail.obtainedMarks && ((detail.obtainedMarks * quizDetail.weightage) / quizDetail.totalMarks).toFixed(2)}</td>
                                        <td className="px-[1vw] py-[1vh] border-b text-[1vw] font-normal text-center">
                                            <button
                                                className={`px-[0.5vw] py-[0.5vh] border font-bold border-yellow-900 rounded-[0.5vw] ${detail.status === 'marked' ? "bg-green-100" : "bg-yellow-100 hover:text-white hover:bg-yellow-900 hover:border-yellow-400 duration-300"} `}
                                                onClick={() => { updateQuizMarks(detail.studName, detail.obtainedMarks, detail.studId, quizDetail.totalMarks) }}
                                                disabled={detail.status === 'marked'}
                                            >
                                                {detail.status === 'marked' ? "Marked" : "Mark"}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuizDetail;
