import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faExpand, faCompress, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators';
import Apis from '../../../../Apis';
import { toast } from "react-hot-toast";

const ViewReports = ({ ReportIndividualDetailHandler }) => {
  // State variables to manage selected values
  const [selectedClass, setSelectedClass] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);
  const [maxScreen, setMaxScreen] = useState(false);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo);
  const selectedActivity = useSelector(state => state.selectedActivity);
  const [report, setReport] = useState([]);

  const handleActivityList = (value) => {
    dispatch(setSelectedActivity(value));
  };

  useEffect(() => {
    const uniqueClasses = Array.from(new Set(userInfo.facultyDetails.courseDetail.map((info) => info.class)));
    setClasses(uniqueClasses);
  }, [userInfo.facultyDetails]);

  useEffect(() => {
    const filteyellowCourses = userInfo.facultyDetails.courseDetail
      .filter((info) => info.class === selectedClass)
      .map((info) => info.course);
    setCourses(filteyellowCourses);
  }, [selectedClass]);


  const maxScreenHandler = () => {
    setMaxScreen(!maxScreen);
    if (maxScreen) {
      handleActivityList("Reports")
    }
    else {
      handleActivityList("")
    }
  };

  const getReportbyCLassCourse = async (classCode, courseCode) => {
    const loading = toast.loading("Generating Report")
    try {
      const Report = await Apis.getDetailPerformanceReport(classCode, courseCode);
      setReport(Report);
      toast.dismiss(loading);
      if(Report.length > 0){
        toast.success("Generated")
      }
      else{
        toast.success("No Record Found")
      }
    } catch (error) {
      console.error('Error fetching Report:', error);
      toast.error("Error! Report can't be generated")
    }
  };

  const GenerateReportHandler = () => {
    if (selectedClass !== "" && selectedCourse !== ""){
      getReportbyCLassCourse(selectedClass, selectedCourse);
    }
    else{
      toast.error("Error! Select Class and Course")
    }
      
  };

  return (
    <div className='w-full pb-[2vh]'>
    <div className='w-full h-[8vh] flex justify-start items-center gap-x-[1vw] py-[1vh] px-[1vw]'>
      {/* Select Class */}
      <select
        id='classSelect'
        className='w-auto h-full text-black text-left border border-yellow-700 rounded-[1vw] px-[1vw] focus:border-yellow-700 focus:outline-none cursor-pointer'
        value={selectedClass}
        onChange={(e) => setSelectedClass(e.target.value)}
      >
        <option value=''>Select a Class</option>
        {classes.map((className) => (
          <option key={className} value={className}>
            {className}
          </option>
        ))}
      </select>

      {/* Select Course */}
      <select
        id='courseSelect'
        className='w-auto h-full text-black text-left border border-yellow-700 rounded-[1vw] px-[1vw] focus:border-yellow-700 focus:outline-none cursor-pointer'
        value={selectedCourse}
        onChange={(e) => setSelectedCourse(e.target.value)}
      >
        <option value=''>Select a Course</option>
        {courses.map((course) => (
          <option key={course} value={course}>
            {course}
          </option>
        ))}
      </select>
      {/* Generate Report Button */}
      <button className='button-84'
      onClick={()=>GenerateReportHandler()}
      >
        {"Generate Report"}
      </button>
    </div>
    <div className='w-full overflow-auto style-scrollbar'>
      <table className="min-w-full bg-white text-black border-spacing-[1vw] border rounded-[1vw] border-gray-300 table-fixed">
        <thead className="sticky top-0 bg-white">
          <tr>
            <th className='px-4 py-2 text-left border-t border-l border-r border-gray-400'>Sr</th>
            <th className='px-4 py-2 text-left border-t border-l border-r border-gray-400'>Student Roll No.</th>
            <th className='px-4 py-2 text-left border-t border-l border-r border-gray-400'>Student Name</th>
            <th colspan="2" className='px-4 py-2 text-left border-t border-l border-r border-gray-400'>Attendance</th>
            <th colspan="2" className='px-4 py-2 text-left border-t border-l border-r border-gray-400'>Assignment</th>
            <th colspan="2" className='px-4 py-2 text-left border-t border-l border-r border-gray-400' >Quiz</th>
            <th colspan="2" className='px-4 py-2 text-left border-t border-l border-r border-gray-400'>Exam</th>
            <th className='px-4 py-2 text-left border-t border-l border-r border-gray-400'>Show Detail</th>
          </tr>
          <tr>
            <th className='px-4 py-2 text-left border-b border-l border-r border-gray-400'></th>
            <th className='px-4 py-2 text-left border-b border-l border-r border-gray-400'></th>
            <th className='px-4 py-2 text-left border-b border-l border-r border-gray-400'></th>
            <th className='px-4 py-2 text-left border border-gray-400'>%age</th>
            <th className='px-4 py-2 text-left border border-gray-400'>Progress</th>
            <th className='px-4 py-2 text-left border border-gray-400'>%age</th>
            <th className='px-4 py-2 text-left border border-gray-400'>Progress</th>
            <th className='px-4 py-2 text-left border border-gray-400'>%age</th>
            <th className='px-4 py-2 text-left border border-gray-400'>Progress</th>
            <th className='px-4 py-2 text-left border border-gray-400'>%age</th>
            <th className='px-4 py-2 text-left border border-gray-400'>Progress</th>
            <th className='px-4 py-2 text-left border-b border-l border-r border-gray-400'></th>
          </tr>
        </thead>
        <tbody>
          {report && report.map((val, index) => (
            <tr key={index} className={`text-sm font-normal divide-y divide-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-200'}`}>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{index + 1}</td>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{val.RollNo}</td>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{val.Name}</td>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{val.Assignment.Percentage === "NaN" ? "0%" : ((val.Attendance.Present/val.Attendance.Total)*100)}</td>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{`${val.Attendance.Present} / ${val.Attendance.Total}`}</td>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{val.Assignment.Percentage === "NaN" ? "0%" : val.Assignment.Percentage}</td>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{`${val.Assignment.Marked} / ${val.Assignment.Total}`}</td>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{val.Quiz.Percentage === "NaN" ? "0%" : val.Quiz.Percentage}</td>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{`${val.Quiz.Marked} / ${val.Quiz.Total}`}</td>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{val.Exam.Percentage === "NaN" ? "0%" : val.Exam.Percentage}</td>
              <td className='px-4 py-2 text-left whitespace-nowrap'>{`${val.Exam.Marked} / ${val.Exam.Total}`}</td>
              <td className='px-4 py-2 flex justify-center items-start'>
                <button className={`flex justify-center items-center bg-[#6ECB32]
5xl:w-[4vh] 5xl:h-[3vh] 5xl:rounded-[0.3vw] 5xl:text-sm
4md:p-[0.75vh] 4md:rounded-[0.3vw]  4md:text-sm
p-[1vh] rounded-[0.7vw] text-lg`}
                  onClick={() => ReportIndividualDetailHandler(val)}
                >
                  <FontAwesomeIcon icon={faCircleInfo} style={{ color: "#fafcff", }} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

  </div>
  )
}

export default ViewReports