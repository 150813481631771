import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from 'react-redux';
import { setViewFile } from '../state/action-creators';
import { faXmark, faExpand, faCompress, faPrint } from "@fortawesome/free-solid-svg-icons";
import config from '../config';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const ViewFileModal = () => {
    const dispatch = useDispatch();
    const viewFile = useSelector(state => state.viewFile);
    const [maxScreen, setMaxScreen] = useState(true);
    const srcURL = `${config.backendLocalhost}/learning/${viewFile.FileData.learningFile}`

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
    }

    const Closing = () => {
        dispatch(setViewFile({
            openModal: false,
            FileData: {},
        }));
    }

    const docs = [
        {
            uri: srcURL,
            fileType: viewFile.FileData.FileType,
            fileName: viewFile.FileData.learningFile,
        },
    ];

    return (
        <div
            className={`absolute flex-1 duration-300 rounded-lg bg-white z-30
        ${viewFile.openModal
                    ? "scale-100 duration-300"
                    : "scale-0 -z-10"
                }
        ${maxScreen
                    ? "w-[100%] h-[100%] top-0 left-0 m-0 translate-0 translate-x-0 translate-y-0 drag"
                    : "w-[90vw] h-[60vh] rounded-t-[1vw]  top-[15vh] left-[5vw]"
                }
      `}
        >
            <header
                className={`w-full flex justify-between items-center px-[2vw] py-[1vw] text-white bg-gradient-to-r from-blue-900 via-blue-600 to-blue-300
         ${maxScreen ? "h-[15%]" : "h-[20%] rounded-t-[1vw]"
                    }`}
            >
                <div>
                    <h1 className="xl:text-[2vw] font-semibold">{`${viewFile.FileData.category} View`}</h1>
                </div>
                <div className="flex gap-x-[1vw]">
                    <button className='w-3 h-3 duration-300 hover:scale-110'>
                        <FontAwesomeIcon className='w-full h-full' icon={faPrint} style={{ color: "#ffffff", }} />
                    </button>
                    <button className="w-3 h-3 duration-300 hover:scale-110" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full" icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full" icon={faExpand} />}
                    </button>
                    {/* Provide the implementation for AddProjectHandler */}
                    <button className="w-3 h-3 duration-300 hover:scale-110" onClick={() => Closing()}>
                        <FontAwesomeIcon className="w-full h-full" icon={faXmark} />
                    </button>
                </div>
            </header>
            <div className={`w-full flex flex-col
            ${maxScreen ? "h-[85%]" : "h-[80%] rounded-t-[1vw]"}
            `}>
                <div className='flex items-center justify-center w-full h-full'>
                    {viewFile.FileData.FileType === "docx" || viewFile.FileData.FileType === "pptx" || viewFile.FileData.FileType === "xlsx" ?
                        <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
                        :
                        viewFile.FileData.FileType === "mp4" || viewFile.FileData.FileType === "mov" || viewFile.FileData.FileType === "avi" || viewFile.FileData.FileType === "wmv" || viewFile.FileData.FileType === "mkv" ?
                            <div className="w-[60%] h-full py-12 bg-transparent rounded-xl overflow-hidden">
                                <video className="inset-0 object-cover w-full h-full" controls autoPlay loop>
                                    <source src={srcURL} type={`video/mp4`}></source>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            :
                            viewFile.FileData.FileType === "mp3" || viewFile.FileData.FileType === "amr" || viewFile.FileData.FileType === "wav" || viewFile.FileData.FileType === "m4a" ?
                                <div className="w-[60%] h-full py-12 bg-transparent rounded-xl overflow-hidden">
                                    <video className="inset-0 object-cover w-full h-full" controls autoPlay loop style={{ height: '40px' }}>
                                        <source src={srcURL} type="audio/mp3" />
                                        Your browser does not support the audio tag.
                                    </video>
                                </div>
                                :
                                viewFile.FileData.FileType === "jpg" || viewFile.FileData.FileType === "png" || viewFile.FileData.FileType === "jpeg" || viewFile.FileData.FileType === "gif" || viewFile.FileData.FileType === "tiff" ?
                                    <div className="w-[60%] h-full py-12 bg-transparent rounded-xl overflow-hidden">
                                        <img className="w-full h-full object-fit" src={srcURL} alt="Images" />
                                    </div>

                                    :
                                    <></>
                    }
                </div>
            </div>
        </div>
    );
}

export default ViewFileModal