import React, { useState } from 'react';

const SubNumericField = ({range, handleRangeChange}) => {

  return (
    <div className='flex'>
      <div className='justify-start mx-2 mt-3 text-black'>
          <h1>Enter Range of Numeric value:</h1>
        </div>
      <div className="flex items-center justify-center w-auto gap-x-2">
        <input
            type="number"
            name="from"
            value={range.from}
            placeholder="From"
            onChange={handleRangeChange}
            className="block w-full p-2 mt-2 text-black border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        <input
            type="number"
            name="to"
            value={range.to}
            placeholder="To"
            onChange={handleRangeChange}
            className="block w-full p-2 mt-2 text-black border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
      </div>
    </div>
  )
}

export default SubNumericField
