import React, { useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faExpand, faCompress } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActivity } from '../../../../state/action-creators';
import OnlineLectureSub from '../../../../components2/Faculty/Pages/OnlineLecture/OnlineLectureSub';

function OnlineLecture({OnlineLectureHandler}) {
    const selectedActivity = useSelector(state => state.selectedActivity);
    const dispatch = useDispatch();
    const [maxScreen, setMaxScreen] = useState(false);

    const handleActivityList = (value) => {
        dispatch(setSelectedActivity(value));
    };

    const maxScreenHandler = () => {
        setMaxScreen(!maxScreen);
        if (maxScreen) {
            handleActivityList("OnlineLecture")
        }
        else {
            handleActivityList("")
        }

    };

    return (
        <div className={`${maxScreen ? "fixed w-full h-full top-0 left-0 z-20" : "w-full h-[5vh] rounded-[1vw] h-auto"} text-white bg-gray-200`}>
      <div
        className={`w-full h-[5vh] bg-gray-700 flex gap-x-[0.4vw] px-4 justify-between items-center cursor-pointer
${selectedActivity === "OnlineLecture" ? `${maxScreen ? "":"rounded-t-lg"}` : "rounded-lg"}
`}
                onClick={!maxScreen ? (() => handleActivityList("OnlineLecture")) : undefined}
            >
                <div className='flex gap-x-[1vw] justify-start items-center'>
                    <FontAwesomeIcon
                        className={`w-6 h-6 duration-300 ${selectedActivity === "OnlineLecture" ? "rotate-180" : "rotate-0"}`}
                        icon={faCaretDown}
                        style={{ color: '#fff' }}
                    />
                    <p className='text-lg'>Online Lecture Management</p>
                </div>
                <div>
                    <button className="w-6 h-6 hover:scale-110 duration-300 text-white outline-none" onClick={() => maxScreenHandler()}>
                        {maxScreen ? <FontAwesomeIcon className="w-full h-full " icon={faCompress} /> :
                            <FontAwesomeIcon className="w-full h-full " icon={faExpand} />}
                    </button>
                </div>
            </div>
            {selectedActivity === "OnlineLecture" &&
                <OnlineLectureSub OnlineLectureHandler={OnlineLectureHandler}/>
            }
        </div>
    )
}

export default OnlineLecture