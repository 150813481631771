import React from 'react'
import PaymentHistory from '../../../components/employeeComp/Dashboard/PaymentHistory'

const Payment = ({PaidModalHandler,PendingModalHandler}) => {
  return (
    <div>
      <PaymentHistory
      PaidModalHandler={PaidModalHandler}
      PendingModalHandler={PendingModalHandler}/>
    </div>
  )
}

export default Payment
