import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import Logo from '../../../../assets/images/LogoLCWU.png'


// const generateDepartmentName = (departments, departmentCode) => {
//     if (!departments || !Array.isArray(departments)) {
//         console.error('Invalid departments array.');
//         return "";
//     }
//     const department = departments.find(dept => dept.deptCode === departmentCode);
//     return department ? department.department : "";
// };
// const generateProgramTitle = (allProgram,programCode) => {
//     if (!allProgram || !Array.isArray(allProgram)) {
//         console.error('Invalid programs array.');
//         return "";
//     }
//     const program = allProgram.find(prog => prog.programCode === programCode);
//     return program ? program.programName + " ["+program.programCode+"]" : "";
// };

const Item = ({ department, index, AllDeptData,allProgram }) => {
    // const departmentName = generateDepartmentName(AllDeptData, department.department);

    return (
    <View style={styles.itemContainer}>
        <View style={styles.header}>
            <View style={styles.startHeader}>
                <Text style={styles.subHeading}>{index + 1}.  {department.weekNo}</Text>
            </View>
            <View style={styles.middleHeader}>
                <Text style={styles.subHeading}>{(allProgram,department.topic)}</Text>
            </View>
            <View style={styles.endHeader}>
                <Text style={styles.subHeading}>Status: {department.status ? 'Active' : 'Inactive'}</Text>
            </View>
        </View>
        <View style={styles.containerBody}>

            <View style={styles.containerFirst}>
                <View style={styles.textAlign1}>
                <Text style={styles.paraText}>1. Type: </Text>
                    {department.type && <Text style={styles.contentText1}>{department.type}</Text>}
                </View> 
                <View style={styles.textAlign2}>
                    <Text style={styles.paraText}>2. CLO: </Text>
                    {department.CLO && <Text style={styles.contentText}>{department.CLO}</Text>}
                </View>
                <View style={styles.textAlign3}>
                    <Text style={styles.paraText}>3. Weightage: </Text>
                    {department.weightage && <Text style={styles.contentText}>{department.weightage}</Text>}
                </View>
            </View>
        </View>
    </View>
 );
};

const getCurrentDateTime = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
    const formattedTime = currentDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    return { date: formattedDate, time: formattedTime };
};

const PDFDocument = ({ data,AllDeptData,allProgram,allClasses }) => {
    const { date, time } = getCurrentDateTime();

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.headerContainer}>
                    <View style={styles.logoContainer}>
                        <Image style={styles.logo} src={Logo} />
                    </View>
                    <View style={styles.middleHeadings}>
                        <Text style={styles.heading}>Lahore College for Women University</Text>
                        <Text style={styles.subHeading}>Class List</Text>
                    </View>

                    <View style={styles.dateTimeContainer}>
                        <Text style={styles.subHeading}>{date}</Text>
                        <Text style={styles.subHeading}>{time}</Text>
                    </View>

                </View>
                <View style={styles.departmentsContainer}>
                    {data.map((department, index) => (
                        <Item key={index} department={department} index={index} AllDeptData={AllDeptData} allClasses={allClasses} allProgram={allProgram} />
                    ))}
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    page: {
        padding: 20,
        backgroundColor: '#ffffff',
    },
    headerContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-center',
        justifyContent: 'space-between',
        marginBottom: 30,
    },
    logoContainer: {
        width: '20%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-center',
    },
    logo: {
        width: 110,
        height: 70,
    },
    middleHeadings: {
        width: '60%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dateTimeContainer: {
        width: '20%',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    heading: {
        fontSize: 16,
        fontWeight: '800',
    },
    subHeading: {
        fontSize: 12,
        fontWeight: '600',
    },
    paraText: {
        fontSize: 10,
        fontWeight: '800',
    },
    departmentsContainer: {
        marginTop: 20,
    },
    itemContainer: {
        marginBottom: 20,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        padding: 10,
        backgroundColor: '#f2f2f2',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    startHeader: {
        width: '25%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-center',
    },
    middleHeader: {
        width: '60%',
        alignItems: 'start',
        justifyContent: 'center',
    },
    endHeader: {
        width: '15%',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    meta: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    containerBody: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '5px',
        padding: '10px',
    },
    containerFirst: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    textAlign1: {
        width: '20%',
        flexDirection: 'row', 
        rowGap: '5px',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },   
    textAlign2: {
        width: '20%',
        flexDirection: 'row',
        rowGap: '5px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    textAlign3: {
        width: '60%',
        flexDirection: 'row',
        rowGap: '5px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    contentText: {
        fontSize: 8,
        fontWeight: '400',
        textAlign: 'left',
    },
    contentText1: {
        fontSize: 10,
        fontWeight: '800',
        textAlign: 'left',
    },
    containerThird: {
        flexDirection: 'row',
        rowGap: '5px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },


});


export default PDFDocument;
